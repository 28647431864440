export const styles: DMCardStyles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: '15px',
    paddingRight: '20px',
    minHeight: '64px',
    maxHeight: '93px',
    width: "100%",
    gap: '10px',
    cursor: 'pointer'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    flex: 1,
  },
  customerName: {
    color: '#fff',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '-0.28px'
  },
  service: {
    color: '#fff',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-0.12px'
  },
  textMessage: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    color: '#C0C0C0',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-0.12px'
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    minWidth: '56px',
    maxWidth: '60px',
    height: 'auto'
  }


}

interface DMCardStyles {
  container: React.CSSProperties;
  textContainer: React.CSSProperties;
  customerName: React.CSSProperties;
  service: React.CSSProperties;
  textMessage: React.CSSProperties;
  dateContainer: React.CSSProperties;
}
