import axios from 'axios';
import { getHostPythonAPIBaseUrl } from "config";
// const { IP_BACKEND_HOST } = require('../config/env');

class ActivityLogsRequests {
  async getPagination(page: number, pageSize: number, search: string | null) {
    let url = `${getHostPythonAPIBaseUrl()}/v1/activity/log/read-many?page=${page}&limit=${pageSize}`;

    if(search){
      url += `&search=${search}`;
    }

    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(url, {
        headers: {
          "Authorization": token
        }
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }

  
}
export default ActivityLogsRequests;
