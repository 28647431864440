import React, { useState } from "react";
import { EventStreamRequest } from "./notifier/notifier_pb"; 
import { Box, Button, TextField, Typography } from "@mui/material";
import { getHostPythonGrpcBaseUrl } from "config";
import { NotifierClient } from "./notifier/NotifierServiceClientPb";

const StreamNotifyEventsInterface = () => {
  const [subscription, setSubscription] = useState<string>("");
  const [messages, setMessages] = useState<string[]>([]);

  const client = new NotifierClient(getHostPythonGrpcBaseUrl(), null, null);

  const handleStreamEvents = () => {
    const request = new EventStreamRequest();
    request.setUserid(subscription);

    const stream = client.streamEvents(request, {});
    stream.on("data", function (response) {
      setMessages((prev) => [
        ...prev,
        `Title: ${response.getTitle()}, Message: ${response.getMessage()}, User ID: ${response.getUserid()}, Created: ${response.getCreated()}`,
      ]);
    });

    stream.on("error", function (err) {
      console.error("Stream error:", err);
    });

    stream.on("end", function () {
      console.log("Stream ended.");
    });
  };

  return (
    <Box display="flex" flexDirection="column" mb={4}>
      <Typography mb={2} variant="h3">
        Server Streaming gRPC Test{" "}
      </Typography>
      <Box display="flex" mb={2}>
        <TextField
          type="text"
          value={subscription}
          onChange={(e) => setSubscription(e.target.value)}
          label="Subscription"
          variant="filled"
        />
        &nbsp;&nbsp;
        <Button
          style={{
            background: "#FF7C06",
            textTransform: "none",
            paddingRight: "16px",
            paddingLeft: "16px",
            width: "124px",
          }}
          onClick={handleStreamEvents}
        >
          Start Streaming
        </Button>
      </Box>
      {messages.length > 0 && (
        <Box display="flex" flexDirection="column">
          <b>Messages from server:</b>
          {messages.map((msg, index) => (
            <p key={index}>{msg}</p>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default StreamNotifyEventsInterface;
