import {
   SelectChangeEvent,
} from "@mui/material";
import ReviewRequests from "requests/reviewRequests";

type Column = {
    field: string;
    headerName: string;
    flex: number;
}


export default class ReviewsViewModel {
    private _serviceType: string = "all"; // The type of service being filtered
    private _page: number = 1; // The current page number
    private _pageSize: number = 8; // Number of items per page
    private _totalPages: number = 1; // Total number of pages
    private _data: any[] = []; // Array to hold the review data
    private _isLoading: boolean = true; // Flag indicating whether data is loading
    private _rowId: any = null; // Placeholder for selected row ID
    private _columns: Column[] = []; // Array to hold the column definitions
    private _pageSizes: number[] = [8, 16, 32, 64]; 
    private _reviewRequests = new ReviewRequests();
    // Define getters and setters for each attribute
  
    // ... Same pattern for other attributes ...
  
    // Getters for computed attributes or derived values
  
    constructor() {
      // Initialize the class properties with default values or null
      this._columns = [
        {
          field: "_id",
          headerName: "Review ID",
          flex: 0.2,
        },
        {
          field: "user",
          headerName: "User ID",
          flex: 0.2,
        },
        {
          field: "specialist",
          headerName: "Specialist ID",
          flex: 0.2,
        },
        {
          field: "service",
          headerName: "Service ID",
          flex: 0.2,
        },
        {
          field: "serviceType",
          headerName: "Service Type",
          flex: 0.2,
        },
        {
          field: "rating",
          headerName: "Rating",
          flex: 0.2,
        },
        {
          field: "date",
          headerName: "Date",
          flex: 0.2,
        },
      ];
  
      this._pageSizes = [8, 16, 32, 64];
    }
     // Define getters and setters for other attributes...
  
    // Setters
    set serviceType(value:string) {
      this._serviceType = value;
    }

    set page(value:number) {
      this._page = value;
    }
    set pageSize(value:number) {
        this._pageSize = value;
    }

    set isLoading(value:boolean) {
        this._isLoading = value;
    }

    set rowId(value:any) {
        this._rowId = value;
    }

    set data(value:any) {
        this._data = value;
    }

    set totalPages(value:number) {
        this._totalPages = value;
    }
    
    
    // Getters for computed attributes or derived values
    get serviceType() : string {
        return this._serviceType;
    }

    get isLoading() : boolean {
        return this._isLoading;
    }
  
    get columns() : Column[] {
      return this._columns;
    }
    get rowId() : any {
        return this._rowId;
    }

    get data():any {
        return this._data;
    }
    
  
    get pageSizes() : number[] {
      return this._pageSizes;
    }
    get pageSize(): number {
        return this._pageSize;
      }
    get page(): number {
        return this._page;
    }
    get totalPages(): number {
        return this._totalPages;
    }

    //Methods 

    handleRowsPerPageChange = (event: SelectChangeEvent<number>): void => {
        if (isNaN(event.target.value as number)) return;
        this.pageSize = event.target.value as number;
      };
    
     handlePageChange = (newPage: number): void => {
        this.page =newPage;
      };
    
     handleServiceChange = (type: string): void => {
        this.serviceType = type;
        this.page = 1;
      }
      handleFetch = async (): Promise<void> => {
        
        try {
            const paginationData = await this._reviewRequests.getPagination(
              this.serviceType,
              this.page,
              this.pageSize
            );
            this._data = paginationData.reviews;
            this._totalPages = paginationData.totalPages;
            this._isLoading = false;
        
            // Process the paginationData here
           ;
          } catch (error) {
            // Handle the error here
            console.error(error);
            this._isLoading = false;
          }
    }
}