export const DEFAULT_VEHICLE_IMG: string =
  "https://res.cloudinary.com/dtrv9pha8/image/upload/v1692741394/black-car-icons-9_arg97k.png";
export const DEFAULT_PROFILE_IMG: string =
  "https://res.cloudinary.com/dtrv9pha8/image/upload/v1692727400/account-profile-icon-2_odt4x2.png";


  export const ACTIVITY_LOGS_ACTIONS = [
    "log-in",
   
  ];
  

export const SERVICE_TYPE_VALUES = [
  "lightDuty",
  "heavyDuty",
  "refuel",
  "jump",
  "unlock",
  "tireChange",
  "basicWash",
  "standardWash",
  "deluxeWash",
  "premiumWash",
];



export const SERVICE_NAME_OPTIONS = [
  "battery",
  "carWash",
  "motorcycleWash",
  "refuel",
  "tireChange",
  "unlock",
  "towing",
].sort();

export const SERVICE_WASH_NAME_OPTIONS = ["carWash", "motorcycleWash"].sort();
export const SERVICE_ROADSIDE_NAME_OPTIONS = [
  "battery",
  "refuel",
  "tireChange",
  "unlock",
  "towing",
].sort();

export const SERVICE_OPTIONS = ["roadside", "wash"].sort();

export const VARIANTS_OPTIONS = [
  "basic",
  "deluxe",
  "diesel",
  "flatbed",
  "heavy",
  "jammedLocks",
  "jump",
  "lockedOut",
  "lostKeys",
  "midgradeGasoline",
  "newTire",
  "premium",
  "premiumGasoline",
  "regularGasoline",
  "replacement",
  "spareTire",
  "standard",
].sort();

export const VARIANTS_WASH = [
  "standard",
  "basic",
  "deluxe",
  "premium",

].sort();


export const VARIANTS_BATTERY = [
  "jump",
  "replacement",
].sort();

export const VARIANTS_TIRE_CHANGE = [
  "replacement",
  "spareTire",
  "newTire",
].sort();

export const VARIANTS_TOWING = [
  "flatbed",
  "heavy",
  "standard",
].sort();

export const VARIANTS_REFUEL = [
  "premiumGasoline",
  "regularGasoline",
  "midgradeGasoline",
  "diesel"
].sort();

export const VARIANTS_UNLOCK = [
  "jammedLocks",
  "lockedOut",
  "lostKeys",
].sort();

export const SERVICE_TYPE_VALUES_ALL = ["all", ...SERVICE_TYPE_VALUES];

export const TOWING_TYPES_VALUES = [
  "flatbedTow",
  "standardTow",
  "heavyDuty",
  "lightDuty",
];

export const COMPANY_CATEGORIES = ["roadside assistance", "carwash"];

export const COMPANY_TYPE_VALUES = [
  "all",
  "towing company",
  "heavy duty",
  "roadside assistance",
  "mobile mechanics",
  "locksmith",
  "wheel alignment",
  "battery replacement",
  "carwash",
];

export const COMPANY_AMOUNT = ["0", "10", "20", "40", "50", "75", "100"];

export const REQUEST_RADIUS_CHECKBOX_DATE_OPTIONS = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "lastWeek",
    label: "Last week",
  },
  {
    value: "lastMonth",
    label: "Last month",
  },
  {
    value: "lastYear",
    label: "Last year",
  },
];
export const RADIUS_CHECKBOX_CHAT_OPTIONS = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "clients",
    label: "Clients",
  },
  {
    value: "specialists",
    label: "Specialists",
  },
  {
    value: "companies",
    label: "Companies",
  },
];

export const DUMMY_INFO_COMPANY_VEHICLES = [
  {
    vehicleImg:
      "https://cdn.salaodocarro.com.br/_upload/carros/2020/05/31/ford-fusion-2008-preto-211807-0_thumb.jpg",
    make: "Toyota",
    model: "Corolla",
    year: "2020",
    plateNumber: "TN-1234",
    vin: "DUMMYDATA",
    workerName: "DUMMYDATA",
    workerProfileImg:
      "https://cdn.salaodocarro.com.br/_upload/carros/2020/05/31/ford-fusion-2008-preto-211807-0_thumb.jpg",
  },
  {
    vehicleImg:
      "https://cdn.salaodocarro.com.br/_upload/carros/2020/05/31/ford-fusion-2008-preto-211807-0_thumb.jpg",
    make: "Toyota",
    model: "Corolla",
    year: "2020",
    plateNumber: "TN-1234",
    vin: "DUMMYDATA",
  },
  {
    vehicleImg:
      "https://cdn.salaodocarro.com.br/_upload/carros/2020/05/31/ford-fusion-2008-preto-211807-0_thumb.jpg",
    make: "Toyota",
    model: "Corolla",
    year: "2020",
    plateNumber: "TN-1234",
    vin: "DUMMYDATA",
  },
];

export const DIALOG_BUTTONS_DELETE_CHAT = ["Cancel", "Delete"];
export const DIALOG_BUTTONS_NEW_CHAT = ["Reject", "Accept"];

export const CHAT_TAB_OPTIONS = ["Active", "Inactive"];
export const ADD_SERVICES_TAB_OPTIONS = ["Information", "Service Request"];
export const ADD_COMPANY_TAB_OPTIONS = ["Basic", "Other"];
export const ADD_SERVICES_REFUEL_OPTIONS = ["Unleaded", "Premium", "Diesel"];
export const ADD_SERVICES_TOWING_GEAR_OPTIONS = ["Yes", "No"];


export const COMPANY_QUERY = [
  'all',
  'battery_replacement',
  'carwash',
  'heavy_duty',
  'locksmith',
  'mobile_mechanics',
  'oil_change',
  'roadside_assistance',
  'towing_company',
  'wheel_alignment'
].sort();

export const COMPANY_QUERIES = [

  'battery_replacement',
  'carwash',
  'heavy_duty',
  'locksmith',
  'mobile_mechanics',
  'oil_change',
  'roadside_assistance',
  'towing_company',
  'wheel_alignment'
].sort();

export const STATE_VALUES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
  "DC", // District of Columbia
];



export const BODY_STYLES: string[] = ["SUV", "Sedan", "Pickup", "Coupe", "Convertible", "Hatchback","Van/Minivan"];


export const all_years = (): number[] => {
  const currentYear = new Date().getFullYear();
  const startYear = 1900;
  const years: number[] = [];

  for (let year = currentYear + 3; year >= startYear; year--) {
    years.push(year);
  }

  return years;
};


export const BRANDS = [
  "Abarth",
  "Alfa Romeo",
  "Aston Martin",
  "Audi",
  "Bentley",
  "BMW",
  "Bugatti",
  "Cadillac",
  "Chevrolet",
  "Chrysler",
  "Citroën",
  "Dacia",
  "Daewoo",
  "Daihatsu",
  "Dodge",
  "Donkervoort",
  "DS",
  "Ferrari",
  "Fiat",
  "Fisker",
  "Ford",
  "Honda",
  "Hummer",
  "Hyundai",
  "Infiniti",
  "Iveco",
  "Jaguar",
  "Jeep",
  "Kia",
  "KTM",
  "Lada",
  "Lamborghini",
  "Lancia",
  "Land Rover",
  "Landwind",
  "Lexus",
  "Lotus",
  "Maserati",
  "Maybach",
  "Mazda",
  "McLaren",
  "Mercedes-Benz",
  "MG",
  "Mini",
  "Mitsubishi",
  "Morgan",
  "Nissan",
  "Opel",
  "Peugeot",
  "Porsche",
  "Renault",
  "Rolls-Royce",
  "Rover",
  "Saab",
  "Seat",
  "Skoda",
  "Smart",
  "SsangYong",
  "Subaru",
  "Suzuki",
  "Tesla",
  "Toyota",
  "Volkswagen",
  "Volvo"
]