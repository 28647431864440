import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

import "./style.css";
import { grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { getHostPythonAPIBaseUrl } from "config";
const styles = {
  title: {
    color: "var(--dark-white-white-900, #FFF)",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px" /* 150% */,
    letterSpacing: "-0.4px",
  },
  subtitle: {
    color: "var(--gray-gray-900, #7E7E7E)",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.12px",
  },
  picture: {
    width: 50,
    height: 50,
    borderRadius: "50%",
  },
  tabDefault: {
    width: "25%",
    display: "flex",
    justifyContent: "center",
    borderBottom: "2px solid #fff",
    paddingBottom: 8,
  },
  buttonTab: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "-0.32px",
    color: "#fff",
    margin: "0 auto",
    background: "transparent",
    textDecoration: "none",
    cursor: "pointer",
    width: "100%",
    border: "none",
  },
  value: {
    color: "var(--dark-white-white-900, #FFF)",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px" /* 187.5% */,
    letterSpacing: "-0.32px",
  },
  label: {
    color: "var(--gray-gray-900, #c0c0c0)",
    fontFamily: "Inter",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.12px",
  },
  buttomDispatch: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: "25px",
    backgroundColor: "#FF7C06",
    borderRadius: "16px",
    padding: "13px 16px",
    width: "168px",
  },
  buttomDispatchText: {
    fontSize: "15px",
    fontWeight: 600,
    letterSpacing: "-0.15px",
    color: "black",
  },
};

type Props = {
  open: boolean;
  handleClose: (event: any) => void;
  option: string;
};

const AddPaymentDialog = ({ open, handleClose, option }: Props) => {
  const [payment, setPayment] = useState<any>({
    amount: 50,
    currency: "usd",
    automatic_payment_methods: {
      enabled: true,
      allow_redirects: "never",
    },
    payment_method: "card_1OJegaEoiwHvWuhAbwyTpGVL",
    confirm: true,
    customer: "cus_P7uV1jNe4olH3C",
  });

  const [payout, setPayout] = useState<any>({
    amount: 50,
    currency: "usd",
    description: "",
    statement_descriptor: "",
    destination: "ba_1IBQPpEoiwHvWuhAnRvGAym4",
  });

  const handleCloseDialog = (event: any) => {
    handleClose(event);
  };

  const handleInputPaymentStrChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    prop: string
  ) => {
    const newValue = event.target.value as string;
    setPayment((prevPayment: any) => ({
      ...prevPayment,
      [prop]: newValue,
    }));
  };

 

  const handleInputPayoutStrChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    prop: string
  ) => {
    const newValue = event.target.value as string;
    setPayout((prevPayment: any) => ({
      ...prevPayment,
      [prop]: newValue,
    }));
  };

  const handleInputPaymentNumberChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    prop: string
  ) => {
    const newValue = event.target.value as Number;
    setPayment((prevPayment: any) => ({
      ...prevPayment,
      [prop]: newValue,
    }));
  };

  const handleInputPayoutNumberChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    prop: string
  ) => {
    const newValue = event.target.value as Number;
    setPayout((prevPayment: any) => ({
      ...prevPayment,
      [prop]: newValue,
    }));
  };

  const handleSave = async (event: any) => {
    let url =  `${getHostPythonAPIBaseUrl()}/stripe/payout`;
    let  payload = payout

    if(option === "payment") {
      url = `${getHostPythonAPIBaseUrl()}/stripe/payment`;
      payload = payment
    } 

    try {
      await axios.post(url, payload, {
        headers: {
          Authorization: localStorage.getItem("access_token"),
        },
      });
      alert(`${option} has been saved successfully`);
      handleCloseDialog(event);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        style: {
          background: "#191919",
          padding: "20px",
          minHeight: "618px",
          minWidth: "483px",
          borderRadius: "8px",
          border: "1px solid rgba(126, 126, 126, 0.60)",
        },
      }}
      fullWidth
      maxWidth={"md"}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          objectFit: "cover",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <DialogTitle style={{ display: "flex", alignContent: "center" }}>
            <Typography ml={2} style={styles.title} sx={styles.subtitle}>
              Add {option}
            </Typography>
          </DialogTitle>
        </div>
        <DialogActions style={{ marginBottom: "20px" }}>
          <Button
            variant="text"
            onClick={handleCloseDialog}
            sx={{ color: grey[50] }}
          >
            <CloseIcon />
          </Button>
        </DialogActions>
      </div>

      <DialogContent>
        {option === "payment" && (
          <Box display={"flex"} flexDirection={"column"} mb={2}>
            <TextField
              label="Amount in cents"
              variant="outlined"
              fullWidth
              color={"secondary"}
              sx={{ mb: 3 }}
              value={payment.amount}
              onChange={(event: any) =>
                handleInputPaymentNumberChange(event, "amount")
              }
            />
            <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
              <InputLabel htmlFor="currency-select">Currency</InputLabel>
              <Select
                label="Currency"
                fullWidth
                color={"secondary"}
                labelId="currency-select"
                id="currency-select"
                value={payment.currency}
                onChange={(event: any) =>
                  handleInputPaymentStrChange(event, "amount")
                }
              >
                <MenuItem value="usd">USD</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Description"
              variant="outlined"
              defaultValue=""
              fullWidth
              color={"secondary"}
              value={payment.payment_method}
              onChange={(event: any) =>
                handleInputPaymentStrChange(event, "payment_method")
              }
              sx={{ mb: 3 }}
            />
            <TextField
              label="Customer"
              variant="outlined"
              defaultValue="cus_P7uV1jNe4olH3C"
              fullWidth
              color={"secondary"}
              value={payment.customer}
              onChange={(event: any) =>
                handleInputPaymentStrChange(event, "customer")
              }
              sx={{ mb: 3 }}
            />
          </Box>
        )}

        {option === "payout" && (
          <Box display={"flex"} flexDirection={"column"} mb={2}>
            <TextField
              label="Amount in cents"
              variant="outlined"
          
              fullWidth
              color={"secondary"}
              sx={{ mb: 3 }}
              value={payout.amount}
              onChange={(event: any) =>
                handleInputPayoutNumberChange(event, "amount")
              }
            />
            <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
              <InputLabel htmlFor="currency-select">Currency</InputLabel>
              <Select
                label="Currency"
                fullWidth
                color={"secondary"}
                labelId="currency-select"
                id="currency-select"
                value={payout.currency}
                onChange={(event: any) =>
                  handleInputPayoutStrChange(event, "amount")
                }
              >
                <MenuItem value="usd">USD</MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="Description"
              variant="outlined"
              defaultValue=""
              fullWidth
              color={"secondary"}
              value={payout.description}
              onChange={(event: any) =>
                handleInputPayoutStrChange(event, "description")
              }
              sx={{ mb: 3 }}
            />
            <TextField
              label="Statement Descriptor"
              variant="outlined"
              defaultValue=""
              fullWidth
              color={"secondary"}
              value={payout.statement_descriptor}
              onChange={(event: any) =>
                handleInputPayoutStrChange(event, "statement_descriptor")
              }
              sx={{ mb: 3 }}
            />
             <TextField
              label="Destination"
              variant="outlined"
              defaultValue=""
              fullWidth
              color={"secondary"}
              value={payout.destination}
              onChange={(event: any) =>
                handleInputPayoutStrChange(event, "destination")
              }
              sx={{ mb: 3 }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          style={{ backgroundColor: "#ff7c06", color: "black" }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPaymentDialog;
