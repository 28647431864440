import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Box,
  InputAdornment,
  Button,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

type Props = {
  onChange: any;
  onCustomerFilter: any;
  width?: string;
};

const DateFilter: React.FC<Props> = ({ onChange, onCustomerFilter, width = "100%" }) => {
  const [options, setOptions] = useState<any[]>([
    "All",
    "Custom",
    "Yesterday",
    "This week (Sunday — Today)",
    "Last week",
    "Last 7 days",
    "Last 14 days",
    "Last 28 days",
    "Last 30 days",
    "This month (01 — Today)",
    "Last month",
    "Last 45 days",
    "Last 60 days",
    "Last 90 days",
    "Last 12 months",
    "This year (Jan — Today)",
    "Last year",
  ]);
  const [label, setLabel] = useState<string>("Date filter");
  const [value, setValue] = useState<string>("All");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("00:00:00");
  const [endTime, setEndTime] = useState("23:59:59");
  const [timestampStart, setTimestampStart] = useState<number>(0);
  const [timestampEnd, setTimestampEnd] = useState<number>(0);

  const handleSelectOptionChange = (
    event: React.ChangeEvent<{}>,
    newValue: any
  ) => {
    setValue(newValue);
    onChange(newValue);
  };
  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
  };

  const handleStartTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartTime(event.target.value);
  };

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndTime(event.target.value);
  };

  const handleCustomFilterClick = () => {
    const selectedStartDateTime = new Date(`${startDate}T${startTime}`);
    const startTimestamp = Math.floor(selectedStartDateTime.getTime() / 1000);
    const selectedEndDateTime = new Date(`${endDate}T${endTime}`);
    const endTimestamp = Math.floor(selectedEndDateTime.getTime() / 1000);
    setTimestampStart(startTimestamp);
    setTimestampEnd(endTimestamp);
    onCustomerFilter(startTimestamp,endTimestamp)
  };

 
  return (
    <Box
      style={{
        marginTop: "8px",
        padding: "0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {label && (
        <div
          style={{
            fontSize: "14px",
            fontWeight: 400,
            marginBottom: "7px",
          }}
        >
          {label}
        </div>
      )}
      <Autocomplete
        options={options}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name
        }
        onChange={handleSelectOptionChange}
        value={value}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={"Search..."}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarMonthIcon></CalendarMonthIcon>
                </InputAdornment>
              ),
            }}
            style={{
              width: `${!width ? "275px" : width}`,
              height: "50px",
              padding: "0px",
              borderRadius: "6px",
              backgroundColor: "#191919",
              fontSize: "11px",
            }}
          />
        )}
      />
      {value === 'Custom' && (
        <div style={{ marginTop: 12 }}>
          <div>
            <label>Start Datetime</label>
            <br />
            <input
              style={{ border: "1px solid #c0c0c0",color:"black"  }}
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
            />
            <input
              style={{ border: "1px solid #c0c0c0",color:"black"  }}
              type="time"
              value={startTime}
              onChange={handleStartTimeChange}
            />
          </div>
          <br />
          <div>
            <label>End Datetime</label>
            <br />
            <input
              type="date"
              style={{ border: "1px solid #c0c0c0",color:"black" }}
              value={endDate}
              onChange={handleEndDateChange}
            />
            <input
              type="time"
              style={{ border: "1px solid #c0c0c0",color:"black"  }}
              value={endTime}
              onChange={handleEndTimeChange}
            />
            <br />
          </div>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 12, border: "1px solid white" }}
            onClick={handleCustomFilterClick}
          >
            Filter
          </Button>
        </div>
      )}
    </Box>
  );
};

export default DateFilter;
