import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import UserRequests from "requests/userRequests";
import { Box, Checkbox, FormControlLabel } from "@mui/material";

interface DialogProps {
  open: boolean;
  onClose: () => void;
  filter: any;
  logicOperator: string;
}

const userRequests = new UserRequests();

const DialogNotifier: React.FC<DialogProps> = ({
  open,
  onClose,
  filter,
  logicOperator,
}) => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const [deviceTokens, setDeviceTokens] = useState<any[]>([]);
  const [mails, setMails] = useState<any[]>([]);
  const [phoneNumbers, setPhoneNumbers] = useState<any[]>([]);
  const [sendSMS, setSendSMS] = useState(false);
  const [sendPush, setSendPush] = useState(true); // Example default
  const [sendEmail, setSendEmail] = useState(false);

  const handleClose = () => {
    onClose();
    setTitle("");
    setMessage("");
    setSendSMS(false);
    setSendPush(false);
    setSendEmail(false);
  };

  useEffect(() => {
    setTitle("");
    setMessage("");
    setSendSMS(false);
    setSendPush(true); // Example default
    setSendEmail(false);

    const fetchData = async () => {
      const paginationData = await userRequests.getPaginationGraphQL(
        filter,
        1,
        null,
        logicOperator
      );
      setData(paginationData.payload);
      processAllData(paginationData.payload);
    };

    if (open) {
      setIsLoading(true);
      fetchData();
    }
  }, [open]);

  const processAllData = (users: any[]) => {
    const devices = [];
    const emails = [];
    const phones = [];
    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      if (user.oneSignalDeviceTokens.length > 0) {
        const token =
          user.oneSignalDeviceTokens[user.oneSignalDeviceTokens.length - 1]
            .token;
        if (isValidToken(token)) {
          devices.push(token);
        }
      }
      const phone = `+${user.phoneNumber}`
   

      if(isValidPhone(phone)) {
        phones.push(phone);
      }

      if(isValidEmail(user.email)) {
         emails.push(user.email);
      }
     
    }

    setDeviceTokens(devices);
    setMails(emails);
    setPhoneNumbers(phones);
    setIsLoading(false);
  };

  const isValidEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }
  

  const isValidPhone = (phone: string) => {
    const regex = /^\+1[2-9]\d{2}[2-9](?!11)\d{6}$/;
    return regex.test(phone);
  }
  

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      // Collect all promises in an array
      const notificationPromises = [];

      if (sendSMS) {
        notificationPromises.push(
          userRequests.sendSMSNotifications(message, phoneNumbers)
        );
      }
      if (sendPush) {
        notificationPromises.push(
          userRequests.notifyOneSignal(title, message, deviceTokens)
        );
      }
      if (sendEmail) {
        notificationPromises.push(
          userRequests.sendEmailNotifications(title, message, mails)
        );
      }

      // Execute all promises concurrently
      await Promise.all(notificationPromises);

      setTitle("");
      setMessage("");
      setSendSMS(false);
      setSendPush(false);
      setSendEmail(false);

      setIsLoading(false);
      handleClose();
    } catch (error) {
      alert("Error on submit notify many");
      setIsLoading(false);
    }
  };

  const isValidToken = (token: string) => {
    const pattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return token.match(pattern);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Notifier</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            <Box display="flex" mb={1}>
              <b>Total emails: &nbsp;</b> <span>{mails.length}</span>
            </Box>

            <Box display="flex" mb={1}>
              <b>Total Phones: &nbsp;</b> <span>{phoneNumbers.length}</span>
            </Box>

            <Box display="flex" mb={2}>
              <b>Total Device Tokens: &nbsp;</b>{" "}
              <span>{deviceTokens.length}</span>
            </Box>

            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Title"
              color="secondary"
              type="text"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              margin="dense"
              id="message"
              label="Message"
              color="secondary"
              type="text"
              fullWidth
              multiline
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            {/* Checkboxes for notification options */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendSMS}
                  onChange={(e) => setSendSMS(e.target.checked)}
                  color="secondary"
                />
              }
              label="SMS"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendPush}
                  onChange={(e) => setSendPush(e.target.checked)}
                  color="secondary"
                />
              }
              label="Push Notification"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendEmail}
                  onChange={(e) => setSendEmail(e.target.checked)}
                  color="secondary"
                />
              }
              label="Email"
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="warning"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogNotifier;
