import React, { useState, useEffect, useMemo } from "react";
import {
  useTheme,
  Box,
  Pagination,
  Select,
  MenuItem,
  SelectChangeEvent,
  Avatar,
  Typography,
  Fab
} from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import ServiceRequests from "requests/serviceRequests";
import CompanyRequests from "requests/companyRequests";
import {
  AssistanceInquiry,
  Location,
} from "models/assistanceInquiryResponseTypes";
import { DEFAULT_PROFILE_IMG, SERVICE_TYPE_VALUES } from "../../utils/const";
import { getDateFromString, formattedDate } from "utils/helper";
import jumpIcon from "../../assets/icons/services/jumpSelected.svg";
import refuelIcon from "../../assets/icons/services/refuelSelected.svg";
import tireChangeIcon from "../../assets/icons/services/tireChangeSelected.svg";
import towingIcon from "../../assets/icons/services/towingSelected.svg";
import unlockIcon from "../../assets/icons/services/unlockSelected.svg";
import Map from "scenes/Map/Map";
import Dropdown from "scenes/companyMagangement/component/dropdown";
import RequestActions from "./RequestActions";
import StaticMap from "components/StaticMap";
import { REQUEST_RADIUS_CHECKBOX_DATE_OPTIONS } from "../../utils/const";
import RadiusCheckbox from "components/RadiusCheckbox";
import { Link } from "react-router-dom";

const debounce = (fn: any, delay: any) => {
  let timeoutId: any;
  return (...args: any) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

const ServiceRequest = () => {
  const theme = useTheme();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(32);
  const [totalPages, setTotalPages] = useState<number>(32);
  const [data, setData] = useState<AssistanceInquiry[]>([]);
  const [secondData, setSecondData] = useState<AssistanceInquiry[]>([]);
  const [previousData, setPreviousData] = useState<AssistanceInquiry[]>([]);
  const [markers, setMarkers] = useState<any[]>([]);
  const [secondMarkers, setSecondMarkers] = useState<any[]>([]);
  const [previousMarkers, setPreviousMarkers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [serviceType, setServiceType] = useState<string>("all");
  const [selectedDate, setSelectedDate] = useState<string>("all");
  const pageSizes: number[] = [64, 32, 16, 8];
  const [updated, setUpdated] = useState<boolean>(false);
  const [rowId, setRowId] = useState<any>(null);
  // const [pollingInterval, setPollingInterval] = useState<number>(1000000);
  const [customerType, setCustomerType] = useState<string>("clients");
  const [companiesData, setCompaniesData] = useState<any[]>([]);

  const fetchData = async (isData: boolean, page: number) => {
    const serviceRequests = new ServiceRequests();
    try {

      // console.log("selected date: " + selectedDate);
      let paginationData = await serviceRequests.getPaginationAssistanceInquiry(
        isData ? page : page + 1,
        pageSize,
        serviceType,
        selectedDate
      );

      if (paginationData.payload === null) {
        // Handle the case when payload is null
        if (isData) {
          setData([]); // Clear the data
          setTotalPages(0);
        } else {
          setSecondData([]); // Clear the secondData
        }
        setIsLoading(false);
      } else {
        if (isData) {
          setData(paginationData.payload as AssistanceInquiry[]);
          setTotalPages(paginationData.totalPages);
          setIsLoading(false);
        } else {
          setSecondData(paginationData.payload as AssistanceInquiry[]);
        }

        paginationData.payload.forEach((inquiry: any) => {
          // rename variables to make simpler code on other components
          if (isData) {
            setMarkers((prev) => {
              return [
                ...prev,
                {
                  _id: inquiry._id + " (marker) " + Date.now(),
                  latitude: inquiry.serviceLocation?.coordinates.latitude,
                  longitude: inquiry.serviceLocation?.coordinates.longitude,
                  specialty: inquiry.specialty,
                  name: inquiry.customer.name,
                  address: inquiry.serviceLocation?.address,
                },
              ];
            });
          }
        });
      }

      // Process the paginationData here
     ;
    } catch (error) {
      // Handle the error here
      console.error(error);
      setIsLoading(false);
    }
  };
  const debouncedFetchData = debounce(fetchData, 300);

  useEffect(() => {
    setIsLoading(true);
    setMarkers([]);

    const currentPage = page; // Get the current page from the ref
    fetchData(true, currentPage); // Call the debounced fetchData function for the first effect
  }, [pageSize, serviceType, selectedDate]);

  useEffect(() => {
    setSecondMarkers([]);
    fetchData(false, page); // Use the 'page' state directly
  }, [page, pageSize, serviceType, selectedDate]);

  const resetData = () => {
    setData([]);
    setSecondData([]);
    setPreviousData([]);
    setSecondMarkers([]);
    setPreviousMarkers([]);
    setMarkers([]);
  };

  const handlePageChange = (newPage: number) => {
    if (newPage === page + 1) {
      // Going to the next page
      setPage(newPage);
      const current = data;
      const currentMarkers = markers;
      setData(secondData);
      setMarkers(secondMarkers);
      setSecondData([]);
      setSecondMarkers([]);
      setPreviousData(current);
      setPreviousMarkers(currentMarkers);
    } else if (
      newPage === page - 1 &&
      page - 1 > 0 &&
      previousData.length > 0
    ) {
      // Going to the previous page
      setPage(newPage);
      const current = data;
      const currentMarkers = markers;
      setData(previousData);
      setMarkers(previousMarkers);
      setSecondData(current);
      setSecondMarkers(currentMarkers);
      setPreviousData([]);
      setPreviousMarkers([]);
    } else {
      // Reset data or handle other cases as needed
      setPage(newPage);
      setSecondData([]);
      resetData();
      setUpdated(!updated);
    }
  };

  useEffect(() => {
    const fetchCompany = async () => {
      const companyRequests = new CompanyRequests();
      try {
        const companyData = await companyRequests.getAllCompanies();
        companyData.formatted_address = companyData.location.address
        setCompaniesData(companyData.companies);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCompany();
  }, []);

  const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
    // if event.target.value is not a number, return
    if (isNaN(event.target.value as number)) return;
    setPageSize(event.target.value as number);
  };

  const handleServiceChange = (type: string) => {
    setServiceType(type);
    setPage(1);
  };

  const columns: any = useMemo(
    () => [
      {
        field: "customer",
        headerName: "Client",
        flex: 0.7,
        renderCell: (params: any) => (
          <>
            <Avatar
              src={
                params.value.profileImg.length
                  ? params.value.profileImg
                  : DEFAULT_PROFILE_IMG
              }
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                marginLeft: "10px",
              }}
            >
              <Typography>{params.value.name}</Typography>
              <Typography style={{ color: "gray" }}>
                {params.value.email}
              </Typography>
            </Box>
          </>
        ),
      },
      {
        field: "created",
        headerName: "Date",
        flex: 0.4,
        valueFormatter: (params: any) => {
          if (!params.value == null) {
            return "";
          }
          const date = formattedDate(params.value);
          return date;
        },
      },
      {
        field: "specialty",
        headerName: "Service",
        flex: 0.3,
        renderCell: (params: any) => {
          if (!params.value) {
            return "";
          }
          const serviceType =
            params.value === "jump"
              ? jumpIcon
              : params.value === "tireChange"
              ? tireChangeIcon
              : params.value === "refuel"
              ? refuelIcon
              : params.value === "unlock"
              ? unlockIcon
              : towingIcon;
          return (
            <Avatar
              src={serviceType}
              sx={{ width: "50px", height: "50px", mt: "8px" }}
            />
          );
        },
      },
      {
        field: "serviceLocation",
        headerName: "Vehicle Location",
        flex: 0.6,
        renderCell: (params: { value: Location }): string => {
          if (params.value) {
            return params.value.address;
          }
          return "";
        },
      },
      {
        field: "destinationLocation",
        headerName: "Drop Off Location",
        flex: 0.6,
        renderCell: (params: { value: Location }): string => {
          if (params.value) {
            return params.value.address;
          }
          return "";
        },
      },
      {
        field: "actions",
        headerName: "User details",
        flex: 0.3,
        renderCell: (params: { value: AssistanceInquiry }) => (
          <RequestActions
            {...{ params, rowId, setRowId, customerType, companiesData }}
          />
        ),
      },

      {
        field: "chatRoomId",
        headerName: "Chat",
        flex: 0.25,
        renderCell: (params: { value: string }) => (
          <div>
            {params.value ? (
              <Link
                to={`/chat?id=${params.value}`}
                style={{
                  textDecoration: "none",
                }}
              >
                <Fab
                size="small"
                  variant="extended"
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "green",
                    color: "white",
                  }}
                >
                  <MessageIcon />
                </Fab>
              </Link>
            ) : (
              <span>No Chat</span>
            )}
          </div>
        ),
      }
      
      
    ],
    [rowId]
  );

  const handleRadiusChange = (e: any) => {
    e.preventDefault();
    setSelectedDate(e.target.value);
    console.log("handleRadiusChange ", handleRadiusChange);
  };

  const handleRowClick = (params: any) => {
    setRowId(params._id);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="REQUESTS" />

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Dropdown
          options={SERVICE_TYPE_VALUES}
          onChange={(value: any) => handleServiceChange(value)}
          value={serviceType}
          icon={"service"}
        />

        <div
          style={{
            pointerEvents:
              data === null ||
              secondData === null ||
              data.length === 0 ||
              secondData.length === 0
                ? "none"
                : "auto",
          }}
        >
          <RadiusCheckbox
            options={REQUEST_RADIUS_CHECKBOX_DATE_OPTIONS}
            selected={selectedDate}
            handleRadiusChange={handleRadiusChange}
          />
        </div>
      </Box>

      {/* MAP */}
      <Box mt="40px" mb="20px" height="374px">
        <StaticMap mapHeight="375px" markers={markers} />
      </Box>

      {/* TABLE */}
      <Box mt="40px" minHeight={"300px"} height="auto">
        <DataGrid
          autoHeight
          rowHeight={60}
          disableExtendRowFullWidth
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data || []}
          columns={columns}
          hideFooter
          pageSize={pageSize}
          onRowClick={handleRowClick}
          // onRowClick={(params) => {
          //   console.log('params->', params)
          //   setRowId(params.id);
          //   console.log('rowId-> ', rowId)
          // }}
          sx={{
            border: "none",
            "& .MuiDataGrid-root": {
              border: "0px solid black",
            },
            "& .MuiDataGrid-columnHeaders": {
              p: "12px 15px",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "8px 8px 0px 0px",
              background: "#191919",
              color: "#7E7E7E",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              letterSpacing: "-0.14px",
              border: "none",
            },
            "& .MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            ".MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-row": {
              background: "black",
              p: "10px 15px",
              alignItems: "center",
              border: `0.5px solid #3E3E3E`,
              minHeight: "60px",
            },
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, newPage) => handlePageChange(newPage)}
            size="large"
            disabled={data.length === 0 || secondData.length === 0}
          />

          <div
            style={{
              marginLeft: 16,
              marginBottom: 16,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label style={{ fontSize: 9 }}> Items per page</label>
            <Select
              style={{
                marginTop: 4,
                width: 64,
                height: 28,
                border: `1px solid ${theme.palette.primary.light}`,
              }}
              value={pageSize}
              onChange={handleRowsPerPageChange}
            >
              {pageSizes.map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default ServiceRequest;
