import React, {
  useState,
  useEffect,
  MouseEvent,
  ChangeEvent,
  FormEventHandler,
} from "react";
import { Container, Box, Typography, TextField, Button } from "@mui/material";
import AdminRequests from "requests/adminRequests";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  // Check if token exists in localStorage on component render
  const token = localStorage.getItem("access_token");
  if (token) {
    navigate("/dashboard");
  }

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (
    event:
      | MouseEvent<HTMLButtonElement>
      | FormEventHandler<HTMLFormElement>
      | undefined
  ) => {
    // @ts-ignore
    event.preventDefault(); 
    const adminRequests = new AdminRequests();
    adminRequests.login(email, password).then(
      (response) => {

        if(!response) { 
          alert("E-mail or password incorrect(s)")
          return;
        }

        localStorage.setItem(
          "access_token",
          `Bearer ${response.data.access_token}`
        );

        adminRequests.getMe().then(
          (response) => {
            console.log(response.data);
            localStorage.setItem("notifications", JSON.stringify([]));
            localStorage.setItem("session", JSON.stringify(response.data));
            localStorage.setItem("id", response.data._id);
            navigate("/dashboard");
            window.location.reload();
          },
          (err) => {
            console.log(err);
          }
        );
      }
    );
  };

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <Container maxWidth="sm" style={{ marginTop: 64 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h2" component="h1" mb={4}>
          Sign in
        </Typography>
        <Box
          component="form"
          style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
          // @ts-ignore
          onSubmit={handleSubmit}
        >
          <TextField
            id="email_login"
            variant="filled"
            color="secondary"
            type="email"
            label="Email"
            value={email}
            onChange={handleEmailChange}
            margin="normal"
            required
          />
          <TextField
            id="email_password"
            variant="filled"
            color="secondary"
            type="password"
            label="Password"
            value={password}
            onChange={handlePasswordChange}
            margin="normal"
            required
          />
          <Box mt={2} mb={1} style={{ display: "flex" }}>
            <small>
              Don't have an account ?&nbsp;
              <a
                href="/signup"
                style={{
                  textDecoration: "none",
                  color: "white",
                  opacity: "0.3",
                }}
              >
                Register user
              </a>
            </small>
          </Box>

          <Box mb={1} style={{ display: "flex" }}>
            <small>
              Forgot your password ?&nbsp;
              <a
                href="/recover"
                style={{
                  textDecoration: "none",
                  color: "white",
                  opacity: "0.3",
                }}
              >
                Recover
              </a>
            </small>
          </Box>
          <Button
            style={{ border: "1px solid #fff", marginTop: 16 }}
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
