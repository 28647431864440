interface GenericDialogStyles {
  // container: React.CSSProperties;
  content: React.CSSProperties;
  text: React.CSSProperties;
  buttons: React.CSSProperties;
  firstButton: React.CSSProperties;
  secondButton: React.CSSProperties;
};

export const styles: GenericDialogStyles = {
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '150px',
    width: '335px',
    borderRadius: '12px'
  },
  text: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '25px', /* 156.25% */
    letterSpacing: '-0.32px'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '0px',
    height: '40px',
    borderTop: '1px solid #7E7E7E',
  },
  firstButton: {
    width: '50%',
    height: '100%',
    padding: '0px',
    color: '#fff',
    fontFamily: 'Inter',
    textDecoration: 'none',
    borderRadius: '0px',
    borderRight: '1px solid #7e7e7e',
    fontWeight: 600
  },
  secondButton: {
    width: '50%',
    height: '100%',
    padding: '0px',
    color: '#FF7C06',
    fontFamily: 'Inter',
    textDecoration: 'none',
    borderRadius: '0px',
    fontWeight: 600
  }
}