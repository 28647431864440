// Define the type
export type CategoryOptionsType = {
    car_wash: string;
    locksmith: string;
    mechanic: string;
    roadside: string;
};

// Define the object
export const categoryOptions: CategoryOptionsType = {
    car_wash: "Car Wash",
    locksmith: "Locksmith",
    mechanic: "Mechanic",
    roadside: "Roadside Assistance",
};
