import evCharging from "./assets/icons/evCharging.svg";
import evChargingWhite from "./assets/icons/evCharging.svg";
import jumpIcon from "./assets/icons/jump.svg";
import jumpIconWhite from "./assets/icons/jump.svg";
import refuel from "./assets/icons/refuel.svg";
import refuelWhite from "./assets/icons/refuel.svg";
import tireChange from "./assets/icons/tirechange.svg";
import tireChangeWhite from "./assets/icons/tirechange.svg";
import towing from "./assets/icons/towing.svg";
import towingWhite from "./assets/icons/towing.svg";
import unlock from "./assets/icons/unlock.svg";
import unlockWhite from "./assets/icons/unlock.svg";

// color design tokens export

type Tokens = {
    orange: {
        800: string;
        900: string;
    },
    blue: {
        300: string;
        400: string;
        500: string;
        600: string;
        700: string;
        800: string;
        900: string;
    },
    primary: {
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
        600: string;
        700: string;
        800: string;
        850: string;
        900: string;
    },
    secondary: {
        0: string;
        50: string;
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
        600: string;
        700: string;
        800: string;
        900: string;
    },
    green: {
        900: string;
    },
    red: {
        900: string;
    }
}

export const tokensDark: Tokens = {
    orange: {
        800: "#FFB46F",
        900: "#FF7C06",
    },
    blue: {
        300: "#F4F6FA",
        400: "#F2F9FB",
        500: "#BAC0CA",
        600: "#667080",
        700: "#6CA3E4",
        800: "#1279F2",
        900: "#063257",
    },
    primary: {
        // dark
        100: "#737373",
        200: "#696969",
        300: "#5E5E5E",
        400: "#535353",
        500: "#494949",
        600: "#3E3E3E",
        700: "#272727",
        800: "#252424",
        850: "#131212",
        900: "#000000",
    },
    secondary: {
        // white
        0: "#FFFFFF",
        // gray
        50: "#F6F6F6",
        100: "#F8F8F8",
        200: "#F9F9F9",
        300: "#FBFBFB",
        400: "#FCFCFC",
        500: "#FEFEFE",
        600: "#F5F5F5",
        700: "#E6E6E6",
        800: "#C0C0C0",
        900: "#7E7E7E",
    },
    green: {
        900: "#34A853",
    },
    red: {
        900: "#FF2D2D",
    },
   
};

// function that reverses the color palette
function reverseTokens(tokensDark: Tokens): Tokens {
    const reversedTokens: Tokens = { ...tokensDark };
    Object.entries(tokensDark).forEach(([key, val]) => {
        const keys = Object.keys(val);
        const values = Object.values(val);
        const length = keys.length;
        const reversedObj = {};
        for (let i = 0; i < length; i++) {
            // @ts-ignore
            reversedObj[keys[i]] = values[length - i - 1];
        }
        // @ts-ignore
        reversedTokens[key] = reversedObj;
    });
    return reversedTokens;
}
export const tokensLight: Tokens = reverseTokens(tokensDark);

// mui theme settings
export const themeSettings = (mode: any) => {
    return {
        palette: {
            mode: mode,
            ...(mode === "dark"
                ? {
                    // palette values for dark mode
                    primary: {
                        ...tokensDark.primary,
                        main: tokensDark.primary[900],
                        light: tokensDark.primary[800],
                    },
                    secondary: {
                        ...tokensDark.secondary,
                        main: tokensDark.secondary[0],
                    },
                    neutral: {
                        ...tokensDark.primary,
                        main: tokensDark.primary[600],
                        alt: tokensDark.primary[900],
                    },
                    background: {
                        default: tokensDark.primary[900],
                        alt: tokensDark.primary[850],
                        alt2: tokensDark.primary[800],
                    },
                    percentages: {
                        positive: tokensDark.green[900],
                        negative: tokensDark.red[900],
                    },
                    blue: {
                        ...tokensDark.blue,
                        main: tokensDark.blue[900],
                        default: tokensDark.blue[800],
                        light: tokensDark.blue[600],
                    },
                    orange: {
                        ...tokensDark.orange,
                        main: tokensDark.orange[900],
                        alt: tokensDark.orange[800],
                    },
                    green: {
                        ...tokensDark.green,
                        main: tokensDark.green[900]
                    },
                    red: {
                        ...tokensDark.red,
                        main: tokensDark.red[900]
                    },
                    icons: {
                        charging: evChargingWhite,
                        jump: jumpIconWhite,
                        refuel: refuelWhite,
                        tire_change: tireChangeWhite,
                        towing: towingWhite,
                        unlock: unlockWhite,
                    },
                }
                : {
                    // palette values for light mode
                    primary: {
                        ...tokensLight.secondary,
                        main: tokensDark.secondary[0],
                        light: tokensDark.secondary[100],
                    },
                    secondary: {
                        ...tokensLight.primary,
                        main: tokensDark.primary[900],
                    },
                    neutral: {
                        ...tokensLight.primary,
                        main: tokensDark.primary[100],
                        alt: tokensDark.primary[900],
                    },
                    background: {
                        default: tokensLight.secondary[900],
                        alt: tokensDark.secondary[100],
                        alt2: tokensDark.secondary[900],
                    },
                    percentages: {
                        positive: tokensDark.green[900],
                        negative: tokensDark.red[900],
                    },
                    blue: {
                        ...tokensDark.blue,
                        main: tokensDark.blue[900],
                        default: tokensDark.blue[800],
                        light: tokensDark.blue[600],
                    },
                    orange: {
                        ...tokensDark.orange,
                        main: tokensDark.orange[900],
                        alt: tokensDark.orange[800],
                    },
                    icons: {
                        charging: evCharging,
                        jump: jumpIcon,
                        refuel: refuel,
                        tire_change: tireChange,
                        towing: towing,
                        unlock: unlock,
                    },
                }),
        },
        typography: {
            fontFamily: ["Inter", "sans-serif"].join(","),
            fontSize: 12,
            h1: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 40,
            },
            h2: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 32,
            },
            h3: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 24,
            },
            h4: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 20,
            },
            h5: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 16,
            },
            h6: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 14,
            },
        },
    };
};
