import React, { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { Switch } from "@mui/material";

const filterOptions = [
  { key: "isClient", label: "Is Client", type: "boolean" },
  { key: "isSpecialist", label: "Is Specialist", type: "boolean" },
  { key: "isCompany", label: "Is Company", type: "boolean" },
  { key: "isIndependent", label: "Is Independent", type: "boolean" },
  { key: "isGuest", label: "Is Guest", type: "boolean" },
  { key: "autoDispatch", label: "Auto Dispatch", type: "boolean" },
  { key: "registerType", label: "Register Type", type: "text" },
  {
    key: "isValidCertification",
    label: "Is Valid Certification",
    type: "boolean",
  },
  { key: "isActive", label: "Is Active", type: "boolean" },
  { key: "name", label: "Name", type: "text" },
  { key: "phoneNumber", label: "Phone Number", type: "text" },
  { key: "email", label: "Email", type: "text" },
  { key: "verificationStatus", label: "Verification Status", type: "text" },
  { key: "companyName", label: "Company Name", type: "text" },
  { key: "fleetSize", label: "Fleet Size", type: "number" },
  { key: "clickedOn", label: "Clicked On", type: "text" },
  { key: "code", label: "Code", type: "text" },
  { key: "insurance", label: "Insurance", type: "text" },
  { key: "isDeveloper", label: "Is Developer", type: "boolean" },
];

const FilterComponent: React.FC<{
  filterCallBackFn: (filters: any) => void;
  logicOperatorBackFn: (logicOperator: string) => void;
}> = ({ filterCallBackFn, logicOperatorBackFn }) => {
  const [selectedFilters, setSelectedFilters] = useState<
    { key: string; value: string }[]
  >([]);
  const [isSwitchChecked, setIsSwitchChecked] = useState<boolean>(false);

  const handleOptionChange = (event: any) => {
    const filterKey = event.target.value as string;
    const filterType = filterOptions.find(
      (option) => option.key === filterKey
    )?.type;
    const initialValue = filterType === "boolean" ? "true" : "";
    setSelectedFilters([
      ...selectedFilters,
      { key: filterKey, value: initialValue },
    ]);
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    filterKey: string
  ) => {
    const updatedFilters = selectedFilters.map((filter) =>
      filter.key === filterKey
        ? { ...filter, value: event.target.value }
        : filter
    );
    setSelectedFilters(updatedFilters);
  };

  const removeFilter = (filterKey: string) => {
    const updatedFilters = selectedFilters.filter(
      (filter) => filter.key !== filterKey
    );
    setSelectedFilters(updatedFilters);
  };

  const availableFilterOptions = filterOptions.filter(
    (option) => !selectedFilters.some((filter) => filter.key === option.key)
  );

  const handleSwitchChange = () => {
    setIsSwitchChecked(!isSwitchChecked);
  };

  const handleFilterClick = () => {
    const filterObject: { [key: string]: any } = {};
    selectedFilters.forEach((filter) => {
      let value: any = filter.value;

      if (value === "true") {
        value = true;
      } else if (value === "false") {
        value = false;
      } else if (value === "null") {
        value = null;
      }

      filterObject[filter.key] = value;
    });
    let logicOperator = "$and";
    if (isSwitchChecked) {
      logicOperator = "$or";
    }

    logicOperatorBackFn(logicOperator);

    filterCallBackFn(filterObject);
  };

  return (
    <div>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <Box display={"flex"} flexDirection={"column"}>
          <small>Select Filter</small>
          <Select
            style={{ width: "120px", height: "40px" }}
            color="secondary"
            value=""
            onChange={handleOptionChange}
          >
            {availableFilterOptions.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box display={"flex"} alignItems={"center"} ml={3} mt={2}>
          <p>And</p>
          <Switch
            checked={isSwitchChecked}
            onChange={handleSwitchChange}
            color="info"
          />
          <p>Or</p>
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        flexDirection={"row"}
        alignItems={"center"}
        mt={2}
      >
        {selectedFilters.map((filter, index) => (
          <Box key={index} display={"flex"} flexDirection={"row"} ml={3}>
            <Box display={"flex"} flexDirection={"column"}>
              <small>
                {
                  filterOptions.find((option) => option.key === filter.key)
                    ?.label
                }
              </small>
              {filterOptions.find((option) => option.key === filter.key)
                ?.type === "text" ||
              filterOptions.find((option) => option.key === filter.key)
                ?.type === "number" ? (
                <input
                  style={{
                    width: "100px",
                    height: "30px",
                    border: "1px solid rgba(255,255,255,.3)",
                    backgroundColor: "transparent",
                    padding: "0px 4px",
                    borderRadius: "8px",
                    fontSize: "10.5px",
                  }}
                  type={
                    filterOptions.find((option) => option.key === filter.key)
                      ?.type
                  }
                  value={filter.value}
                  onChange={(event) => handleTextFieldChange(event, filter.key)}
                />
              ) : (
                <select
                  style={{
                    width: "60px",
                    height: "30px",
                    border: "1px solid rgba(255,255,255,.3)",
                    backgroundColor: "transparent",
                    padding: "0px 4px",
                    borderRadius: "8px",
                  }}
                  value={filter.value}
                  onChange={(event) => handleTextFieldChange(event, filter.key)}
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                  <option value="null">Null</option>
                </select>
              )}
            </Box>
            <Box style={{ marginTop: "10px" }}>
              <IconButton onClick={() => removeFilter(filter.key)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          </Box>
        ))}
        <Box ml={3} mt={1} display={"flex"} flexDirection={"row"}>
          <Button
            style={{
              display: "flex",
              width: "80px",
              height: "30px",
              padding: "8px 12px",
              justifyContent: "center",
              alignItems: "center",
              flexShrink: 0,
              borderRadius: "16px",
              background: "var(--Orange-Orange-900, #FF7C06)",
              color: "#000",
              textTransform: "none", // Corrected to be a valid type
              fontFamily: "Inter",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              letterSpacing: "-0.15px",
            }}
            variant="contained"
            onClick={handleFilterClick}
          >
            Filter
          </Button>
          &nbsp; &nbsp; &nbsp;
          <Button
            style={{
              display: "flex",
              width: "80px",
              height: "30px",
              padding: "8px 12px",
              justifyContent: "center",
              alignItems: "center",
              flexShrink: 0,
              borderRadius: "16px",
              background: "transparent",
              color: "rgba(255, 255, 255,0.5)",
              border: "1px solid rgba(255, 255, 255,0.5)",
              textTransform: "none", // Corrected to be a valid type
              fontFamily: "Inter",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              letterSpacing: "-0.15px",
            }}
            variant="contained"
            onClick={() => {
              setSelectedFilters([]);
              filterCallBackFn({});
            }}
          >
            Clear X
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default FilterComponent;
