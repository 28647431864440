import React from "react";
import { Autocomplete, TextField, Box, InputAdornment } from "@mui/material";
import { BuildOutlined, BusinessCenterOutlined } from "@mui/icons-material";
import Filter9PlusIcon from "@mui/icons-material/Filter9Plus";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MapIcon from "@mui/icons-material/Map";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CategoryIcon from "@mui/icons-material/Category";
import Person4Icon from "@mui/icons-material/Person4";
import TableViewIcon from "@mui/icons-material/TableView";
type Props = {
  options: any[];
  onChange: any;
  value: any;
  label?: string;
  icon?: string;
  fetchData?: boolean;
  width?: string;
  placeholder?: string;
};

const Dropdown = ({
  options,
  onChange,
  value,
  label,
  icon,
  width,
  placeholder,
  fetchData = false,
}: Props) => {
  function getIcon(icon: string | undefined) {
    switch (icon) {
      case "service":
        return <BuildOutlined />;
      case "company":
        return <BusinessCenterOutlined />;
      case "amount":
        return <Filter9PlusIcon />;
      case "account":
        return <Person4Icon />;
      case "show":
        return <VisibilityIcon />;
      case "map":
        return <MapIcon />;
      case "filter":
        return <FilterAltIcon />;
      case "category":
        return <CategoryIcon />;
      case "tables":
        return <TableViewIcon />;
      default:
        return null;
    }
  }

  return (
    <Box
      style={{
        marginTop: "8px",
        padding: "0px",
      }}
    >
      {label && (
        <div
          style={{
            fontSize: "14px",
            fontWeight: 400,
            marginBottom: "7px",
          }}
        >
          {label}
        </div>
      )}
      <Autocomplete
        options={options}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name
        }
        value={value}
        disableClearable
        onChange={(_, newValue) => {
          if (typeof newValue === "string") {
            onChange(newValue);
          } else {
            const selectedName = newValue?.name || "";
            onChange(selectedName, newValue?._id || "");
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={!placeholder ? "Search..." : placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  {getIcon(icon)}
                </InputAdornment>
              ),
            }}
            style={{
              width: `${!width ? "220px" : width}`,
              height: "50px",
              padding: "0px",
              borderRadius: "6px",
              backgroundColor: "#191919",
              fontSize: "11px",
            }}
          />
        )}
      />
    </Box>
  );
};

export default Dropdown;
