import React from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";
interface TagProps {
  color: string;
  name: string;
  isEditable: boolean;
  
  onRemove?: () => void;

}

const Tag: React.FC<TagProps> = ({ color, name, isEditable,onRemove }) => {
  const tagStyles = {
    backgroundColor: color,
    display: "inline-flex",
    padding: "4px 10px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "16px",
  };

  return (
    <div style={tagStyles}>
      <span
        style={{
          color: "var(--dark-dark-900, #fff)",
          fontFamily: "Inter",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          letterSpacing: "-0.12px",
          fontFeatureSettings: "'calt' off",
          marginRight: 2,
        }}
      >
        {name}
      </span>
      {isEditable && (
        <div
          style={{
            display: "inline-flex",
            justifyContent: "center",
          }}
        >
        
          <CloseIcon onClick={onRemove} style={{ marginRight: 2,marginTop:1, fontSize: "13px" }}></CloseIcon>
        </div>
      )}
    </div>
  );
};

export default Tag;
