import { Avatar, Box, Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import { DEFAULT_PROFILE_IMG, DEFAULT_VEHICLE_IMG } from 'utils/const';

type Props = {
  _id: string,
  image: string,
  worker?: boolean,
  workerName?: string,
  workerProfileImg?: string,
  make: string,
  model: string,
  plate: string,
  vin: string,
  year: number,
  width?: string,
  mt?: number
}

const VehicleCard = ({ _id, image, worker, workerName, workerProfileImg, make, model, plate, vin, year, width, mt }: Props) => {
  return (
    <Card style={{ marginTop: mt ? mt : 16, width: `${width ? width : 'auto'}` }}>
      <CardContent style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
        <Box display={'flex'} style={{ flex: 0.9 }}>
          <Avatar
            style={{ backgroundColor: "#c0c0c0" }}
            alt="Car Avatar"
            src={image ? image : DEFAULT_VEHICLE_IMG}
            sx={{ width: 50, height: 50 }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              marginLeft: 2,
            }}>
            <Typography
              variant="subtitle1"
              sx={{
                color: "var(--dark-white-white-900, #FFF)",
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "30px" /* 187.5% */,
                letterSpacing: "-0.32px",
              }}>
                {make.toUpperCase()} &nbsp;{model.toUpperCase()}
            </Typography>
            <Box display={'flex'} flexDirection='row' flex={1} gap={5}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#c0c0c0",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  letterSpacing: "-0.12px"
                }}>
                {`Year: ${year}`}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#c0c0c0",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  letterSpacing: "-0.12px",
                }}>
                {plate}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#c0c0c0",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  letterSpacing: "-0.12px",
                }}>
                VIN: {vin}
              </Typography>
            </Box>
          </Box>
        </Box>

        {worker &&
          <Box display={'flex'} flexDirection='row' gap={2} alignItems='center'>
            <Avatar
              style={{ backgroundColor: "#c0c0c0" }}
              alt="Car Avatar"
              src={ workerProfileImg ? workerProfileImg : DEFAULT_PROFILE_IMG}
              sx={{ width: 50, height: 50 }}
            />
            <Typography
              variant="subtitle2"
              sx={{
                color: "#c0c0c0",
                fontFamily: "Inter",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                letterSpacing: "-0.12px",
              }}>
              {workerName || 'WORKER NAME'}
            </Typography>
          </Box>}
      </CardContent>
    </Card>
  )
};

export default VehicleCard;