import React, { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import { Container, Box, Typography, TextField, Button } from "@mui/material";
import AdminRequests from "requests/adminRequests";
import { useNavigate, useParams } from "react-router-dom";

const Signup = () => {
  const navigate = useNavigate();
  const { seed } = useParams();
  const [email, setEmail] = useState<string>("");
  const [fullname, setFullname] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("+");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [allPasswordRequirementsValid, setAllPasswordRequirementsValid] =
    useState<boolean>(false);

  const [emailValidation, setEmailValidation] = useState<boolean>(true);

  const [passwordValidation, setPasswordValidation] = useState<any>({
    oneDigit: false,
    oneUpperCase: false,
    oneLowerCase: false,
    oneSpecialCaracter: false,
    confirmationStricEqual: false,
    atLeast: false,
  });

  useEffect(() => {
    const validateEmail = (email:string) => {
      // Regular expression for a basic email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    };

    if (email) {
      if (validateEmail(email)) {
        setEmailValidation(true);
      } else {
        setEmailValidation(false);
      }
    } else {
      setEmailValidation(false);
    }
  }, [email]);

  useEffect(() => {
    const validatePassword = () => {
      const atleast = password.length >= 8;
      const hasOneDigit = /[0-9]/.test(password);
      const hasOneUpperCase = /[A-Z]/.test(password);
      const hasOneLowerCase = /[a-z]/.test(password);
      const hasOneSpecialCaracter = /[@#$%!=!*()]/.test(password);
      const isConfirmationStrictEqual = password === passwordConfirm;

      setPasswordValidation({
        oneDigit: hasOneDigit,
        oneUpperCase: hasOneUpperCase,
        oneLowerCase: hasOneLowerCase,
        oneSpecialCaracter: hasOneSpecialCaracter,
        confirmationStricEqual: isConfirmationStrictEqual,
        atleast: atleast,
      });

      if (
        hasOneDigit &&
        hasOneUpperCase &&
        hasOneLowerCase &&
        hasOneSpecialCaracter &&
        isConfirmationStrictEqual
      ) {
        setAllPasswordRequirementsValid(true);
      } else {
        setAllPasswordRequirementsValid(false);
      }
    };

    validatePassword();
    console.log(passwordValidation);
  }, [password, passwordConfirm]);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value);
  };

  const handlePasswordConfirmChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setPasswordConfirm(event.target.value);
  };

  const handleFullnameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setFullname(event.target.value);
  };

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setPhoneNumber(event.target.value);
  };

  const handleSubmit = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault(); // Prevents the default form submission behavior

    const adminRequests = new AdminRequests();

    if (!seed) {
      adminRequests
        .register(email, password, fullname, phoneNumber.trim())
        .then((res: any) => {
          console.log(res);
          alert(`Admin ${email} successfully created`);
          navigate("/login");
        })
        .catch((err) => {
          console.error("Error:", err); // Mostra o erro no console para debug
          alert(`Error creating admin, verify e-mail`); // Alerta o erro para o usuário
        });
    } else {
      adminRequests
        .register_invite(email, password, fullname, phoneNumber, seed)
        .then(() => {
          alert(`Admin ${email} successfully created`);
          navigate("/login");
        })
        .catch((err) => {
          console.error("Error:", err); // Mostra o erro no console para debug
          alert(`Error creating admin, verify permissions`); // Alerta o erro para o usuário
        });
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);
  return (
    <Container maxWidth="sm" style={{ marginTop: 64 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h2" component="h1" mb={4}>
          Sign up
        </Typography>
        <Box
          component="form"
          style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
        >
          <TextField
            id="signup_email"
            variant="filled"
            color="secondary"
            type="email"
            label="Email"
            value={email}
            onChange={handleEmailChange}
            margin="normal"
            required
          />

          {!emailValidation && (
            <span
              style={{
                color: "#ff2d2d",
              }}
            >
              E-mail is invalid
            </span>
          )}

          <TextField
            id="signup_fullname"
            variant="filled"
            color="secondary"
            type="text"
            label="Full name"
            value={fullname}
            onChange={handleFullnameChange}
            margin="normal"
            required
          />

          <TextField
            id="signup_phone"
            variant="filled"
            color="secondary"
            type="text"
            label="Phone number"
            value={phoneNumber}
            onChange={handlePhoneChange}
            margin="normal"
            required
          />

          <Box mt={2} mb={2}>
            <ul>
              <li
                style={{
                  color: passwordValidation.atleast ? "#34a853" : "#ff2d2d",
                }}
              >
                Password must contain at least 8 caracters
              </li>
              <li
                style={{
                  color: passwordValidation.oneUpperCase
                    ? "#34a853"
                    : "#ff2d2d",
                }}
              >
                Password must contain at least one uppercase letter.
              </li>
              <li
                style={{
                  color: passwordValidation.oneLowerCase
                    ? "#34a853"
                    : "#ff2d2d",
                }}
              >
                Password must contain at least one lowercase letter.
              </li>
              <li
                style={{
                  color: passwordValidation.oneDigit ? "#34a853" : "#ff2d2d",
                }}
              >
                Password must contain at least one digit.
              </li>
              <li
                style={{
                  color: passwordValidation.oneSpecialCaracter
                    ? "#34a853"
                    : "#ff2d2d",
                }}
              >
                Password must contain at least one special character: @, #, $,
                %, !, =, *, (, or ).
              </li>
              <li
                style={{
                  color: passwordValidation.confirmationStricEqual
                    ? "#34a853"
                    : "#ff2d2d",
                }}
              >
                Password must be strict equal to confirmation
              </li>
            </ul>
          </Box>

          <TextField
            id="signup_password"
            variant="filled"
            color="secondary"
            type="password"
            label="Password"
            value={password}
            onChange={handlePasswordChange}
            margin="normal"
            required
          />

          <TextField
            id="signup_password_confirmation"
            variant="filled"
            color="secondary"
            type="password"
            label="Password confirmation"
            value={passwordConfirm}
            onChange={handlePasswordConfirmChange}
            margin="normal"
            required
          />

          <div style={{ display: "flex", marginTop: 16 }}>
            <p>
              Already has an account ?&nbsp;
              <a
                href="/login"
                style={{
                  textDecoration: "none",
                  color: "white",
                  opacity: "0.3",
                }}
              >
                Login
              </a>
            </p>
          </div>
          <Button
            style={{ border: "1px solid #fff", marginTop: 16 }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              !email ||
              !password ||
              !fullname ||
              !phoneNumber ||
              !allPasswordRequirementsValid ||
              !emailValidation
            }
            onClick={handleSubmit}
          >
            Register
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Signup;
