import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import "./style.css"; // Corrected import for the style file
import {
  Box,
  Fab,
  TextField,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import ChatIcon from "@mui/icons-material/Chat";
import {
  DEFAULT_PROFILE_IMG,
  SERVICE_TYPE_VALUES,
  COMPANY_AMOUNT,
} from "../../utils/const";
import { formattedDate } from "utils/helper";

interface ServiceInformationDialogProps {
  open: boolean;
  handleClose: () => void;
  marker: any;
}
const whiteText = {
  color: "white",
};

const ServiceInformationDialog: React.FC<ServiceInformationDialogProps> = ({
  open,
  handleClose,
  marker,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          padding: "20px",
          width: 756,
          zIndex: 1000, // Ensure it's on top of other elements
        },
      }}
    >
      <DialogTitle
        style={{
          color: "var(--dark-white-white-900, #FFF)",
          fontFeatureSettings: "'calt' off",
          fontFamily: "Inter",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "30px", // Note that you don't need /* 150% */ in inline styles
          letterSpacing: "-0.4px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        #{marker._id}
        <div>
          <a
            href={`tel:${marker.companyPhoneNumber}`}
            style={{ textDecoration: "none" }}
          >
            <Fab
              style={{
                width: "36px",
                height: "36px",
                backgroundColor: "green",
              }}
            >
              <PhoneIcon
                style={{
                  width: "16px",
                  height: "16px",
                  color: "white",
                }}
              />
            </Fab>
          </a>

          {/* <a
            href={`/chat?id=${marker.chatRoomId}`}
            style={{ textDecoration: "none", marginLeft: 12 }}
          >
            <Fab
              style={{
                width: "36px",
                height: "36px",
                backgroundColor: "green",
              }}
            >
              <ChatIcon
                style={{
                  width: "16px",
                  height: "16px",
                  color: "white",
                }}
              />
            </Fab>
          </a> */}
        </div>
      </DialogTitle>
      <DialogContent style={{ marginTop: 18 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            flexDirection: "column",  
          }}
          gap={1}
        >
          <div>
            <b>Company name: </b>
            <span>{marker.companyName}</span>
          </div>
          <div>
            <b>Address: </b>
            <span>{marker.locations[0]["address"]}</span>
          </div>

          <div>
            <b>Date time: </b>
            <span>{formattedDate(marker.created)}</span>
          </div>

          <div>
            <b>offerPrice: </b>
            <span>$ {(marker.offerPrice/100).toFixed(2)}</span>
          </div>
        </Box>
      </DialogContent>

      <DialogActions style={{ marginTop: 16 }}>
        <Button
          style={{
            backgroundColor: "transparent",
            width: "100px",
            height: "54px",
            padding: "13px 16px",
            justifyContent: "center", // Note: Added quotes around "space-around"
            alignItems: "center", // Note: Added quotes around "center"
            borderRadius: 16,
            color: "#fff",
            textTransform: "none",
          }}
          onClick={handleClose}
          color="primary"
        >
          <span
            style={{
              fontFeatureSettings: "'calt' off",
              fontFamily: "Inter",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              letterSpacing: "-0.15px",
            }}
          >
            Close
          </span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceInformationDialog;
