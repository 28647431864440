import React from "react";
import MapBox, { Marker, Popup } from "react-map-gl";
import { getMapboxToken, getMapboxStyle } from "config";

// import { MAP_BOX_STYLE, MAP_BOX_TOKEN } from "config/env";
import RoomIcon from "@mui/icons-material/Room";
import { Fab, Typography, Box, Avatar } from "@mui/material";
import { List, ListItem } from "@mui/material";
import PriceCompanyDialog from "scenes/googleCompanies/priceDialog";
import ServiceInformationDialog from "scenes/googleCompanies/ServiceInformationDialog";

import CloseIcon from "@mui/icons-material/Close";

import PlaceIcon from "@mui/icons-material/Place";
import ListIcon from "@mui/icons-material/List";
import PhoneIcon from "@mui/icons-material/Phone";

import AutoCallDialog from "scenes/googleCompanies/autoCallDialog";
import LocationRequests from "requests/locationRequests";

import jumpIcon from "../assets/icons/Jump.png";
import refuelIcon from "../assets/icons/Refuel.png";
import tireChangeIcon from "../assets/icons/Tirechange.png";
import towingIcon from "../assets/icons/Towing.png";
import unlockIcon from "../assets/icons/Unlock.png";
import carWashIcon from "../assets/icons/wash.png";

interface Props {
  viewModel: any;
  mapHeight: number;
}

const customFabStyle = {
  width: "45px",
  height: "45px",
};
const locationRequests = new LocationRequests();
const StaticMapCompanies = ({ mapHeight, viewModel }: Props) => {
  const height: string = mapHeight ? `${mapHeight}px` : "75vh";
  const [listOpen, setListOpen] = React.useState(true);
  const [popupInfo, setPopupInfo] = React.useState<any>(null);

  const getCurrentColor = (marker: any) => {
    return "#000";
  };

  const getServiceType = (companyCall: any) => {
    const service = companyCall.specialty.name;
    const serviceType =
      service === "jump"
        ? jumpIcon
        : service === "tireChange"
        ? tireChangeIcon
        : service === "refuel"
        ? refuelIcon
        : service === "unlock"
        ? unlockIcon
        : service.toLowerCase().includes("wash")
        ? carWashIcon
        : towingIcon;
    return serviceType;
  };

  const handleMarkerClick = async (event: any, marker: any) => {
    console.log("--------------------------->");
    console.log(marker);
    const address = await locationRequests.getAddress(
      marker.latitude,
      marker.longitude
    );

    marker.address = address;
    setPopupInfo(marker);
  };
  return (
    <div style={{ position: "relative" }}>
      <MapBox
        ref={viewModel.getMapRef()}
        initialViewState={viewModel.getMapViewState()}
        style={{
          width: "auto",
          height: height,
          borderRadius: "10px",
        }}
        mapStyle={getMapboxStyle()}
        mapboxAccessToken={getMapboxToken()}
        attributionControl={false}
        onClick={(event) => {
          let { lng, lat } = event.lngLat;
          setPopupInfo(null);
          if (isNaN(lng) || isNaN(lat) || !lng || !lat) {
            console.log("Invalid lngLat:", lng, lat);
            lng = 0;
            lat = 0;
            return;
          }
          viewModel.centerMapOnLocation(lat, lng, 16);

          viewModel.handleMapClick(event);
        }}
      >
        {viewModel.getShowCompanies() && <svg width={400} height={400}></svg>}
        {viewModel.getMarkers().map((marker: any) => (
          <Marker
            key={Math.floor(Math.random() * 10001)}
            latitude={marker.locations[0].geoJsonPoint.coordinates[1]}
            longitude={marker.locations[0].geoJsonPoint.coordinates[0]}
          >
            <div
              onClick={async (e: any) => {
                e.stopPropagation();
                viewModel.handleMarkerClick(marker);
              }}
            >
              <Avatar
                style={{
                  backgroundColor: "#FF7C06",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "9px",
                }}
                src={getServiceType(marker) as string | undefined}
              />
            </div>
          </Marker>
        ))}
        {viewModel.getCurrentCompanyId() &&
        viewModel.getCurrentCompanyMarker() ? (
          <PriceCompanyDialog
            open={viewModel.getDialogOpen()}
            marker={viewModel.getCurrentCompanyMarker()}
            handleClose={() => viewModel.handledialogClose()}
          />
        ) : null}

        {viewModel.getCurrentId() && viewModel.getCurrentServiceMarker() ? (
          <ServiceInformationDialog
            open={viewModel.getDialogOpen2()}
            marker={viewModel.getCurrentServiceMarker()}
            handleClose={() => viewModel.handledialogClose2()}
          />
        ) : null}
        {viewModel.getCompanyMarkers() &&
          viewModel.getCompanyMarkers().length > 0 &&
          viewModel.getCompanyMarkers().map((marker: any) =>
            marker.geometry.location &&
            marker.geometry.location.coordinates &&
            marker.geometry.location.coordinates[0] !== null &&
            marker.geometry.location.coordinates[1] !== null ? (
              <div
                key={"marker" + Date.now() + Math.floor(Math.random() * 100000)}
                style={{ position: "relative" }}
              >
                <Marker
                  latitude={marker.geometry.location.coordinates[1]}
                  longitude={marker.geometry.location.coordinates[0]}
                >
                  <div
                    onClick={async (e: any) => {
                      e.stopPropagation();
                      viewModel.handleCompanyMarkerClick(marker);
                      if (
                        isNaN(marker.geometry.location.coordinates[0]) ||
                        isNaN(marker.geometry.location.coordinates[1]) ||
                        !marker.geometry.location.coordinates[0] ||
                        !marker.geometry.location.coordinates[1]
                      ) {
                        return;
                      }
                      viewModel.centerMapOnLocation(
                        marker.geometry.location.coordinates[1],
                        marker.geometry.location.coordinates[0],
                        16
                      );
                    }}
                    style={{
                      ...customFabStyle,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      backgroundColor: getCurrentColor(marker),
                      border: viewModel.handleBorder(marker.status),
                    }}
                  >
                    <small
                      style={{ fontSize: 9, fontWeight: 600, color: "#fff" }}
                    >
                      {parseFloat(marker.distance.toFixed(1))} mi
                    </small>
                  </div>
                  {marker.favorite && (
                    <div
                      style={{
                        position: "absolute",
                        top: -3,
                        right: -1,
                        width: 16,
                        height: 16,
                      }}
                    ></div>
                  )}
                </Marker>
              </div>
            ) : null
          )}

        {viewModel.getCustomMarker() ? (
          <Marker
            key={viewModel.getCustomMarker().id + Date.now() + " date"}
            latitude={viewModel.getCustomMarker().latitude}
            longitude={viewModel.getCustomMarker().longitude}
          >
            <div
              onClick={(e: any) => {
                e.stopPropagation();
                setPopupInfo(null);
                handleMarkerClick(e, viewModel.getCustomMarker());
              }}
            >
              <RoomIcon style={{ fontSize: "2.5rem" }} color="info"></RoomIcon>
            </div>
          </Marker>
        ) : null}
        {popupInfo && (
          <Popup
            latitude={popupInfo.latitude}
            longitude={popupInfo.longitude}
            closeButton={true}
            closeOnClick={false}
            onClose={() => setPopupInfo(null)}
          >
            <div style={{ width: "120px", height: "auto" }}>
              <div
                style={{
                  width: "120px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <CloseIcon
                  onClick={() => {
                    setPopupInfo(null);
                  }}
                  color="primary"
                ></CloseIcon>
              </div>
              <div>
                <b style={{ color: "black" }}>Lat:</b>&nbsp;
                <small style={{ color: "black" }}>
                  {popupInfo.latitude.toFixed(4)}
                </small>
              </div>

              <div>
                <b style={{ color: "black" }}>Lng:</b>&nbsp;
                <small style={{ color: "black" }}>
                  {popupInfo.longitude.toFixed(4)}
                </small>
              </div>

              <div>
                <b style={{ color: "black" }}>Address:</b>&nbsp;
                <small style={{ color: "black" }}>{popupInfo.address}</small>
              </div>
            </div>
          </Popup>
        )}
      </MapBox>
      {listOpen &&
      viewModel.getCompanyMarkers() &&
      viewModel.getCompanyMarkers().length > 0 ? (
        <List
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            zIndex: 999,
            background: "rgba(0, 0, 0, 0.93)", // Use rgba for transparency
            borderRadius: 16,
            height: `${mapHeight * 0.935}px`,
            width: 280,
            paddingTop: 18,
            paddingLeft: 14,
            paddingRight: 14,
            paddingBottom: 18,
            maxHeight: mapHeight, // Set a max height for the list
            boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.5)", // Shadow effect
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 16,
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5">{`Companies (${
              viewModel.getCompanyMarkers().length
            })`}</Typography>
            <AutoCallDialog
              viewModel={viewModel}
              mapHeight={mapHeight}
              companyMarkers={viewModel.getCompanyMarkers()}
            ></AutoCallDialog>
            <CloseIcon onClick={() => setListOpen(false)} color="secondary" />
          </div>
          <hr style={{ opacity: 0.1 }} />
          <div style={{ overflowY: "auto", height: `${mapHeight * 0.76}px` }}>
            {viewModel.getCompanyMarkers() &&
              viewModel.getCompanyMarkers().length > 0 &&
              viewModel
                .getCompanyMarkers()
                .map((marker: any, index: number) => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    width={`${100}%`}
                    key={JSON.stringify(marker) + index}
                  >
                    <ListItem
                      onClick={() => {
                        viewModel.handleCompanyMarkerClick(marker);
                        viewModel.centerMapOnLocation(
                          marker.geometry.location.coordinates[1],
                          marker.geometry.location.coordinates[0],
                          16
                        );
                      }}
                    >
                      <Box display="flex" width={`${100}%`}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          width={`${100}%`}
                        >
                          <Box
                            display="inline-flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              style={{
                                color: "var(--dark-white-white-900, #FFF)",
                                fontFeatureSettings: "'calt' off",
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "30px",
                                letterSpacing: "-0.32px",
                              }}
                            >
                              {marker.name}
                            </Typography>
                            <a
                              href="#"
                              onClick={() => {
                                window.location.href = `tel:${
                                  marker._id + Date.now()
                                }`;
                              }}
                            >
                              <PhoneIcon
                                style={{
                                  color: "var(--dark-white-white-900, #34A853)",
                                  width: 18,
                                  marginTop: 4,
                                }}
                              />{" "}
                            </a>
                          </Box>
                          <Box display="inline-flex" alignItems="center">
                            <Box display="inline-flex" alignItems="center">
                              <PlaceIcon
                                style={{
                                  color: "var(--dark-white-white-900, #7E7E7E)",
                                  width: 16,
                                }}
                              />

                              <small
                                style={{
                                  color: "var(--dark-white-white-900, #FFF)",
                                  marginBottom: 2,
                                }}
                              >
                                {parseFloat(marker.distance.toFixed(1))} MI
                              </small>
                            </Box>

                            <div
                              style={{
                                width: 8,
                                height: 8,
                                background: "#7E7E7E",
                                borderRadius: "50%",
                                marginLeft: 8,
                                marginRight: 4,
                                marginBottom: 2,
                              }}
                            ></div>

                            <Typography
                              style={{
                                color: "var(--dark-white-white-900, #FFF)",
                                fontFeatureSettings: "'calt' off",
                                fontFamily: "Inter",
                                fontSize: "11px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal",
                                letterSpacing: "-0.14px",
                                marginBottom: 2,
                              }}
                            >
                              +{marker.contact.internationalPhoneNumber}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </ListItem>
                    <hr style={{ opacity: 0.15 }} />
                  </Box>
                ))}
          </div>
        </List>
      ) : (
        <Fab
          onClick={() => setListOpen(true)}
          style={{
            top: 8,
            right: 8,
            width: 36,
            height: 36,
            position: "absolute",
            backgroundColor: "#333", // Adjust the color to your desired silver/dark shade
            color: "silver", // Set the color of the icon
            boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.5)",
          }}
        >
          <ListIcon></ListIcon>
        </Fab>
      )}
    </div>
  );
};

export default StaticMapCompanies;