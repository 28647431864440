import React, { useMemo, useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  useTheme,
  Pagination,
  Select,
  MenuItem,
} from "@mui/material";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import CompanyActions from "../company/CompanyActions";
import UserRequests from "../../requests/userRequests";
import { FaSuitcase } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import Details from "./Details";
import CompanyRequests from "requests/companyRequests";
import Dropdown from "./component/dropdown";

const userRequests = new UserRequests();

const CompanyManagement = () => {
  const theme = useTheme();

  const [verificationStatus, setVerificationStatus] = useState<string>("all");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(8);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pollingInterval, setPollingInterval] = useState<number>(1000000);
  const [rowId, setRowId] = useState<any>(null);
  const [totalCompanies, setTotalCompanies] = useState<number>(0);
  const [totalWorkers, setTotalWorkers] = useState<number>(0);
  const [allCompaniesDropdown, setAllCompaniesDropdown] = useState<any[]>([]);
  const [company, setCompany] = useState();
  const pageSizes = [8, 16, 32, 64];

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
    
      const userRequest = new UserRequests();
      try {
        const paginationData = await userRequest.getPagination(
          "company",
          page,
          pageSize,

        );

        console.log("companies ->", paginationData.users);
        setData(paginationData.users);
        console.log("totalPages ->", paginationData.totalPages);
        setTotalPages(paginationData.totalPages);
        setIsLoading(false);
        // Process the paginationData here
      } catch (error) {
        // Handle the error here
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [pageSize, page, verificationStatus]);

  useEffect(() => {
    const fetchData = async () => {
      const totalCompaniesData = await userRequests.getTotalFilter("isCompany", true);
      setTotalCompanies(totalCompaniesData);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const totalWorkersData = await userRequests.getTotalFilter("isSpecialist", true);
      setTotalWorkers(totalWorkersData);
    };

    fetchData();
  }, []);

 

  const handleRowsPerPageChange = (event: any) => {
    setPageSize(event.target.value);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleStatusChange = (type: string) => {
    setVerificationStatus(type);
    setPage(1);
  };

  const handleCompanyChange = async (value: any, id: any) => {
    /* console.log("ID-->", id)
        console.log("value-->", value); */
    setIsLoading(true);
    setCompany(value);
    const companyRequest = new CompanyRequests();
    if (!id || !value || value === "all") {
      const paginationData = await companyRequest.getPagination(page, pageSize);
      setData(paginationData.users);
      setTotalPages(paginationData.totalPages);
      setIsLoading(false);
    } else {
      const paginationData = await companyRequest.getPagination(1, 64);
      //@ts-ignore
      const filteredCompanies = paginationData.users.filter(
        (company:any) => company._id === id
      );
      setData(filteredCompanies);
      setTotalPages(paginationData.totalPages);
      setIsLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        field: "profileImg", // I cant remove but im not using it only
        headerName: "Name",
        flex: 0.35,
        renderCell: (params: any) => (
          <>
            <Avatar
              src={params.row.profileImg}
              sx={{ width: params.width, height: params.height }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                marginLeft: "10px",
              }}
            >
              <span> {params.row.name}</span>
              <small style={{ color: "gray" }}>{params.row.email}</small>
            </div>
          </>
        ),
        sortable: false,
        filterable: false,
      },
      {
        field: "verificationStatus",
        headerName: "Status",
        flex: 0.1,
      },
      {
        field: "companyName",
        headerName: "Company name",
        sortable: false,
        flex: 0.2,
      },
      {
        field: "isCompany",
        headerName: "Company Type",
        sortable: false,
        flex: 0.2,
        renderCell: (params: any) => (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                marginLeft: "10px",
              }}
            >
              <text>{params.row.isCompany ? "Company" : "Specialist"}</text>
              <text style={{ color: "gray" }}>{params.row._id}</text>
            </div>
          </>
        ),
      },

      {
        field: "_id",
        headerName: "ID",
        flex: 0.3,
        hide: true,
      },
      {
        field: "actions",
        headerName: "Details",
        sortable: false,
        flex: 0.4,
        renderCell: (params: any) => <Details params={params.row} />,
      },
      {
        field: "user Actions",
        headerName: "User Actions",
        flex: 0.4,
        renderCell: (params: any) => (
          <CompanyActions
            {...{
              params,
              pollingInterval,
              setPollingInterval,
              setVerificationStatus,
            }}
          />
        ),
      },
    ],
    []
  );

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="COMPANIES" subtitle="List of companies" />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          margin: "20px 0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            background:
              "linear-gradient(193deg, #000 -312.22%, rgba(129, 129, 129, 0.00) 88.59%)",
            border: "1px solid #252424",
            borderRadius: "8px",
            minWidth: "206px",
            minHeight: "113px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <FaSuitcase style={{ width: "20px", height: "20px" }} />
            <text
              style={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              Total Companies
            </text>
          </div>
          <text
            style={{
              marginLeft: "auto",
              marginTop: "auto",
              fontSize: "20px",
              fontWeight: "600",
              lineHeight: "30px",
            }}
          >
            {totalCompanies}
          </text>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            background:
              "linear-gradient(193deg, #000 -312.22%, rgba(129, 129, 129, 0.00) 88.59%)",
            border: "1px solid #252424",
            borderRadius: "8px",
            minWidth: "206px",
            minHeight: "113px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <FiUsers style={{ width: "20px", height: "20px" }} />
            <text
              style={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              Total Workers
            </text>
          </div>
          <text
            style={{
              marginLeft: "auto",
              marginTop: "auto",
              fontSize: "20px",
              fontWeight: "600",
              lineHeight: "30px",
            }}
          >
            {totalWorkers}
          </text>
        </div>
      </div>
      {/* <ButtonGroup variant="outlined">
        <Button
          onClick={() => handleStatusChange("all")}
          sx={{
            backgroundColor:
              verificationStatus === "all"
                ? // @ts-ignore
                  theme.palette.secondary[100]
                : // @ts-ignore
                  theme.palette.primary[600],
            color:
              verificationStatus === "all"
                ? // @ts-ignore
                  theme.palette.primary.main
                : // @ts-ignore
                  theme.palette.secondary[100],
            ":hover": {
              backgroundColor:
                verificationStatus === "all"
                  ? // @ts-ignore
                    theme.palette.secondary[100]
                  : // @ts-ignore
                    theme.palette.secondary[900],
            },
          }}
        >
          ALL
        </Button>
        <Button
          onClick={() => handleStatusChange("pending")}
          sx={{
            backgroundColor:
              verificationStatus === "pending"
                ? // @ts-ignore
                  theme.palette.secondary[100]
                : // @ts-ignore
                  theme.palette.primary[600],
            color:
              verificationStatus === "pending"
                ? // @ts-ignore
                  theme.palette.primary.main
                : // @ts-ignore
                  theme.palette.secondary[100],
            ":hover": {
              backgroundColor:
                verificationStatus === "pending"
                  ? // @ts-ignore
                    theme.palette.secondary[100]
                  : // @ts-ignore
                    theme.palette.secondary[900],
            },
          }}
        >
          PENDING
        </Button>
        <Button
          onClick={() => handleStatusChange("approved")}
          sx={{
            backgroundColor:
              verificationStatus === "approved"
                ? // @ts-ignore
                  theme.palette.secondary[300]
                : // @ts-ignore
                  theme.palette.primary[600],
            color:
              verificationStatus === "approved"
                ? // @ts-ignore
                  theme.palette.primary.main
                : // @ts-ignore
                  theme.palette.secondary[100],
            ":hover": {
              backgroundColor:
                verificationStatus === "approved"
                  ? // @ts-ignore
                    theme.palette.secondary[300]
                  : // @ts-ignore
                    theme.palette.secondary[900],
            },
          }}
        >
          APPROVED
        </Button>
        <Button
          onClick={() => handleStatusChange("rejected")}
          sx={{
            backgroundColor:
              verificationStatus === "rejected"
                ? // @ts-ignore
                  theme.palette.secondary[300]
                : // @ts-ignore
                  theme.palette.primary[600],
            color:
              verificationStatus === "rejected"
                ? // @ts-ignore
                  theme.palette.primary.main
                : // @ts-ignore
                  theme.palette.secondary[100],
            ":hover": {
              backgroundColor:
                verificationStatus === "rejected"
                  ? // @ts-ignore
                    theme.palette.secondary[300]
                  : // @ts-ignore
                    theme.palette.secondary[900],
            },
          }}
        >
          REJECTED
        </Button>
      </ButtonGroup> */}
      <div style={{ display: "flex", justifyContent: "Start", gap: "20px" }}>
         {/* <Dropdown
          options={["all", ...allCompaniesDropdown]}
          onChange={(value: any, id: any) => handleCompanyChange(value, id)}
          value={company}
          label={"Company"}
          icon="company"
        />*/}
      </div>

      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            // @ts-ignore
            backgroundColor: theme.palette.background.alt,
            // @ts-ignore
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            // @ts-ignore
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            // @ts-ignore
            backgroundColor: theme.palette.background.alt,
            // @ts-ignore
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            // @ts-ignore
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          // @ts-ignore
          getRowId={(row) => row._id}
          rows={data || []}
          columns={columns}
          hideFooter
          pageSize={pageSize}
          onRowClick={(params) => {
            setRowId(params.id);
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, newPage) => handlePageChange(newPage)}
            size="large"
          />

          <div
            style={{
              marginLeft: 16,
              marginBottom: 16,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label style={{ fontSize: 9 }}> Items per page</label>
            <Select
              style={{
                marginTop: 4,
                width: 64,
                height: 28,
                border: `1px solid ${theme.palette.primary.light}`,
              }}
              value={pageSize}
              onChange={handleRowsPerPageChange}
            >
              {pageSizes.map((size) => (
                <MenuItem key={size + "pageSizes.map()"} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default CompanyManagement;
