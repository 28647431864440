import React, { useState, useEffect, useMemo } from "react";
import {
  useTheme,
  Box,
  Pagination,
  Select,
  MenuItem,
  SelectChangeEvent,
  Avatar,
  Typography,
  Fab,
} from "@mui/material";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import ServiceRequests from "requests/serviceRequests";
import {
  ServiceInquiry,
  ServiceLocation,
} from "models/assistanceInquiryResponseTypes";
import { DEFAULT_PROFILE_IMG, SERVICE_TYPE_VALUES } from "../../utils/const";
import { getDateFromString, formattedDate } from "utils/helper";
import jumpIcon from "../../assets/icons/services/jumpSelected.svg";
import refuelIcon from "../../assets/icons/services/refuelSelected.svg";
import tireChangeIcon from "../../assets/icons/services/tireChangeSelected.svg";
import towingIcon from "../../assets/icons/services/towingSelected.svg";
import MessageIcon from "@mui/icons-material/Message";
import unlockIcon from "../../assets/icons/services/unlockSelected.svg";
import Dropdown from "scenes/companyMagangement/component/dropdown";
import StaticMap from "components/StaticMap";
import { Link } from "react-router-dom";
import "./style.css";
import { REQUEST_RADIUS_CHECKBOX_DATE_OPTIONS } from "../../utils/const";
import RadiusCheckbox from "components/RadiusCheckbox";
import Pill from "components/Pill";

const ServiceRequest = () => {
  const theme = useTheme();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(8);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [data, setData] = useState<ServiceInquiry[]>([]);
  const [markers, setMarkers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [serviceType, setServiceType] = useState<string>("all");
  const [selectedDate, setSelectedDate] = useState<string>("lastWeek");
  const pageSizes: number[] = [8, 16, 32, 64];
  const [rowId, setRowId] = useState<any>(null);

  useEffect(() => {
    setIsLoading(true);
    setMarkers([]);
    const fetchData = async () => {
      const serviceRequests = new ServiceRequests();
      try {
        let paginationData = await serviceRequests.getPagination(
          page,
          pageSize,
          serviceType,
          selectedDate
        );
        console.log("paginationData->", paginationData);
        setData(paginationData.services as ServiceInquiry[]);
        setTotalPages(paginationData.totalPages);
        setIsLoading(false);
      } catch (error) {
        // Handle the error here
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [pageSize, page, serviceType, selectedDate]);

  const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
    // if event.target.value is not a number, return
    if (isNaN(event.target.value as number)) return;
    setPageSize(event.target.value as number);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleServiceChange = (type: string) => {
    setServiceType(type);
    setPage(1);
  };

  const columns: any = useMemo(
    () => [
      {
        field: "userInfo",
        headerName: "Client",
        flex: 0.2,
        renderCell: (params: any) => (
          <>
            <Avatar
              src={
                params.value.profileImg.length
                  ? params.value.profileImg
                  : DEFAULT_PROFILE_IMG
              }
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                marginLeft: "10px",
              }}
            >
              <Typography>{params.value.name}</Typography>
              <Typography style={{ color: "gray" }}>
                {params.value.email}
              </Typography>
            </Box>
          </>
        ),
      },
      {
        field: "createdAt",
        headerName: "Date",
        flex: 0.12,
        valueFormatter: (params: any) => {
          if (!params.value == null) {
            return "";
          }
          // const date = getDateFromString(params.value);
          const date = formattedDate(params.value);
          return date;
        },
      },
      {
        field: "serviceType",
        headerName: "Service",
        flex: 0.07,
        renderCell: (params: any) => {
          if (!params.value) {
            return "";
          }
          const serviceType =
            params.value === "jump"
              ? jumpIcon
              : params.value === "tireChange"
              ? tireChangeIcon
              : params.value === "refuel"
              ? refuelIcon
              : params.value === "unlock"
              ? unlockIcon
              : towingIcon;
          return (
            <Avatar
              src={serviceType}
              sx={{ width: "50px", height: "50px", mt: "8px" }}
            />
          );
        },
      },
      {
        field: "location",
        headerName: "Vehicle Location",
        flex: 0.2,
        renderCell: (params: { value: ServiceLocation }): string => {
          if (params.value) {
            return params.value.pick;
          }
          return "";
        },
      },
      {
        field: "status",
        headerName: "Status",
        flex: 0.1,
        renderCell: (params: { value: string }): any => {
          if (!params.value) {
            return "";
          }

          return <Pill status={params.value} />;
        },
      },
      {
        field: "amount",
        headerName: "Price",
        flex: 0.1,
        valueFormatter: (params: any) => {
          if (!params.value == null) {
            return "";
          }
          const price = `$${params.value}`;
          return price;
        },
      },
      {
        field: "companyInfo",
        headerName: "Company",
        flex: 0.15,
        renderCell: (params: any) => (
          <Typography>{params.value.companyName}</Typography>
        ),
      }
      ,

      {
        field: "specialistInfo",
        headerName: "Assigned",
        flex: 0.22,
        renderCell: (params: any) => (
          <>
            <Avatar
              src={
                params.value.profileImg.length
                  ? params.value.profileImg
                  : DEFAULT_PROFILE_IMG
              }
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                marginLeft: "8px",
              }}
            >
              <Typography>{params.value.name}</Typography>
              <Typography style={{ color: "gray" }}>
                {params.value.phoneNumber}
              </Typography>
            </Box>
          </>
        ),
      },
      {
        field: "chatId",
        headerName: "Chat",
        flex: 0.12,
        renderCell: (params: { value: string }) => (
          <div>
            {params.value ? (
              <Link
                to={`/chat?id=${params.value}`}
                style={{
                  textDecoration: "none",
                }}
              >
                <Fab
                 size="small"
                  variant="extended"
                  style={{
                    borderRadius: "50%",
              
                    backgroundColor: "green",
                    color: "white",
                  }}
                >
                  <MessageIcon
                   style={{
                    borderRadius: "50%",
                    width: "18px",
                    height: "18px",
                   
                  }}
                  />
                </Fab>
              </Link>
            ) : (
              <span>No Chat</span>
            )}
          </div>
        ),
      },
    ],
    [rowId]
  );

  const handleRadiusChange = (e: any) => {
    e.preventDefault();
    setSelectedDate(e.target.value);
    console.log(selectedDate);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="SERVICES" />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Dropdown
          options={SERVICE_TYPE_VALUES}
          onChange={(value: any) => handleServiceChange(value)}
          value={serviceType}
          icon={"service"}
        />
        <RadiusCheckbox
          options={REQUEST_RADIUS_CHECKBOX_DATE_OPTIONS}
          selected={selectedDate}
          handleRadiusChange={handleRadiusChange}
        />
      </Box>

      {/* MAP */}
      <Box mt="40px" mb="20px" height="374px">
        <StaticMap mapHeight="375px" markers={markers} />
      </Box>

      {/* TABLE */}
      <Box mt="40px" minHeight={"300px"} height="auto">
        <DataGrid
          autoHeight
          rowHeight={60}
          // disableExtendRowFullWidth
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data || []}
          columns={columns}
          hideFooter
          pageSize={pageSize}
          onRowClick={(params: any) => {
            setRowId(params.id);
            console.log("rowId->", rowId);
          }}
          sx={{
            border: "none",
            "& .MuiDataGrid-root": {
              border: "0px solid black",
            },
            "& .MuiDataGrid-columnHeaders": {
              p: "12px 15px",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "8px 8px 0px 0px",
              background: "#191919",
              color: "#7E7E7E",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              letterSpacing: "-0.14px",
              border: "none",
            },
            "& .MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            ".MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-row": {
              background: "black",
              p: "10px 15px",
              alignItems: "center",
              border: `0.5px solid #3E3E3E`,
              minHeight: "60px",
            },
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, newPage) => handlePageChange(newPage)}
            size="large"
          />

          <div
            style={{
              marginLeft: 16,
              marginBottom: 16,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label style={{ fontSize: 9 }}> Items per page</label>
            <Select
              style={{
                marginTop: 4,
                width: 64,
                height: 28,
                border: `1px solid ${theme.palette.primary.light}`,
              }}
              value={pageSize}
              onChange={handleRowsPerPageChange}
            >
              {pageSizes.map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default ServiceRequest;
