import React, { useEffect, useMemo, useState } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";

import {
  Box,
  Fab,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useOpenAIViewModel from "./openAI";
import { styles } from "./styles";
import { DataGrid } from "@mui/x-data-grid";
import AddItemDialog from "./addItemDialog";

import ChatMessenger from "components/ChatMessenger/ChatMessenger";
import { AddCircleOutlineOutlined } from "@mui/icons-material";

import DeleteIcon from "@mui/icons-material/Delete";
import GenericInput from "components/GenericInput/GenericInput";
import ChatBotRequests from "requests/chatBotRequests";

const chatBotRequests = new ChatBotRequests();

const OpenAI: React.FC = () => {
  const viewModel = useOpenAIViewModel();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState<any[]>([]);
  const [newPage, setNewPage] = useState(1);

  const columns = useMemo(
    () => [
      {
        field: "question",
        headerName: "Question",
        flex: 4,
        renderCell: (params: any) => {
          return (
            <div style={{ whiteSpace: "pre-wrap", padding: "10px" }}>
              <span>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "answer",
        headerName: "Answer",
        flex: 4,
        renderCell: (params: any) => {
          return (
            <div style={{ whiteSpace: "pre-wrap", padding: "10px" }}>
              <span>{params.value}</span>
            </div>
          );
        },
      },
      {
        field: "action",
        headerName: "Actions",
        flex: 0.6,
        renderCell: (params: any) => {
          return (
            <Fab
              onClick={() => {
                handleDeleteQuestion(params.row.question);
              }}
              color="primary"
              size="small"
            >
              <DeleteIcon style={{ fontSize: "1.4rem" }} color="secondary" />
            </Fab>
          );
        },
      },
    ],
    []
  );

  const handleDeleteQuestion = async (question: string) => {
    try {
      await chatBotRequests.deleteQuestion(question);
      alert("Question deleted successfully")
    } catch (err) {
      alert("Error on deleting quesiton");
    }
  };

  useEffect(() => {
    if (search === "") {
      setSearchData([]);
    }
  }, [search]);

  return (
    <Box m="1.5rem 2.5rem" style={{ position: "relative" }}>
      <FlexBetween>
        <Header
          title={"OPEN AI"}
          subtitle="The place where you can manager Artificial Intelligence models for Chatgpt"
        />
      </FlexBetween>
      <ChatMessenger></ChatMessenger>
      <Box
        display={"flex"}
        mt={5}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display={"flex"} alignItems="center" justifyContent="flex-start">
          <SearchIcon style={{ fontSize: "32px" }} />
          <TextField
            value={search}
            color="secondary"
            style={{ color: "white", marginLeft: 16 }}
            onFocus={() => {
              console.log("Focus");
            }}
            onChange={(event: any) => {
              setSearch(event.target.value);
              const data = viewModel.data;

              const filteredData = data.filter((item) => {
                return (
                  item.question.toLowerCase().includes(search.toLowerCase()) ||
                  item.answer.toLowerCase().includes(search.toLowerCase())
                );
              });
              setSearchData(filteredData);
            }}
          />
        </Box>

        <Box mt="14px" style={styles.addServiceBox}>
          <AddCircleOutlineOutlined
            onClick={() => setIsDialogOpen(true)}
            sx={{ color: "#FF7C06" }}
          />
          <Typography
            onClick={() => setIsDialogOpen(true)}
            sx={{ color: "#FF7C06" }}
          >
            Add Item to AI
          </Typography>
        </Box>
      </Box>

      {/* TABLE */}
      <Box mt="40px" minHeight={"300px"} height="auto">
        <DataGrid
          autoHeight
          rowHeight={60}
          disableExtendRowFullWidth
          // loading={viewModel.getIsLoading() || !viewModel.data}
          getRowId={(row) => JSON.stringify(row)}
          rows={searchData.length > 0 ? searchData : viewModel.data}
          columns={columns}
          hideFooter
          pageSize={100}
          sx={viewModel.dataGridStyle()}
        />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
          marginBottom={2}
        >
          <Pagination
            count={viewModel.getTotalPages()}
            page={viewModel.getPage()}
            onChange={(event, newPage) => viewModel.handlePageChange(newPage)}
            size="large"
            disabled={!viewModel.data || viewModel.data.length === 0}
          />

          <Box
            marginLeft={2}
            marginBottom={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <label>Items per page</label>
            <Select
              style={styles.itemsPerPage}
              value={viewModel.getPageSize()}
              onChange={viewModel.handleRowsPerPageChange}
            >
              {viewModel.getPageSizes().map((size: number) => (
                <MenuItem key={size + "sizesPayment"} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>

      <AddItemDialog
        open={isDialogOpen}
        handleClose={(event: any) => {
          event.stopPropagation();
          setIsDialogOpen(false);
        }}
      ></AddItemDialog>
    </Box>
  );
};

export default OpenAI;
