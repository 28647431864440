interface AddServicesDialogStyles {
  dialogContainer: React.CSSProperties,
  header: React.CSSProperties,
  dialogTitle: React.CSSProperties,
  tabOptionsContainer: React.CSSProperties,
  inputsContainer: React.CSSProperties,
  inputRowContainer: React.CSSProperties,
  vehicleButtonsContainer: React.CSSProperties,
  buttonContainer: React.CSSProperties,
  saveButton: React.CSSProperties,
  cancelButton: React.CSSProperties,
  textButton: React.CSSProperties,
  serviceContainer: React.CSSProperties,
  stateContainer: React.CSSProperties,
  vehicleSkeletonCard: React.CSSProperties,
  vehicleSkeletonText: React.CSSProperties,
  vehicleCardContainer: React.CSSProperties,
}

export const styles: AddServicesDialogStyles = {
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    gap: '20px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  dialogTitle: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '30px',
    letterSpacing: '-0.4px'
  },
  tabOptionsContainer: {
    display: 'flex',
    width: '100%'
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '15px',
    padding: '0px'
  },
  inputRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    padding: '0px'
  },
  vehicleButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
  saveButton: {
    display: 'flex',
    flexDirection: 'row',
    color: '#FF7C06',
    gap: '8px',
    cursor: 'pointer'
  },
  cancelButton: {
    display: 'flex',
    flexDirection: 'row',
    color: '#fff',
    gap: '8px',
    cursor: 'pointer'
  },
  textButton: {
    fontSize: '14px',
    fontWeight: 600
  },
  serviceContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "50%",
    height: "52px",
    paddingLeft: "1rem",
    marginTop: '16px',
    marginBottom: '8px',
    border: "1px solid rgba(126, 126, 126, 0.60)",
    borderRadius: "6px",
  },
  stateContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    height: 'auto',
    marginTop: '16px',
    marginBottom: '8px',
  },
  vehicleSkeletonCard: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '10px'
  },
  vehicleSkeletonText: {
    display: 'flex',
    flexDirection: 'column',
    height: '50px',
    justifyContent: 'space-between'
  },
  vehicleCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    // width: '100%',
    // backgroundColor: '#252424'
  }
}