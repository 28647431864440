// src/ConfirmDelete.tsx
import React from 'react';

interface ConfirmDeleteProps {
  onDelete: () => void;
  onCancel: () => void;
}

const ConfirmDelete: React.FC<ConfirmDeleteProps> = ({ onDelete, onCancel }) => {
  return (
    <div style={styles.container}>
      <p>Are you sure you want to delete this company? This action couldn't be reverted.</p>
      <div style={styles.buttonContainer}>
        <button style={styles.deleteButton} onClick={onDelete}>Delete</button>
        <button style={styles.cancelButton} onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    width: '300px',
    textAlign: 'center' as 'center',
    marginBottom:"16px",
  },
  buttonContainer: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between' as 'space-between',
  },
  deleteButton: {
    backgroundColor: '#DA6565',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    borderRadius: 16
  },
  cancelButton: {
    backgroundColor: 'gray',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    borderRadius: 16
  }
};

export default ConfirmDelete;
