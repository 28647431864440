import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import "./style.css";
import { grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import CompanyRequests from "requests/companyRequests";
import CloseIcon from "@mui/icons-material/Close";
import VehicleCard from "components/VehicleCard";
import { DEFAULT_PROFILE_IMG, SERVICE_TYPE_VALUES } from "utils/const";
import DropdownMenu from "components/DropdownMenu";
import { getQueryString } from "utils/helper";
import AssistanceInquiryRequests from "requests/assistanceInquiry";
import { AssignServiceRequestDTO } from "adapters/assignServiceDTO";

const styles = {
  title: {
    color: "var(--dark-white-white-900, #FFF)",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px" /* 150% */,
    letterSpacing: "-0.4px",
  },
  subtitle: {
    color: "var(--gray-gray-900, #7E7E7E)",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.12px",
  },
  picture: {
    width: 50,
    height: 50,
    borderRadius: "50%",
  },
  tabDefault: {
    width: "25%",
    display: "flex",
    justifyContent: "center",
    borderBottom: "2px solid #fff",
    paddingBottom: 8,
  },
  buttonTab: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "-0.32px",
    color: "#fff",
    margin: "0 auto",
    background: "transparent",
    textDecoration: "none",
    cursor: "pointer",
    width: "100%",
    border: "none",
  },
  value: {
    color: "var(--dark-white-white-900, #FFF)",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px" /* 187.5% */,
    letterSpacing: "-0.32px",
  },
  label: {
    color: "var(--gray-gray-900, #c0c0c0)",
    fontFamily: "Inter",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.12px",
  },
  buttomDispatch: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: "25px",
    backgroundColor: "#FF7C06",
    borderRadius: "16px",
    padding: "13px 16px",
    width: "168px",
  },
  buttomDispatchText: {
    fontSize: "15px",
    fontWeight: 600,
    letterSpacing: "-0.15px",
    color: "black",
  },
};

type Props = {
  open: boolean;
  handleClose: () => void;
  companiesData?: any;
  id: string;
  customer: any;
  destinationLocation: any;
  serviceLocation: any;
  specialty: string;
  specialist: any;
  company: any;
  customerVehicle: any;
  vehicle: any;
};

const RequestDialogAction = ({
  open,
  handleClose,
  companiesData,
  id,
  customer,
  destinationLocation,
  serviceLocation,
  specialty,
  specialist,
  company,
  customerVehicle,
}: Props) => {
  const [currentTab, setCurrentTab] = useState("Information");
  const [serviceType, setServiceType] = useState(specialty);
  const [updatedCompany, setUpdatedCompany] = useState(
    Array.isArray(company) ? company : [company]
  );
  const [updatedSpecialist, setUpdatedSpecialist] = useState(
    Array.isArray(specialist) ? specialist : [specialist]
  );
  const [specialistsData, setSpecialistsData] = useState(
    Array.isArray(specialist) ? specialist : [specialist]
  );
  const [updatedPrice, setUpdatedPrice] = useState("");

  const handleTabChange = (tab: string) => {
    setCurrentTab(tab);
  };

  const information = [
    { label: "Name", value: customer.name || "" },
    { label: "E-mail", value: customer.email },
    { label: "Phone number", value: customer.phoneNumber },
    { label: "customer ID", value: customer._id },
  ];

  const handleChangeService = (value: any) => {
    setServiceType(value);
  };

  const handleSpecialistChange = async (value: any) => {
    setUpdatedSpecialist([value]);
  };

  const handleCompanyChange = async (value: any) => {
    setUpdatedCompany([value]);
    setUpdatedSpecialist([]);
    if (value._id) {
      const companyRequests = new CompanyRequests();
      let specialists = await companyRequests.getAllWorkersCompany(value._id);
      if (specialists?.length > 0) {
        setSpecialistsData(specialists);
      } else {
        setSpecialistsData([]);
      }
    }
  };

  useEffect(() => {
    const getCompanyPrice = async () => {
      const payload = {
        assistanceInquiryId: id,
        companyId: updatedCompany[0]._id,
      };

      const assistanceInquiryRequests = new AssistanceInquiryRequests();
      const response = await assistanceInquiryRequests.getCompanyPrice(payload);
      if (!response) {
        return null;
      }
      if (response.status > 300) return;
      setUpdatedPrice(response.payload.tariff);
    };

    // console.log("🚀 ~ useEffect ~ getCompanyPrice:", updatedCompany[0]._id);

    if (updatedCompany[0]._id) getCompanyPrice();
  }, [id, updatedCompany]);

  const handleDispatch = async () => {
    if (!updatedSpecialist.length || !updatedSpecialist[0]._id.length)
      return alert("Please choose a specialist");
    if (!updatedCompany[0]._id.length) return alert("Please choose a company");

    const payload: AssignServiceRequestDTO = {
      assistanceInquiryId: id,
      companyId: updatedCompany[0]._id,
      specialistId: updatedSpecialist[0]._id,
    };

    const assistanceInquiryRequests = new AssistanceInquiryRequests();
    const response = await assistanceInquiryRequests.assignService(payload);
    // alert(JSON.stringify(updatedService));
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          background: "#191919",
          padding: "20px",
          minHeight: "618px",
          minWidth: "483px",
          borderRadius: "8px",
          border: "1px solid rgba(126, 126, 126, 0.60)",
        },
      }}
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"md"}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          objectFit: "cover",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            src={
              customer.profileImg ? customer.profileImg : DEFAULT_PROFILE_IMG
            }
            style={styles.picture}
            alt="User"
          />

          <DialogTitle>
            <Typography style={styles.title} sx={styles.subtitle}>
              {customer.name || ""}
            </Typography>
            <Typography
              variant="subtitle2"
              style={styles.subtitle}
              sx={styles.subtitle}
            >
              &nbsp;Los Angeles, CA
            </Typography>
          </DialogTitle>
        </div>
        <DialogActions style={{ marginBottom: "20px" }}>
          <Button variant="text" onClick={handleClose} sx={{ color: grey[50] }}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </div>

      <DialogContent>
        <div style={{ display: "flex", width: "100%" }}>
          <div
            style={{
              ...styles.tabDefault,
              flex: 1,
              borderBottom: `${
                currentTab === "Information"
                  ? "2px solid #FF7C06"
                  : "2px solid #fff"
              }`,
            }}
          >
            <button
              onClick={() => handleTabChange("Information")}
              style={{
                ...styles.buttonTab,
                color: `${currentTab === "Information" ? "#FF7C06" : "#fff"}`,
              }}
            >
              Information
            </button>
          </div>
          <div
            style={{
              ...styles.tabDefault,
              flex: 1,
              borderBottom: `${
                currentTab === "Service requested"
                  ? "2px solid #FF7C06"
                  : "2px solid #fff"
              }`,
            }}
          >
            <button
              onClick={() => handleTabChange("Service requested")}
              style={{
                ...styles.buttonTab,
                color: `${
                  currentTab === "Service requested" ? "#FF7C06" : "#fff"
                }`,
              }}
            >
              Service requested
            </button>
          </div>
        </div>

        {currentTab === "Information" && (
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              // m: "auto",
              width: "fit-content",
            }}
          >
            {information.map(({ label, value }, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 16,
                }}
              >
                <Typography style={styles.label}>{label}</Typography>
                <Typography style={styles.value}>{value}</Typography>
              </div>
            ))}
          </Box>
        )}

        {currentTab === "Service requested" && (
          <Box display="flex" flexDirection="column" gap={"2rem"}>
            {/* VEHICLE */}
            <Box
              noValidate
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <VehicleCard key={customerVehicle._id} {...customerVehicle} />
            </Box>

            {/* FIRST ROW */}

            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent="space-between"
              gap="1rem"
              mb="1rem"
            >
              {/* SELECT SERVICE TYPE */}
              <Box width={"399px"} height={"48px"} alignItems="center">
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    marginBottom: "7px",
                    lineHeight: "22px" /* 157.143% */,
                    letterSpacing: "-0.28px",
                  }}
                >
                  Service
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent="space-between"
                  alignItems={"center"}
                  width={"100%"}
                  height={"48px"}
                  pl="1rem"
                  sx={{
                    border: "1px solid #7E7E7E",
                    borderRadius: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "22px" /* 157.143% */,
                      letterSpacing: "-0.28px",
                    }}
                  >
                    {serviceType ? serviceType : "None"}
                  </Typography>
                  <DropdownMenu
                    onSelect={handleChangeService}
                    options={SERVICE_TYPE_VALUES}
                  />
                </Box>
              </Box>

              {/* SELECT SPECIALIST */}
              <Box width={"399px"} height={"48px"} alignItems="center">
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    marginBottom: "7px",
                    lineHeight: "22px" /* 157.143% */,
                    letterSpacing: "-0.28px",
                  }}
                >
                  Specialist
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent="space-between"
                  alignItems={"center"}
                  width={"100%"}
                  height={"48px"}
                  pl="1rem"
                  sx={{
                    border: "1px solid #7E7E7E",
                    borderRadius: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "22px" /* 157.143% */,
                      letterSpacing: "-0.28px",
                    }}
                  >
                    {updatedSpecialist ? updatedSpecialist[0]?.name : "None"}
                  </Typography>
                  <DropdownMenu
                    onSelect={handleSpecialistChange}
                    options={specialistsData}
                    optionName={"name"}
                  />
                </Box>
              </Box>
            </Box>

            {/* SECOND ROW */}
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent="space-between"
              gap="1rem"
              mb="1rem"
            >
              {/* PICK UP LOCATION */}
              <Box width={"399px"} height={"48px"} alignItems="center">
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    marginBottom: "7px",
                    lineHeight: "22px" /* 157.143% */,
                    letterSpacing: "-0.28px",
                  }}
                >
                  Pick up location
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "392px",
                    minHeight: "48px",
                    height: "auto",
                    padding: "5.5px 14px",
                    borderRadius: "6px",
                    border: "1px solid #7E7E7E",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    {serviceLocation.address}
                  </Typography>
                </Box>
              </Box>

              {/* SELECT COMPANY */}
              <Box width={"399px"} height={"48px"} alignItems="center">
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    marginBottom: "7px",
                    lineHeight: "22px" /* 157.143% */,
                    letterSpacing: "-0.28px",
                  }}
                >
                  Company
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent="space-between"
                  alignItems={"center"}
                  width={"100%"}
                  height={"48px"}
                  pl="1rem"
                  sx={{
                    border: "1px solid #7E7E7E",
                    borderRadius: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "22px" /* 157.143% */,
                      letterSpacing: "-0.28px",
                    }}
                  >
                    {updatedCompany ? updatedCompany[0].companyName : "None"}
                  </Typography>
                  <DropdownMenu
                    onSelect={handleCompanyChange}
                    options={companiesData}
                    optionName={"companyName"}
                  />
                </Box>
              </Box>
            </Box>

            {/* THIRD ROW */}

            {[
              "heavyDuty",
              "flatbedTow",
              "tireChange",
              "refuel",
              "jump",
              "unlock",
              "oilChange",
              "brakeService",
              "batteryReplacement",
              "tireRotation",
            ].includes(serviceType) ? (
              <></>
            ) : (
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent="space-between"
                gap="1rem"
                mb="1rem"
              >
                {/* DESTINATION LOCATION */}
                <Box width={"399px"} height={"48px"} alignItems="center">
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 400,
                      marginBottom: "7px",
                      lineHeight: "22px" /* 157.143% */,
                      letterSpacing: "-0.28px",
                    }}
                  >
                    Destination location
                  </Typography>
                  <Box
                    style={{
                      width: "392px",
                      minHeight: "48px",
                      padding: "5.5px 14px",
                      borderRadius: "6px",
                      border: "1px solid #7E7E7E",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      {destinationLocation.address}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}

            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent="space-between"
              alignItems="center"
              gap="1rem"
              mb="1rem"
            >
              <Box
                display="flex"
                width={"399px"}
                height={"60px"}
                alignItems="center"
              >
                <Button style={styles.buttomDispatch} onClick={handleDispatch}>
                  Dispatch
                </Button>
              </Box>
              <Box
                display="flex"
                width={"399px"}
                height={"60px"}
                alignItems="center"
                justifyContent={"flex-start"}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "22px" /* 157.143% */,
                    letterSpacing: "-0.28px",
                  }}
                >
                  Price: $ {updatedPrice}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RequestDialogAction;
