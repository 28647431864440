import axios from 'axios';
import { getHostPythonAPIBaseUrl } from "config";
// const { IP_BACKEND_HOST } = require('../config/env');

class UserRequests {
  async getPagination(filter:string,page: number, pageSize: number, dateFilter: string = "All",startDate: Number=0,endDate: Number=0,search: string | null = null) {
    let url = `${getHostPythonAPIBaseUrl()}/regular_users/table/?table_type=${filter}&page=${page}&limit=${pageSize}&date_filter=${dateFilter}&start_date=${startDate}&end_date=${endDate}`;
    if(search) {
      url += `&search=${encodeURI(search)}`
    }
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(url, {
        headers: {
          "Authorization": token
        }
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }

  async getPaginationGraphQL(filter: any, page: number, pageSize: number | null, logicOperator: string) {


    let url = `${getHostPythonAPIBaseUrl()}/regular_users/read-many-graphql?page=${page}&logic_operator=${encodeURI(logicOperator)}`;

    if (pageSize) {
      url += `&limit=${pageSize}`
    }

    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.post(url, filter, {
        headers: {
          "Authorization": token
        }
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }

  async getById(id: string) {
    const url = `${getHostPythonAPIBaseUrl()}/regular_users/id?id=${id}`;
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(url, {
        headers: {
          "Authorization": token
        }
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }

  async updateVerificationStatus(email: string, verificationStatus: string, message?: string) {
    const url = `${getHostPythonAPIBaseUrl()}/companies/verification/status`;
    const token = localStorage.getItem("access_token");
    return await axios.post(url, {
      email,
      verificationStatus,
      message
    }, {
      headers: {
        "Authorization": token
      }
    });
  }

  async getTotal(period: string) {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/regular_users/count?period=${period}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }

  async getTotalFilter(field: string, value: any) {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/regular_users/count/filter`;
    try {
      const response = await axios.post(url, {
        field,
        value,
      }, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }

  async getAllFilter(field: string, value: any) {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/regular_users/all`;
    try {
      const response = await axios.post(url, {
        field,
        value,
      }, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }

  async getAbsolute() {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/regular_users/count/absolute`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }

  async getAll() {
    const url = `${getHostPythonAPIBaseUrl()}/regular_users/all`;
    const token = localStorage.getItem("access_token");
    const response = await axios.get(url, {
      headers: {
        "Authorization": token
      }
    });
    return response.data;
  }

  async delete(email: string) {
    const url = `${getHostPythonAPIBaseUrl()}/regular_users?user_email=${email}`;
    const token = localStorage.getItem("access_token");
    return await axios.delete(url, {
      headers: {
        "Authorization": token
      }
    });
  }

  async updateDeviceToken(token: string, device?: string, platform?: string, email?: string | null) {
    const url = `${getHostPythonAPIBaseUrl()}/firebase`;

    const data = {
      deviceToken: {
        platform,
        device,
        token,
      },

      email
    };

    try {
      const response = await axios.put(url, data, {
        headers: {
          "Authorization": localStorage.getItem("access_token")
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating device token:', error);
      throw error;
    }
  }


  async overviewChartUser() {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/regular_users/count/chart`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }

  async pieUserChartData() {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/regular_users/token`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }

  async notifyOneSignal(title: string, message: string, deviceTokens: string[]) {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/shared/onesignal/send-notification`;
    try {
      const response = await axios.post(url, {
        title,
        message,
        device_tokens: deviceTokens
      }, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async sendEmailNotifications(title: string, message: string, recipients: string[]) {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/shared/send-emails`;
    try {
      const response = await axios.post(url, {
        title,
        message,
        recipients
      }, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }


  async sendSMSNotifications( message: string, recipients: string[]) {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/twilio/send/sms`;
    try {
      const response = await axios.post(url, {
        message,
        recipients
      }, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
export default UserRequests;
