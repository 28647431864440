import axios from "axios";
import { getHostPythonAPIBaseUrl } from "config";
// const { IP_BACKEND_HOST } = require('../config/env');

class ReviewRequests {
  async getPagination(filter: any, page: number, pageSize: number) {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/api/review/?page=${page}&limit=${pageSize}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ",error)
    }
  }
}
export default ReviewRequests;
