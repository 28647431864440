import React, { useState } from "react";

interface ColorPickerProps {
  onChange?: (color: string) => void;
  handleChangeColor: (hex: string, index: number, colorIndex:number) => void;
  currentColor?: string;
  visible: boolean;
  currentColorIndex: number;
  currentTagIndex: number;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  onChange,
  currentColor,
  currentColorIndex,
  visible,
  handleChangeColor,
  currentTagIndex
}) => {
  const colors = ["#1279F2", "#FF7C06", "#FF2D2D", "#34A853"];

  return (
    <div>
      {visible && (
        <div
          style={{
            borderRadius: "8px",
            border: "1px solid var(--dark-dark-600, #3E3E3E)",
            background: "var(--dark-dark-500, #191919)",
            display: "flex",
            padding: "16px",
            alignItems: "flex-start",
            gap: "8px",
            position: "absolute",
            left: "188px",
            bottom: "2px",
        
          }}
        >
          {colors.map((c, index) => (
            <div
              onClick={() => {
                handleChangeColor(c,index,currentTagIndex);
              }}
              key={"color" + index + '_'}
            >
              <div
                style={{
                  borderRadius: "50%",
                  height: "19px",
                  width: "19px",
                  backgroundColor: c,

                }}
              ></div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
