import { getServiceRequestApiUrl, getHostRustWsBaseUrl, getHostRustAPIBaseUrl } from "config";
// import { IP_WS_HOST, SERVICE_REQUEST_HOST } from "../config/env";
import { useState, useEffect, ChangeEvent, FormEvent } from "react";
import ServiceRequests from "../requests/serviceRequests";
import UserRequests from "../requests/userRequests";
import AssistanceInquiryRequests from "../requests/assistanceInquiry";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CHAT_TAB_OPTIONS, DEFAULT_PROFILE_IMG } from "utils/const";
import axios from "axios";
// const { SERVICE_WS_REQUEST_HOST } = require("../config/env");

interface ChatMessage {
    id: string;
    chatRoomId: string;
    content: string;
    memberId: string;
    memberReferenceId: string;
    memberName: string;
    recipients: string[];
    data: any;
    updated: string;
    created: string;
}

interface ChatViewModel {
    addParticipant: boolean;
    assistanceRequests: AssistanceInquiryRequests;
    chats: any[];
    chatsLoading: boolean;
    currentChatRoom: any;
    deleteChat: boolean;
    currentTab: string;
    fetchChats: (currentTab: string) => Promise<void>;
    filteredChats: any[];
    filteredChatsLoading: boolean;
    filteredNotCustomerChatParticipants: any[];
    formatMessageDate: (date: string) => string;
    handleAddParticipant: () => void;
    handleChangeTab: (e: string) => Promise<void>;
    handleCloseAddParticipant: () => void;
    handleCloseNewConversation: () => void;
    handleInvitedToChat: () => void;
    handleCloseInvitedToChat: () => void;
    handleDeleteChat: () => void;
    handleCloseDeleteChat: () => void;
    handleCloseRemoveParticipant: () => void;
    handleFileChange: (e: ChangeEvent<HTMLInputElement | undefined>) => void;
    handleFilterChats: (e: ChangeEvent<HTMLInputElement>) => void;
    handleFilterNotCustomerChatParticipant: (
        e: ChangeEvent<HTMLInputElement>,
    ) => void;
    handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleNewConversation: () => void;
    handleRemoveParticipant: () => void;
    handleSelectParticipant: (value: string) => void;
    handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
    isConnected: boolean;
    messages: ChatMessage[];
    newConversation: boolean;
    newMessage: string;
    notCustomerChatParticipants: any;
    onInitialized: () => Promise<void>;
    removeParticipant: boolean;
    invitedToChat: boolean;
    serviceRequests: ServiceRequests;
    session: any;
    setChat: (chat: any) => void;
    setCurrentChatId: (id: string) => void;
    socket: WebSocket | null;
    userRequests: UserRequests;
}

const useChatViewModel = (): ChatViewModel => {
    const [userRequests, setUserRequests] = useState(new UserRequests());
    const [messages, setMessages] = useState<ChatMessage[]>([]); // State for messages
    const [newMessage, setNewMessage] = useState<string>(""); // State for new message input
    const sessionDataString: string | null = localStorage.getItem("session");
    const [assistanceRequests, setAssistanceRequests] = useState(
        new AssistanceInquiryRequests(),
    );
    const initialSession = sessionDataString
        ? JSON.parse(sessionDataString)
        : {};
    const [session, setSession] = useState<any>(initialSession);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [isConnected, setIsConnected] = useState(false);
    const [user, setUser] = useState<any>({});
    const [deleteChat, setDeleteChat] = useState(false);
    const [serviceRequests, setServiceRequests] = useState(
        new ServiceRequests(),
    );
    const [chats, setChats] = useState<any[]>([]);
    const [chatsLoading, setChatsLoading] = useState<boolean>(false);
    const [filteredChats, setFilteredChats] = useState<any[]>([]);
    const [filteredChatsLoading, setFilteredChatsLoading] =
        useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState(CHAT_TAB_OPTIONS[0]);
    const [currentChatRoom, setCurrentChatRoom] = useState<any>(null);
    const [chatRoomId, setChatRoomId] = useState<string>("");
    const [invitedToChat, setInvitedToChat] = useState(false);
    const [notCustomerChatParticipants, setNotCustomerChatParticipants] =
        useState<any[]>([]);
    const [
        filteredNotCustomerChatParticipants,
        setFilteredNotCustomerChatParticipants,
    ] = useState<any[]>([]);
    const [now, setNow] = useState<string>(new Date().getTime().toString());
    const [addParticipant, setAddParticipant] = useState(false);
    const [removeParticipant, setRemoveParticipant] = useState(false);
    const [newConversation, setNewConversation] = useState(false);
    const [isData, setIsData] = useState<boolean>(false);
    const [isReset, setIsReset] = useState<boolean>(false);
    const { id } = useParams();

    if (id && id !== chatRoomId) {
        setChatRoomId(id);
        console.log(`Chat room ${id}`);
    }

    async function onInitialized() {
        if (initialized) return;
        setInitialized(true);
        await fetchChats(currentTab);
    }

    function setChat(chat: any) {
        setMessages([]);
        setCurrentChatRoom(chat);
        setChatRoomId(chat.message.chatRoomId);
        console.log("Chat has been updated:", JSON.stringify(chat));
    }

    async function setCurrentChatId(id: string) {
        setChatRoomId(id);

        const data = await serviceRequests.getServiceByChat(id);
        if (!data) {
            return;
        }
        if (data.payload) {
            setCurrentChatRoom(data.payload);
        }
    }

    useEffect(() => {
        onInitialized();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setOnlineMembers(filteredChats);
        }, 15000);

        return () => {
            clearInterval(intervalId);
        };
    }, [filteredChats, setOnlineMembers]);


    async function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (newMessage.trim() === "") return;
        const url = `${getServiceRequestApiUrl()}/chat/chat-message/create`;
        const dataMessage = {
            chatRoomId: chatRoomId,
            content: newMessage,
            memberId: "",
            data: {},
            memberName: session.full_name,
            memberReferenceId: session._id,
            recipients: ["bot", "admin", "customer", "company", "specialist"],
        }
        console.log(dataMessage)
        await axios.post(url, dataMessage);
    }

    useEffect(() => {
        const initialParticipants = [
            {
                id: "1",
                name: "Amanda Jones",
                email: "amandajones@gmail.com",
                profileImg: DEFAULT_PROFILE_IMG,
                isOnline: true,
            },
            {
                id: "2",
                name: "Emma Miller",
                email: "emmamiller@gmail.com",
                profileImg: DEFAULT_PROFILE_IMG,
                isOnline: false,
            },
            {
                id: "3",
                name: "Sophia Davis",
                email: "sophiadavis@gmail.com",
                profileImg: DEFAULT_PROFILE_IMG,
                isOnline: true,
            },
            {
                id: "4",
                name: "Sarah Smith",
                email: "sarasmith@gmail.com",
                profileImg: DEFAULT_PROFILE_IMG,
                isOnline: false,
            },
        ];

        setNotCustomerChatParticipants(initialParticipants);
        setFilteredNotCustomerChatParticipants(initialParticipants);
    }, []);

    useEffect(() => {
        const fetchRsToken = async () => {
            try {
                const urlSignup = `${getHostRustAPIBaseUrl()}/auth/sign-in`;
                const response = await axios.post(urlSignup, {
                    "userId": session["_id"]
                });

                localStorage.setItem("access_rs_token", response.data.message)
            } catch (error) {
                console.error("Error fetching token:", error);
            }
        };
        if (!localStorage.getItem("access_rs_token")) {
            fetchRsToken()
        }

    }, [])

    useEffect(() => {
        if (!session || !chatRoomId || chats.length === 0) {
            return;
        }
    
        const delay = 1000;
        let ws: WebSocket | null = null;
    
        const openWebSocket = () => {
            ws = new WebSocket(`${getHostRustWsBaseUrl()}/chat?chatRoomId=${chatRoomId}`);
    
            ws.onopen = () => {
                console.log("WebSocket connection established.");
                setIsConnected(true);
                setSocket(ws);
                setOnlineMembers(filteredChats);
            };
    
            ws.onmessage = (event: MessageEvent) => {
                if (typeof event.data === "string") {
                    try {
                        const receivedMessage: ChatMessage = JSON.parse(
                            event.data,
                        );
    
                        if (
                            !messages.some(
                                (message) => message.id === receivedMessage.id,
                            )
                        ) {
                            if (receivedMessage.memberId !== session.Id) {
                                // !TODO fix this
                                setMessages((prevMessages) => [
                                    ...prevMessages,
                                    receivedMessage,
                                ]);
                            }
                        }
                    } catch (error) {
                        console.error("Error parsing JSON:", error);
                    }
                } else if (event.data instanceof ArrayBuffer) {
                    const data = new Uint8Array(event.data);
                    if (data[0] === 0x9) {
                        console.log("Recebi um binário 0x9");
                    }
                    // seu código para lidar com mensagens binárias aqui
                }
            };
    
            ws.onclose = () => {
                if (isConnected) {
                    console.log("WebSocket connection closed.");
                    setIsConnected(false);
                }
            };
    
            ws.onerror = (error: any) => {
                console.error("WebSocket error:", error);
            };
        };
    
        const fetchChatHistory = async () => {
            const urlhistory = `${getHostRustAPIBaseUrl()}/chat/message?page=1&pageSize=32&chatRoomId=${chatRoomId}`;
    
            try {
                const response = await axios.get(urlhistory, {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem("access_rs_token")}`,
                    },
                });
                const history = response.data.payload
                setMessages(history)
                openWebSocket();
            } catch (error) {
                console.error("Error fetching chat history:", error);
            }
        };
    
        fetchChatHistory();
    
        // Cleanup function to close the WebSocket if the component unmounts
        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, [currentChatRoom, chatRoomId, currentTab]);
    
    async function fetchOnlineMembers(): Promise<any[]> {
        try {
            const response = await assistanceRequests.getOnlineMembers();
            if (!response) {
                return Promise.resolve([]);
            }
            return Promise.resolve(response.payload);
        } catch (error) {
            console.error("Error fetching assistance:", error);
            return Promise.resolve([]);
        }
    }

    function formatMessageDate(created: string) {
        const messageDate = new Date(created);
        const currentDate = new Date();

        if (
            messageDate.getDate() === currentDate.getDate() &&
            messageDate.getMonth() === currentDate.getMonth() &&
            messageDate.getFullYear() === currentDate.getFullYear()
        ) {
            // Format as HH:mm AM/PM
            const hours = messageDate.getHours();
            const minutes = messageDate.getMinutes();
            const amOrPm = hours >= 12 ? "PM" : "AM";
            const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
            return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
        } else {
            // Format as MM/dd/yyyy HH:mm AM/PM
            const month = messageDate.getMonth() + 1;
            const day = messageDate.getDate();
            const year = messageDate.getFullYear();
            const hours = messageDate.getHours();
            const minutes = messageDate.getMinutes();
            const amOrPm = hours >= 12 ? "PM" : "AM";
            const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
            return `${month}/${day} ${formattedHours}:${formattedMinutes} ${amOrPm}`;
        }
    }

    async function fetchChats(currentTab: string): Promise<void> {
        const finished = currentTab === "Active" ? false : true;
        setChatsLoading(true);
        setFilteredChatsLoading(true);
        if (isReset) {
            setChats([]);
            setIsReset(false);
        }
        try {
            const response = await assistanceRequests.getChats(finished);
            if (!response) {
                return;
            }

            // Combine the new chat data with the existing chats array
            setChats(response);
            setFilteredChats(response);
            await setOnlineMembers(response);

        } catch (error) {
            console.error("Error fetching assistance:", error);
        } finally {
            setChatsLoading(false);
            setFilteredChatsLoading(false);
        }
    }

    async function setOnlineMembers(c: any) {
        console.log("filteredChats", c);
        if (c.length === 0) return;
        const response = await fetchOnlineMembers();
        if (!response) return;
        let newChats: any[] = [];
        c.map((chat: any) => {
            chat.isOnline = false;
            response.map((memberOnline) => {
                if (
                    memberOnline.referenceId === chat.customer.id ||
                    memberOnline.chatRoomId === chat.message.chatRoomId
                ) {
                    chat.isOnline = true;
                }
            });
            newChats.push(chat);
        });
        console.log("newChats", newChats)
        setFilteredChats(newChats);
    }

    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        setNewMessage(e.target.value);
    }

    function handleFileChange(e: ChangeEvent<HTMLInputElement | undefined>) {
        if (e.target.files) {
            const selectedFile = e.target.files?.[0].name;
            setNewMessage(selectedFile);
        }
    }

    async function handleChangeTab(/** */ tab: string) {
        console.log(tab);

        setIsReset(true);
        setCurrentTab(tab);
        await fetchChats(tab);
    }

    function handleAddParticipant() {
        setAddParticipant(true);
    }

    function handleCloseAddParticipant() {
        setAddParticipant(false);
        console.log("addParticipant-> ", addParticipant);
    }

    function handleInvitedToChat() {
        setInvitedToChat(true);
    }

    function handleCloseInvitedToChat() {
        setInvitedToChat(false);
    }

    function handleDeleteChat(): void {
        setDeleteChat(true);
    }

    function handleCloseDeleteChat(): void {
        setDeleteChat(false);
    }

    function handleRemoveParticipant() {
        setRemoveParticipant(true);
    }

    function handleCloseRemoveParticipant() {
        setRemoveParticipant(false);
    }

    function handleNewConversation() {
        setNewConversation(true);
    }

    function handleCloseNewConversation() {
        setNewConversation(false);
        console.log("newConversation->", newConversation);
    }

    function handleSelectParticipant(value: string) {
        console.log(value);
    }

    function handleFilterNotCustomerChatParticipant(
        e: ChangeEvent<HTMLInputElement>,
    ) {
        console.log(e.target.value);
        let result = notCustomerChatParticipants.filter((el) =>
            el.name.toLowerCase().includes(e.target.value.toLowerCase()),
        );
        setFilteredNotCustomerChatParticipants(result);
    }

    function handleFilterChats(e: ChangeEvent<HTMLInputElement>) {
        let result = chats.filter((el) =>
            el.customer.name
                .toLowerCase()
                .includes(e.target.value.toLowerCase()),
        );
        setFilteredChats(result);
    }


    return {
        addParticipant,
        assistanceRequests,
        chats,
        chatsLoading,
        currentChatRoom,
        currentTab,
        deleteChat,
        fetchChats,
        filteredChats,
        filteredChatsLoading,
        filteredNotCustomerChatParticipants,
        formatMessageDate,
        handleAddParticipant,
        handleChangeTab,
        handleCloseAddParticipant,
        handleCloseDeleteChat,
        handleCloseInvitedToChat,
        handleCloseNewConversation,
        handleCloseRemoveParticipant,
        handleDeleteChat,
        handleFileChange,
        handleFilterChats,
        handleFilterNotCustomerChatParticipant,
        handleInputChange,
        handleInvitedToChat,
        handleNewConversation,
        handleRemoveParticipant,
        handleSelectParticipant,
        handleSubmit,
        invitedToChat,
        isConnected,
        messages,
        newConversation,
        newMessage,
        notCustomerChatParticipants,
        onInitialized,
        removeParticipant,
        serviceRequests,
        session,
        setChat,
        setCurrentChatId,
        socket,
        userRequests,
    };
};

export default useChatViewModel;
