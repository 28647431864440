import React, { useState, useEffect, useRef } from "react";
import MapBox, {
  MapRef,
  Layer,
  Marker,
  Source,
  HeatmapLayer,
} from "react-map-gl";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import axios from "axios";
import "../Map/Map.css";
import { useTheme, Box, Button, ButtonGroup } from "@mui/material";

import Header from "components/Header";
import ServiceRequests from "requests/serviceRequests";

import LocationRequests from "requests/locationRequests";
import { getMapboxToken, getMapboxStyle } from "config";
// const { MAP_BOX_STYLE } = require("../../config/env");
// const { MAP_BOX_TOKEN } = require("../../config/env");

const locationRequest = new LocationRequests();
const serviceRequests = new ServiceRequests();
type Marker = {
  id: string;
  date: string;
  latitude: number;
  longitude: number;
  type: string;
  name: string;
  dispatched: boolean;
};
interface Location {
  latitude: number;
  longitude: number;
  zoom: number;
}

const heatmapLayer = {
  id: "heatmap-layer",
  type: "heatmap",
  source: "earthquakes", // Replace with your source id
  maxzoom: 9,
  paint: {
    // Define heatmap paint properties here
    "heatmap-weight": ["interpolate", ["linear"], ["get", "mag"], 0, 0, 6, 1],
    "heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 9, 3],
    "heatmap-color": [
      "interpolate",
      ["linear"],
      ["heatmap-density"],
      0,
      "rgba(33,102,172,0)",
      0.2,
      "rgba(103,169,207,0.7)",
      0.4,
      "rgb(209,229,240,0.7)",
      0.6,
      "rgba(253,219,199,0.7)",
      0.8,
      "rgba(239,138,98,0.7)",
      1,
      "rgba(178,24,43,0.7)",
    ],
    "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 2, 9, 5],
    "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 7, 1, 9, 0],
  },
};

const ServicesRequest = () => {
  const theme = useTheme();
  const [data, setData] = useState<any>(null);
  const [features, setFeatures] = useState<any[]>([]);
  const [userType, setuserType] = useState<string>("all");
  const mapRef = useRef<MapRef>(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [timestampStart, setTimestampStart] = useState<number>(0);
  const [timestampEnd, setTimestampEnd] = useState<number>(0);
  const [startTime, setStartTime] = useState("00:00:00");
  const [endTime, setEndTime] = useState("23:59:59");
  const [viewPort, setViewPort] = useState<Location>({
    latitude: 34.053691,
    longitude: -118.242766,
    zoom: 16,
  });

  useEffect(() => {
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);

    const yesterdayStartDate = new Date(yesterday);
    yesterdayStartDate.setHours(0, 0, 0, 0);

    const yesterdayEndDate = new Date(yesterday);
    yesterdayEndDate.setHours(23, 59, 59, 999);

    const formattedStartDate = yesterdayStartDate.toISOString().substr(0, 10);
    const formattedEndDate = yesterdayEndDate.toISOString().substr(0, 10);

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  }, []);

  //TODO CREATE THE REQUEST TO CASSANDRA TO GET DATA
  useEffect(() => {
    // Fetch GeoJSON data using Axios
    axios
      .get("https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson")
      .then((response) => {
        console.log("data fetch ------------->", response.data);
        
        setData(response.data);
        setFeatures(response.data.features);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const handleFilterClick = () => {
    // Combine the selected start date and time
    const selectedStartDateTime = new Date(`${startDate}T${startTime}`);
    // Convert the combined date-time to a Unix epoch timestamp (in seconds)
    const startTimestamp = Math.floor(selectedStartDateTime.getTime() / 1000);

    // Combine the selected end date and time
    const selectedEndDateTime = new Date(`${endDate}T${endTime}`);
    // Convert the combined date-time to a Unix epoch timestamp (in seconds)
    const endTimestamp = Math.floor(selectedEndDateTime.getTime() / 1000);

    console.log(
      "Unix epoch timestamp for Selected Start Date/time:",
      startTimestamp
    );
    console.log(
      "Unix epoch timestamp for Selected End Date/time:",
      endTimestamp
    );

    setTimestampStart(startTimestamp);
    setTimestampEnd(endTimestamp);

    // Filter features based on the time range


    // Apply your filter logic using startTimestamp and endTimestamp
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
  };

  const handleUserTypeChange = (type: string) => {
    setuserType(type);
  };
  const handleStartTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartTime(event.target.value);
  };

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndTime(event.target.value);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="HEATMAP" subtitle="List of users" />
      <ButtonGroup variant="outlined">
        <Button
          onClick={() => handleUserTypeChange("all")}
          sx={{
            backgroundColor:
              userType === "all"
                ? // @ts-ignore
                  theme.palette.secondary[300]
                : // @ts-ignore
                  theme.palette.primary[600],
            color:
              userType === "all"
                ? theme.palette.primary.main
                : // @ts-ignore
                  theme.palette.secondary[100],
            ":hover": {
              backgroundColor:
                userType === "all"
                  ? // @ts-ignore
                    theme.palette.secondary[300]
                  : // @ts-ignore
                    theme.palette.secondary[900],
            },
          }}
        >
          ALL
        </Button>
        <Button
          onClick={() => handleUserTypeChange("specialist")}
          sx={{
            backgroundColor:
              userType === "specialist"
                ? // @ts-ignore
                  theme.palette.secondary[300]
                : // @ts-ignore
                  theme.palette.primary[600],
            color:
              userType === "specialist"
                ? theme.palette.primary.main
                : // @ts-ignore
                  theme.palette.secondary[100],
            ":hover": {
              backgroundColor:
                userType === "specialist"
                  ? // @ts-ignore
                    theme.palette.secondary[300]
                  : // @ts-ignore
                    theme.palette.secondary[900],
            },
          }}
        >
          specialist
        </Button>
        <Button
          onClick={() => handleUserTypeChange("customer")}
          sx={{
            backgroundColor:
              userType === "customer"
                ? // @ts-ignore
                  theme.palette.secondary[300]
                : // @ts-ignore
                  theme.palette.primary[600],
            color:
              userType === "customer"
                ? theme.palette.primary.main
                : // @ts-ignore
                  theme.palette.secondary[100],
            ":hover": {
              backgroundColor:
                userType === "customer"
                  ? // @ts-ignore
                    theme.palette.secondary[300]
                  : // @ts-ignore
                    theme.palette.secondary[900],
            },
          }}
        >
          customer
        </Button>
      </ButtonGroup>
      <br />

      <div style={{ marginTop: 12 }}>
        <div>
          <label>Start Datetime</label>
          <br />
          <input
            style={{ border: "1px solid #c0c0c0" }}
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
          />
          <input
            style={{ border: "1px solid #c0c0c0" }}
            type="time"
            value={startTime}
            onChange={handleStartTimeChange}
          />
        </div>
        <br />
        <div>
          <label>End Datetime</label>
          <br />
          <input
            type="date"
            style={{ border: "1px solid #c0c0c0" }}
            value={endDate}
            onChange={handleEndDateChange}
          />
          <input
            type="time"
            style={{ border: "1px solid #c0c0c0" }}
            value={endTime}
            onChange={handleEndTimeChange}
          />
          <br />
        </div>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 12, border: "1px solid white" }}
          onClick={handleFilterClick}
        >
          Filter
        </Button>
      </div>
      <MapBox
        ref={mapRef}
        initialViewState={viewPort}
        style={{
          width: "auto",
          height: "75vh",
          borderRadius: "10px",
          marginTop: "5vh",
          marginBottom: "6vh",
        }}
        mapStyle={getMapboxStyle()}
        // transitionDuration="200"
        mapboxAccessToken={getMapboxToken()}
        // onViewportChange={(viewPort) => setViewPort(viewPort)}
        attributionControl={false}
      >
        {" "}
        <Source id="earthquakes" type="geojson" data={data}>
          <Layer
            id="heatmap-layer"
            type="heatmap"
            source="earthquakes"
            paint={{
              // Define heatmap paint properties here
              "heatmap-weight": {
                property: "mag",
                type: "exponential",
                stops: [
                  [0, 0],
                  [6, 1],
                ],
              },
              // ... (other paint properties)
            }}
          />
        </Source>
      </MapBox>
    </Box>
  );
};

export default ServicesRequest;
