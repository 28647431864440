import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import ChatBotRequests from "requests/chatBotRequests";

interface AddVectorStoreDialogProps {
  open: boolean;
  onClose: () => void;
  emitFetchData: ()=>void;
}

const chatBotRequests = new ChatBotRequests()

interface FormData {
  name: string;
}

const AddVectorStoreDialog: React.FC<AddVectorStoreDialogProps> = ({
  open,
  onClose,
  emitFetchData
}) => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
  });

  const handleCreate = async () => {
    if (formData.name.trim() === "") {
      alert("Name cannot be empty");
      return;
    }

    try {
     await chatBotRequests.createVectoreStore(formData)
      emitFetchData();
      onClose(); 

    } catch (err) {
      console.error("Error creating vector store:", err);
      alert("Failed to create vector store. Please try again later.");
    }
    

  // Close the dialog after creating the vector store
  };

  const handleInputChange = (field: keyof FormData, value: any) => {
    setFormData({ ...formData, [field]: value });
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>Add New Vector Store</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          color="secondary"
          value={formData.name}
          onChange={(e) => handleInputChange("name", e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button variant="outlined" color="warning" onClick={handleCreate}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddVectorStoreDialog;
