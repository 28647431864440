import React, { useMemo } from "react";
import { IconButton, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { formattedDate } from "utils/helper";
import EditIcon from '@mui/icons-material/Edit';

const TableMaiaBrain = ({
  items,
  handleDeleteFile,
  handleEditItem,
}: {
  items: any[];
  handleDeleteFile: (id: string) => void;
  handleEditItem: (id: string) => void;
}) => {
  const columns = useMemo(
    () => [
      {
        field: "system",
        headerName: "System Message",
        flex: 0.1,
        renderCell: (params: any) => (
          <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
            <small>{params.value}</small>
          </div>
        ),
      },
      {
        field: "user",
        headerName: "User Message",
        flex: 0.1,
        renderCell: (params: any) => (
          <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
            <small>{params.value}</small>
          </div>
        ),
      },
      {
        field: "assistant",
        headerName: "Assistant Message",
        flex: 0.3,
        renderCell: (params: any) => (
          <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
            <small>{params.value}</small>
          </div>
        ),
      },
      {
        field: "created",
        headerName: "Created",
        flex: 0.1,
        valueFormatter: (params: any) => {
          if (params.value == null) {
            return "";
          }
          return formattedDate(params.value);
        },
      },
      {
        field: "edit",
        headerName: "Edit",
        flex: 0.05,
        renderCell: (params: any) => (
          <IconButton
            aria-label="delete"
            onClick={() => handleEditItem(params.row._id)}
          >
            <EditIcon color="secondary" />
          </IconButton>
        ),
      },
      {
        field: "delete",
        headerName: "Delete",
        flex: 0.05,
        renderCell: (params: any) => (
          <IconButton
            aria-label="delete"
            onClick={() => handleDeleteFile(params.row._id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
        ),
      },
    ],
    [handleDeleteFile]
  );

  return (
    <Box mt="40px" minWidth="100%" height="auto">
      <DataGrid
        autoHeight
        rowHeight={220}
        disableExtendRowFullWidth={false}
        loading={false}
        getRowId={(row) => row._id}
        rows={items || []}
        columns={columns}
        hideFooter
      />
    </Box>
  );
};

export default TableMaiaBrain;
