import React, { useEffect, useMemo, useState } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import BuildIcon from "@mui/icons-material/Build";
import {
  Avatar,
  Box,
  Button,
  Fab,
  MenuItem,
  Pagination,
  Select,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import StatBox from "components/StatBox";
import usePaymentViewModel from "./payment";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { formattedDate, formattedDateEpoch } from "utils/helper";
import { styles } from "./styles";
import { DataGrid } from "@mui/x-data-grid";
import PriceDialog from "./priceDialog";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DateFilter from "components/DateFilter";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import AddPaymentDialog from "./addPaymentDialog";
import LinkIcon from "@mui/icons-material/Link";
import { getStripeUrl } from "config";

const Payment: React.FC = () => {
  const viewModel = usePaymentViewModel();
  const isNonMediumScreens: boolean = useMediaQuery("(min-width: 1200px)");
  const theme = useTheme();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const columnsPayment: any = useMemo(
    () => [
      {
        field: "_id",
        headerName: "_id",
        flex: 0.2,
        renderCell: (params: any) => {
          return <small>{params.value}</small>;
        },
      },
      {
        field: "amount",
        headerName: "Amount",
        flex: 0.1,
        valueFormatter: (params: any) => {
          if (!params.value) {
            return "";
          }
          return `$ ${params.value / 100}`;
        },
      },
      {
        field: "currency",
        headerName: "Currency",
        flex: 0.1,
      },
      {
        field: "paymentMethod",
        headerName: "Payment Method",
        flex: 0.2,
        renderCell: (params: any) => {
          return <div>{params.value}</div>;
        },
      },
      {
        field: "category",
        headerName: "Category",
        flex: 0.2,
      },
      {
        field: "createdAt",
        headerName: "Created",
        flex: 0.2,
        valueFormatter: (params: any) => {
          if (!params.value) {
            return "";
          }
          const date = formattedDate(params.value);
          return date;
        },
      },

      {
        field: "charges",
        headerName: "Refund",
        flex: 0.1,
        valueFormatter: (params: any) => {
          if (!params.value) {
            return "NaN";
          }
          const value = params.value.data[0]["amount_refunded"] / 100;
          return `$ ${value}`;
        },
      },

      {
        field: "status",
        headerName: "status",
        flex: 0.2,
        renderCell: (params: any) => {
          let color = "#7E7E7E";
          if (params.value == "canceled") {
            color = "#FF2D2D";
          } else if (params.value == "succeeded") {
            color = "#74be66";
          }

          return (
            <div>
              <Button
                style={{
                  color: color,
                  border: `1px solid ${color}`,
                  borderRadius: `18px`,
                  padding: "6px 8px",
                }}
              >
                <small>{params.value}</small>
              </Button>
            </div>
          );
        },
      },
      {
        field: "paymentIntent",
        headerName: "Url",
        flex: 0.1,
        renderCell: (params: any) => {
          return (
            <Fab
              color="primary"
              size="small"
              onClick={() => {
                const url = `${getStripeUrl()}payments/${params.value}`;
                window.open(url, "_blank");
              }}
            >
              <LinkIcon style={{ fontSize: "1.4rem" }} color="secondary" />
            </Fab>
          );
        },
      },
      {
        field: "action",
        headerName: "Actions",
        flex: 0.2,
        renderCell: (params: any) => {
          return (
            <Fab
              color="primary"
              size="small"
              onClick={() => {
                const id = params.id;
                let selectedPayment = null;
                for (let i = 0; i < viewModel.data.length; i++) {
                  if (viewModel.data[i]._id === id) {
                    console.log("selected id -->", viewModel.data[i]._id);
                    selectedPayment = viewModel.data[i];
                    viewModel.setSelectedPayment(selectedPayment);
                    break;
                  }
                }

                setIsDialogOpen(!isDialogOpen);
              }}
            >
              {/* Larger Icon */}
              <MoreVertIcon style={{ fontSize: "1.4rem" }} color="secondary" />
            </Fab>
          );
        },
      },
    ],
    [viewModel.data]
  );

  const columnsPayout: any = useMemo(
    () => [
      {
        field: "_id",
        headerName: "_id",
        flex: 0.18,
        renderCell: (params: any) => {
          return <small>{params.value}</small>;
        },
      },
      {
        field: "amount",
        headerName: "Amount",
        flex: 0.2,
        valueFormatter: (params: any) => {
          if (!params.value) {
            return "";
          }
          return `$ ${params.value / 100}`;
        },
      },
      {
        field: "currency",
        headerName: "Currency",
        flex: 0.2,
      },
      {
        field: "method",
        headerName: "Method",
        flex: 0.2,
        renderCell: (params: any) => {
          return <div>{params.value}</div>;
        },
      },
      {
        field: "type",
        headerName: "Type",
        flex: 0.2,
      },
      {
        field: "arrival_date",
        headerName: "Arrival date",
        flex: 0.2,
        valueFormatter: (params: any) => {
          if (!params.value) {
            return "";
          }
          const date = formattedDate(params.value);
          return date;
        },
      },

      {
        field: "status",
        headerName: "status",
        flex: 0.2,
        renderCell: (params: any) => {
          let color = "#7E7E7E";
          if (params.value == "canceled") {
            color = "#FF2D2D";
          } else if (params.value == "succeeded") {
            color = "#74be66";
          }

          return (
            <div>
              <Button
                style={{
                  color: color,
                  border: `1px solid ${color}`,
                  borderRadius: `18px`,
                  padding: "6px 8px",
                }}
              >
                <small>{params.value}</small>
              </Button>
            </div>
          );
        },
      },
      {
        field: "payout",
        headerName: "Url",
        flex: 0.15,
        renderCell: (params: any) => {
          return (
            <Fab
              color="primary"
              size="small"
              onClick={() => {
                const url = `${getStripeUrl()}payouts/${params.value}`;
                window.open(url, "_blank");
              }}
            >
              <LinkIcon style={{ fontSize: "1.4rem" }} color="secondary" />
            </Fab>
          );
        },
      },
      {
        field: "action",
        headerName: "Actions",
        flex: 0.4,
        renderCell: (params: any) => {
          return (
            <Fab
              color="primary"
              size="small"
              onClick={() => {
                setIsDialogOpen(!isDialogOpen);
              }}
            >
              {/* Larger Icon */}
              <MoreVertIcon style={{ fontSize: "1.4rem" }} color="secondary" />
            </Fab>
          );
        },
      },
    ],
    [viewModel.data]
  );

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header
          title={viewModel.isSwitchChecked ? "PAYOUTS" : "PAYMENTS"}
          subtitle="The place where you can do financial operations"
        />
      </FlexBetween>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* <StatBox
          title="Total Service"
          value={"0"}
          increase={!viewModel.data}
          increasePercentage={"10%"}
          description=""
          icon={
            <BuildIcon
              // @ts-ignore
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Specialist’s Total"
          value={"$0"}
          increase={!viewModel.data}
          increasePercentage={viewModel.serviceStatus.percentageDay}
          description=""
          icon={
            <HowToRegIcon
              // @ts-ignore
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />

        <StatBox
          title="Roadr Total"
          value={"$0"}
          increase={!viewModel.data}
          increasePercentage={viewModel.serviceStatus.percentageMonth}
          description=""
          icon={
            <TimeToLeaveIcon
              // @ts-ignore
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title="Refunded"
          value={"$0"}
          increase={!viewModel.data}
          increasePercentage={viewModel.serviceStatus.percentageYear}
          description=""
          icon={
            <SwapVertIcon
              // @ts-ignore
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        /> */}
      </Box>
      <Box display={"flex"} alignItems="center" justifyContent="space-between">
        <Box display={"flex"} alignItems="center" justifyContent="flex-start">
          <DateFilter
            onChange={(value: any) => {
              if (value === "Custom") return;
              viewModel.setDateFilter(value);
            }}
            onCustomerFilter={(start: Number, end: Number) => {
              viewModel.setDateStartFilter(start);
              viewModel.setDateEndFilter(end);
              if (viewModel.dateFilter !== "Custom") {
                viewModel.setDateFilter("Custom");
              } else {
                viewModel.setIsFetchData(!viewModel.isFetchData);
              }
            }}
          ></DateFilter>
        </Box>

        <Box
          marginTop={4}
          marginLeft={3}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <p>Payments</p>
          <Switch
            checked={viewModel.isSwitchChecked}
            onChange={() =>
              viewModel.setIsSwitchChecked(!viewModel.isSwitchChecked)
            }
            color="info"
          />
          <p>Payouts</p>
        </Box>

        <Box mt="14px" style={styles.addServiceBox}>
          <AddCircleOutlineOutlined
            onClick={() => viewModel.setsDialogAddPayoutOpen(true)}
            sx={{ color: "#FF7C06" }}
          />
          <Typography
            onClick={() => viewModel.setsDialogAddPayoutOpen(true)}
            sx={{ color: "#FF7C06" }}
          >
            {viewModel.isSwitchChecked ? "Add Payout" : "Add Payment"}
          </Typography>
        </Box>
      </Box>
      <AddPaymentDialog
        option={viewModel.isSwitchChecked ? "payout" : "payment"}
        open={viewModel.isDialogAddPayoutOpen}
        handleClose={(event: any) => {
          event.stopPropagation();
          viewModel.setsDialogAddPayoutOpen(false);
        }}
      ></AddPaymentDialog>
      {viewModel.selectedPayment.hasOwnProperty("_id") && (
        <PriceDialog
          open={isDialogOpen}
          handleClose={(event: any) => {
            event.stopPropagation();
            setIsDialogOpen(false);
          }}
          viewModel={viewModel}
        ></PriceDialog>
      )}

      {/* TABLE */}
      <Box mt="40px" minHeight={"300px"} height="auto">
        <DataGrid
          autoHeight
          rowHeight={60}
          disableExtendRowFullWidth
          loading={viewModel.getIsLoading() || !viewModel.data}
          getRowId={(row) => row._id}
          rows={viewModel.data || []}
          columns={viewModel.isSwitchChecked ? columnsPayout : columnsPayment}
          hideFooter
          pageSize={viewModel.getPageSize()}
          sx={viewModel.dataGridStyle()}
        />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
          marginBottom={2}
        >
          <Pagination
            count={viewModel.getTotalPages()}
            page={viewModel.getPage()}
            onChange={(event, newPage) => viewModel.handlePageChange(newPage)}
            size="large"
            disabled={!viewModel.data || viewModel.data.length === 0}
          />

          <Box
            marginLeft={2}
            marginBottom={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <label>Items per page</label>
            <Select
              style={styles.itemsPerPage}
              value={viewModel.getPageSize()}
              onChange={viewModel.handleRowsPerPageChange}
            >
              {viewModel.getPageSizes().map((size: number) => (
                <MenuItem key={size + "sizesPayment"} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Payment;
