import React, { useMemo, useState, useEffect } from "react";
import { Box, MenuItem, Pagination, Select } from "@mui/material";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { formattedDate } from "utils/helper";
import SearchBar from "components/SearchBar/SearchBar";
import ActivityLogsRequests from "requests/activityLogsRequests";
import { ActivityLogClient } from "scenes/grpcTest/activitylog/ActivitylogServiceClientPb";
import { getHostPythonGrpcBaseUrl } from "config";
import { EventStreamRequest } from "scenes/grpcTest/activitylog/activitylog_pb";

const ActivityLogs = () => {
  const [subscription, setSubscription] = useState<string>("login");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(8);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [emitSearch, setEmitSearch] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [messages, setMessages] = useState<string[]>([]);
  const pageSizes = [8, 16, 32, 64];

  const client = new ActivityLogClient(getHostPythonGrpcBaseUrl(), null, null);

  const handleStreamEvents = () => {
    const request = new EventStreamRequest();
    request.setSubscription(subscription);

    const stream = client.streamEvents(request, {});
    stream.on("data", function (response) {
      const messagePayload = {
        action: response.getAction(),
        userId: response.getUserId(),
        created: response.getCreated(),
      };

      setMessages((prev) => [...prev, JSON.stringify(messagePayload)]);

      setData((prev) => [
        { ...messagePayload, id: messagePayload.created },
        ...prev,
      ]);
    });

    stream.on("error", function (err) {
      console.error("Stream error:", err);
    });

    stream.on("end", function () {
      console.log("Stream ended.");
    });
  };

  useEffect(() => {
    handleStreamEvents();
  }, []);

  const fetchData = async () => {
    const activityLogsRequests = new ActivityLogsRequests();
    try {
      const result = await activityLogsRequests.getPagination(
        page,
        pageSize,
        searchTerm
      );
      const formattedData = result.data.map((item:any) => ({
        ...item,
        id: item.created,
      }));
      setData(formattedData);
      setTotalPages(result.totalPages);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [pageSize, page, emitSearch]);

  const handleRowsPerPageChange = (event: any): void => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const columns = useMemo(
    () => [
      {
        field: "action",
        headerName: "Action",
        flex: 1,
        sortable: true,
        filterable: true,
      },
      {
        field: "created",
        headerName: "Date",
        flex: 1,
        valueFormatter: (params: any) => {
          if (!params.value) {
            return "";
          }
          return formattedDate(params.value);
        },
      },
      {
        field: "userId",
        headerName: "User Id",
        flex: 1,
      },
    ],
    []
  );

  const handleSearch = () => {
    setEmitSearch(!emitSearch);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="ACTIVITY LOGS" subtitle="List of Activity Logs" />

      <Box>
        <Box mt={3}>
          <SearchBar
            width="400px"
            inputCallback={(e: any) => setSearchTerm(e.target.value)}
            buttonCallback={handleSearch}
            placeholder={"search userId, Action..."}
          />
        </Box>
      </Box>
      <Box mt="20px" height="75vh">
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row.created}
          rows={data || []}
          columns={columns}
          hideFooter
          pageSize={pageSize}
        />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
          marginBottom={2}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, newPage) => setPage(newPage)}
            size="large"
            disabled={!data || data.length === 0}
          />

          <Box
            marginLeft={2}
            marginBottom={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <label>Items per page</label>
            <Select value={pageSize} onChange={handleRowsPerPageChange}>
              {pageSizes.map((size: number) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ActivityLogs;
