import React from "react";
import { styles } from "./styles";
import { Avatar } from "@mui/material";
import useChatViewModel from "view_models/chat";

interface ChatMessage {
    id: string;
    chatRoomId: string;
    content: string;
    memberId: string;
    memberName: string;
    recipients: string[];
    data: any;
    updated: string;
    created: string;
}

interface MessageProps {
    message: ChatMessage;
    isOwnMessage: boolean;
    viewModel: any;
}

const Message: React.FC<MessageProps> = ({
    message,
    isOwnMessage,
    viewModel,
}) => {
    const messageStyle = isOwnMessage
        ? styles.ownMessageStyle
        : styles.receivedMessageStyle;
    const messageBox = isOwnMessage
        ? styles.messageBoxReverse
        : styles.messageBoxRow;
    const messageSubcontainer = isOwnMessage
        ? styles.messageSubcontainerEnd
        : styles.messageSubcontainerStart;

    return (
        <div style={messageBox}>
            <div style={styles.flexColumn}>
                {/* User information */}
                <div style={messageSubcontainer}>
                    {/* Avatar */}

                    {!isOwnMessage ? (
                        <>
                            <div>
                                <Avatar style={styles.smallAvatar} />
                            </div>
                            <div style={styles.userNameContainer}>
                                <span style={styles.subtitleNameMessage}>
                                    {message.memberName}
                                </span>
                            </div>
                        </>
                    ) : null}

                    <div>
                        <span style={styles.timeStyle}>
                            {viewModel.formatMessageDate(message.created)}
                        </span>
                    </div>
                </div>

                {/* Message content */}
                <div
                    style={{
                        display: "flex",
                        flexDirection: isOwnMessage ? "row-reverse" : "row",
                    }}
                >
                    <div
                        style={{
                            ...messageStyle,
                            ...styles.messageContentStyle,
                        }}
                    >
                        {message.content}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Message;
