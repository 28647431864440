import React, { useState } from "react";
import { GreeterClient } from "./greet/GreetServiceClientPb"; // Adjust path according to your structure
import { HelloRequest } from "./greet/greet_pb"; // Adjust path
import { Box, Button, TextField, Typography } from "@mui/material";
import { getHostPythonGrpcBaseUrl } from "config";

const ServerStreamingInterface = () => {
  const [name, setName] = useState<string>("");
  const [greeting, setGreeting] = useState<string>("");
  const [messages, setMessages] = useState<string[]>([]);

  const client = new GreeterClient(getHostPythonGrpcBaseUrl(), null, null);

  const handleParrotSaysHello = () => {
    const request = new HelloRequest();
    request.setName(name);
    request.setGreeting(greeting);

    const stream = client.parrotSaysHello(request, {});
    stream.on("data", function (response) {
      setMessages((prev) => [...prev, response.getMessage()]);
    });

    stream.on("error", function (err) {
      console.error("Stream error:", err);
    });

    stream.on("end", function () {
      console.log("Stream ended.");
    });
  };

  return (
    <Box display="flex" flexDirection="column" mb={4}>
      <Typography mb={2} variant="h3">
        Server Streaming gRPC Test{" "}
      </Typography>
      <Box display="flex" mb={2}>
        <TextField
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Name"
          variant="filled"
        />
        &nbsp;&nbsp;
        <TextField
          type="text"
          value={greeting}
          onChange={(e) => setGreeting(e.target.value)}
          label="Greeting"
          variant="filled"
        />
        &nbsp;&nbsp;
        <Button
          style={{
            background: "#FF7C06",
            textTransform: "none",
            paddingRight: "16px",
            paddingLeft: "16px",
            width: "124px",
          }}
          onClick={handleParrotSaysHello}
        >
          Start Streaming
        </Button>
      </Box>
      {messages.length > 0 && (
        <Box display="flex" flexDirection="column">
          <b>Messages from server:</b>
          {messages.map((msg, index) => (
            <p key={index}>{msg}</p>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ServerStreamingInterface;
