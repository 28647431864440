/**
 * @fileoverview gRPC-Web generated client stub for notifier
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.12.4
// source: notifier.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as notifier_pb from './notifier_pb'; // proto import: "notifier.proto"


export class NotifierClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorStreamEvents = new grpcWeb.MethodDescriptor(
    '/notifier.Notifier/StreamEvents',
    grpcWeb.MethodType.SERVER_STREAMING,
    notifier_pb.EventStreamRequest,
    notifier_pb.Event,
    (request: notifier_pb.EventStreamRequest) => {
      return request.serializeBinary();
    },
    notifier_pb.Event.deserializeBinary
  );

  streamEvents(
    request: notifier_pb.EventStreamRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<notifier_pb.Event> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/notifier.Notifier/StreamEvents',
      request,
      metadata || {},
      this.methodDescriptorStreamEvents);
  }

}

