import React, { useEffect, useState } from "react";
import {
  useTheme,
  // Avatar,
  Box,
  Button,
  ButtonGroup,
  Pagination,
  Select,
  MenuItem,
} from "@mui/material";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import ReviewsViewModel from "../../view_models/reviews";

type Props = {
  viewModel: ReviewsViewModel
}
const Reviews = ({ viewModel }: Props) => {
  const theme = useTheme();

  useEffect(() => {
    viewModel.isLoading = true;
    const fetchData = async () => {
      await viewModel.handleFetch();
    };
    fetchData();
  }, [viewModel]);

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="REVIEWS"
        subtitle="List of reviews done by the servise type"
      />
      <ButtonGroup variant="outlined">
        <Button
          onClick={() => viewModel.handleServiceChange("all")}
          sx={{
            backgroundColor:
            viewModel.serviceType === "all"
                ? // @ts-ignore
                  theme.palette.secondary[300]
                : // @ts-ignore
                  theme.palette.primary[600],
            color:
            viewModel.serviceType === "all"
                ? // @ts-ignore
                  theme.palette.primary.main
                : // @ts-ignore
                  theme.palette.secondary[100],
            ":hover": {
              backgroundColor:
              viewModel.serviceType === "all"
                  ? // @ts-ignore
                    theme.palette.secondary[300]
                  : // @ts-ignore
                    theme.palette.secondary[900],
            },
          }}
        >
          ALL
        </Button>
        <Button
          onClick={() => viewModel.handleServiceChange("towing")}
          sx={{
            backgroundColor:
            viewModel.serviceType === "towing"
                ? // @ts-ignore
                  theme.palette.secondary[300]
                : // @ts-ignore
                  theme.palette.primary[600],
            color:
            viewModel.serviceType === "towing"
                ? theme.palette.primary.main
                : // @ts-ignore
                  theme.palette.secondary[100],
            ":hover": {
              backgroundColor:
              viewModel.serviceType === "towing"
                  ? // @ts-ignore
                    theme.palette.secondary[300]
                  : // @ts-ignore
                    theme.palette.secondary[900],
            },
          }}
        >
          TOWING
        </Button>
        <Button
          onClick={() => viewModel.handleServiceChange("jump")}
          sx={{
            backgroundColor:
            viewModel.serviceType === "jump"
                ? // @ts-ignore
                  theme.palette.secondary[300]
                : // @ts-ignore
                  theme.palette.primary[600],
            color:
            viewModel.serviceType === "jump"
                ? theme.palette.primary.main
                : // @ts-ignore
                  theme.palette.secondary[100],
            ":hover": {
              backgroundColor:
              viewModel.serviceType === "jump"
                  ? // @ts-ignore
                    theme.palette.secondary[300]
                  : // @ts-ignore
                    theme.palette.secondary[900],
            },
          }}
        >
          JUMP
        </Button>
        <Button
          onClick={() => viewModel.handleServiceChange("refuel")}
          sx={{
            backgroundColor:
            viewModel.serviceType === "refuel"
                ? // @ts-ignore
                  theme.palette.secondary[300]
                : // @ts-ignore
                  theme.palette.primary[600],
            color:
            viewModel.serviceType === "refuel"
                ? theme.palette.primary.main
                : // @ts-ignore
                  theme.palette.secondary[100],
            ":hover": {
              backgroundColor:
              viewModel.serviceType === "refuel"
                  ? // @ts-ignore
                    theme.palette.secondary[300]
                  : // @ts-ignore
                    theme.palette.secondary[900],
            },
          }}
        >
          REFUEL
        </Button>
        <Button
          onClick={() => viewModel.handleServiceChange("unlock")}
          sx={{
            backgroundColor:
            viewModel.serviceType === "unlock"
                ? // @ts-ignore
                  theme.palette.secondary[300]
                : // @ts-ignore
                  theme.palette.primary[600],
            color:
              viewModel.serviceType === "unlock"
                ? theme.palette.primary.main
                : // @ts-ignore
                  theme.palette.secondary[100],
            ":hover": {
              backgroundColor:
              viewModel.serviceType === "unlock"
                  ? // @ts-ignore
                    theme.palette.secondary[300]
                  : // @ts-ignore
                    theme.palette.secondary[900],
            },
          }}
        >
          UNLOCK
        </Button>
      </ButtonGroup>
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            // @ts-ignore
            backgroundColor: theme.palette.background.alt,
            // @ts-ignore
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            // @ts-ignore
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            // @ts-ignore
            backgroundColor: theme.palette.background.alt,
            // @ts-ignore
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            // @ts-ignore
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={viewModel.isLoading || !viewModel.data}
          getRowId={(row) => row._id}
          rows={viewModel.data || []}
          columns={viewModel.columns}
          hideFooter
          pageSize={viewModel.pageSize}
          onRowClick={(params) => {
            viewModel.rowId(params.id);
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          <Pagination
            count={viewModel.totalPages}
            page={viewModel.page}
            onChange={(event, newPage) => viewModel.handlePageChange(newPage)}
            size="large"
          />

          <div
            style={{
              marginLeft: 16,
              marginBottom: 16,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label style={{ fontSize: 9 }}> Items per page</label>
            <Select
              style={{
                marginTop: 4,
                width: 64,
                height: 28,
                border: `1px solid ${theme.palette.primary.light}`,
              }}
              value={viewModel.pageSize}
              onChange={viewModel.handleRowsPerPageChange}
            >
              {viewModel.pageSizes.map((size) => (
                <MenuItem key={size  + Date.now()} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </Box>
    </Box>
  );
};


export default function ReviewView() {
  const viewModel = new ReviewsViewModel();
  return <Reviews viewModel={viewModel} />
}


