import React, { useState } from "react";
import { Box, Typography, TextField, IconButton, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { styles } from "./styles";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CompanyRequests from "requests/companyRequests";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { categoryOptions, CategoryOptionsType } from "components/AddCompanyDialog/options";

const companyRequests = new CompanyRequests();
interface CompanyInfoProps {
  marker: any;
}

const CompanyInfo: React.FC<CompanyInfoProps> = ({ marker }) => {
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingRating, setIsEditingRating] = useState(false);
  const [isEditingCurrency, setIsEditingCurrency] = useState(false);
  const [isEditingImageUrl, setIsEditingImageUrl] = useState(false);
  const [isEditingCategory, setIsEditingCategory] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState(marker.contact.internationalPhoneNumber);
  const [email, setEmail] = useState(marker.contact.email);
  const [rating, setRating] = useState(marker.contact.rating);
  const [currency, setCurrency] = useState(marker.currency);
  const [imageUrl, setImageUrl] = useState(marker.imageUrl);
  const [category, setCategory] = useState(marker.category);

  const handleEditClick = () => {
    setIsEditingPhone(true);
  };

  const handleEditEmailClick = () => {
    setIsEditingEmail(true);
  };

  const handleEditRatingClick = () => {
    setIsEditingRating(true);
  };

  const handleEditCurrencyClick = () => {
    setIsEditingCurrency(true);
  };

  const handleEditImageUrlClick = () => {
    setIsEditingImageUrl(true);
  };

  const handleEditCategoryClick = () => {
    setIsEditingCategory(true);
  };

  const isValidPhone = () => {
    return !/^\d*$/.test(phoneNumber);
  };

  const isValidEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return !emailRegex.test(email);
  };
  const isValidRating = () => {
    const value = parseFloat(rating);

    return !isNaN(value) && value >= 1 && value <= 5;
  };
  const isValidCurrency = () => {
    const currencyRegex = /^[A-Z]{3}$/;
    return !currencyRegex.test(currency);
  };

  const isValidImageUrl = () => {
    const imageRegex = /^.*\.(png|jpg)$/;
    return !imageRegex.test(imageUrl) || imageUrl === "";
  };

  const handleSavePhoneClick = async () => {
    setIsEditingPhone(false);
    try {
      const contact = { ...marker.contact };
      await companyRequests.updateData({
        data: {
          ...contact,
          internationalPhoneNumber: phoneNumber,
        },
        property: "contact",
        placeId: marker.placeId,
      });
    } catch (error) {
      alert("Error on update phoneNumber");
    }
  };

  const handleSaveMailClick = async () => {
    setIsEditingEmail(false);
    try {
      const contact = { ...marker.contact };
      await companyRequests.updateData({
        data: {
          ...contact,
          email: email,
        },
        property: "contact",
        placeId: marker.placeId,
      });
    } catch (error) {
      alert("Error on update phoneNumber");
    }
  };

  const handleSaveRatingClick = async () => {
    setIsEditingRating(false);
    try {
      const contact = { ...marker.contact };
      await companyRequests.updateData({
        data: {
          ...contact,
          rating: rating,
        },
        property: "contact",
        placeId: marker.placeId,
      });
    } catch (error) {
      alert("Error on update phoneNumber");
    }
  };

  const handleSaveCurrencyClick = async () => {
    setIsEditingCurrency(false);
    try {
      await companyRequests.updateData({
        data: currency,
        property: "currency",
        placeId: marker.placeId,
      });
    } catch (error) {
      alert("Error on update currency");
    }
  };

  const handleSaveImageUrlClick = async () => {
    setIsEditingImageUrl(false);
    try {
      await companyRequests.updateData({
        data: imageUrl,
        property: "imageUrl",
        placeId: marker.placeId,
      });
    } catch (error) {
      alert("Error on update image url");
    }
  };

  const handleSaveCategoryClick = async () => {
    setIsEditingCategory(false);
    try {
      await companyRequests.updateData({
        data: category,
        property: "category",
        placeId: marker.placeId,
      });
    } catch (error) {
      alert("Error on update category");
    }
  };

  const handleCancelClick = () => {
    setIsEditingPhone(false);
    setPhoneNumber(marker.contact.internationalPhoneNumber);
  };

  const handleCancelEmailClick = () => {
    setIsEditingEmail(false);
    setEmail(marker.contact.email);
  };

  const handleCancelRatingClick = () => {
    setIsEditingRating(false);
    setRating(marker.contact.rating);
  };

  const handleCancelCurrencyClick = () => {
    setIsEditingCurrency(false);
    setCurrency(marker.currency);
  };

  const handleCancelImageUrlClick = () => {
    setIsEditingImageUrl(false);
    setImageUrl(marker.imageUrl);
  };

  const handleCancelCategoryClick = () => {
    setIsEditingCategory(false);
    setCategory(marker.category);
  };

  const handlePhoneNumberChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPhoneNumber(event.target.value);
  };

  const handleEmailChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmail(event.target.value);
  };

  const handleRatingChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value.trim(); // Trim any leading or trailing whitespace
    const value = parseFloat(inputValue);

    if (!inputValue || isNaN(value) || value < 1 || value > 5) {
      setRating(null); // Handle invalid input by setting rating to null or another appropriate value
      return;
    }

    // Set the rating within valid range
    setRating(value);
  };

  const handleCurrencyChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrency(event.target.value);
  };

  const handleImageUrlChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setImageUrl(event.target.value);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as keyof CategoryOptionsType);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(marker._id)
      .then(() => {
        console.log("Copied to clipboard:", marker._id);
        // Optionally, you can add a visual cue or message that the copy was successful
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
        // Handle any errors that may occur during copying
      });
  };

  return (
    <Box sx={styles.flexColumnCenter} gap={1} mb={2}>
      <div style={{ display: "flex" }}>
        <b>_id: &nbsp;&nbsp;</b>
        <Typography>{marker._id}</Typography>&nbsp;&nbsp;
        <ContentCopyIcon
          onClick={copyToClipboard}
          style={{ cursor: "pointer" }}
        />
      </div>

      <div>
        <b>PhoneNumber: </b>
        {isEditingPhone ? (
          <div>
            <TextField
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              variant="outlined"
              color={!isValidPhone() ? "secondary" : "error"}
              size="small"
            />

            {!isValidPhone() && (
              <IconButton onClick={handleSavePhoneClick}>
                <CheckIcon />
              </IconButton>
            )}

            <IconButton onClick={handleCancelClick}>
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <span>
            +{phoneNumber}
            &nbsp;&nbsp;
            <IconButton onClick={handleEditClick}>
              <ModeEditIcon />
            </IconButton>
          </span>
        )}
      </div>

      <div>
        <b>E-mail: </b>
        {isEditingEmail ? (
          <div>
            <TextField
              value={email}
              onChange={handleEmailChange}
              variant="outlined"
              color={!isValidEmail() ? "secondary" : "error"}
              size="small"
            />

            {!isValidEmail() && (
              <IconButton onClick={handleSaveMailClick}>
                <CheckIcon />
              </IconButton>
            )}

            <IconButton onClick={handleCancelEmailClick}>
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <span>
            {email}
            &nbsp;&nbsp;
            <IconButton onClick={handleEditEmailClick}>
              <ModeEditIcon />
            </IconButton>
          </span>
        )}
      </div>

      <Box display={"inline-flex"} alignItems={"center"}>
        <Box mb={1}>
          <b>Rating: &nbsp;</b>
        </Box>
        {isEditingRating ? (
          <Box>
            <TextField
              value={rating}
              onChange={handleRatingChange}
              variant="outlined"
              color={isValidRating() ? "secondary" : "error"}
              size="small"
              type="number"
            />
            {isValidRating() && (
              <IconButton onClick={handleSaveRatingClick}>
                <CheckIcon />
              </IconButton>
            )}

            <IconButton onClick={handleCancelRatingClick}>
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <Box>
            <Typography>
              {rating}
              &nbsp;&nbsp;
              <IconButton onClick={handleEditRatingClick}>
                <ModeEditIcon />
              </IconButton>
            </Typography>{" "}
          </Box>
        )}
      </Box>

      <div>
        <b>Address: </b>
        <span>{marker.geometry.formattedAddress}</span>
      </div>

      <div>
        <b>Query: </b>
        <span>{marker._id.split("#")[0]}</span>
      </div>

      <div>
        <b>Currency: </b>
        {isEditingCurrency ? (
          <div>
            <TextField
              value={currency}
              onChange={handleCurrencyChange}
              variant="outlined"
              color={!isValidCurrency() ? "secondary" : "error"}
              size="small"
            />

            {!isValidCurrency() && (
              <IconButton onClick={handleSaveCurrencyClick}>
                <CheckIcon />
              </IconButton>
            )}

            <IconButton onClick={handleCancelCurrencyClick}>
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <span>
            {currency}
            &nbsp;&nbsp;
            <IconButton onClick={handleEditCurrencyClick}>
              <ModeEditIcon />
            </IconButton>
          </span>
        )}
      </div>

      <div>
        <b>Image Url: </b>
        {isEditingImageUrl ? (
          <div>
            <TextField
              value={imageUrl}
              onChange={handleImageUrlChange}
              variant="outlined"
              color={!isValidImageUrl() ? "secondary" : "error"}
              size="small"
            />

            {!isValidImageUrl() && (
              <IconButton onClick={handleSaveImageUrlClick}>
                <CheckIcon />
              </IconButton>
            )}

            <IconButton onClick={handleCancelImageUrlClick}>
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <span>
            {imageUrl}
            &nbsp;&nbsp;
            <IconButton onClick={handleEditImageUrlClick}>
              <ModeEditIcon />
            </IconButton>
          </span>
        )}
      </div>

      <div>
        <b>Category: </b>
        {isEditingCategory ? (
          <div>
            <Select
              value={category}
              onChange={handleCategoryChange}
              variant="outlined"
              size="small"
            >
              {Object.keys(categoryOptions).map((key) => (
                <MenuItem key={key} value={key}>
                  {categoryOptions[key as keyof CategoryOptionsType]}
                </MenuItem>
              ))}
            </Select>

            <IconButton onClick={handleSaveCategoryClick}>
              <CheckIcon />
            </IconButton>

            <IconButton onClick={handleCancelCategoryClick}>
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <span>
            {categoryOptions[category as keyof CategoryOptionsType]}
            &nbsp;&nbsp;
            <IconButton onClick={handleEditCategoryClick}>
              <ModeEditIcon />
            </IconButton>
          </span>
        )}
      </div>
    </Box>
  );
};

export default CompanyInfo;
