import React, { useMemo, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import Header from "components/Header";
import { ResponsiveLine } from "@nivo/line";
import { useGetSalesQuery } from "state/api";

type TotalLine = {
    id: string;
    color: string;
    data: { x: string | number; y: number }[] | any[];
}

const Monthly = () => {
  // @ts-ignore
  const { data } = useGetSalesQuery();
  const theme = useTheme();

  const [year, setYear] = useState("2022");

  const [formattedData] = useMemo(() => {
    if (!data) return [];

    const { monthlyData } = data.yearlyData.find(
      (data: any): boolean => data.year === Number(year)
    );
    const totalUsersLine: TotalLine = {
      id: "totalUsers",
      color: theme.palette.secondary.main,
      data: [],
    };
    const totalServicesLine: TotalLine = {
      id: "totalServices",
      // @ts-ignore
      color: theme.palette.secondary[600],
      data: [],
    };
    const totalVehiclesLine: TotalLine = {
      id: "totalVehicles",
      // @ts-ignore
      color: theme.palette.secondary[700],
      data: [],
    };

    // @ts-ignore
    Object.values(monthlyData).forEach(({ month, totalUsers, totalServices, totalVehicles }) => {
        totalUsersLine.data = [
          ...totalUsersLine.data,
          { x: month, y: totalUsers },
        ];
        totalServicesLine.data = [
          ...totalServicesLine.data,
          { x: month, y: totalServices },
        ];
        totalVehiclesLine.data = [
          ...totalVehiclesLine.data,
          { x: month, y: totalVehicles },
        ];
      }
    );

    const formattedData = [
      totalUsersLine,
      totalServicesLine,
      totalVehiclesLine,
    ];
    return [formattedData];
  }, [data, year]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="MONTHLY STATS" subtitle="Chart of monthlystats" />
      <Box height="7.5vh">
        <FormControl sx={{ mt: "1rem", ml: "1rem" }}>
          <InputLabel>Year</InputLabel>
          <Select
            value={year}
            label="Year"
            onChange={(e) => setYear(e.target.value)}
          >
            <MenuItem value="2022">2022</MenuItem>
            <MenuItem value="2023">2023</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box height="75vh">
        {data ? (
          // @ts-ignore
          <ResponsiveLine
            data={formattedData}
            theme={{
              axis: {
                domain: {
                  line: {
                    // @ts-ignore
                    stroke: theme.palette.secondary[200],
                  },
                },
                legend: {
                  text: {
                    // @ts-ignore
                    fill: theme.palette.secondary[200],
                  },
                },
                ticks: {
                  line: {
                    // @ts-ignore
                    stroke: theme.palette.secondary[200],
                    strokeWidth: 1,
                  },
                  text: {
                    // @ts-ignore
                    fill: theme.palette.secondary[200],
                  },
                },
              },
              legends: {
                text: {
                  // @ts-ignore
                  fill: theme.palette.secondary[200],
                },
              },
              tooltip: {
                container: {
                  // @ts-ignore
                  color: theme.palette.primary.main,
                },
              },
            }}
            colors={{ scheme: "set1" }}
            margin={{ top: 50, right: 50, bottom: 70, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            yFormat=" >-.2f"
            // curve="catmullRom"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 90,
              legend: "Month",
              legendOffset: 60,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Total",
              legendOffset: -50,
              legendPosition: "middle",
            }}
            enableGridX={false}
            enableGridY={false}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: "top-right",
                direction: "column",
                justify: false,
                translateX: 50,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        ) : (
          <>Loading...</>
        )}
      </Box>
    </Box>
  );
};

export default Monthly;
