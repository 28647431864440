interface ChatParticipantCardStyles {
  container: React.CSSProperties;
  textContainer: React.CSSProperties;
}

export const styles: ChatParticipantCardStyles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '0px',
    border: '0.5px solid #3E3E3E'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginLeft: '15px'
  }
};