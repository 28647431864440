import React, { useState } from "react";
import "./styles.css";
import { Avatar, Box, Button, Fab } from "@mui/material";
import { StyledBadge } from "components/StyledBadge";
import CloseIcon from "@mui/icons-material/Close";
import WppBg from "../../assets/bg/wpp-bg.jpg";
import CommentIcon from "@mui/icons-material/Comment";
import ChatBotRequests from "requests/chatBotRequests";
import SmartToyIcon from "@mui/icons-material/SmartToy";

const chatBotRequests = new ChatBotRequests();

const ChatMessenger = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [messages, setMessages] = useState<any[]>([]);
  const [inputMessage, setInputMessage] = useState<string>("");

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleSendMessage = async () => {
    const inputBackup = inputMessage;

    setMessages((prevMessages: any) => [
      ...prevMessages,
      {
        message: inputBackup,
        role: "Admin",
      },
    ]);
    setInputMessage("");
    const botMessage = await receiveChatBotMessage(inputBackup);

    setMessages((prevMessages: any) => [
      ...prevMessages,
      {
        message: botMessage,
        role: "Bot",
      },
    ]);
  };

  const receiveChatBotMessage = async (message: string) => {
    const data = await chatBotRequests.sendMessage(message);
    return data.answer;
  };

  return (
    <>
      {isVisible && (
        <div
          className={`rectangle-box border-radius  ${
            isVisible ? "visible" : "hidden"
          }`}
          style={{
            backgroundImage: `url(${WppBg})`,
          }}
        >
          <div
            className={`top-border border-radius  ${
              isVisible ? "visible" : "hidden"
            }`}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              ml={3}
              mt={1}
              mb={1}
            >
              <Box display={"flex"} alignItems={"center"}>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  variant="dot"
                  isOnline={true}
                >
                  <Avatar alt="avatar" />
                </StyledBadge>
                <Box ml={3}>
                  <span className={`typography-chat-title`}>
                    Maia Assistant
                  </span>
                </Box>
              </Box>
              <Box mr={4} mt={1}>
                <CloseIcon onClick={toggleVisibility} />
              </Box>
            </Box>
          </div>

          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"start"}
            gap={1}
            style={{ overflowY: "auto" }}
            pt={3}
            pb={3}
            pl={2}
            pr={2}
          >
            {messages.map((message, index) => (
              <div
                key={index}
                className={
                  message.role === "Admin" ? "message-sent" : "message-comming"
                }
              >
                <span>{message.message}</span>
              </div>
            ))}
          </Box>
          <Box
            display={"flex"}
            pb={2}
            pt={2}
            pl={2}
            pr={2}
            style={{ background: "#202020" }}
          >
            <form>
              <Box display={"flex"}>
                <input
                  type="text"
                  style={{
                    borderRadius: "52px 0px 0px 52px",
                    color: "black",
                    background: "var(--gray-gray-800, #C0C0C0)",
                    padding: "8px",
                    flex: 1,
                    width: "280px",
                    border: "none",
                    outline: "none",
                    paddingLeft: "8px",
                    paddingRight: "32px",
                  }}
                  placeholder="Send a message"
                  onChange={(event: any) => {
                    setInputMessage(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      handleSendMessage();
                    }
                  }}
                />

                <Button
                  style={{
                    borderRadius: "0px 52px 52px 0px",
                    color: "black",
                    background: "var(--gray-gray-800, #FF7C06)",
                    padding: "8px 16px",
                  }}
                  onClick={handleSendMessage}
                >
                  Send
                </Button>
              </Box>
            </form>
          </Box>
        </div>
      )}

      {!isVisible && (
        <div className={`fixed-div`} style={{ zIndex: 9999 }}>
          <Fab onClick={toggleVisibility}>
            <SmartToyIcon style={{ fontSize: "28px" }}></SmartToyIcon>
          </Fab>
        </div>
      )}
    </>
  );
};

export default ChatMessenger;
