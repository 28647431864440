export const styles: ChatStyles = {
  whiteBg: {
    backgroundColor: "#000",
  },
  secondaryContainer: {
    backgroundColor: "#000",
    borderBottomLeftRadius: 32,
    borderBottomRightRadius: 32,
    width: 500,
  },
  container: {
    display: "flex",
    flexDirection: "column",

    marginBottom: 8,
  },
  appBar: {
    backgroundColor: "#191919",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: 70,
  },
  topAvatar: {
    width: 40,
    height: 40,
  },
  toolbarDiv: {
    height: 70,
  },
  avatarContainer: {
    display: "flex",
    flex: "column",
    alignContent: "center",
    justifyContent: "center",
  },
  avatar: {
    marginLeft: "20px",
    marginTop: "4px",
  },
  smallAvatar: {
    width: "22px",
    height: "22px",
    marginRight: "1px",
  },
  userInfoContainer: {
    display: "flex",
    flex: "column",
    alignContent: "center",
    justifyContent: "center",
  },
  userInfo: {
    marginLeft: "40px",
    marginTop: "5px",
  },
  paper: {
    padding: "16px",
    paddingBottom: "0",
    backgroundColor: "#000",
    borderBottomLeftRadius: 32,
    borderBottomRightRadius: 32,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderRight: "1px solid #191919",
    borderLeft: "1px solid #191919",
  },

  nameTextStyle: {
    color: "#FFF",
    fontFeatureSettings: "calt off",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "-0.32px",
    marginLeft: "20px",
    marginTop: "4px",
  },
  messageList: {
    backgroundColor: "#000",
    borderRadius: 16,
    padding: 0,
  },
  totalWidth: {
    width: "100%",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  marginLeftSmall: {
    marginLeft: 8,
  },
// input container styles
  form: {
    display: "flex",
    width: "100%",
    paddingTop: 24,
    paddingBottom: 24,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: "#191919",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: "100%",
  },
  input: {
    borderRadius: "52px 0px 0px 52px",
    color: "black",
    background: "var(--gray-gray-800, #C0C0C0)",
    padding: "10px",
    flex: 1,
    width: "94%",
    border: "none",
    outline: "none",
    paddingLeft: "16px",
    paddingRight: "40px",
  },
  sendButton: {
    borderRadius: "0px 52px 52px 0px",
    color: "black",
    background: "var(--gray-gray-800, #FF7C06)",
    padding: "10px 20px",
  },
  listItemCustomStyle: {
    paddingLeft: 0, // Customize the left padding
    paddingRight: 0, // Customize the right padding
  },
  nameStyle: {
    color: "var(--dark-white-white-900, #FFF)",
    fontFeatureSettings: "normal",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px", // This value represents "line-height: 20px;"
    letterSpacing: "-0.28px",
  },
  serviceStyle: {
    color: "var(--dark-white-white-900, #FFF)",
    fontFeatureSettings: "normal",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.12px",
  },
  iconSize: {
    fontSize: "1.75rem",
  },
  timeStyle: {
    color: "#FFF",
    fontFeatureSettings: "'calt' off",
    fontFamily: "Inter",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.1px",
    marginLeft: 4,
  },
  userNameContainer: {
    textAlign: "center",
    marginLeft: 4,
  },
  newConversation: {
    display: "flex",
    flexDirection: "row",
    width: "151px",
    alignItems: "center",
    color: "#FF7C06",
    gap: "5px",
    cursor: "pointer",
  },
  dmContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    gap: "1rem",
    height: "auto",
    width: "380px",
    padding: "15px",
    // minWidth: "317px",
    borderRadius: "8px",
    backgroundColor: "#191919",
    boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.30)",
  },
  listOfDMsContainer: {
    position: "relative",
    flex: 1,
  },
  listOfDMsContainerAbsolute: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: "#191919",
    gap: "15px",
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
  },
  customTypography: {
    color: "#FFF",
    fontFeatureSettings: "'calt' off",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "-0.28px",
  },
  customTypographyService: {
    color: "var(--dark-white-white-900, #FFF)",
    fontFeatureSettings: "'calt' off",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.12px",
  },
  customTypographyMessage: {
    color: "var(--gray-gray-800, #C0C0C0)",
    fontFeatureSettings: "'calt' off",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.12px",
  },
  timeHour: {
    color: "var(--gray-gray-900, #7E7E7E)",
    fontFeatureSettings: "'calt' off",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "-0.28px",
  },
  buttonBase: {
    textDecoration: "none",
    backgroundColor: "#191919",
    borderRadius: 8,
    border: "none",
    cursor: "pointer",
    marginBottom: 21,
    width: 290,
  },
  chatContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    width: "100%",
    borderRadius: "8px",
    backgroundColor: "transparent",
    boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.30)",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "15px",
    height: "70px",
    width: "100%",
    borderRadius: "8px 8px 0px 0px",
    backgroundColor: "#191919",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "10px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minWidth: "160px",
    width: "auto",
  },
  customerName: {
    color: "#fff",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "-0.28px",
  },
  service: {
    color: "#fff",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.12px",
  },
  rightContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "30px",
  },
  messagesContainer: {
    position: "relative",
    flex: 1,
  },
  messagesContainerAbsolute: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    // minHeight: "500px",
    // maxHeight: "500px",
    flex: 1,
    overflowY: "auto",
    // scrollBehavior: 'smooth',
    width: "100%",
    gap: "20px",
    backgroundColor: "transparent",
    borderRight: "0.5px solid #191919",
    borderLeft: "0.5px solid #191919",
    padding: "1rem",
  },
  parentContainerStyle: {
    minHeight: "500px",
    maxHeight: "500px",
    overflowY: "auto",
    width: "100%",
  },
  ownMessageStyle: {
    backgroundColor: "#1279F2",
    color: "white",
    borderRadius: "32px",
    paddingBlock: "8px",
    paddingInline: "16px",
    marginTop: "5px",
    wordWrap: "break-word",
    overflow: "hidden",
  },

  receivedMessageStyle: {
    backgroundColor: "#667080",
    color: "white",
    borderRadius: "32px",
    paddingBlock: "8px",
    paddingInline: "16px",
    marginTop: "5px",
    wordWrap: "break-word",
    overflow: "hidden",
  },

  subtitleNameMessage: {
    color: "#FFF",
    fontFeatureSettings: "'calt' off",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px", // This value represents "line-height: 30px;"
    letterSpacing: "-0.28px",
  },

  messageBoxReverse: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "flex-start",
    marginBottom: 16,
  },

  messageBoxRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 16,
  },
  messageSubcontainerStart: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },

  messageSubcontainerEnd: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  messageContentStyle:{
    flexGrow: 0, // Prevent message content from growing
  },
};

interface ChatStyles {
  appBar: React.CSSProperties;
  avatar: React.CSSProperties;
  avatarContainer: React.CSSProperties;
  buttonBase: React.CSSProperties;
  chatContainer: React.CSSProperties;
  container: React.CSSProperties;
  customTypography: React.CSSProperties;
  customTypographyMessage: React.CSSProperties;
  customTypographyService: React.CSSProperties;
  customerName: React.CSSProperties;
  dmContainer: React.CSSProperties;
  flexColumn: React.CSSProperties;
  form: React.CSSProperties;
  headerContainer: React.CSSProperties;
  iconSize: React.CSSProperties;
  input: React.CSSProperties;
  inputContainer: React.CSSProperties;
  leftContainer: React.CSSProperties;
  listItemCustomStyle: React.CSSProperties;
  listOfDMsContainer: React.CSSProperties;
  listOfDMsContainerAbsolute: React.CSSProperties;
  marginLeftSmall: React.CSSProperties;
  messageBoxReverse: React.CSSProperties;
  messageBoxRow: React.CSSProperties;
  messageContentStyle: React.CSSProperties;
  messageList: React.CSSProperties;
  messageSubcontainerEnd: React.CSSProperties;
  messageSubcontainerStart: React.CSSProperties;
  messagesContainer: React.CSSProperties;
  messagesContainerAbsolute: React.CSSProperties;
  nameStyle: React.CSSProperties;
  nameTextStyle: React.CSSProperties;
  newConversation: React.CSSProperties;
  ownMessageStyle: React.CSSProperties;
  paper: React.CSSProperties;
  parentContainerStyle: React.CSSProperties;
  receivedMessageStyle: React.CSSProperties;
  rightContainer: React.CSSProperties;
  secondaryContainer: React.CSSProperties;
  sendButton: React.CSSProperties;
  service: React.CSSProperties;
  serviceStyle: React.CSSProperties;
  smallAvatar: React.CSSProperties;
  subtitleNameMessage: React.CSSProperties;
  textContainer: React.CSSProperties;
  timeHour: React.CSSProperties;
  timeStyle: React.CSSProperties;
  toolbarDiv: React.CSSProperties;
  topAvatar: React.CSSProperties;
  totalWidth: React.CSSProperties;
  userInfo: React.CSSProperties;
  userInfoContainer: React.CSSProperties;
  userNameContainer: React.CSSProperties;
  whiteBg: React.CSSProperties;
}
