import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  IconButton,
  Box,
  Typography,
  Avatar,
  AvatarGroup,
  ListItem,
} from "@mui/material";

import {
  AddCircleOutlineOutlined,
  AttachFileOutlined,
  DeleteOutlined,
} from "@mui/icons-material";
import addUser from "../../assets/icons/user-plus.svg";
import removeUser from "../../assets/icons/user-minus.svg";
import useChatViewModel from "view_models/chat";
import Header from "components/Header";

import { styles } from "./styles";
import { StyledBadge } from "components/StyledBadge";
import FlexBetween from "components/FlexBetween";
import TabOptions from "components/TabOptions/TabOptions";
import {
  CHAT_TAB_OPTIONS,
  DIALOG_BUTTONS_DELETE_CHAT,
  DIALOG_BUTTONS_NEW_CHAT,
} from "utils/const";
import DMCard from "components/DMCard/DMCard";
import Message from "./message";
import SearchBar from "components/SearchBar/SearchBar";
import ChatDialog from "components/ChatDialog/ChatDialog";

import GenericDialog from "components/GenericDialog/GenericDialog";

const Chat: React.FC = () => {
  const viewModel = useChatViewModel();
  const [selectedCard, setSelectedCard] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [viewModel.messages]);

  useEffect(() => {
    if (id) {
      const isValidObjectId = /^[0-9a-fA-F]{24}$/.test(id);
      if (isValidObjectId) {
        console.log("valid: " + isValidObjectId);
        viewModel.setCurrentChatId(id);
      }
    }
  }, [id]);

  const handleCardClick = (chat: any) => {
    setSelectedCard(chat.message.chatRoomId);
  };
  const handleListItemClick = () => {
    console.log("click");
  };

  const handleAttach = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  return (
    <Box
      p=".5rem 1.5rem 1rem 1.5rem"
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "calc(100vh - 64px)",
      }}
    >
      {/* FIRST ROW */}
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
        mb={"20px"}
      >
        <Header title={"CHAT"} />
        <Box
          style={styles.newConversation}
          onClick={viewModel.handleInvitedToChat}
        >
          <AddCircleOutlineOutlined />
          <Typography>INVITATION TEST</Typography>
        </Box>
        <GenericDialog
          open={viewModel.invitedToChat}
          text={"Suzana is inviting you to participate in a conversation"}
          options={DIALOG_BUTTONS_NEW_CHAT}
          onClickFirstOption={viewModel.handleCloseInvitedToChat}
          onClickSecondOption={viewModel.handleCloseInvitedToChat}
        />
        <Box
          style={styles.newConversation}
          onClick={viewModel.handleNewConversation}
        >
          <AddCircleOutlineOutlined />
          <Typography>New conversation</Typography>
        </Box>
        <ChatDialog
          open={viewModel.newConversation}
          onClose={viewModel.handleCloseNewConversation}
          viewModel={viewModel}
          title={"New conversation"}
          isNewConversation={true}
        />
      </Box>

      {/* SECOND ROW */}
      <Box
        display={"flex"}
        flexDirection={"row"}
        gap={"16px"}
        flex={1}
        alignItems={"stretch"}
      >
        {/* DIRECT MESSAGES */}
        <Box style={styles.dmContainer}>
          <Typography variant="h5" mb={2} fontSize={"16px"} fontWeight={600}>
            Direct messages
          </Typography>
          <SearchBar width="100%" inputCallback={viewModel.handleFilterChats} />
          <TabOptions
            options={CHAT_TAB_OPTIONS}
            callback={async (value) => await viewModel.handleChangeTab(value)}
            currentTab={viewModel.currentTab}
          />
          {!viewModel.filteredChatsLoading ? (
            viewModel.filteredChats.length > 0 && (
              <Box style={styles.listOfDMsContainer}>
                <Box style={styles.listOfDMsContainerAbsolute}>
                  {viewModel.filteredChats.map((chat: any, i: number) => (
                    <DMCard
                      key={`dmCard-${i}`}
                      chat={chat}
                      viewModel={viewModel}
                      isSelected={chat.message.chatRoomId === selectedCard}
                      onDMCardClick={handleCardClick}
                    />
                  ))}{" "}
                </Box>
              </Box>
            )
          ) : (
            <div>Loading...</div>
          )}
        </Box>

        {/* CHAT */}
        <Box style={{ ...styles.chatContainer, flex: 1 }}>
          {/* HEADER */}
          <Box style={styles.headerContainer}>
            {/* LEFT */}
            {viewModel.currentChatRoom && (
              <Box style={styles.leftContainer}>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  variant="dot"
                  isOnline={viewModel.currentChatRoom.isOnline}
                >
                  <Avatar
                    alt="avatar"
                    src={viewModel.currentChatRoom.customer.profileImg}
                  />
                </StyledBadge>
                <Box style={styles.textContainer}>
                  <Typography style={styles.customerName}>
                    {viewModel.currentChatRoom.customer.name}
                  </Typography>
                  <Typography style={styles.service}>
                    Service: {viewModel.currentChatRoom.service.serviceType}
                  </Typography>
                </Box>
              </Box>
            )}

            {/* RIGHT */}
            {viewModel.currentChatRoom && (
              <Box style={styles.rightContainer}>
                <AvatarGroup
                  max={3}
                  sx={{
                    "& .MuiAvatarGroup-avatar": {
                      height: 24,
                      width: 24,
                      fontSize: "15px",
                    },
                  }}
                >
                  {viewModel.notCustomerChatParticipants.map(
                    (el: any, i: number) => {
                      return (
                        <Avatar
                          src={el.profileImg}
                          key={"chat" + i}
                          sx={{
                            height: 24,
                            width: 24,
                          }}
                        />
                      );
                    }
                  )}
                </AvatarGroup>
                <FlexBetween>
                  {/* ADD PARTICIPANT */}
                  <IconButton onClick={viewModel.handleAddParticipant}>
                    <Avatar src={addUser} sx={{ height: 20, width: 20 }} />
                  </IconButton>
                  <ChatDialog
                    open={viewModel.addParticipant}
                    onClose={viewModel.handleCloseAddParticipant}
                    viewModel={viewModel}
                    title={"Invite attendant"}
                  />
                  {/* REMOVE PARTICIPANT */}
                  <IconButton onClick={viewModel.handleRemoveParticipant}>
                    <Avatar src={removeUser} sx={{ height: 20, width: 20 }} />
                  </IconButton>
                  <ChatDialog
                    open={viewModel.removeParticipant}
                    onClose={viewModel.handleCloseRemoveParticipant}
                    viewModel={viewModel}
                    title="Remove attendant"
                  />
                  {/* CLOSE CONVERSATION */}
                  <IconButton onClick={viewModel.handleDeleteChat}>
                    <DeleteOutlined
                      sx={{
                        height: 24,
                        width: 24,
                        color: "#FF2D2D",
                      }}
                    />
                  </IconButton>
                  <GenericDialog
                    open={viewModel.deleteChat}
                    text={"Are you sure you want to delete this conversation?"}
                    options={DIALOG_BUTTONS_DELETE_CHAT}
                    onClickFirstOption={viewModel.handleCloseDeleteChat}
                    onClickSecondOption={viewModel.handleCloseDeleteChat}
                  />
                </FlexBetween>
              </Box>
            )}
          </Box>

          {/* MESSAGES */}
          {/* <Box style={styles.parentContainerStyle}> */}

          <Box style={styles.messagesContainer}>
            <Box
              style={styles.messagesContainerAbsolute}
              ref={chatContainerRef}
            >
              {viewModel.messages.length > 0 && (
                // <List style={styles.messageList}>
                <>
                  {viewModel.messages.map((message, index) => (
                    <ListItem
                      key={`message-${index}-msg`} // Add a unique key using a template literal
                      style={styles.listItemCustomStyle}
                    >
                      {message.content === "" ? (
                        <div
                          style={
                            message.memberReferenceId === viewModel.session._id
                              ? { textAlign: "right", width: "100%" }
                              : {}
                          }
                        >
                          {"event" in message.data ? (
                            message.data.event == "newMember" ? (
                              <span>
                                {message.memberReferenceId ===
                                viewModel.session._id
                                  ? "You have"
                                  : `${message.data?.memberName} has`}{" "}
                                become a member.
                              </span>
                            ) : message.data.event == "enter" ? (
                              <span>
                                {message.memberReferenceId ===
                                viewModel.session._id
                                  ? "You have"
                                  : `${message.data?.memberName} has`}{" "}
                                joined the chat.
                              </span>
                            ) : message.data.event == "exit" ? (
                              <span>
                                {message.memberReferenceId ===
                                viewModel.session._id
                                  ? "You have"
                                  : `${message.data?.memberName} has`}{" "}
                                left the chat.
                              </span>
                            ) : null
                          ) : null}
                        </div>
                      ) : (
                        <div style={styles.totalWidth}>
                          <Message
                            message={message}
                            viewModel={viewModel}
                            isOwnMessage={
                              message.memberReferenceId ===
                              viewModel.session._id
                            }
                          />
                        </div>
                      )}
                    </ListItem>
                  ))}
                </>
                // </List>
              )}
            </Box>
          </Box>
          {/* </Box> */}

          {/* INPUT */}
          <form onSubmit={viewModel.handleSubmit} style={styles.form}>
            <Box style={styles.inputContainer}>
              <input
                type="text"
                value={viewModel.newMessage}
                onChange={viewModel.handleInputChange}
                style={styles.input}
                placeholder="Send a message"
              />
              <input
                style={{ display: "none" }}
                type={"file"}
                ref={fileInputRef}
                onChange={viewModel.handleFileChange}
              />
              <IconButton
                sx={{
                  backgroundColor: "#C0C0C0",
                  borderRadius: "0px",
                  "&:hover": {
                    backgroundColor: "#FF7C06 !important",
                  },
                }}
                onClick={handleAttach}
              >
                <AttachFileOutlined sx={{ color: "black" }} />
              </IconButton>
              <button style={styles.sendButton}>Send</button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Chat;
