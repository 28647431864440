import axios from "axios";
import { getHostPythonAPIBaseUrl } from "config";
// const { IP_BACKEND_HOST } = require('../config/env');

class VehicleRequests {

  async getPagination(filter: any, page: number, pageSize: number) {

    const url = `${getHostPythonAPIBaseUrl()}/vehicle?page=${page}&limit=${pageSize}`;
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }

  async getPaginationVehicleModels(payload: any, page: number, pageSize: number) {

    if (!payload) {
      payload = {
        "year": null,
        "make": null,
        "model": null,
        "bodyStyle": null
      }
    }

    const url = `${getHostPythonAPIBaseUrl()}/api/v1/vehicle-model/read/all?page=${page}&limit=${pageSize}`;
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.post(url, payload, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }

  async getPaginationSearchVehicleModels(search: any, page: number, pageSize: number) {

  
    const url = `${getHostPythonAPIBaseUrl()}/api/v1/vehicle-model/search?page=${page}&limit=${pageSize}&keywords=${search}`;
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }

  async getByUser(userId: any,) {
    const url = `${getHostPythonAPIBaseUrl()}/vehicle/vehicle_by_user_id?id=${userId}`;
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }

  async delete(id: string) {
    const url = `${getHostPythonAPIBaseUrl()}/vehicle/plateNumber?id=${id}`;
    const token = localStorage.getItem("access_token");
    return await axios.post(url, {}, {
      headers: {
        "Authorization": token
      }
    });

  }

  async deleteModel(id: string) {
    const url = `${getHostPythonAPIBaseUrl()}/api/v1/vehicle-model/delete/one?id=${id}`;
    const token = localStorage.getItem("access_token");
    return await axios.post(url, {}, {
      headers: {
        "Authorization": token
      }
    });

  }

  async create_vehicle_model(payload: any) {
    const url = `${getHostPythonAPIBaseUrl()}/api/v1/vehicle-model/create/one`;

    const token = localStorage.getItem("access_token");
    try {
      return await axios.post(url, payload, {
        headers: {
          "Authorization": token
        }
      });
    } catch (err:any) {
      throw err
    }
  }

  
  async update_vehicle_model(payload: any) {
    const url = `${getHostPythonAPIBaseUrl()}/api/v1/vehicle-model/update/one?id=${payload.id}`;

    const token = localStorage.getItem("access_token");
    try {
      return await axios.post(url, payload, {
        headers: {
          "Authorization": token
        }
      });
    } catch (err:any) {
      throw err
    }
  }
}
export default VehicleRequests;
