import React from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import useMaiaAIViewModel from "./openAI";
import TableVectorStore from "./tableVectorStore";
import {
  Box,
  Button,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Breadcrumb from "./breadcrump";
import AddVectorStoreDialog from "./addVectorStoreDialog";
import TableVectorStoreFiles from "./tableVectorStoreFiles";
import UploadFileDialog from "./uploadFileDialog";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddVectorStoreFileDialog from "./addFileToVectorStoreDialog";
import AddIcon from "@mui/icons-material/Add";
import TableFiles from "./tableFiles";
import DeleteConfirmDialog from "./deleteConfirmationDialog";
import TableMaiaBrain from "./tableMaiaBrain";
import AddMaiaBrainDialog from "./addMaiaBrainDialog";

const MaiaAI: React.FC = () => {
  const viewModel = useMaiaAIViewModel();

  const buttonStyles = {
    display: "flex",
    width: "180px",
    height: "48px",
    padding: "13px 16px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "16px",
    background: "var(--Orange-Orange-900, #FF7C06)",
    color: "#000",
    textTransform: "none" as const,
    fontFamily: "Inter",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "-0.15px",
  };

  return (
    <Box m="1.5rem 2.5rem" style={{ position: "relative" }}>
      <FlexBetween>
        <Header
          title={"MAIA"}
          subtitle="The place where you can manage Maia assistant wite the powerful OpenAI API"
        />

        <Box display={"flex"}>
          {viewModel.breadcrumb.currentLevel === "Vector Store" &&
            viewModel.selectOption === "Vector Store" && (
              <Button
                style={buttonStyles}
                variant="contained"
                onClick={() => viewModel.setAddVectorStoreDialogOpen(true)}
              >
                Add Vector Store&nbsp;<AddIcon></AddIcon>
              </Button>
            )}
          {viewModel.breadcrumb.currentLevel === "Files" &&
            viewModel.breadcrumb.currentLevel === "Vector Store" && (
              <Button
                style={buttonStyles}
                variant="contained"
                onClick={() => viewModel.setAddFileVectorStoreDialogOpen(true)}
              >
                Add File &nbsp;<AddIcon></AddIcon>
              </Button>
            )}
          &nbsp;&nbsp;&nbsp;
          {viewModel.selectOption !== "Maia Brain" ? (
            <Button
              style={buttonStyles}
              variant="contained"
              onClick={() => viewModel.setUploadFileDialogOpen(true)}
            >
              Updalod File &nbsp; <FileUploadIcon></FileUploadIcon>
            </Button>
          ) : (
            <Button
              style={buttonStyles}
              variant="contained"
              onClick={() => {
                viewModel.setIsEditMaiaItem(false);
                viewModel.setCurrentMaiaItem(null);
                viewModel.setAddEditMaisBrainDialogOpen(true);
              }}
            >
              Add item &nbsp; <AddIcon></AddIcon>
            </Button>
          )}
        </Box>
      </FlexBetween>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
        mt={3}
        width={"100%"}
      >
        {viewModel.selectOption === "Vector Store" ? (
          <Breadcrumb
            subcomponents={viewModel.breadcrumb.subcomponents}
            currentLevel={viewModel.breadcrumb.currentLevel}
            changeLevel={(level: string) => {
              viewModel.setBreadcrumb({
                ...viewModel.breadcrumb,
                currentLevel: level,
              });
            }}
          />
        ) : (
          <div>{/* Content for the else condition */}</div>
        )}

        {viewModel.breadcrumb.currentLevel === "Files" && (
          <Box display="flex">
            <b>{"Current VectorStore Id:"}&nbsp;</b>
            <span>{viewModel.selectedVectorStoreId}</span>
          </Box>
        )}

        <Box
          ml={viewModel.selectOption === "Vector Store" ? 5 : 0}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Select
            variant="outlined"
            onChange={(event: SelectChangeEvent<string>) => {
              viewModel.setSelectOption(event.target.value);
            }}
            style={{ height: "40px", width: "140px" }}
            value={viewModel.selectOption}
          >
            {["Vector Store", "Files", "Maia Brain"].map((option) => (
              <MenuItem key={option + Date.now()} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between">
        {viewModel.breadcrumb.currentLevel === "Vector Store" &&
          viewModel.selectOption === "Vector Store" && (
            <TableVectorStore
              vectorStoreList={viewModel.vectorStoreList}
              handleSelectVectorStore={viewModel.handleSelectVectorStore}
              handleOpenDeleteVectoreStore={
                viewModel.handleOpenDeleteVectoreStore
              }
            />
          )}

        {viewModel.breadcrumb.currentLevel === "Files" && (
          <TableVectorStoreFiles
            vectorStoreFilesList={viewModel.vectorStoreFilesList}
            emitFetchData={viewModel.handleFetchData}
          />
        )}

        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
          {viewModel.selectOption === "Files" && (
            <TableFiles
              files={viewModel.fileList}
              handleDeleteFile={viewModel.handleOpenDeleteFile}
            />
          )}

          {viewModel.selectOption === "Maia Brain" && (
            <TableMaiaBrain
              items={viewModel.maiaBrainList}
              handleEditItem={viewModel.handleEditMaiaItem}
              handleDeleteFile={viewModel.handleOpenDeleteMaiaBrainItem}
            />
          )}
          {viewModel.selectOption === "Maia Brain" && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop={2}
              marginBottom={2}
            >
              <Pagination
                count={viewModel.totalPages}
                page={viewModel.page}
                onChange={(event, newPage) => viewModel.setPage(newPage)}
                size="large"
                disabled={
                  !viewModel.maiaBrainList ||
                  viewModel.maiaBrainList.length === 0
                }
              />

              <Box
                marginLeft={2}
                marginBottom={2}
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems="start "
              >
                <label>Items per page</label>
                <Select
                  style={{ height: "30px" }}
                  value={viewModel.pageSize}
                  onChange={(e: any) => {
                    viewModel.setPageSize(e.target.value);
                    viewModel.setPage(e.target.value);
                  }}
                >
                  {viewModel.pageSizes.map((size) => (
                    <MenuItem key={size + "  - size"} value={size}>
                      {size}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <AddVectorStoreDialog
        open={viewModel.addVectorStoreDialogOpen}
        onClose={() => {
          viewModel.setAddVectorStoreDialogOpen(false);
        }}
        emitFetchData={viewModel.handleFetchData}
      ></AddVectorStoreDialog>

      <AddMaiaBrainDialog
        open={viewModel.addEditMaisBrainDialogOpen}
        onClose={() => {
          viewModel.setAddEditMaisBrainDialogOpen(false);
          viewModel.setIsEditMaiaItem(false);
        }}
        isEdit={viewModel.isEditMaiaItem}
        emitFetchData={viewModel.handleFetchData}
        item={viewModel.currentMaiaItem}
      ></AddMaiaBrainDialog>

      <AddVectorStoreFileDialog
        open={viewModel.addFileVectorStoreDialogOpen}
        onClose={() => {
          viewModel.setAddFileVectorStoreDialogOpen(false);
        }}
        vectorStoreId={viewModel.selectedVectorStoreId}
        listFiles={viewModel.fileList}
        emitFetchData={viewModel.handleFetchData}
      ></AddVectorStoreFileDialog>

      <UploadFileDialog
        open={viewModel.uploadFileDialogOpen}
        onClose={() => {
          viewModel.setUploadFileDialogOpen(false);
        }}
        vectorStoreId={viewModel.selectedVectorStoreId}
        vectorStoreList={viewModel.vectorStoreList}
        emitFetchData={viewModel.handleFetchData}
      ></UploadFileDialog>

      <DeleteConfirmDialog
        message={`Are you sure you want to delete this ${
          viewModel.selectOption === "Vector Store"
            ? "File"
            : viewModel.selectOption === "Maia Brain"
            ? "Item"
            : "Item"
        }? This action cannot be reverted.`}
        open={viewModel.isDeleteDialogOpen}
        onDelete={() => {
          viewModel.deleteData(
            viewModel.selectOption === "Vector Store"
              ? viewModel.selectedVectorStoreId
              : viewModel.selectOption === "Maia Brain"
              ? viewModel.selectedMaiaBrainId
              : viewModel.selectedFileId
          );
        }}
        onClose={() => {
          viewModel.setIsDeleteDialogOpen(false);
        }}
      ></DeleteConfirmDialog>
    </Box>
  );
};

export default MaiaAI;
