import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "./FlexBetween";

type Props = {
    title: string;
    value: string | number;
    increase?: boolean;
    increasePercentage?: string;
    icon: React.ReactNode;
    description: string;
}

const StatBox: React.FC<Props> = ({
  title,
  value,
  increase = false,
  increasePercentage = "",
  icon,
  description,
}) => {
  const theme = useTheme();
  return (

    // @ts-ignore
    <Box
      gridColumn="span 2"
      gridRow="span 1"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      p="1.25rem 1rem"
      flex="1 1 45%"
      m={1}
      // @ts-ignore
      backgroundColor={theme.palette.background.alt}
      borderRadius="0.55rem"
    >
      <FlexBetween>
        {/* @ts-ignore */}
        <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
          {title}
        </Typography>
        {icon}
      </FlexBetween>

      <Typography
        variant="h3"
        fontWeight="600"
        // @ts-ignore
        sx={{ color: theme.palette.secondary[200] }}
      >
        {value}
      </Typography>
      <FlexBetween gap="1rem">
        <Typography
          variant="button"
          align="center"
          sx={{
            padding: "0 4px",
            borderRadius: "10px",
            color: theme.palette.secondary.main,
            backgroundColor:
              // @ts-ignore
              increasePercentage > 0 || increase > 0
                // @ts-ignore
                ? theme.palette.percentages.positive
                // @ts-ignore
                : theme.palette.percentages.negative,
          }}
        >
          {increase !== null
            ? increase
            // @ts-ignore
            : increasePercentage > 0
            ? `${increasePercentage}%`
            : `${increasePercentage}%`}
        </Typography>
        <Typography>{description}</Typography>
      </FlexBetween>
    </Box>
  );
};

export default StatBox;
