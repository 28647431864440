import { Box, Typography } from "@mui/material";
import React from "react";
import SimpleUnaryInterface from './SimpleUnaryInterface';
import ServerStreamingInterface from "./ServerStreamingInterface";
import ClientStreamingInterface from "./ClientStreamingInterface";
import BiDirectionalStreamingInterface from "./BiDirectionalStreamingInterface";
import StreamNotifyEventsInterface from "./StreamNotifyEventsInterface";

const GrpcTest: React.FC = () => {
  return (
    <Box sx={{ padding: 4 }}>
      <Typography mb={4} variant="h2">gRPC Client Interfaces</Typography>
      <SimpleUnaryInterface />
      <ServerStreamingInterface />
      <ClientStreamingInterface />
      <BiDirectionalStreamingInterface />
      <StreamNotifyEventsInterface />
    </Box>
  );
};

export default GrpcTest;
