import React, { useState } from "react";
import { GreeterClient } from "./greet/GreetServiceClientPb"; // Adjust path according to your structure
import { HelloRequest } from "./greet/greet_pb"; // Adjust path
import { Box, Button, TextField, Typography } from "@mui/material";
import { getHostPythonGrpcBaseUrl } from "config";

const SimpleUnaryInterface = () => {
  const [name, setName] = useState<string>("");
  const [greeting, setGreeting] = useState<string>("");
  const [replyMessage, setReplyMessage] = useState<string>("");

  const client = new GreeterClient(getHostPythonGrpcBaseUrl(), null, null);

  const handleSayHello = async () => {
    const request = new HelloRequest();
    request.setName(name);
    request.setGreeting(greeting);

    client.sayHello(request, {}, (err, response) => {
      if (err) {
        console.error(err);
        return;
      }
      setReplyMessage(response.getMessage());
    });
  };

  return (
    <Box display="flex" flexDirection="column" mb={4}>
      <Typography mb={2} variant="h3">
        Unary gRPC Test
      </Typography>

      <Box display="flex" mb={2}>
        <TextField
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Name"
          variant="filled"
        />
        &nbsp;&nbsp;
        <TextField
          type="text"
          value={greeting}
          onChange={(e) => setGreeting(e.target.value)}
          label="Greeting"
          variant="filled"
        />
        &nbsp;&nbsp;
        <Button
          style={{
            background: "#FF7C06",
            textTransform: "none",
            paddingRight: "16px",
            paddingLeft: "16px",
            width: "124px",
          }}
          onClick={handleSayHello}
        >
          Send Greeting
        </Button>
      </Box>
      {replyMessage && (
        <p>
          <b>Server Response:</b> <br />
          {replyMessage}
        </p>
      )}
    </Box>
  );
};

export default SimpleUnaryInterface;
