import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, MenuItem, TextField, useTheme } from "@mui/material";
import { useGetAdminsQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import AdminRequests from "requests/adminRequests";

const Admin = () => {
  const theme = useTheme();
  const [data, setData] = useState<any[]>([]);
  const [email, setEmail] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  // @ts-ignore


  const handleNewAdmin = (event:any) => {
    event.preventDefault();
    const adminRequests = new AdminRequests();
    // Handle form submission logic here
    console.log('Email:', email);
    console.log('Selected Role:', selectedRole);
    adminRequests.invite(email,selectedRole).then(()=> {
      setEmail("")
      setSelectedRole("")
      alert("Invitation has been successfully made")
    },(err:any)=> {
      alert(JSON.stringify(err))
    })
  };

  const clickRemove = (email:string) => {
    const adminRequests = new AdminRequests();
    adminRequests.delete(email).then(()=>{
      alert("Admin removed successfully")
      setRefresh(!refresh)
    },(err)=> {
      alert(JSON.stringify(err))
    })
  }



  const roles = ['super admin','admin', 'guest', 'support'];
  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
      hide: true,
    },
    {
      field: "profileImg",
      headerName: "Avatar",
      flex: 0.25,
      renderCell: (params: any) => (
        <Avatar
          src={params.value?.uri}
          sx={{ width: params.width, height: params.height }}
        />
      ),
      sortable: false,
      filterable: false,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 0.5,
    },
    {
      field: "actions",
      headerName: "User Actions",
      flex: 0.4,
      renderCell: (params: any) => (
        <Button onClick={() => clickRemove(params.row.email)} variant="outlined" color="error">Remove</Button>
      ),
    },
  ];
  useEffect(() => {
    
    const fetchData = async () => {
      const adminRequests = new AdminRequests();
      try {
        const result = await adminRequests.getAll();
        setData(result.data);
  
      } catch (error) {
        // Handle the error here
        console.error(error);
      
      }
    };

    fetchData();
  }, [refresh]);
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="ADMINS" subtitle="Managing admins access control" />

      <Box mb={3} mt={3} style={{maxWidth:300}}>
      <b>Add new admin user</b>
      <form onSubmit={handleNewAdmin}>
        <TextField
          label="Email"
          type="email"
          variant="filled"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          color="secondary"
        />

        <TextField
          select
          label="Role"
          variant="filled"
          fullWidth
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
          margin="normal"
          color="secondary"
        >
          {roles.map((role) => (
            <MenuItem key={role + '_role'} value={role}>
              {role}
            </MenuItem>
          ))}
                 
        </TextField>
            
        <Button  type="submit" variant="contained" style={{backgroundColor:"#FD7B05",marginTop:8, color:"black"}}>
          Invite User
        </Button>
      </form>
      </Box>
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            // @ts-ignore
            backgroundColor: theme.palette.background.alt,
            // @ts-ignore
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            // @ts-ignore
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            // @ts-ignore
            backgroundColor: theme.palette.background.alt,
            // @ts-ignore
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            // @ts-ignore
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          getRowId={(row) => row._id}
          rows={data || []}
          columns={columns}
          components={{
            ColumnMenu: CustomColumnMenu,
          }}
        />
      </Box>
    </Box>
  );
};

export default Admin;
