/**
 * @fileoverview gRPC-Web generated client stub for activitylog
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.21.12
// source: activitylog.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as activitylog_pb from './activitylog_pb'; // proto import: "activitylog.proto"


export class ActivityLogClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorPostEvent = new grpcWeb.MethodDescriptor(
    '/activitylog.ActivityLog/PostEvent',
    grpcWeb.MethodType.UNARY,
    activitylog_pb.EventRequest,
    activitylog_pb.EventResponse,
    (request: activitylog_pb.EventRequest) => {
      return request.serializeBinary();
    },
    activitylog_pb.EventResponse.deserializeBinary
  );

  postEvent(
    request: activitylog_pb.EventRequest,
    metadata?: grpcWeb.Metadata | null): Promise<activitylog_pb.EventResponse>;

  postEvent(
    request: activitylog_pb.EventRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: activitylog_pb.EventResponse) => void): grpcWeb.ClientReadableStream<activitylog_pb.EventResponse>;

  postEvent(
    request: activitylog_pb.EventRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: activitylog_pb.EventResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/activitylog.ActivityLog/PostEvent',
        request,
        metadata || {},
        this.methodDescriptorPostEvent,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/activitylog.ActivityLog/PostEvent',
    request,
    metadata || {},
    this.methodDescriptorPostEvent);
  }

  methodDescriptorStreamEvents = new grpcWeb.MethodDescriptor(
    '/activitylog.ActivityLog/StreamEvents',
    grpcWeb.MethodType.SERVER_STREAMING,
    activitylog_pb.EventStreamRequest,
    activitylog_pb.Event,
    (request: activitylog_pb.EventStreamRequest) => {
      return request.serializeBinary();
    },
    activitylog_pb.Event.deserializeBinary
  );

  streamEvents(
    request: activitylog_pb.EventStreamRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<activitylog_pb.Event> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/activitylog.ActivityLog/StreamEvents',
      request,
      metadata || {},
      this.methodDescriptorStreamEvents);
  }

}

