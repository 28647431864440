import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, Box } from "@mui/material";

type Props = {
  options: any[];
  onChange: any;
  value: any;
  label?: string;
  icon?: string;
  fetchData?: boolean;
  width?: string;
  placeholder?: string;
  callback: (variants: string[]) => void;
};

const DropdownCheckbox = ({
  options,
  width,
  onChange,
  value,
  label = "Select the variant",
  placeholder = "Variant",
}: Props) => {
  const [selectedTags, setSelectedTags] = useState<any[]>(value || []);

  useEffect(() => {
    // Clear selected tags when options change
    setSelectedTags([]);
  }, [options]);

  const handleChange = (event: any, newValue: any) => {
    setSelectedTags(newValue);
    console.log("The new value-------->",newValue)
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Box
      style={{
        marginTop: "0px",
        marginLeft: "16px",
        padding: "0px",
        width,
      }}
    >
      <Autocomplete
        multiple
        id="tags-outlined"
        options={options}
        value={selectedTags}
        onChange={handleChange}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={label}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
        )}
      />
    </Box>
  );
};

export default DropdownCheckbox;
