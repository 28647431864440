import { useTheme, Box, Avatar } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import MapBox, { MapRef, Marker } from "react-map-gl";
import { getMapboxToken, getMapboxStyle } from "config";
// import { MAP_BOX_STYLE, MAP_BOX_TOKEN } from "config/env";
import towingSelected from "../assets/icons/services/notAssigned/towingNotAssigned.svg";
import tireChangeSelected from "../assets/icons/services/notAssigned/tireChangeNotAssigned.svg";
import refuelSelected from "../assets/icons/services/notAssigned/refuelNotAssigned.svg";
import jumpSelected from "../assets/icons/services/notAssigned/jumpNotAssigned.svg";
import unlockSelected from "../assets/icons/services/notAssigned/unlockNotAssigned.svg";

type Marker = {
  id: string;
  latitude: number;
  longitude: number;
  specialty: string;
  name: string;
  address: string;
};

interface Location {
  latitude: number;
  longitude: number;
  zoom: number;
}

interface Props {
  // onMarkersChange: (markers: Marker[]) => void;
  // Other props here
  mapHeight?: string;
  markers: Marker[];
}

const StaticMap = ({ mapHeight, markers }: Props) => {
  // const [markers, setMarkers] = useState<Marker[]>([]);
  const theme = useTheme();
  const height: string = mapHeight ? mapHeight : "75vh";
  const [viewPort, setViewPort] = useState<Location>({
    latitude: 34.053691,
    longitude: -118.242766,
    zoom: 16,
  });
  const mapRef = useRef<MapRef>(null);

  const handleMarkerData = (marker: Marker) => {
    alert(
      `Name: ${marker.name}\n
  Specialty: ${marker.specialty}\n
  Address: ${marker.address}`
    );
  };

  return (
    <MapBox
      ref={mapRef}
      initialViewState={viewPort}
      style={{
        width: "auto",
        height: height,
        borderRadius: "10px",
      }}
      mapStyle={getMapboxStyle()}
      // transitionDuration="200"
      mapboxAccessToken={getMapboxToken()}
      // onViewportChange={(viewPort) => setViewPort(viewPort)}
      attributionControl={false}
    >
      {markers.map((marker: any) => {
        return (
          <Marker
            onClick={() => handleMarkerData(marker)}
            key={marker.id + " + handleMarkerData"}
            latitude={marker.latitude}
            longitude={marker.longitude}
          >
            {["standardTow", "flatbedTow", "heavyDuty", "towing"].includes(
              marker.specialty
            ) && <Avatar src={towingSelected} />}
            {marker.specialty === "tireChange" && (
              <Avatar src={tireChangeSelected} />
            )}
            {marker.specialty === "refuel" && <Avatar src={refuelSelected} />}
            {marker.specialty === "jump" && <Avatar src={jumpSelected} />}
            {marker.specialty === "unlock" && <Avatar src={unlockSelected} />}
          </Marker>
        );
      })}
    </MapBox>
  );
};

export default StaticMap;
