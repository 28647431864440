import React, { useMemo, useCallback } from "react";
import { IconButton, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { formattedDateEpoch } from "utils/helper";


const TableFiles = ({
  files,
  handleDeleteFile,

}: {
  files: any[];
  handleDeleteFile: (id: string) => void;

}) => {
  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", flex: 0.2 },
      { field: "filename", headerName: "Filename", flex: 0.2 },
      {
        field: "created_at",
        headerName: "Created At",
        flex: 0.2,
        valueFormatter: (params: any) => {
          if (!params.value == null) {
            return "";
          }
          const date = formattedDateEpoch(params.value);
          return date;
        },
      },
      {
        field: "bytes",
        headerName: "Size (MB)",
        flex: 0.2,
        valueGetter: (params: any) =>
          (params.row.bytes / (1024 * 1024)).toFixed(2),
      },
      {
        field: "purpose",
        headerName: "Purpose",
        flex: 0.2,
      },
    
      {
        field: "delete",
        headerName: "Delete",
        flex: 0.1,
        renderCell: (params: any) => (
          <IconButton
            aria-label="delete"
            onClick={() => handleDeleteFile(params.row.id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
        ),
      },
    ],
    []
  );

  // Memoize the handler functions to prevent recreation on each render
 
  const memoizedHandleDeleteVectorStore = useCallback(
    (id: string) => {
      handleDeleteFile(id);
    },
    [handleDeleteFile]
  );

  return (
    <Box mt="40px" minWidth={"100%"} height="auto">
      <DataGrid
        autoHeight
        rowHeight={60}
        disableExtendRowFullWidth={false}
        loading={false}
        getRowId={(row) => JSON.stringify(row)}
        rows={files || []}
        columns={columns}
        hideFooter
        pageSize={99}
      />
    </Box>
  );
};

export default TableFiles;
