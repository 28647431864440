import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, List, ListItem } from "@mui/material";
import { styles } from "./styles";
import { AddCircleOutlineOutlined, Update } from "@mui/icons-material";
import Tag from "./tag";
import axios from "axios";
import ColorPicker from "./colorPicker";
import { getHostPythonAPIBaseUrl } from "config";
// const { BACKEND, IP_BACKEND_HOST } = require("../../config/env");

interface CustomCardProps {
  formData: any;
  handleSetContact: (val: boolean) => void;
}

const CustomCard: React.FC<CustomCardProps> = ({
  formData,
  handleSetContact,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

  const [currentColorSelected, setCurrentColorSelected] = useState<string>("");

  const [notes, setNotes] = useState<string>("");
  const [tags, setTags] = useState<any>([]);
  const [tagsSuggestions, setTagsSuggestions] = useState<any>([]);
  const inputId = "tagInput";
  const [currentColorIndex, setCurrentIndex] = useState<number>(0);
  const [currentTagIndex, setCurrentTagIndex] = useState<number>(0);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const colors = ["#1279F2", "#FF7C06", "#FF2D2D", "#34A853"];

  const handleKeyDown = async (event: any) => {
    if (event.key === "Enter") {
      if (inputValue === "") return;
      let exist = false;
      tags.forEach((t: any) => {
        if (t.name === inputValue) {
          resetTagInput();
          setInputValue("");

          exist = true;
        }
      });
      if (exist) return;

      const url = `${getHostPythonAPIBaseUrl()}/tag/change`;
      const token = localStorage.getItem("access_token");
      const randomColor = getRandomColor();
      setInputValue("");
      resetTagInput();

      try {
        const response = await axios.put(
          url,
          {
            name: inputValue,
            color: randomColor,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        const data = response.data;

        // Assuming setTags is a function to update state with the new data
        setTags((prevTags: any) => [...prevTags, data]);

        // Optionally, alert the user about successful operation
        alert("Tag added successfully!");
      } catch (error) {
        console.error("Error adding tag:", error);

        alert("Error on add contact try again later");
      }
    }
  };

  useEffect(() => {
    const data: any = [] as any;
    formData.tags.map((el: any) => {
      const { name, color } = extractTagInfo(el._id);
      data.push({
        _id: el,
        color: `#${color}`, // Ensure the color is prefixed with '#'
        name: name,
      });
    });
    console.log(data);
    setTags(data);
  }, [formData]);

  const extractTagInfo = (tagString: string) => {
    // Assuming the format is 'name#color_tag'
    const [nameColor, _tag] = tagString.split("_");
    const [name, color] = nameColor.split("#");
    return { name, color };
  };

  function resetTagInput() {
    setInputValue("");
    const inputElement = document.getElementById(
      inputId
    ) as HTMLInputElement | null;
    if (inputElement) {
      inputElement.value = "";
    }
  }

  // useEffect(() => {
  //   console.log("tags ------->", JSON.stringify(tags));
  // }, [tags]);

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const handleSaveContact = async () => {
    try {
      const url = `${getHostPythonAPIBaseUrl()}/company/aggregate/update/updates`;
      const token = localStorage.getItem("access_token");
      setInputValue("");
      resetTagInput();
      let sessionString = localStorage.getItem("session");

      let session: any = {};
      if (sessionString !== null) {
        session = JSON.parse(sessionString);
      }

      let formDataSave = formData;
      formDataSave.updates = [
        ...formDataSave.updates,
        {
          companyId: formData.placeId,
          email: session.email,
          created: new Date(),
          updated: new Date(),
          notes: notes,
          __v: "0.1.0",
        },
      ];

      let limit = 10;

      if (formDataSave.updates.length > limit) {
        formDataSave.updates.shift();
      }

      const response = await axios.put(
        url,
        {
          companyId: formData.placeId,
          email: session.email,

          notes: notes,
          __v: "0.1.0",
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      alert("Contact has been save successfully");
      handleSetContact(false);
      setNotes("");
      setInputValue("");
    } catch (e: any) {
      alert(JSON.stringify(e));
      
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (event.target.value != "") {
      searchTags();
    } else {
      setTagsSuggestions([]);
    }
  };

  const searchTags = async () => {
    // Clear the timeout if it's already set.
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (inputValue === "") {
      setTagsSuggestions([]);
      return;
    }
    // Set a new timeout to delay the API call.
    setTimeoutId(
      setTimeout(async () => {
        const url = `${getHostPythonAPIBaseUrl()}/tag/search?name=${inputValue}`;

        if (inputValue != "") {
          const response = await axios.get(url, {
            headers: {
              Authorization: localStorage.getItem("access_token"),
            },
          });
          let all = response.data;
          setTagsSuggestions(all);
          tags.forEach((t: any) => {
            const indexToRemove = all.findIndex(
              (element: any) => element.name === t.name
            );
            if (indexToRemove !== -1) {
              all.splice(indexToRemove, 1);
            }
          });
        }
      }, 300)
    );
  };
  const handleClickTagSuggestions = async (suggestion: any) => {
    // Check if a tag with the specified name already exists

    const url = `${getHostPythonAPIBaseUrl()}/company/aggregate/update/property`;
    const updatedTags = [
      ...tags,
      {
        color: suggestion.color,
        name: suggestion.name,
        _id: suggestion._id,
      },
    ];
    console.log(updatedTags);
    setTags(updatedTags);
    resetTagInput();
    setTagsSuggestions([]);

    await axios.put(
      url,
      {
        placeId: formData.placeId,
        data: updatedTags,
        property: "tags",
      },
      {
        headers: {
          Authorization: localStorage.getItem("access_token"),
        },
      }
    );
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value);
  };

  const handleRemoveTag = async (indexToRemove: number) => {
    const updatedTags = [...tags];
    const removedTag = updatedTags.splice(indexToRemove, 1)[0]; // Remove and store the removed tag
    setTags(updatedTags);

    const url = `${getHostPythonAPIBaseUrl()}/company/aggregate/update/property`;
    await axios.put(
      url,
      {
        companyId: formData._id,
        placeId: formData.placeId,
        data: updatedTags,
        property: "tags",
      },
      {
        headers: {
          Authorization: localStorage.getItem("access_token"),
        },
      }
    );
  };

  const handleChangeColor = async (
    hex: string,
    colorIndex: number,
    index: number
  ) => {
    setShowColorPicker(false);

    const url = `${getHostPythonAPIBaseUrl()}/tag/change`;
    const token = localStorage.getItem("access_token");
    setInputValue("");
    resetTagInput();

    // Clone the array to avoid mutating the state directly
    let updatedTags = [...tags];

    const response = await axios.put(
      url,
      {
        name: updatedTags[index].name,
        color: colors[colorIndex],
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const data = response.data;
    // Update the color of the tag at the specified index
    updatedTags[index] = {
      ...updatedTags[colorIndex],
      color: colors[colorIndex],
      name: data.name,
    };
    setCurrentColorSelected(colors[index]);

    // Set the updated array in the state
    setTags(updatedTags);
  };

  return (
    <Box
      display="flex"
      padding="10px 16px"
      flexDirection="column"
      alignItems="flex-end"
      gap="16px"
      alignSelf="stretch"
      borderRadius="8px"
      sx={{
        background: "#191919",
      }}
      position={"relative"}
    >
      <div style={{ width: "100%" }}>
        <Typography style={styles.newContactTitle}>New Contact</Typography>
      </div>
      <TextField
        label="Notes"
        multiline
        rows={2} // Set the number of rows
        variant="outlined"
        fullWidth
        color="secondary"
        onChange={handleNoteChange}
      />

      <input
        id={inputId}
        placeholder="Tags"
        type="text"
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        style={{
          borderRadius: "16px",
          border: "1px solid var(--gray-gray-900, #7E7E7E)",
          padding: "8px",
          width: "100%",
          boxSizing: "border-box",
          background: "transparent",
          outline: "none", // Remove the default focus outline
          height: "46px",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      />
      {tagsSuggestions && tagsSuggestions.length > 0 && (
        <List sx={styles.listLarger}>
          {tagsSuggestions.map((suggestion: any, i: number) => (
            <ListItem
              key={i}
              onClick={() => {
                handleClickTagSuggestions(suggestion);
              }}
              sx={{
                display: "flex",
              }}
            >
              <Typography>{suggestion.name}</Typography>
            </ListItem>
          ))}
        </List>
      )}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "start",
          alignItems: "start",
          width: "100%",
        }}
      >
        {tags.map((tag: any, index: number) => (
          <div style={{ marginBottom: 6 }}>
            <Tag
              key={`${Math.random() * 1000000000}${index}`}
              name={tag.name}
              color={tag.color}
              onRemove={() => handleRemoveTag(index)}
              isEditable={true}
            />
            &nbsp;
          </div>
        ))}
      </div>

      <ColorPicker
        visible={showColorPicker}
        currentColor={currentColorSelected}
        currentColorIndex={currentColorIndex}
        handleChangeColor={handleChangeColor}
        currentTagIndex={currentTagIndex}
      />

      <Box onClick={handleSaveContact} style={styles.addServiceBox}>
        <AddCircleOutlineOutlined sx={{ color: "#FF7C06" }} />
        <Typography sx={{ color: "#FF7C06" }}>Save contact</Typography>
      </Box>
    </Box>
  );
};

export default CustomCard;
