import axios from "axios";
import { getHostPythonAPIBaseUrl } from "config";
// const { IP_BACKEND_HOST } = require('../config/env');

class NotificationRequests {
  async getPagination(page: number, pageSize: number, user_id: string) {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/v1/notifier/read-many?page=${page}&limit=${pageSize}&user_id=${user_id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }

  async clearNotifications(user_id: string | null) {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/v1/notifier/clear-many?user_id=${user_id}`;
    try {
      const response = await axios.post(url, null,{
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }

  async getDeviceInfo() {
    const appId = 'YOUR_ONESIGNAL_APP_ID';
    const apiKey = 'YOUR_ONESIGNAL_API_KEY';

    try {
      const response = await axios.get(
        `https://onesignal.com/api/v1/players`,
        {
          headers: {
            Authorization: `Basic ${apiKey}`,
          },
          params: {
            app_id: appId,
          },
        }
      );
      console.log('Device Information:', response.data);
    } catch (error) {
      console.error('Error fetching device information:', error);
    }
  };
}
export default NotificationRequests;
