import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  Groups2Outlined,
  AdminPanelSettingsOutlined,
  PublicOutlined,
  MarkChatUnreadOutlined
} from "@mui/icons-material";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BusinessIcon from '@mui/icons-material/Business';
import UpdateIcon from '@mui/icons-material/Update';
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import roadrLogo from "../assets/roadrLogo1.svg";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
type NavItem = {
  text: string;
  icon?: React.ReactNode;
}

const navItems: NavItem[] = [
  {
    text: "Dashboard",
    icon: <HomeOutlined />,
  },
 
  {
    text: "Operation",
    icon: <LocalShippingIcon />, // ask Celso which icon will use for Company
  },

  {
    text: "Maia",
    icon: <SmartToyIcon />,
  },
  {
    text: "Live Roadr",
    icon: <PublicOutlined />,
  },
  // {
  //   text: "Open AI",
  //   icon: <SmartToyIcon />,
  // },
  {
    text: "Client Facing",
  },

 
  {
    text: "Customers",
    icon: <Groups2Outlined />,
  },
  {
    text: "Vehicles",
    icon: <DirectionsCarFilledIcon />,
  },
  {
    text: "Activity Logs",
    icon: <FollowTheSignsIcon />,
  },

  {
    text: "Company Managment",
    icon: <BusinessIcon />,
  },
  {
    text: "Sales",
    icon: null,
  },
  {
    text: "soon",
    icon: <UpdateIcon />,
  },
  {
    text: "Payment",
    icon: <AttachMoneyIcon />,
  },

  {
    text: "Management",
    icon: null,
  },
  {
    text: "Admin",
    icon: <AdminPanelSettingsOutlined />,
  },

  
 
];

type Props = {

  user: any;
  drawerWidth: string;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (value: boolean) => void;
  // isNonMobile: boolean;
}

const Sidebar = ({

  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  // isNonMobile,
}: Props) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      setIsAuthenticated(true)
    }
  }, []);

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  return isAuthenticated ? (
    <Box
      component="nav"
      sx={{
        outlineStyle: "solid",
        outlineWidth: "1px",
        // @ts-ignore
        outlineColor: theme.palette.background.alt2,
      }}
    >
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            outlineWidth: "1px",
            outlineColor: theme.palette.secondary.main,
            outlineStyle: "solid",
            "& .MuiDrawer-paper": {
              // @ts-ignore
              color: theme.palette.secondary[100],
              backgroundColor: theme.palette.background.default,
              boxSixing: "border-box",
             
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box
              m="0.75rem 0 0.5rem 1.5rem"
              padding="0.75rem 0 1rem 2rem"
              width="80%"
              sx={{
                // @ts-ignore
                backgroundColor: theme.palette.neutral.alt,
                borderRadius: "50px",
              }}
            >
              <FlexBetween color={theme.palette.secondary.light}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Box
                    component="img"
                    alt="roadrLogo"
                    src={roadrLogo}
                    height="50px"
                    width="150px"
                    sx={{
                      objectFit: "contain",
                    }}
                  />
                </Box>
                
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
               
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon }) => {
                if (!icon) {
                  return (
                    <Typography key={text + "text"} sx={{ m: "2.25rem 0 0.8rem 3rem" }}>
                      {text}
                    </Typography>
                  );
                }
                const lcText = text.toLowerCase();

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`/${lcText}`);
                        setActive(lcText);
                      }}
                      sx={{
                        backgroundColor:
                          active === lcText
                            ? theme.palette.secondary.main
                            : theme.palette.background.default,
                        color:
                          active === lcText
                            ? theme.palette.primary.main
                            // @ts-ignore
                            : theme.palette.secondary[100],
                        ":hover": {
                          backgroundColor:
                            active === lcText
                              ? theme.palette.secondary.main
                              // @ts-ignore
                              : theme.palette.background.alt,
                          color:
                            active === lcText
                              ? theme.palette.primary.main
                              : theme.palette.secondary.main,
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === lcText
                              ? theme.palette.primary.main
                              // @ts-ignore
                              : theme.palette.secondary[100],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>

          <Box position="absolute" bottom="2rem">
            <Divider />
            <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 0 3rem">

              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.9rem"
                  // @ts-ignore
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user && user.name}
                </Typography>
                <Typography
                  fontSize="0.8rem"
                  // @ts-ignore
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user && user.occupation}
                </Typography>
              </Box>
        
            </FlexBetween>
          </Box>
        </Drawer>
      )}
    </Box>
  ) : null;
};

export default Sidebar;
