import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Services",
    "Customers",
    "Payments",
    "Geography",
    "Sales",
    "Admins",
    "Performance",
    "Dashboard",
    "Vehicles",
    "Reviews",
  ],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `general/user/${id}`,
      providesTags: ["User"],
    }),
    getServices: build.query({
      query: () => "client/services",
      providesTags: ["Services"],
    }),
    getVehicles: build.query({
      query: () => "management/vehicles",
      providesTags: ["Vehicles"],
    }),
    getCustomers: build.query({
      // query: () => "client/customers",
      // providesTags: ["Customers"],
      query: ({ type }) => ({
        url: "client/customers",
        method: "GET",
        params: { type },
      }),
    }),
    getPayments: build.query({
      query: ({ page, pageSize, sort, search }) => ({
        url: "client/payments",
        method: "GET",
        params: { page, pageSize, sort, search },
      }),
      providesTags: ["Payments"],
    }),
    getGeography: build.query({
      query: () => "client/geography",
      providesTags: ["Geography"],
    }),
    getSales: build.query({
      query: () => "sales/sales",
      providesTags: ["Sales"],
    }),
    getAdmins: build.query({
      query: () => "management/admins",
      providesTags: ["Admins"],
    }),
    getUserPerformance: build.query({
      query: (id) => `management/performance/${id}`,
      providesTags: ["Performance"],
    }),
    getDashboard: build.query({
      query: () => "general/dashboard",
      providesTags: ["Dashboard"],
    }),
    getReviews: build.query({
      query: () => "client/reviews",
      providesTags: ["Reviews"],
    }),
    patchUserCriminalStatus: build.mutation({
      query: ({ userId, criminalStatus }) => ({
        url: `management/user/criminalstatus/${userId}`,
        method: "PATCH",
        body: { criminalStatus },
      }),
      providesTags: ["User"],
    }),
    patchUserRole: build.mutation({
      query: ({ userId, role }) => ({
        url: `management/user/role/${userId}`,
        method: "PATCH",
        body: { role },
      }),
      providesTags: ["User"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetServicesQuery,
  useGetVehiclesQuery,
  useGetReviewsQuery,
  useGetCustomersQuery,
  useGetPaymentsQuery,
  useGetGeographyQuery,
  useGetSalesQuery,
  useGetAdminsQuery,
  useGetUserPerformanceQuery,
  useGetDashboardQuery,
  usePatchUserCriminalStatusMutation,
  usePatchUserRoleMutation,
} = api;
