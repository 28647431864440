import { CloseOutlined } from '@mui/icons-material'
import { Box, Dialog, IconButton, List, Typography } from '@mui/material'
import { callback } from 'chart.js/dist/helpers/helpers.core'
import ChatParticipantCard from 'components/ChatParticipantCard/ChatParticipantCard'
import RadiusCheckbox from 'components/RadiusCheckbox'
import SearchBar from 'components/SearchBar/SearchBar'
import React from 'react'
import { RADIUS_CHECKBOX_CHAT_OPTIONS } from 'utils/const'
import { styles } from './styles'

type Props = {
  open: boolean,
  viewModel: any,
  title: string,
  isNewConversation?: boolean,
  onClose: () => void
}

const ChatDialog = ({ open, viewModel, title, onClose, isNewConversation }: Props) => {
  const handleClose = () => {
    onClose()
  }
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          padding: '20px',
          gap: '15px',
          width: '500px',
          minHeight: '150px',
          height: 'auto',
          border: '1px solid rgba(126, 126, 126, 0.60)',
          borderRadius: '8px',
          background: '#191919 !important',
          boxShadow: '0px 10px 10px 0px rgba(0, 0, 0, 0.30)',
          backdropFilter: 'blur(15px)',
        }
      }}
      open={open}
    >
      <Box style={styles.dialogContainer}>
        <Typography style={styles.dialogTitle}>{title}</Typography>
        <IconButton onClick={handleClose}>
          <CloseOutlined />
        </IconButton>
      </Box>
      {isNewConversation && <RadiusCheckbox options={RADIUS_CHECKBOX_CHAT_OPTIONS} selected={RADIUS_CHECKBOX_CHAT_OPTIONS[0].value} handleRadiusChange={() => {}}/>}
      <SearchBar width='100%' inputCallback={viewModel.handleFilterNotCustomerChatParticipant} />
      {/* PARTICIPANTS LIST */}
      <List style={styles.dialogListContainer}>
        {viewModel.filteredNotCustomerChatParticipants.map((el: any, i: number) => (
          <ChatParticipantCard
            key={i + " chat dialog list"}
            {...el}
            callback={viewModel.handleSelectParticipant}
          />
        ))}
      </List>
    </Dialog>
  )
}

export default ChatDialog;