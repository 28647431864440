import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import ChatBotRequests from "requests/chatBotRequests";



interface File {
  id: string;
  filename: string;
  
}

interface AddVectorStoreFileDialogProps {
  open: boolean;
  onClose: () => void;
  vectorStoreId: string;
  listFiles: any[];
  emitFetchData: ()=>void;
}

const AddVectorStoreFileDialog: React.FC<AddVectorStoreFileDialogProps> = ({
  open,
  onClose,
  vectorStoreId,
  listFiles,
  emitFetchData,
}) => {
  const [selectedFileId, setSelectedFileId] = useState<string>("");
  const [chatBotRequests, setChatBotRequests] = useState(new ChatBotRequests());

  const handleFileChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSelectedFileId(value);
  };

  const handleAddFile = async () => {
    try {
      await chatBotRequests.associateFileToVectorStore(vectorStoreId,selectedFileId)
      emitFetchData();
      onClose();
    } catch (err) {
      console.error("Error creating vector store:", err);
      alert("Failed Add file to Vector store. Please try again later."); 
    }
    
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>Add File to Vector Store</DialogTitle>
      <DialogContent>
        <InputLabel id="fileIdLabel">File</InputLabel>
        <Select
          labelId="fileIdLabel"
          id="fileId"
          value={selectedFileId}
          onChange={handleFileChange}
          fullWidth
          color="secondary"
        >
          {listFiles.map((file: File, index: number) => (
            <MenuItem key={file.id + index} value={file.id}>
              {file.id} / {file.filename}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="warning"
          onClick={handleAddFile}
          disabled={!selectedFileId}
        >
          Add File
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddVectorStoreFileDialog;
