import { useTheme, Box } from "@mui/material";
import Header from "components/Header";
import Map from "scenes/Map/Map";
import SpecialistStatusBar from "./specialistStatusBar";
import { useEffect, useState } from "react";
import UserRequests from "requests/userRequests";

type Marker = {
  id: string;
  latitude: number;
  longitude: number;
  type: string;
  name: string;
  isClient: boolean | "";
};

const LiveMap = () => {
  const [markers, setMarkers] = useState<Marker[]>([]);
  const [amountOnline, setAmountOnline] = useState(0);
  const [amountInactive, setAmountInactive] = useState(0);
  const [amountOnroute, setAmountOnRoute] = useState(0);
  const [amountOffline, setAmountOffline] = useState(0);
  const [amountClient, setAmountClient] = useState(0);
  const [amountTotal, setAmountTotal] = useState(0);

  const theme = useTheme();

  const columns = [
    {
      field: "_id",
      headerName: "Review ID",
      flex: 0.2,
    },
    {
      field: "user",
      headerName: "User ID",
      flex: 0.2,
    },
    {
      field: "specialist",
      headerName: "Specialist ID",
      flex: 0.2,
    },
    {
      field: "service",
      headerName: "Service ID",
      flex: 0.2,
    },
    {
      field: "serviceType",
      headerName: "Service Type",
      flex: 0.2,
    },
    {
      field: "rating",
      headerName: "Rating",
      flex: 0.2,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 0.2,
    },
  ];

  const handleMarkersChange = async (newMarkers: Marker[]) => {
    const onlineMarkers = newMarkers.filter(marker => marker.type === "online");
    const inactiveMarkers = newMarkers.filter(marker => marker.type === "inactive");
    const busyMarkers = newMarkers.filter(marker => marker.type === "busy");
    const routeMarkers = newMarkers.filter(marker => marker.type === "route");
    const clientMarkers = newMarkers.filter(marker => marker.type === "client");
  
    setAmountOnline(onlineMarkers.length);
    setAmountInactive(inactiveMarkers.length);
    setAmountOnRoute(routeMarkers.length + busyMarkers.length);
    setAmountClient(clientMarkers.length);
  
    const totalOffline =
      amountTotal - onlineMarkers.length - routeMarkers.length - clientMarkers.length;
    setAmountOffline(totalOffline);
  };
  

  useEffect(() => {
    const fetchData = async () => {
      const userRequests = new UserRequests();
      try {
        const absolute = await userRequests.getAbsolute();
        console.log(absolute)
      
        setAmountTotal(absolute);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  return (
    <Box m="1.5rem 2.5rem" style={{ paddingBottom: 20 }}>
      <Header
        title="LIVE MAP"
        subtitle="Real time roadr map for specilist location"
      />

      <Box mt="40px" mb="20px" height="75vh">
        {/* @ts-ignore */}
        <Map onMarkersChange={handleMarkersChange} />
      </Box>
      <SpecialistStatusBar
        amountOnline={amountOnline}
        amountInactive={amountInactive}
        amountOnroute={amountOnroute}
        amountOffline={amountOffline}
      />
    </Box>
  );
};

export default LiveMap;
