import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
} from "@mui/material";

import "./style.css";
import { grey } from "@mui/material/colors";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ChatBotRequests from "requests/chatBotRequests";

const chatBotRequests = new ChatBotRequests();
const styles = {
  title: {
    color: "var(--dark-white-white-900, #FFF)",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px" /* 150% */,
    letterSpacing: "-0.4px",
  },
  subtitle: {
    color: "var(--gray-gray-900, #7E7E7E)",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.12px",
  },
  picture: {
    width: 50,
    height: 50,
    borderRadius: "50%",
  },
  tabDefault: {
    width: "25%",
    display: "flex",
    justifyContent: "center",
    borderBottom: "2px solid #fff",
    paddingBottom: 8,
  },
  buttonTab: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "-0.32px",
    color: "#fff",
    margin: "0 auto",
    background: "transparent",
    textDecoration: "none",
    cursor: "pointer",
    width: "100%",
    border: "none",
  },
  value: {
    color: "var(--dark-white-white-900, #FFF)",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px" /* 187.5% */,
    letterSpacing: "-0.32px",
  },
  label: {
    color: "var(--gray-gray-900, #c0c0c0)",
    fontFamily: "Inter",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.12px",
  },
  buttomDispatch: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: "25px",
    backgroundColor: "#FF7C06",
    borderRadius: "16px",
    padding: "13px 16px",
    width: "168px",
  },
  buttomDispatchText: {
    fontSize: "15px",
    fontWeight: 600,
    letterSpacing: "-0.15px",
    color: "black",
  },
};

type Props = {
  open: boolean;
  handleClose: (event: any) => void;
  children?: React.ReactNode;
  id?: string;
};

const AddItemDialog = ({ id, open, handleClose, children }: Props) => {
  const [question, setQuestion] = useState<string>("");
  const [answer, setAnswer] = useState<string>("");

  const handleQuestionChange = (event: any) => {
    setQuestion(event.target.value);
  };

  const handleAnswerChange = (event: any) => {
    setAnswer(event.target.value);
  };

  const handleCloseDialog = (event: any) => {
    handleClose(event);
  };
  const handleSave = async (event: any) => {
    try {
      await chatBotRequests.addQuestionAndAnswer(question, answer);
      alert("Question and answer saved successfully");
      handleClose(event);
    } catch (err: any) {
      console.log(err.message);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        style: {
          background: "#191919",
          padding: "20px",
          minHeight: "320px",
          minWidth: "283px",
          borderRadius: "8px",
          border: "1px solid rgba(126, 126, 126, 0.60)",
        },
      }}
      fullWidth={true}
      maxWidth={"md"}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          objectFit: "cover",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <DialogTitle style={{ display: "flex", alignContent: "center" }}>
            <Box mr={5} display={"flex"} alignItems={"center"}>
              <Typography ml={2} style={styles.title} sx={styles.subtitle}>
                Add a new Item to model
              </Typography>
            </Box>
          </DialogTitle>
        </div>
        <DialogActions style={{ marginBottom: "20px" }}>
          <Button
            variant="text"
            onClick={handleCloseDialog}
            sx={{ color: grey[50] }}
          >
            <CloseIcon />
          </Button>
        </DialogActions>
      </div>

      <DialogContent>
        <Box mb={3}>
          <TextField
            label="Question"
            placeholder="Enter your question here..."
            multiline
            fullWidth
            value={question}
            color="secondary"
            onChange={handleQuestionChange}
          />
        </Box>
        <Box mt={3}>
          <TextField
            label="Answer"
            placeholder="Enter your answer here..."
            multiline
            fullWidth
            value={answer}
            color="secondary"
            onChange={handleAnswerChange}
          />
        </Box>
      </DialogContent>

      <DialogActions style={{ marginTop: "20px" }}>
        <Button
          onClick={handleCloseDialog}
          variant="text"
          sx={{ color: grey[50] }}
        >
          Cancel
        </Button>

        <Button
          onClick={handleSave}
          style={{ backgroundColor: "#FF8514", border: "1px solid #FF8514" }}
        >
          <span>Save</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddItemDialog;
