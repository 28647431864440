import React, { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import { Container, Box, Typography, TextField, Button } from "@mui/material";
import AdminRequests from "requests/adminRequests";
import { useNavigate, useParams } from "react-router-dom";

const Recover = () => {
  const navigate = useNavigate();
  const { seed } = useParams();
  const { id } = useParams();

  console.log("seed: " + seed);
  console.log("id: " + id);

  const [email, setEmail] = useState<string>("");

  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [allPasswordRequirementsValid, setAllPasswordRequirementsValid] =
    useState<boolean>(false);

  const [passwordValidation, setPasswordValidation] = useState<any>({
    oneDigit: false,
    oneUpperCase: false,
    oneLowerCase: false,
    oneSpecialCaracter: false,
    confirmationStricEqual: false,
    atLeast: false,
  });
  const [isFirstStep, setIsFirstStep] = useState<boolean>(true);

  useEffect(() => {
    if (seed && id) {
      setIsFirstStep(false);
    }
  }, []);

  useEffect(() => {
    const validatePassword = () => {
      const atleast = password.length >= 8;
      const hasOneDigit = /[0-9]/.test(password);
      const hasOneUpperCase = /[A-Z]/.test(password);
      const hasOneLowerCase = /[a-z]/.test(password);
      const hasOneSpecialCaracter = /[@#$%!=!*()]/.test(password);
      const isConfirmationStrictEqual = password === passwordConfirm;

      setPasswordValidation({
        oneDigit: hasOneDigit,
        oneUpperCase: hasOneUpperCase,
        oneLowerCase: hasOneLowerCase,
        oneSpecialCaracter: hasOneSpecialCaracter,
        confirmationStricEqual: isConfirmationStrictEqual,
        atleast: atleast,
      });

      if (
        hasOneDigit &&
        hasOneUpperCase &&
        hasOneLowerCase &&
        hasOneSpecialCaracter &&
        isConfirmationStrictEqual
      ) {
        setAllPasswordRequirementsValid(true);
      } else {
        setAllPasswordRequirementsValid(false);
      }
    };

    validatePassword();
    console.log(passwordValidation);
  }, [password, passwordConfirm]);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value);
  };

  const handlePasswordConfirmChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setPasswordConfirm(event.target.value);
  };

  const handleSubmit = async (
    event: MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    event.preventDefault(); // Prevents the default form submission behavior

    const adminRequests = new AdminRequests();

    if (isFirstStep) {
      try {
        await adminRequests.sendRecoverPassword(email);
        alert("E-mail for password change has been sent successfully");
        
      } catch {
        alert("Invalid e-mail address");
      }
    } else {
      try {
        if (!seed || !id) return;
        await adminRequests.changePassword(seed, id, password);
        alert("Password changed succesfully");
        navigate("/login");
      } catch {
        alert("Access Unauthorized.");
      }
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);
  return (
    <Container maxWidth="sm" style={{ marginTop: 64 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h3" mb={2}>
          Recover password
        </Typography>
        <Box
          component="form"
          style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
        >
          {isFirstStep && (
            <Box>
              <TextField
                variant="filled"
                color="secondary"
                type="email"
                label="Email"
                value={email}
                onChange={handleEmailChange}
                margin="normal"
                required
              />
            </Box>
          )}

          {!isFirstStep && (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Box p={2}>
                <ul>
                  <li
                    style={{
                      color: passwordValidation.atleast ? "#34a853" : "#ff2d2d",
                    }}
                  >
                    Password must contain at least 8 caracters
                  </li>
                  <li
                    style={{
                      color: passwordValidation.oneUpperCase
                        ? "#34a853"
                        : "#ff2d2d",
                    }}
                  >
                    Password must contain at least one uppercase letter.
                  </li>
                  <li
                    style={{
                      color: passwordValidation.oneLowerCase
                        ? "#34a853"
                        : "#ff2d2d",
                    }}
                  >
                    Password must contain at least one lowercase letter.
                  </li>
                  <li
                    style={{
                      color: passwordValidation.oneDigit
                        ? "#34a853"
                        : "#ff2d2d",
                    }}
                  >
                    Password must contain at least one digit.
                  </li>
                  <li
                    style={{
                      color: passwordValidation.oneSpecialCaracter
                        ? "#34a853"
                        : "#ff2d2d",
                    }}
                  >
                    Password must contain at least one special character: @, #, $, %, !, =, *, (, or ).
                  </li>
                  <li
                    style={{
                      color: passwordValidation.confirmationStricEqual
                        ? "#34a853"
                        : "#ff2d2d",
                    }}
                  >
                    Password must be strict equal to confirmation
                  </li>
                </ul>
              </Box>

              <TextField
                variant="filled"
                color="secondary"
                type="password"
                label="Password"
                value={password}
                onChange={handlePasswordChange}
                margin="normal"
                required
              />

              <TextField
                variant="filled"
                color="secondary"
                type="password"
                label="Password confirmation"
                value={passwordConfirm}
                onChange={handlePasswordConfirmChange}
                margin="normal"
                required
              />
            </Box>
          )}

          <Box mb={1} mt={1} style={{ display: "flex" }}>
            <small>
              <a
                href="/login"
                style={{
                  textDecoration: "none",
                  color: "white",
                  opacity: "0.7",
                }}
              >
                Go to login
              </a>
            </small>
          </Box>

          <Button
            style={{ border: "1px solid #fff", marginTop: 16 }}
            type="submit"
            variant="contained"
            color="primary"
            // disabled={false}
            onClick={handleSubmit}
          >
            {isFirstStep ? "Recover" : "Change"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Recover;
