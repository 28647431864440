import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from '@mui/material';
import React from 'react';
import { styles } from './styles';

type Props = {
  text: string;
  options: string[];
  open?: boolean,
  onClickFirstOption: () => void,
  onClickSecondOption: () => void,
}

const GenericDialog = ({ text, options, open=false, onClickFirstOption, onClickSecondOption }: Props) => {
  const handleClickFirstOption = () => {
    onClickFirstOption()
  }

  const handleClickSecondOption = () => {
    onClickSecondOption()
  }
  return (
    <Dialog open={open} sx={{
     '& .MuiDialog-paper': {
        borderRadius: '12px'
      }
    }}>
      <DialogContent style={styles.content}>
        <DialogContentText style={styles.text}>{text}</DialogContentText>
      </DialogContent>
      <DialogActions style={styles.buttons}>
        <Button onClick={handleClickFirstOption} style={styles.firstButton}>{options[0]}</Button>
        <Button onClick={handleClickSecondOption} style={styles.secondButton}>{options[1]}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default GenericDialog