
interface DialogStyles {
  dialogContainer: React.CSSProperties;
  dialogTitle: React.CSSProperties;
  dialogListContainer: React.CSSProperties;
}

export const styles: DialogStyles = {
  dialogContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
    },
    dialogTitle: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '30px',
    letterSpacing: '-0.4px'
    },
    dialogListContainer: {
      width: '100%',
      height: '225px',
      overflowY: 'auto',
      overflowX: 'hidden',
    }
}