import React from 'react';
import { styles } from './styles';


type Props = {

  options: string[];
  currentTab: string;
  callback?: (value: string) => void; 
  // handleChangeTab?: any
}

const TabOptions = ({ options, callback, currentTab /**,  handleChangeTab*/ }: Props) => {

  return (
    <div style={{ display: "flex", width: "100%" }}>
      {options.map((el, i) => {
        return (
          <div
            key={`tab-${i}`}
            style={{
              ...styles.tabDefault,
              flex: 1,
              borderBottom: `${currentTab === el
                ? "2px solid #FF7C06"
                : "2px solid #7E7E7E"
                }`,
              transition: 'border-bottom 0.3s ease'
            }}>
            <button
              onClick={callback && (() => callback(el))}
              style={{
                ...styles.buttonTab,
                color: `${currentTab === el ? "#FF7C06" : '#7E7E7E'}`,
                transition: 'border-bottom 0.3s ease'
              }}>
              {el}
            </button>
          </div>
        )
      })}
    </div>
  )
}

export default TabOptions;