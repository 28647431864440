import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import ChatBotRequests from "requests/chatBotRequests";

interface AddMaiaBrainDialogProps {
  open: boolean;
  onClose: () => void;
  emitFetchData: () => void;
  isEdit: boolean;
  item?: any;
}

const chatBotRequests = new ChatBotRequests();

interface FormData {
  system: string;
  user: string;
  assistant: string;
}

const AddMaiaBrainDialog: React.FC<AddMaiaBrainDialogProps> = ({
  open,
  onClose,
  emitFetchData,
  isEdit,
  item,
}) => {
  const [formData, setFormData] = useState<FormData>({
    system: "",
    user: "",
    assistant: "",
  });

  useEffect(() => {
    if (isEdit) {
      setFormData({
        system: item.system,
        user: item.user,
        assistant: item.assistant,
      });
    }
  }, [isEdit]);

  const handleCreate = async () => {
    try {
      await chatBotRequests.addItemMaiaBrain(formData);
      emitFetchData();
      onClose();
    } catch (err) {
      console.error("Error creating vector store:", err);
      alert("Failed to create item. Please try again later.");
    }

    // Close the dialog after creating the vector store
  };

  const handleEdit = async () => {
    try {
      await chatBotRequests.updateMaiaBrainItem(item._id, formData);
      emitFetchData();
      onClose();
    } catch (err) {
      console.error("Error deleting vector store:", err);
      alert("Failed to edit item. Please try again later.");
    }
  };

  const handleInputChange = (field: keyof FormData, value: any) => {
    setFormData({ ...formData, [field]: value });
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{isEdit ? "Edit" : "Add"} Item to Maia Brain</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="system"
          label="System message"
          fullWidth
          color="secondary"
          value={formData.system}
          onChange={(e) => handleInputChange("system", e.target.value)}
        />

        <TextField
          autoFocus
          margin="dense"
          id="user"
          label="User message"
          fullWidth
          color="secondary"
          value={formData.user}
          onChange={(e) => handleInputChange("user", e.target.value)}
        />

        <TextField
          autoFocus
          multiline
          rows={5}
          margin="dense"
          id="assistant"
          label="Assistant Message"
          variant="outlined"
          fullWidth
          color="secondary"
          value={formData.assistant}
          onChange={(e) => handleInputChange("assistant", e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="warning"
          onClick={() => {
            isEdit ? handleEdit() : handleCreate();
          }}
        >
          {isEdit ? "Edit" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMaiaBrainDialog;
