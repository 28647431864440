import { useTheme } from "@emotion/react";
import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import RequestDialogAction from "./RequestDialogAction";
// import AssistanceInquiryRequests from "requests/assistanceInquiry";
// const assistanceInquiry = new AssistanceInquiryRequests();

type Props = {
    params: any;
    customerType: string;
    companiesData: any;
};

const RequestActions = ({ params, customerType, companiesData }: Props) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const handleOpen = async () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Button
                variant="outlined"
                onClick={handleOpen}
                sx={{
                    ml: "10px",
                    textTransform: "none",
                    borderRadius: "9px",
                    // @ts-ignore
                    color: theme.palette.blue[800],
                    // @ts-ignore
                    borderColor: theme.palette.blue[800],
                    ":hover": {
                        // @ts-ignore
                        backgroundColor: theme.palette.blue[700],
                        // @ts-ignore
                        color: theme.palette.secondary[0],
                    },
                }}
            >
                Details
            </Button>
            {open && (
                <RequestDialogAction
                    open={open}
                    handleClose={handleClose}
                    companiesData={companiesData}
                    {...params.row}
                />
            )}
            {loading && (
                <CircularProgress
                    size={15}
                    sx={{ ml: "5px" }}
                    color="success"
                />
            )}
        </Box>
    );
};

export default RequestActions;
