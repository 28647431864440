// import { IP_WS_HOST } from "../../config/env";
import React, { useState } from "react";

type FlexDirection = "row" | "row-reverse" | "column" | "column-reverse";

const styles = {
  container: {
    display: "flex",
    width: "100%",
    padding: "15px 40px 15px 40px",
    justifyContent: "space-around",
    alignItems: "flex-start",
    borderRadius: "8px",
    background: "#191919",
  },
  text: {
    fontSize: "18px",
    color: "darkblue",
  },
  divStatus: {
    display: "flex",
    flexDirection: "column" as FlexDirection,
  },
  statusText: {
    fontFamily: "Inter",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: -0.16,
    marginLeft: 8,
  },
  textAmount: {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: 24,
    fontWeight: "600",
  },
  circle: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    backgroundColor: "#19CB49",
  },
};

interface SpecialistStatusBarProps {
  amountOnline: number;
  amountOnroute: number;
  amountInactive: number;
  amountOffline: number;
}

const SpecialistStatusBar: React.FC<SpecialistStatusBarProps> = ({
  amountOnline,
  amountOnroute,
  amountInactive,
  amountOffline
}) => {
  
  return (
    <div style={styles.container}>
      <div style={styles.divStatus}>
        <div style={{ display: "flex" }}>
          <div style={styles.circle}></div>
          <span style={styles.statusText}>Online</span>
        </div>
        <span style={styles.textAmount}>{amountOnline}</span>
      </div>
      <div style={styles.divStatus}>
        <div style={{ display: "flex" }}>
          <div style={{ ...styles.circle, backgroundColor: "#FF7575" }}></div>
          <span style={styles.statusText}>Inactive</span>
        </div>
        <span style={styles.textAmount}>{amountInactive}</span>
      </div>
      <div style={styles.divStatus}>
        <div style={{ display: "flex" }}>
          <div style={{ ...styles.circle, backgroundColor: "#FFB46F" }}></div>
          <span style={styles.statusText}>On route</span>
        </div>
        <span style={styles.textAmount}>{amountOnroute}</span>
      </div>

      <div style={styles.divStatus}>
        <div style={{ display: "flex" }}>
          <div style={{ ...styles.circle, backgroundColor: "#C0C0C0" }}></div>
          <span style={styles.statusText}>Offline</span>
        </div>
        <span style={styles.textAmount}>{amountOffline}</span>
      </div>
    </div>
  );
};

export default SpecialistStatusBar;
