import { AssignServiceRequestDTO } from "adapters/assignServiceDTO";
import axios from "axios";
import { getQueryString } from "utils/helper";
import { AssistanceInquiry } from "../models/assistanceInquiryResponseTypes";
import { finiteOrDefault } from "chart.js/dist/helpers/helpers.core";
import { getHostAPIBaseUrl, getServiceRequestApiUrl , getHostPythonAPIBaseUrl} from "config";
// const { IP_BACKEND_HOST, SERVICE_REQUEST_HOST } = require("../config/env");

class AssistanceInquiryRequests {
    async getRequestsPagination(page: number, pageSize: number, filter: any) {
        const token = localStorage.getItem("token");
        const url = `${getHostAPIBaseUrl()}/api/assistance-inquiry/paginated?page=${page}&pageSize=${pageSize}&filter=${filter}`;

        try {
            const response = await axios.get(url, {
                headers: {
                    "Authorization": token,
                },
            });
            return response.data;
        } catch (error) {
            console.error("error : ", error);
        }
    }

   

    async getByUser(userId: any) {
        const url = `${getHostPythonAPIBaseUrl()}/assistances/get-by-user-id?id=${userId}`;
        try {
            const token = localStorage.getItem("access_token");
            const response = await axios.get(url, {
                headers: {
                    "Authorization": token,
                },
            });
            return response.data;
        } catch (error) {
            console.error("error : ", error);
        }
    }

    async getCompanyPrice(payload: any) {
        const url = `${getHostPythonAPIBaseUrl()}/admin/assistance/quote${getQueryString(
            payload,
        )}`;
        try {
            const token = localStorage.getItem("access_token");
            const response = await axios.get(url, {
                headers: {
                    "Authorization": token,
                },
            });
            return response.data;
        } catch (error) {
            console.error("error : ", error);
        }
    }

    async assignService(payload: AssignServiceRequestDTO) {
        console.log(
            "🚀 ~ AssistanceInquiryRequests ~ assignService ~ payload:",
            payload,
        );

        const url = `${getServiceRequestApiUrl()}/admin/assistance/assign`;

        try {
            const token = localStorage.getItem("access_token");
            const response = await axios.post(url, payload, {
                headers: {
                    "Authorization": token,
                },
            });
            return response.data;
        } catch (error) {
            console.error("error : ", error);
        }
    }

    async getByChat(chatRoomId: string) {
        const url = `${getHostPythonAPIBaseUrl()}/assistances/get-by-chat?id=${chatRoomId}`;
        try {
            const token = localStorage.getItem("access_token");
            const response = await axios.get(url, {
                headers: {
                    "Authorization": token,
                },
            });
            return response.data;
        } catch (error) {
            console.error("error : ", error);
        }
    }

    async getChats(inactive: boolean) {
        console.log("get chats ", inactive);
        const url = `${getHostPythonAPIBaseUrl()}/chat/recent?completed=${inactive}`;
        try {
            const token = localStorage.getItem("access_token");
            const response = await axios.get(url, {
                headers: {
                    "Authorization": token,
                },
            });
            return response.data;
        } catch (error) {
            console.log("error : ", error);
        }
    }

    async getOnlineMembers() {
        const url = `${getServiceRequestApiUrl()}/chat/members/online`;
        try {
            const token = localStorage.getItem("access_token");
            const response = await axios.get(url, {
                headers: {
                    "Authorization": token,
                },
            });
            return response.data;
        } catch (error) {
            console.log("error : ", error);
        }
    }
}
export default AssistanceInquiryRequests;
