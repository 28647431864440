import { Avatar, Box } from "@mui/material";
import jumpIcon from "../../assets/icons/services/jumpSelected.svg";
import refuelIcon from "../../assets/icons/services/refuelSelected.svg";
import tireChangeIcon from "../../assets/icons/services/tireChangeSelected.svg";
import towingIcon from "../../assets/icons/services/towingSelected.svg";
import unlockIcon from "../../assets/icons/services/unlockSelected.svg";
import carWashIcon from "../../assets/icons/services/carWashSelected.svg";
import { useMemo } from "react";
import { formattedDate } from "utils/helper";
import { DataGrid } from "@mui/x-data-grid";

interface TableDashboradProps {
  data: any[];
  currentTab: number;
}
const TableDashborad: React.FC<TableDashboradProps> = ({
  data,
  currentTab,
}) => {
  const columnsService = useMemo(
    () => [
      {
        field: "_id",
        headerName: "Id",
        flex: 0.4,
      },
      {
        field: "companyName",
        headerName: "Company Name",
        flex: 0.4,
      },
      {
        field: "companyPhoneNumber",
        headerName: "Company Phone",
        flex: 0.4,
        valueGetter: (params: any) => params.row.companyPhoneNumber,
      },
      {
        field: "customerName",
        headerName: "Customer Name",
        flex: 0.4,
        valueGetter: (params: any) => params.row.customer?.name || "",
      },
      {
        field: "customerPhone",
        headerName: "Customer Phone",
        flex: 0.4,
        valueGetter: (params: any) => params.row.customer?.phoneNumber || "",
      },
      {
        field: "created",
        headerName: "Date",
        flex: 0.4,
        valueFormatter: (params: any) => {
          if (!params.value) {
            return "";
          }
          const date = formattedDate(params.value);
          return date;
        },
      },
      {
        field: "specialty",
        headerName: "Specialty",
        flex: 0.3,
        renderCell: (params: any) => {
          let serviceType = "None";
          if (params.value) {
            const service = params.value.name;
            serviceType =
              service === "jump"
                ? jumpIcon
                : service === "tireChange"
                ? tireChangeIcon
                : service === "refuel"
                ? refuelIcon
                : service === "unlock"
                ? unlockIcon
                : service.toLowerCase().includes("wash")
                ? carWashIcon
                : towingIcon;
          }
          return (
            <Avatar
              src={serviceType}
              sx={{ width: "50px", height: "50px", mt: "8px" }}
            />
          );
        },
      },
      {
        field: "location1",
        headerName: "Vehicle Location",
        flex: 0.6,
        valueGetter: (params: any) => {
          if (params.row.locations && params.row.locations[0]) {
            return params.row.locations[0].address;
          }
          return "";
        },
      },
      {
        field: "location2",
        headerName: "Drop Location",
        flex: 0.6,
        valueGetter: (params: any) => {
          if (params.row.locations && params.row.locations[1]) {
            return params.row.locations[1].address;
          }
          return "";
        },
      },
    ],
    []
  );

  const columnsUsers = useMemo(
    () => [
      {
        field: "profileImg",
        headerName: "Avatar",
        flex: 0.25,
        renderCell: (params: any) => (
          <Avatar
            src={params.value?.uri}
            sx={{ width: params.width, height: params.height }}
          />
        ),
        sortable: false,
        filterable: false,
      },
      {
        field: "name",
        headerName: "Name",
        flex: 0.5,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 0.6,
      },
      {
        field: "phoneNumber",
        headerName: "Phone",
        flex: 0.3,
      },
      {
        field: "custom",
        headerName: "User Type",
        flex: 0.4,
        renderCell: (params: any) => {
          const { isCompany, isClient, isSpecialist } = params.row;
          // Conditional rendering based on row data
          if (isCompany) {
            return <div>Company</div>;
          } else if (isSpecialist) {
            return <div>Specialist</div>;
          } else if (isClient) {
            return <div>Client</div>;
          } else {
            return <div>Other</div>;
          }
        },
      },
      {
        field: "custom2",
        headerName: "Plataform",
        flex: 0.3,
        renderCell: (params: any) => {
          console.log(params.row)

          const oneSignalDeviceTokens = params.row.oneSignalDeviceTokens
          console.log(oneSignalDeviceTokens)
          if (!oneSignalDeviceTokens || oneSignalDeviceTokens.length === 0) {
            return "Register By  " + params.row.registerType
          }

          const lastOne =
            oneSignalDeviceTokens[oneSignalDeviceTokens.length - 1];
          const plataform = lastOne.platform;
          return plataform;
        },
      },
    ],
    []
  );

  const columnsCompanies = useMemo(
    () => [
      {
        field: "profileImg",
        headerName: "Avatar",
        flex: 0.25,
        renderCell: (params: any) => (
          <Avatar
            src={params.value?.uri}
            sx={{ width: params.width, height: params.height }}
          />
        ),
        sortable: false,
        filterable: false,
      },
      {
        field: "name",
        headerName: "Name",
        flex: 0.5,
      },
      {
        field: "companyType",
        headerName: "Company type",
        flex: 0.4,
        renderCell: (params: any) => {
          const { _id } = params.row;
          return <div>{_id.split("#")[0].replace("_", " ")}</div>;
        },
      },

      {
        field: "customer",
        headerName: "City & State",
        flex: 0.4,
        renderCell: (params: any) => {
          const city = params.row.geometry?.city || "";
          const state = params.row.geometry?.state || "";

          return (
            <div>
              {city}
              {", "}
              {state}
            </div>
          );
        },
      },
      {
        field: "updated",
        headerName: "Updated",
        flex: 0.4,
        valueFormatter: (params: any) => {
          if (!params.value == null) {
            return "";
          }
          const date = formattedDate(params.value);
          return date;
        },
      },
    ],
    []
  );

  return (
    <Box mt="40px" minHeight={"300px"} height="auto">
      <DataGrid
        autoHeight
        rowHeight={60}
        disableExtendRowFullWidth
        getRowId={(row) => row._id}
        rows={data || []}
        columns={
          currentTab === 1
            ? columnsService
            : currentTab === 2
            ? columnsUsers
            : columnsCompanies
        }
        hideFooter
      />
    </Box>
  );
};

export default TableDashborad;
