import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Fab,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

import "./style.css";
import { grey } from "@mui/material/colors";
import React, { useEffect, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { SERVICE_TYPE_VALUES_ALL } from "utils/const";

import Dropdown from "scenes/companyMagangement/component/dropdown";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import SearchBar from "components/SearchBar/SearchBar";
import { getHostPythonAPIBaseUrl } from "config";
const styles = {
  title: {
    color: "var(--dark-white-white-900, #FFF)",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px" /* 150% */,
    letterSpacing: "-0.4px",
  },
  subtitle: {
    color: "var(--gray-gray-900, #7E7E7E)",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.12px",
  },
  picture: {
    width: 50,
    height: 50,
    borderRadius: "50%",
  },
  tabDefault: {
    width: "25%",
    display: "flex",
    justifyContent: "center",
    borderBottom: "2px solid #fff",
    paddingBottom: 8,
  },
  buttonTab: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "-0.32px",
    color: "#fff",
    margin: "0 auto",
    background: "transparent",
    textDecoration: "none",
    cursor: "pointer",
    width: "100%",
    border: "none",
  },
  value: {
    color: "var(--dark-white-white-900, #FFF)",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px" /* 187.5% */,
    letterSpacing: "-0.32px",
  },
  label: {
    color: "var(--gray-gray-900, #c0c0c0)",
    fontFamily: "Inter",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.12px",
  },
  buttomDispatch: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: "25px",
    backgroundColor: "#FF7C06",
    borderRadius: "16px",
    padding: "13px 16px",
    width: "168px",
  },
  buttomDispatchText: {
    fontSize: "15px",
    fontWeight: 600,
    letterSpacing: "-0.15px",
    color: "black",
  },
};

type Props = {
  open: boolean;
  handleClose: (event: any) => void;
  children?: React.ReactNode;
  id?: string;
  viewModel: any;
};

const PriceDialog = ({ id, open, handleClose, children, viewModel }: Props) => {
  const [currentTab, setCurrentTab] = useState("Information");
  const [currentTabLevel, setCurrentTabLevel] = useState(1);
  const [information, setInformation] = useState<any[]>([]);

  const [percentageRefunded, setPercentageRefunded] = useState<Number>(0);
  const [amountRefunded, setAmountRefunded] = useState<number>(
    viewModel.selectedPayment.charges.data[0].amount_refunded === 0
      ? 0
      : viewModel.selectedPayment.charges.data[0].amount_refunded / 100
  );

  useEffect(() => {
    setInformation([
      {
        label: "_id",
        value: viewModel.selectedPayment._id,
      },
      {
        label: "Amount",
        value: viewModel.selectedPayment.amount / 100,
      },
      {
        label: "Currency",
        value: viewModel.selectedPayment.currency,
      },
      {
        label: "Created At",
        value: viewModel.selectedPayment.createdAt,
      },
      {
        label: "Payment Method",
        value: viewModel.selectedPayment.paymentMethod,
      },
      {
        label: "Category",
        value: viewModel.selectedPayment.category,
      },
      {
        label: "Payment Intent",
        value: viewModel.selectedPayment.paymentIntent,
      },
      {
        label: "Status",
        value: viewModel.selectedPayment.status,
      },
      {
        label: "issuedBy",
        value: viewModel.selectedPayment.issuedBy,
      },
      {
        label: "Charge Id",
        value: viewModel.selectedPayment.charges.data[0].id,
      },
      {
        label: "Refunded",
        value: viewModel.selectedPayment.charges.data[0].amount_refunded / 100,
      },
    ]);
  }, [viewModel.selectedPayment]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "url",
        headerName: "link",
        flex: 1,
        renderCell: (params) => (
          <a style={{ color: "#1279F2" }} href="https://roadr.com">
            https://roadr.com
          </a>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1.8,
        renderCell: (params: any) => (
          <Button
            style={{
              backgroundColor: "#34A853",
              color: "white",
              borderRadius: 15,
            }}
          >
            Active
          </Button>
        ),
      },
      {
        field: "specialty",
        headerName: "Service",
        flex: 1,
        renderCell: (params) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            <span>Towing</span>
          </Box>
        ),
      },

      {
        field: "date",
        headerName: "Date",
        flex: 1,
        renderCell: (params: any) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            <span>{params.value}</span>
          </Box>
        ),
      },
      {
        field: "action",
        headerName: "Actions",
        flex: 0.4,
        renderCell: (params: any) => {
          return (
            <Fab style={{ backgroundColor: "transparent" }} size="small">
              <MoreVertIcon style={{ fontSize: "1.4rem" }} color="secondary" />
            </Fab>
          );
        },
      },
    ],
    []
  );

  const rows = [
    {
      id: 1,
      date: "12/15/2024 10:00",
      note: "Field is nested within an object, so you need to access it correctly in the code.",
      adminName: "Adriel",
    },
  ];

  const handleTabChange = (tab: string) => {
    setCurrentTab(tab);
  };

  const handleCloseDialog = (event: any) => {
    handleClose(event);
  };

  const onClickDeeperLevel = () => {
    setCurrentTabLevel(currentTabLevel + 1);
  };
  const onClickHigherLevel = () => {
    setCurrentTabLevel(currentTabLevel - 1);
  };
  const handlePercentageRefundDropdownChange = (event: any) => {
    const percentage = event.target.value;
    setPercentageRefunded(percentage);

    const totalRefund =
      ((viewModel.selectedPayment.amount / 100) * percentage) / 100;
    const totalFloatAmount = parseFloat(totalRefund.toFixed(2))
    setAmountRefunded(totalFloatAmount);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        style: {
          background: "#191919",
          padding: "20px",
          minHeight: "618px",
          minWidth: "483px",
          borderRadius: "8px",
          border: "1px solid rgba(126, 126, 126, 0.60)",
        },
      }}
      fullWidth={true}
      maxWidth={"md"}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          objectFit: "cover",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <DialogTitle style={{ display: "flex", alignContent: "center" }}>
            <Box mr={5} display={"flex"} alignItems={"center"}>
              {currentTabLevel > 1 && (
                <ArrowBackIcon onClick={onClickHigherLevel}></ArrowBackIcon>
              )}

              <Typography ml={2} style={styles.title} sx={styles.subtitle}>
                Payment datails
              </Typography>
            </Box>
          </DialogTitle>
        </div>
        <DialogActions style={{ marginBottom: "20px" }}>
          <Button
            variant="text"
            onClick={handleCloseDialog}
            sx={{ color: grey[50] }}
          >
            <CloseIcon />
          </Button>
        </DialogActions>
      </div>

      <DialogContent>
        {/* {currentTabLevel === 1 && (
          <Box display={"flex"} mb={2}>
            <div
              style={{
                width: "30%",
                marginTop: 7,
                borderTop: "2px solid #3E3E3E",
              }}
            ></div>

            <Box display={"flex"} flexDirection={"column"}>
              <div
                style={{
                  borderRadius: "50%",
                  width: "16px",
                  height: "16px",
                  background: "#3E3E3E",
                  border: "2px solid #7E7E7E",
                }}
              ></div>
            </Box>

            <div
              style={{
                width: "15%",
                marginTop: 7,
                borderTop: "2px solid #3E3E3E",
              }}
            ></div>

            <Box display={"flex"} flexDirection={"column"}>
              <div
                style={{
                  borderRadius: "50%",
                  width: "16px",
                  height: "16px",
                  background: "#3E3E3E",
                  border: "2px solid #7E7E7E",
                }}
              ></div>
            </Box>
            <div
              style={{
                width: "15%",
                marginTop: 7,
                borderTop: "2px solid #3E3E3E",
              }}
            ></div>
            <Box display={"flex"} flexDirection={"column"}>
              <div
                style={{
                  borderRadius: "50%",
                  width: "16px",
                  height: "16px",
                  background: "#3E3E3E",
                  border: "2px solid #7E7E7E",
                }}
              ></div>
            </Box>
            <div
              style={{
                width: "30%",
                marginTop: 7,
                borderTop: "2px solid #3E3E3E",
              }}
            ></div>
          </Box>
        )} */}
        {/* {currentTabLevel === 1 && (
          <Box display={"flex"} mb={6}>
            <div
              style={{
                width: "30%",
                marginTop: 7,
              }}
            ></div>

            <Typography ml={4}>Awaiting</Typography>
            <div
              style={{
                width: "15%",
                marginTop: 7,
              }}
            ></div>

            <Typography mr={4}>Processing</Typography>
            <div
              style={{
                width: "5%",
                marginTop: 7,
              }}
            ></div>
            <Typography mr={8}>Failed</Typography>
            <div
              style={{
                width: "30%",
                marginTop: 7,
              }}
            ></div>
          </Box>
        )} */}

        {currentTabLevel === 1 && (
          <div style={{ display: "flex", width: "100%" }}>
            <div
              style={{
                ...styles.tabDefault,
                flex: 1,
                borderBottom: `${
                  currentTab === "Information"
                    ? "2px solid #FF7C06"
                    : "2px solid #fff"
                }`,
              }}
            >
              <button
                onClick={() => handleTabChange("Information")}
                style={{
                  ...styles.buttonTab,
                  color: `${currentTab === "Information" ? "#FF7C06" : "#fff"}`,
                }}
              >
                Information
              </button>
            </div>
            <div
              style={{
                ...styles.tabDefault,
                flex: 1,
                borderBottom: `${
                  currentTab === "Refund"
                    ? "2px solid #FF7C06"
                    : "2px solid #fff"
                }`,
              }}
            >
              <button
                onClick={() => handleTabChange("Refund")}
                style={{
                  ...styles.buttonTab,
                  color: `${currentTab === "Refund" ? "#FF7C06" : "#fff"}`,
                }}
              >
                Refund
              </button>
            </div>

            {/* <div
              style={{
                ...styles.tabDefault,
                flex: 1,
                borderBottom: `${
                  currentTab === "Payment link"
                    ? "2px solid #FF7C06"
                    : "2px solid #fff"
                }`,
              }}
            >
              <button
                onClick={() => handleTabChange("Payment link")}
                style={{
                  ...styles.buttonTab,
                  color: `${
                    currentTab === "Payment link" ? "#FF7C06" : "#fff"
                  }`,
                }}
              >
                Payment link
              </button>
            </div> */}
          </div>
        )}

        {currentTab === "Information" && currentTabLevel === 1 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            mt={3}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
              }}
              mt={3}
            >
              <Box
                noValidate
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // m: "auto",
                  width: "fit-content",
                }}
              >
                {information.slice(0, 4).map(({ label, value }, index) => (
                  <div
                    key={index + "information"}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 16,
                    }}
                  >
                    <Typography style={styles.label}>{label}</Typography>
                    <Typography style={styles.value}>{value}</Typography>
                  </div>
                ))}
              </Box>

              <Box
                noValidate
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // m: "auto",
                  width: "fit-content",
                }}
              >
                {information.slice(4, 8).map(({ label, value }, index) => (
                  <div
                    key={index + "information2"}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 16,
                    }}
                  >
                    <Typography style={styles.label}>{label}</Typography>
                    <Typography style={styles.value}>{value}</Typography>
                  </div>
                ))}
              </Box>

              <Box
                noValidate
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // m: "auto",
                  width: "fit-content",
                }}
              >
                {information.slice(8, 12).map(({ label, value }, index) => (
                  <div
                    key={index + "information3"}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 16,
                    }}
                  >
                    <Typography style={styles.label}>{label}</Typography>
                    <Typography style={styles.value}>{value}</Typography>
                  </div>
                ))}
              </Box>
            </Box>
          </Box>
        )}

        {currentTab === "Information" && currentTabLevel === 2 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            mt={3}
          >
            <Box
              display={"inline-flex"} // Use camelCase for property names
              flexDirection={"column"}
              width={"100%"}
              padding={"11px 15px 11px 15px"} // Adjusted the padding values
              alignItems={"flex-start"}
              borderRadius={4}
              height={"189px"}
              style={{ backgroundColor: "#252424" }}
            >
              <Typography>Payment Method</Typography>
              <Box
                style={{
                  backgroundColor: "#000",
                  width: "305px",
                  height: "69px",
                }}
                mt={5}
                pt={1}
                pl={1}
                borderRadius={4}
              >
                <Typography
                  style={{
                    overflow: "hidden",
                    color: "var(--Dark-White-White-900, #FFF)",
                    fontFeatureSettings: "'calt' off",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "30px",
                    letterSpacing: "-0.28px",
                  }}
                >
                  Mastercard ... 1232
                </Typography>

                <Typography
                  style={{
                    color: "var(--Gray-Gray-800, #C0C0C0)",
                    fontFeatureSettings: "'calt' off",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    letterSpacing: "-0.12px",
                  }}
                >
                  Expiry 06/24
                </Typography>
              </Box>

              <Box
                onClick={onClickDeeperLevel}
                display={"flex"}
                alignItems={"center"}
                mt={2}
              >
                <AddCircleOutlineOutlined sx={{ color: "#FF7C06" }} />
                &nbsp;
                <span style={{ color: "#FF7C06" }}>Add a payment method</span>
              </Box>
            </Box>
            <Box
              mt={3}
              width={"100%"}
              height={249}
              display={"flex"}
              justifyContent={"end"}
              alignItems={"end"}
            >
              <Button
                style={{
                  backgroundColor: "#fff",
                  width: "185px",
                  height: "40px",
                  padding: "13px 16px ",
                }}
              >
                Pay
              </Button>
            </Box>
          </Box>
        )}

        {currentTab === "Information" && currentTabLevel === 3 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            mt={3}
          >
            <Box
              style={{
                backgroundColor: "#252424",
                width: "100%",
                height: "auto",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <TextField
                label="Cardholder Name"
                variant="outlined"
                margin="normal"
                fullWidth
                color="secondary"
              />

              <TextField
                label="Card Number"
                variant="outlined"
                margin="normal"
                fullWidth
                color="secondary"
              />

              <TextField
                label="Expiration Date"
                variant="outlined"
                margin="normal"
                fullWidth
                color="secondary"
              />

              <TextField
                label="Security Code"
                variant="outlined"
                margin="normal"
                fullWidth
                color="secondary"
              />
            </Box>

            <Box
              mt={3}
              width="100%"
              height={120}
              display="flex"
              justifyContent="end"
              alignItems="end"
            >
              <Button
                style={{
                  backgroundColor: "#FF7C06",
                  width: "185px",
                  height: "40px",
                  padding: "13px 16px",
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        )}

        {currentTab === "Refund" && currentTabLevel === 1 && (
          <Box display="flex" flexDirection="column" gap={"2rem"}>
            <FormControl style={{ width: 140, marginTop: 45 }}>
              <Typography mb={2} style={styles.label}>
                Percentage
              </Typography>
              <Select
                labelId="select-label"
                id="select"
                variant="filled"
                color="secondary"
                onChange={handlePercentageRefundDropdownChange}
                disabled={
                  viewModel.selectedPayment.charges.data[0].amount_refunded > 0
                }
              >
                {[
                  100, 95, 90, 85, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30,
                  25, 20, 15, 10, 5, 0,
                ].map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}%
                  </MenuItem>
                ))}
              </Select>

              <Typography mb={2} mt={5} style={styles.label}>
                Total Refunded
              </Typography>
              <Typography style={styles.value}>
                $ {amountRefunded.toString()}
              </Typography>
            </FormControl>
            {}
            <Box mt={3} width={"100%"} display={"flex"} justifyContent={"end"}>
              {viewModel.selectedPayment.charges.data[0].amount_refunded ===
                0 &&
                typeof amountRefunded === "number" && (
                  <Button
                    style={{
                      backgroundColor: "#FF7C06",
                      width: "185px",
                      height: "40px",
                      padding: "13px 16px ",
                    }}
                    onClick={async () => {
                      const token = localStorage.getItem("access_token");
                      const url = `${getHostPythonAPIBaseUrl()}/stripe/refund`;
                      const newAmount = amountRefunded * 100;

                      try {
                        await axios.post(
                          url,
                          {
                            amount: newAmount,
                            charge:
                              viewModel.selectedPayment.charges.data[0].id,
                            payment_intent:
                              viewModel.selectedPayment.paymentIntent,
                            reason: "requested_by_customer",
                          },
                          {
                            headers: {
                              Authorization: token,
                            },
                          }
                        );
                        const newPaymentSelected = {
                          ...viewModel.selectedPayment,
                        };
                        newPaymentSelected.charges.data[0].amount_refunded =
                          amountRefunded;
                        viewModel.setSelectedPayment(newPaymentSelected);
                        alert("Refund successfully made")
                      } catch (error) {
                        console.error("error : ", error);
                      }
                    }}
                  >
                    Pay
                  </Button>
                )}
            </Box>
          </Box>
        )}

        {currentTab === "Payment link" && currentTabLevel === 1 && (
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              // m: "auto",
              width: "fit-content",
            }}
          >
            <Box
              mt={2}
              width={"805px"}
              display={"flex"}
              justifyContent={"start"}
            >
              <Dropdown
                options={["filter1"]}
                onChange={(value: any) => console.log(value)}
                value={"Filter"}
                icon="filter"
              />
              <Box style={{ marginTop: "13px" }} ml={2}>
                <SearchBar
                  width="556px"
                  inputCallback={(e: any) => console.log(e)}
                  buttonCallback={() => console.log("cb")}
                  onFocus={() => {
                    console.log("focus");
                  }}
                  onBlur={async () => {
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                    console.log("blur");
                  }}
                  placeholder={"Search ..."}
                />
              </Box>
            </Box>

            <Box mt={4}>
              <DataGrid
                hideFooter
                rows={rows}
                columns={columns}
                pageSize={rows.length}
                autoHeight
              />
            </Box>

            <Box mt={3} width={"100%"} display={"flex"} justifyContent={"end"}>
              <Button
                onClick={onClickDeeperLevel}
                style={{
                  backgroundColor: "#FF7C06",
                  width: "185px",
                  height: "40px",
                  padding: "13px 16px ",
                }}
              >
                Create Link
              </Button>
            </Box>
          </Box>
        )}

        {currentTab === "Payment link" && currentTabLevel === 2 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            mt={3}
          >
            <Box
              style={{
                backgroundColor: "#252424",
                width: "100%",
                height: "auto",
                padding: "16px",
                borderRadius: "8px",
              }}
            >
              <Dropdown
                options={SERVICE_TYPE_VALUES_ALL.slice(
                  1,
                  SERVICE_TYPE_VALUES_ALL.length - 1
                )}
                onChange={(value: any) => console.log(value)}
                value={""}
                icon="service"
                placeholder="Select a service"
              />

              <TextField
                label="Description (optional)"
                variant="outlined"
                margin="normal"
                fullWidth
                color="secondary"
              />
              <Box display={"flex"}>
                <TextField
                  label="Pickup location"
                  variant="outlined"
                  margin="normal"
                  style={{ width: "50%" }}
                  color="secondary"
                />
                &nbsp;&nbsp;&nbsp;
                <TextField
                  label="Drop off location"
                  variant="outlined"
                  margin="normal"
                  style={{ width: "50%" }}
                  color="secondary"
                />
              </Box>

              <Box display={"flex"}>
                <TextField
                  label="Mileage rate"
                  variant="outlined"
                  margin="normal"
                  style={{ width: "50%" }}
                  color="secondary"
                />
                &nbsp;&nbsp;&nbsp;
                <TextField
                  label="Price"
                  variant="outlined"
                  value={"$ 60"}
                  margin="normal"
                  style={{ width: "50%" }}
                  color="secondary"
                />
              </Box>
            </Box>
            <Box
              mt={3}
              width="100%"
              height={120}
              display="flex"
              justifyContent="end"
              alignItems="end"
            >
              <Button
                style={{
                  backgroundColor: "#FF7C06",
                  width: "185px",
                  height: "40px",
                  padding: "13px 16px",
                }}
              >
                Create
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PriceDialog;
