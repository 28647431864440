import React, { useState } from 'react';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import { Box } from '@mui/material';
import DetailsDialog from './DetailsDialog';

type Props = {
  params: any;
}

const Details = ({ params }: Props) => {

  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }
  // const handleClick2 = () => {
  //   alert('tool');
  // }

  return (
    <Box justifyContent={'space-between'} gap={10}>
      <FormatAlignLeftIcon onClick={handleClick} style={{ fontSize: 24 }} />
      {open &&
        <DetailsDialog
        open={open}
        handleClose={handleClose}
        params={params}
        />}
      <BuildCircleOutlinedIcon style={{ fontSize: 24 }}/>
    </Box>
  )
}

export default Details