import React, { useMemo, useCallback } from "react";
import { IconButton, Box , Button, Typography} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {  formattedDateEpoch } from "utils/helper";

const TableVectorStore = ({
  vectorStoreList,
  handleOpenDeleteVectoreStore,
  handleSelectVectorStore,
}: {
  vectorStoreList: any[];
  handleOpenDeleteVectoreStore: (id: string) => void;
  handleSelectVectorStore: (id: string) => void;
}) => {
  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", flex: 0.2 },
      { field: "name", headerName: "Name", flex: 0.2 },
      {
        field: "created_at",
        headerName: "Created At",
        flex: 0.2,
        valueFormatter: (params: any) => {
          if (!params.value == null) {
            return "";
          }
          const date = formattedDateEpoch(params.value);
          return date;
        },
      },
      {
        field: "usage_bytes",
        headerName: "Size (MB)",
        flex: 0.1,
        valueGetter: (params: any) =>
          (params.row.usage_bytes / (1024 * 1024)).toFixed(2),
      },
      {
        field: "file_counts",
        headerName: "File Counts",
        flex: 0.1,
        valueGetter: (params: any) => params.row.file_counts.total,
      },
      {
        field: "files",
        headerName: "Files",
        flex: 0.1,
        renderCell: (params: any) => (
          <Button
            variant="outlined"
            color="info"
            aria-label="files"
            onClick={() => handleSelectVectorStore(params.row.id)}
            style={{textTransform: 'none'}}
          >
            <Typography  mr={1}>View Files</Typography>
            <UploadFileIcon color="info" />
          </Button>
        ),
      },
      {
        field: "delete",
        headerName: "Delete",
        flex: 0.1,
        renderCell: (params: any) => (
          <IconButton
            aria-label="delete"
            onClick={() => handleOpenDeleteVectoreStore(params.row.id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
        ),
      },
    ],
    []
  );



  return (
    <Box mt="40px" minWidth={"100%"} height="auto">
      <DataGrid
        autoHeight
        rowHeight={60}
        disableExtendRowFullWidth={false}
        loading={vectorStoreList.length === 0}
        getRowId={(row) => JSON.stringify(row)}
        rows={vectorStoreList || []}
        columns={columns}
        hideFooter
        pageSize={99}
      />
    </Box>
  );
};

export default TableVectorStore;
