import React, { useState, FC } from "react";
import { Box, Button, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import axios from "axios";
import { getHostPythonAPIBaseUrl } from "config";

// Define interfaces for type safety
interface OpeningHours {
  open: string;
  close: string;
}

interface DayOpeningHours {
  [key: string]: OpeningHours[];
}

interface DetailFormData {
  currentOpeningHours: DayOpeningHours;
}

interface FormData {
  detail: DetailFormData;
  placeId: string; // Replace with actual type
}

interface ScheduleButtonProps {
  setFormData: (value: FormData) => void;
  formData: FormData;
  cb: () => void;
}

const generateTimeOptions = () => {
  const options = [];
  const periods = ["AM", "PM"];

  for (let hours = 0; hours <= 23; hours++) {
    const period = hours < 12 ? periods[0] : periods[1];
    const hour12 = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;

    for (let minutes = 0; minutes < 60; minutes += 30) {
      const hourString = hour12.toString().padStart(2, "0");
      const minuteString = minutes.toString().padStart(2, "0");
      options.push(`${hourString}:${minuteString} ${period}`);
    }
  }

  return options;
};

const allWeekdays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const ScheduleButton: FC<ScheduleButtonProps> = ({
  formData,
  cb,
  setFormData,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const timeOptions = generateTimeOptions();

  const handleSave = async () => {
    const url = `${getHostPythonAPIBaseUrl()}/company/aggregate/update/property`;
    const token = localStorage.getItem("access_token");
    const detail = { ...formData.detail } as any;

    try {
      const response = await axios.put(
        url,
        {
          data: {
            _id: detail._id,
            currentOpeningHours: detail.currentOpeningHours,
          },
          property: "detail",
          placeId: formData.placeId,
        },
        {
          headers: {
            Authorization: localStorage.getItem("access_token"),
          },
        }
      );

      // Assuming response handling logic goes here if needed

      alert("Saved successfully");
      handleToggle(); // Assuming this function handles UI state after successful save
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Failed to save data. Please try again later.");
    }
  };

  const handleCloseHours = (day: string) => {
    const updatedHours = {
      ...formData.detail.currentOpeningHours,
      [day]: [{ open: "closed", close: "closed" }],
    };

    setFormData({
      ...formData,
      detail: {
        ...formData.detail,
        currentOpeningHours: updatedHours,
      },
    });
  };

  const handleRepeatForAll = (day: string) => {
    const currentHours = formData.detail.currentOpeningHours[day][0];
    const updatedHours = allWeekdays.reduce((acc, d) => {
      acc[d] = [currentHours];
      return acc;
    }, {} as DayOpeningHours);

    setFormData({
      ...formData,
      detail: {
        ...formData.detail,
        currentOpeningHours: updatedHours,
      },
    });
  };

  return (
    <Box
      style={{
        marginTop: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <Box style={{ marginRight: "8px" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            borderRadius: 8,
          }}
        >
          <AccessTimeIcon />
          <Typography
            variant="body1"
            style={{ marginLeft: "6px", marginRight: "6px" }}
          >
            Opening / Closing hours
          </Typography>
          {!expanded ? (
            <KeyboardArrowDownOutlinedIcon onClick={handleToggle} />
          ) : (
            <KeyboardArrowUpOutlinedIcon onClick={handleToggle} />
          )}
        </Box>
        {expanded && (
          <Box mt={2} display="flex" flexDirection="column">
            {allWeekdays.map((day, index) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: `${
                    index < allWeekdays.length - 1 ? "24px" : "8px"
                  }`,
                }}
                key={day}
              >
                <Box sx={{ display: "flex", alignItems: "center" }} mb={1}>
                  <Typography variant="body2" mr={3}>
                    <b>{day}: </b>
                  </Typography>

                  <small>from&nbsp;&nbsp;</small>
                  <input
                    value={formData.detail.currentOpeningHours[day][0].open}
                    style={{
                      marginRight: "10px",
                      height: "22px",
                      width: "107px",
                      padding: "0px 5px",
                      backgroundColor: "transparent",
                      border: "1px solid rgba(255,255,255,0.33)",
                      borderRadius: "6px",
                    }}
                    onChange={(event: any) => {
                      const value = event.target.value;

                      const currentCompanyHour = {
                        ...formData.detail.currentOpeningHours,
                      };

                      currentCompanyHour[day][0].open = value;

                      setFormData({
                        ...formData,
                        detail: {
                          ...formData.detail,
                          currentOpeningHours: currentCompanyHour,
                        },
                      });
                    }}
                  ></input>
                  <small>to&nbsp;&nbsp;</small>
                  <input
                    value={formData.detail.currentOpeningHours[day][0].close}
                    onChange={(event: any) => {
                      const value = event.target.value;

                      const currentCompanyHour = {
                        ...formData.detail.currentOpeningHours,
                      };

                      currentCompanyHour[day][0].close = value;

                      setFormData({
                        ...formData,
                        detail: {
                          ...formData.detail,
                          currentOpeningHours: currentCompanyHour,
                        },
                      });
                    }}
                    style={{
                      marginRight: "10px",
                      height: "22px",
                      width: "107px",
                      padding: "0px 5px",
                      backgroundColor: "transparent",
                      border: "1px solid rgba(255,255,255,0.33)",
                      borderRadius: "6px",
                    }}
                  ></input>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"start"}
                  alignItems={"start"}
                  mr={4}
                >
                  <Box mr={1} ml={1} mt={1}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        const currentCompanyHour = {
                          ...formData.detail.currentOpeningHours,
                        };

                        currentCompanyHour[day][0].open = "Open 24 hours";
                        currentCompanyHour[day][0].close = "closed";
                        setFormData({
                          ...formData,
                          detail: {
                            ...formData.detail,
                            currentOpeningHours: currentCompanyHour,
                          },
                        });
                      }}
                    >
                      24 hours
                    </Button>
                  </Box>
                  <Box mr={1} ml={1} mt={1}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      size="small"
                      onClick={() => handleCloseHours(day)}
                    >
                      Closed
                    </Button>
                  </Box>
                  <Box mr={1} ml={1} mt={1}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      size="small"
                      onClick={() => handleRepeatForAll(day)}
                    >
                      Repeat for all
                    </Button>
                  </Box>
                </Box>
                <div
                  style={{
                    backgroundColor: "rgba(255,255,255,0.22)",
                    marginTop: "12px",
                    width: "100%",
                    height: "1px",
                  }}
                ></div>
              </Box>
            ))}
            <Button onClick={handleSave} color="secondary" variant="contained">
              Save hours
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ScheduleButton;
