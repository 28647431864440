import { Avatar, Box, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { StyledBadge } from 'components/StyledBadge';
import React from 'react'
import { DEFAULT_PROFILE_IMG } from 'utils/const';
import { styles } from './styles';

type Props = {
  id: string,
  profileImg: string,
  isOnline: boolean,
  name: string,
  email: string,
  callback: (value: string) => void
}

const ChatParticipantCard = ({ id, profileImg, isOnline, name, email, callback }: Props) => {
  
  const handleListItemClick = () => {
    callback(id)
  }
  return (
    <ListItem key={email + 'ListItem key='} style={styles.container}>
      <ListItemButton onClick={handleListItemClick}>
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
          isOnline={isOnline}
        >
          <Avatar alt='avatar' src={profileImg.length ? profileImg : DEFAULT_PROFILE_IMG} />
        </StyledBadge>
        <Box style={styles.textContainer}>
          <ListItemText primary={name} />
          <ListItemText primary={email} />
        </Box>
      </ListItemButton>
    </ListItem>
  )
}

export default ChatParticipantCard;