import React, {
  useState,
  useEffect,
  MouseEvent,
  ChangeEvent,
  FormEventHandler,
} from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Container,
  MenuItem,
  Checkbox,
  Box,
  Grid,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { Select, InputLabel, FormControl } from "@mui/material";
import CompanyRequests from "requests/companyRequests";
import axios from "axios";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { getHostAPIBaseUrl , getHostPythonAPIBaseUrl} from "config";
// const { IP_BACKEND_HOST } = require("../../config/env");

type TowState = {
  hookupFee: number;
  milesRate: number;
};

const Connect = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const token = localStorage.getItem("access_token");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countries, setCountries] = useState([]);
  const [maxMiles, setMaxMiles] = useState<number | null>(0);
  const [selectedCountry, setSelectedCountry] = useState("United States");

  const [towTypes, setTowTypes] = useState<any | null>([
    { name: "Standard Tow", state: { hookupFee: 0, milesRate: 0 } },
    { name: "Flatbed Tow", state: { hookupFee: 0, milesRate: 0 } },
  ]);
  const [heavyDutyTowTypes, setHeavyDutyTowTypes] = useState<any | null>([
    { name: "box truck", state: { hookupFee: 0, milesRate: 0 } },
    { name: "sprinter van", state: { hookupFee: 0, milesRate: 0 } },
    { name: "semi truck", state: { hookupFee: 0, milesRate: 0 } },
    { name: "18 wheeler", state: { hookupFee: 0, milesRate: 0 } },
  ]);
  const [step, setStep] = useState<number | null>(1);
  const [placeId, setPlaceId] = useState<string | null>("");
  const [refuel, setRefuel] = useState<number | null>(0);
  const [jump, setJump] = useState<number | null>(0);
  const [unlock, setUnlock] = useState<number | null>(0);
  const [tireChange, setTireChange] = useState<number | null>(0);
  const [tireRotation, setTireRotation] = useState<number | null>(0);
  const [oilChange, setOilChange] = useState<number | null>(0);
  const [fuelFilter, setFuelFilter] = useState<number | null>(0);
  const [airConditionerFilter, setAirConditionerFilter] = useState<
    number | null
  >(0);

  
  const [wheelAlignment, setwheelAlignment] = useState<number | null>(0);
  const [brakeRepair, setBrakeRepair] = useState<number | null>(0);
  const [engineRepair, setEngineRepair] = useState<number | null>(0);
  const [carInspection, setCarInspection] = useState<number | null>(0);
  const [carWash, setCarWash] = useState<number | null>(0);
  const [accepted, setAccepted] = useState<boolean>(true);
  const [showAll, setShowAll] = useState(false);
  const [key, setKey] = useState<string | null>("");
  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setCompanyName(event.target.value);
  };

  useEffect(() => {
    handleGetPlaceById();
  }, []);

  const handleGetPlaceById = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const currentPlaceId = urlSearchParams.get("placeId");
    setPlaceId(currentPlaceId);

    axios
      .get(
        `${getHostPythonAPIBaseUrl()}/aspira/companies/get-by-place-id?id=${currentPlaceId}`
      )
      .then((response) => {
        const data = response.data;
        console.log("data ---------->",JSON.stringify(data));
        setEmail(data["email"]);
        setPhoneNumber(data["details"]["formatted_phone_number"]);
        setCompanyName(data["name"]);
        setMaxMiles(data["maxMiles"]);
        setKey(data["query"].replace(/ /g, "_"));
        //Services
        const price = data['prices'];
        //Towing

        setTowTypes([
          {
            name: "Standard Tow",
            state: {
              hookupFee: price.standardTow.hookupFee,
              milesRate: price.standardTow.milesRate,
            },
          },
          {
            name: "Flatbed Tow",
            state: {
              hookupFee: price.flatbedTow.hookupFee,
              milesRate: price.flatbedTow.milesRate,
            },
          },
        ]);
        setHeavyDutyTowTypes([
          {
            name: "Box Truck",
            state: {
              hookupFee: price.heavyDuty.boxTruck.hookupFee,
              milesRate: price.heavyDuty.boxTruck.milesRate,
            },
          },
          {
            name: " Sprinter Van",
            state: {
              hookupFee: price.heavyDuty.sprinterVan.hookupFee,
              milesRate: price.heavyDuty.sprinterVan.milesRate,
            },
          },
          {
            name: "Semi Truck",
            state: {
              hookupFee: price.heavyDuty.semiTruck.hookupFee,
              milesRate: price.heavyDuty.semiTruck.milesRate,
            },
          },
          {
            name: "18 Wheeler",
            state: {
              hookupFee: price.heavyDuty.wheeler18.hookupFee,
              milesRate: price.heavyDuty.wheeler18.milesRate,
            },
          },
        ]);

        //Other
        setUnlock(price.unlock);
        setRefuel(price.refuel);
        setJump(price.jump);
        setTireChange(price.tireChange);
     
        setCarWash(price.carWash);
        setOilChange(price.oilChange);
        setTireRotation(price.tireRotation);
        setwheelAlignment(price.wheelAlignment);
        setEngineRepair(price.engineRepair);
        setBrakeRepair(price.brakeRepair);
        setCarInspection(price.carInspection);
        setAirConditionerFilter(price.airConditionerFilter);
        setFuelFilter(price.fuelFilter);
      })
      .catch((err) => {
        //window.location.href = "https://roadr.com";
        console.log(err);
      });
  };

  const handleNextStep = () => {
    if (step === 1) {
      setStep(step + 1);
    } else if (step === 2) {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    let requestData = {
      _id: (`${key}#${placeId}_pricing`).replace('" "', '"_"'),
      email: email,
      phoneNumber: phoneNumber,
      maxMiles: maxMiles,
      price: {
        standardTow: {
          hookupFee: towTypes[0].state.hookupFee,
          milesRate: towTypes[0].state.milesRate,
        },
        flatbedTow: {
          hookupFee: towTypes[1].state.hookupFee,
          milesRate: towTypes[1].state.milesRate,
        },

        heavyDuty: {
          boxTruck: {
            hookupFee: heavyDutyTowTypes[0].state.hookupFee,
            milesRate: heavyDutyTowTypes[0].state.milesRate,
          },
          sprinterVan: {
            hookupFee: heavyDutyTowTypes[1].state.hookupFee,
            milesRate: heavyDutyTowTypes[1].state.milesRate,
          },
          semiTruck: {
            hookupFee: heavyDutyTowTypes[2].state.hookupFee,
            milesRate: heavyDutyTowTypes[2].state.milesRate,
          },
          wheeler18: {
            hookupFee: heavyDutyTowTypes[3].state.hookupFee,
            milesRate: heavyDutyTowTypes[3].state.milesRate,
          },
        },
        refuel: refuel,
        tireChange: tireChange,
        unlock: unlock,
        jump: jump,
     
        oilChange: oilChange,
        carWash: carWash,
        carInspection: carInspection,
        wheelAlignment: wheelAlignment,
        tireRotation: tireRotation,
        engineRepair: engineRepair,
        fuelFilter: fuelFilter,
        airConditionerFilter: airConditionerFilter,
        brakeRepair:brakeRepair,
      },
      placeId: placeId,
      key: key,
    };

    try {
   
      const companyRequests = new CompanyRequests()

      companyRequests.saveAspiraCompany(requestData).then(()=>{
        alert("Prices saved successfully")
      })
     
    } catch (err) {
      console.error(err);
      alert("Error saving prices");
    }
  };

  const handleChangeShowAll = () => {
    setShowAll(!showAll);
  };
  const handleTowFieldChange = (
    event: ChangeEvent<HTMLInputElement>,
    towType: string,
    fieldName: string
  ) => {
    const { value } = event.target;
    const updatedTowTypes = towTypes.map((type: any) => {
      if (type.name === towType) {
        // Update the specific field for the matching tow type
        return {
          ...type,
          state: {
            ...type.state,
            [fieldName]: Number(value),
          },
        };
      }
      return type;
    });

    setTowTypes(updatedTowTypes);
  };

  return (
    <Container maxWidth="md" style={{ marginTop: 64, paddingBottom: 22 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "auto",
        }}
      >
        <a href="https://roadr.com/">
          <img
            style={{ maxWidth: 184 }}
            src="https://dim.mcusercontent.com/cs/3e2980a509f2f7889fe5f74c0/images/ad464767-8d39-7c15-a73c-3b4146c196e4.png?rect=0%2C25%2C1500%2C400&w=1133&dpr=1"
            alt="Roadr Logo"
          />
        </a>
        <Grid container mt={7} mb={5} alignItems="flex-start" spacing={2}>
          {" "}
          {/* Updated with spacing property */}
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column-reverse", sm: "row" },
            }}
          >
            {step === 1 && (
              <Typography
                variant="body2"
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: "1px",
                  textAlign: "start",
                }}
              >
                We're thrilled to introduce{" "}
                <a href="https://roadr.com">
                  <b>Roadr</b>
                </a>
                ,&nbsp;the platform that connects automotive service companies
                to a community of users eager for quick and reliable solutions.
                Whether you specialize in towing, tire changes, jump-starts, or
                any other automotive service,&nbsp;
                <a href="https://roadr.com">
                  <b>Roadr</b>
                </a>{" "}
                is your gateway to increasing visibility and expanding your
                reach.
              </Typography>
            )}

            {step === 2 && (
              <Typography
                variant="body2"
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: "1px",
                  textAlign: "start",
                }}
                mb={2}
              >
                Join us to become part of a network of over 14,000 experts
                nationwide. List your prices, set your availability, and tap
                into a steadily growing customer base – when you list your
                prices with us, you receive 100% of the service value for any
                customer request made through Roadr. Be a part of the revolution
                that redefines how automotive services are discovered and
                delivered. It's time to elevate your business with Roadr.
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <a href="https://roadr.com">
              <img
                src="https://dim.mcusercontent.com/cs/3e2980a509f2f7889fe5f74c0/images/13a0cc6c-649a-57cd-ddd3-265d4e3221e4.png?w=1020&dpr=1"
                style={{ maxWidth: "100%" }}
                alt="Roadr Image"
              />{" "}
            </a>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
          }}
        >
          <Box
            component="form"
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              width: "100%",
            }}
            onSubmit={() => console.log("submit")}
          >
            {step === 1 && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  width: "100%",
                }}
              >
                <Typography variant="h4" component="h2" mb={2}>
                  Basic Information
                </Typography>

                <TextField
                  variant="filled"
                  color="secondary"
                  type="text"
                  label="Company name"
                  margin="normal"
                  value={companyName}
                  onChange={handleNameChange}
                />
                <TextField
                  variant="filled"
                  color="secondary"
                  type="email"
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                  margin="normal"
                  required
                />

                <TextField
                  variant="filled"
                  color="secondary"
                  type="text"
                  label="SMS Phone Number"
                  margin="normal"
                  required
                  onChange={(event) => setPhoneNumber(event.target.value)}
                />

                {/* <FormControl
                  fullWidth
                  variant="filled"
                  margin="normal"
                  required
                >
                  <InputLabel id="company-type-label">Company Type</InputLabel>
                  <Select
                    labelId="company-type-label"
                    id="company-type"
                    value={key}
                    onChange={(event) => {
                      let newKey = event.target.value
                        ? event.target.value.toLowerCase()
                        : key;
                      setKey(newKey);
                      
                    }}
                  >
                    <MenuItem value={"towing company"}>Towing</MenuItem>
                    <MenuItem value={"heavy duty"}>Heavy Duty</MenuItem>
                    <MenuItem value={"roadside assistance"}>
                      Roadside Assistance
                    </MenuItem>
                    <MenuItem value={"mobile mechanics"}>
                      Mobile Mechanics
                    </MenuItem>
                    <MenuItem value={"locksmith"}>Locksmith</MenuItem>
                  </Select>
                </FormControl> */}

                <TextField
                  variant="filled"
                  color="secondary"
                  type="text"
                  label="Maximum miles to accept a service"
                  margin="normal"
                  value={maxMiles}
                  onChange={(event) => setMaxMiles(Number(event.target.value))}
                />
              </Box>
            )}

            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                width: "100%",
                maxWidth: "320px",
              }}
            >
              {step === 2 && (
                <div>
                  <Typography variant="h4" component="h2" mt={2} mb={2} >
                    Join us
                  </Typography>
                </div>
              )}

              {(key === "heavy duty" ||
                key === "towing company" ||
                key === "roadside assistance") &&
                step === 2 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h4" component="h2" mt={3} mb={2}>
                      Towing
                    </Typography>
                    {towTypes.map((towType: any) => (
                      <div key={towType.name + 'towTypes'}>
                        <Typography variant="h5" component="h2" mt={3} mb={2}>
                          {towType.name}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                          }}
                        >
                          <TextField
                            variant="filled"
                            color="secondary"
                            type="number"
                            label="Hookup fee ($)"
                            value={towType.state.hookupFee}
                            name="hookupFee"
                            onChange={(event) =>
                              handleTowFieldChange(
                                event as ChangeEvent<HTMLInputElement>,
                                towType.name,
                                "hookupFee"
                              )
                            }
                            margin="normal"
                            sx={{ flexGrow: 1, margin: 1 }} //
                          />

                          <TextField
                            variant="filled"
                            color="secondary"
                            type="number"
                            label="Mileage rate ($)"
                            value={towType.state.milesRate}
                            name="milesRate"
                            onChange={(event) =>
                              handleTowFieldChange(
                                event as ChangeEvent<HTMLInputElement>,
                                towType.name,
                                "milesRate"
                              )
                            }
                            margin="normal"
                            sx={{ flexGrow: 1, margin: 1 }} //
                          />
                        </Box>
                      </div>
                    ))}
                  </Box>
                )}

              {(key === "heavy duty" ||
                key === "towing company" ||
                key === "roadside assistance") &&
                step === 2 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h4" component="h2" mt={3} mb={2}>
                      Heavy duty
                    </Typography>
                    {heavyDutyTowTypes.map((towType: any) => (
                      <div key={towType.name + 'heavyDutyTowTypes'}>
                        <Typography variant="h5" component="h2" mt={3} mb={2}>
                          {towType.name}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                          }}
                        >
                          <TextField
                            variant="filled"
                            color="secondary"
                            type="number"
                            label="Hookup fee ($)"
                            value={towType.state.hookupFee}
                            name="hookupFee"
                            onChange={(event) =>
                              handleTowFieldChange(
                                event as ChangeEvent<HTMLInputElement>,
                                towType.name,
                                "hookupFee"
                              )
                            }
                            margin="normal"
                            sx={{ flexGrow: 1, margin: 1 }} //
                          />

                          <TextField
                            variant="filled"
                            color="secondary"
                            type="number"
                            label="Mileage rate ($)"
                            value={towType.state.milesRate}
                            name="milesRate"
                            onChange={(event) =>
                              handleTowFieldChange(
                                event as ChangeEvent<HTMLInputElement>,
                                towType.name,
                                "milesRate"
                              )
                            }
                            margin="normal"
                            sx={{ flexGrow: 1, margin: 1 }} //
                          />
                        </Box>
                      </div>
                    ))}
                  </Box>
                )}
              {step === 2 && (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    width: "100%",
                  }}
                >
                  <Typography variant="h4" component="h2" mt={3} mb={2}>
                    General roadside assistance
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                    gap={1}
                    style={{
                      width: "100%",
                    }}
                  >
                    <TextField
                      variant="filled"
                      color="secondary"
                      type="number"
                      label="Refuel ($)"
                      value={refuel}
                      onChange={(event) =>
                        setRefuel(Number(event.target.value))
                      }
                      margin="normal"
                      sx={{ width: "100%" }}
                    />
                    <TextField
                      variant="filled"
                      color="secondary"
                      type="number"
                      label="Jump ($)"
                      value={jump}
                      onChange={(event) => setJump(Number(event.target.value))}
                      margin="normal"
                      sx={{ width: "100%" }}
                    />
                    <TextField
                      variant="filled"
                      color="secondary"
                      type="number"
                      label="Unlock ($)"
                      value={unlock}
                      onChange={(event) =>
                        setUnlock(Number(event.target.value))
                      }
                      margin="normal"
                      sx={{ width: "100%" }}
                    />
                    <TextField
                      variant="filled"
                      color="secondary"
                      type="number"
                      label="Tire Change ($)"
                      value={tireChange}
                      onChange={(event) =>
                        setTireChange(Number(event.target.value))
                      }
                      margin="normal"
                      sx={{ width: "100%" }}
                    />

                    {key === "mobile_mechanics" && (
                      <div>
                        <Typography variant="h4" component="h2" mt={3} mb={2}>
                          Mobile Mechanics
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                          gap={1}
                        >
                          <TextField
                            variant="filled"
                            color="secondary"
                            type="number"
                            label="Oil change ($)"
                            value={oilChange}
                            onChange={(event) =>
                              setOilChange(Number(event.target.value))
                            }
                            margin="normal"
                            style={{ width: "100%", flexGrow: 1 }}
                          />
                          <TextField
                            variant="filled"
                            color="secondary"
                            type="number"
                            label="Wheel Alignment ($)"
                            value={wheelAlignment}
                            onChange={(event) =>
                              setwheelAlignment(Number(event.target.value))
                            }
                            sx={{ flexGrow: 1, width: "100%" }}
                            margin="normal"
                          />
                          <TextField
                            variant="filled"
                            color="secondary"
                            type="number"
                            label="Tire Rotation ($)"
                            value={tireRotation}
                            onChange={(event) =>
                              setTireRotation(Number(event.target.value))
                            }
                            margin="normal"
                            style={{ width: "100%", flexGrow: 1 }}
                          />
                          <TextField
                            variant="filled"
                            color="secondary"
                            type="number"
                            label="Car inspection ($)"
                            value={carInspection}
                            onChange={(event) =>
                              setCarInspection(Number(event.target.value))
                            }
                            sx={{ flexGrow: 1 }}
                            margin="normal"
                          />
                          <TextField
                            variant="filled"
                            color="secondary"
                            type="number"
                            label="Engine Repair ($)"
                            value={engineRepair}
                            onChange={(event) =>
                              setEngineRepair(Number(event.target.value))
                            }
                            style={{ width: "100%", flexGrow: 1 }}
                            margin="normal"
                          />
                          
                          <TextField
                            variant="filled"
                            color="secondary"
                            type="number"
                            label="Brake Repair ($)"
                            value={brakeRepair}
                            onChange={(event) =>
                              setBrakeRepair(Number(event.target.value))
                            }
                            style={{ width: "100%", flexGrow: 1 }}
                            margin="normal"
                          />
                          <TextField
                            variant="filled"
                            color="secondary"
                            type="number"
                            label="Fuel Filter change ($)"
                            value={fuelFilter}
                            onChange={(event) =>
                              setFuelFilter(Number(event.target.value))
                            }
                            style={{ width: "100%", flexGrow: 1 }}
                            margin="normal"
                          />
                          <TextField
                            variant="filled"
                            color="secondary"
                            type="number"
                            label="Air conditioner filter change ($)"
                            value={airConditionerFilter}
                            onChange={(event) =>
                              setAirConditionerFilter(
                                Number(event.target.value)
                              )
                            }
                            style={{ width: "100%", flexGrow: 1 }}
                            margin="normal"
                          />
                        </Box>
                      </div>
                    )}
                  </Box>
                </Box>
              )}
              <div style={{ display: "flex" }}>
                {step === 1 && (
                  <div style={{ display: "flex" }}>
                    <Checkbox
                      color="secondary"
                      checked={accepted}
                      onChange={(event) => setAccepted(event.target.checked)}
                    />
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: "12px",
                        margin: "16px 0",
                        opacity: "0.7",
                      }}
                    >
                      Check this box if you want to receive special offers and
                      opportunities from customers who come through Roadr.
                    </Typography>
                  </div>
                )}
              </div>
              <Button
                style={{
                  backgroundColor: "#D76600",
                  marginTop: 24,
                  height: 58,
                  marginBottom: 32,
                  textTransform: "none",
                }}
                type="submit"
                variant="contained"
                color="primary"
                onClick={(event) => {
                  event.preventDefault();
                  handleNextStep();
                }}
              >
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: "#000",
                    letterSpacing: "0.5px",
                  }}
                >
                  Next
                </span>
              </Button>
            </Box>

            <Box mt={5}></Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Connect;
