import { Avatar, Box, Card, CardActionArea, Typography } from "@mui/material";
import { StyledBadge } from 'components/StyledBadge';
import React, { useEffect, useState } from "react";
import { DEFAULT_PROFILE_IMG } from "utils/const";
import { formattedDate } from "utils/helper";
import { styles } from "./styles";

type Props = {
  chat: any;
  viewModel: any;
  isSelected: boolean;
  onDMCardClick: (chat: any) => void;
};

const DMCard = ({ chat, viewModel, isSelected, onDMCardClick }: Props) => {
  const [currentChatRoom, setCurrentChatRoom] = useState<any>(null);

  const handleViewModelChat = (data: any) => {
    viewModel.setChat(data);
    onDMCardClick(chat)
  };


  const msgDate: any = formattedDate(chat.message.created);
  const handleClick = (e: any) => {
    e.preventDefault();
  }

  return (
    <Box
      style={styles.container}
      sx={{
        borderLeft: isSelected ? '2px solid #fff' : '2px solid transparent',
        transition: 'border-left 0.3s ease',
      }}
      onClick={() => handleViewModelChat(chat)}>
      {/* PROFILE PICTURE */}
      <Box>
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
          isOnline={chat.isOnline}
        >
          <Avatar alt='avatar' src={chat.customer.profileImg?.length ? chat.customer.profileImg : DEFAULT_PROFILE_IMG} />
        </StyledBadge>
      </Box>

      {/* NAME, SERVICE, MSG */}
      <Box style={styles.textContainer}>
        <Typography style={styles.customerName}>
          {chat.customer.name}
        </Typography>
        <Typography style={styles.service}>
          Service: {chat.service.serviceType}
        </Typography>
        <Typography style={styles.textMessage}>
          {chat.message.content}
        </Typography>
      </Box>

      {/* DATETIME, NUMBER OF NOTIFICATIONS UNREAD */}
      <Box style={styles.dateContainer}>
        <Typography style={styles.service}>{msgDate}</Typography>
        <Typography style={styles.service}></Typography>
      </Box>
    </Box>
  );
};

export default DMCard;
