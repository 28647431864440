import { ReactComponentElement } from "react";

interface GoogleCompaniesStyles {
  headerLeftBox: React.CSSProperties;
  addServiceBox: React.CSSProperties;
  average: React.CSSProperties;
  fabLocation: React.CSSProperties;
  fabChat: React.CSSProperties;
  itemsPerPage: React.CSSProperties;
  none: React.CSSProperties;
  priceDialogPrice: React.CSSProperties;
  favoriteFab: React.CSSProperties;
  iconWhite: React.CSSProperties;
  iconGreen: React.CSSProperties;
  fabGreen: React.CSSProperties;
  fabWhite: React.CSSProperties;
  iconBlack: React.CSSProperties;
  btnSave: React.CSSProperties;
  spanCancel: React.CSSProperties;
  spanSave: React.CSSProperties;
  btnCancel: React.CSSProperties;
  defaultSpan: React.CSSProperties;
  flexColumn: React.CSSProperties;
  spanMechanical: React.CSSProperties;
  papperPros: React.CSSProperties;
  flexColumnCenter: React.CSSProperties;
  subtitle: React.CSSProperties;
  spanDate: React.CSSProperties;
  spanContactedBy: React.CSSProperties;
  inputHalf: React.CSSProperties;
  defaultSubService: React.CSSProperties;
  refuelInput: React.CSSProperties;
  flexColumnGrow: React.CSSProperties;
  newContactTitle: React.CSSProperties;
  listLarger: React.CSSProperties;
  legendPriceNotSet: React.CSSProperties;
  legendPriceHigher: React.CSSProperties;
  legendPriceLower: React.CSSProperties;
  listSmaller: React.CSSProperties;
  inputCarWash: React.CSSProperties;
  toggleTitle: React.CSSProperties;
  transparentOutlinedTextarea: React.CSSProperties;
  papperPros2: React.CSSProperties;
}

export const styles: GoogleCompaniesStyles = {
  toggleTitle: {
    fontFeatureSettings: "'calt' off",
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px" /* 214.286% */,
    letterSpacing: "-0.28px",
    marginBottom: "16px",
    color: "#fff",
  },
  newContactTitle: {
    color: "var(--dark-white-white-900, #FFF)",
    fontFeatureSettings: "'calt' off",
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px" /* 214.286% */,
    letterSpacing: "-0.28px",
  },
  transparentOutlinedTextarea: {
    width: "100%",
    padding: "8px",
    borderRadius: "4px",
    backgroundColor: "rgba(0, 0, 0, 0.1)", // Transparent background
    color: "#FFF", // Text color
    border:"1px solid white"
  },
  inputCarWash: {
    marginBottom: "14px",
    width: "127px",
    marginTop: "8px",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexColumnCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    flexDirection: "column",
  },
  flexColumnGrow: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
  },

  refuelInput: {
    width: "512px",
    marginBottom: "12px",
  },
  spanDate: {
    opacity: "0.9",
    marginLeft: "auto",
    fontWeight: 600,
  },
  spanSave: {
    fontFeatureSettings: "'calt' off",
    fontFamily: "Inter",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "-0.15px",
  },
  subtitle: {
    color: "var(--dark-white-white-900, #FFF)",
    fontFeatureSettings: "'calt' off",
    /* Subtitle 1 */
    fontFamily: "Inter, sans-serif", // Include fallback options
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px" /* 187.5% */,
    letterSpacing: "-0.32px",
  },
  btnCancel: {
    backgroundColor: "transparent",
    width: "200px",
    height: "54px",
    padding: "13px 16px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 16,
    color: "#fff",
    textTransform: "none",
  },
  listLarger: {
    width: "100%",
    height: "auto",
    maxWidth: 456,
    border: "1px solid grey",
    zIndex: 100,
    position:"absolute",
    background:"black",
    top:"3em"
  },
  listSmaller: {
    width: 359,
    height: "auto",
    maxWidth: 360,
    border: "1px solid grey",
    zIndex: 100,
    position:"absolute",
    background:"black",
    top:"5em"
  },
  spanCancel: {
    fontFeatureSettings: "'calt' off",
    fontFamily: "Inter",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "-0.15px",
  },
  headerLeftBox: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  inputHalf: {
    marginBottom: "12px",
    width: "252px",
  },
  addServiceBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: "5px",
    cursor: "pointer",
  },
 
  none: {
    textDecoration: "none",
  },
  itemsPerPage: {
    marginTop: 4,
    width: 64,
    height: 28,
  },
  average: {
    width: "auto",
    height: 32,
    backgroundColor: "green",
    paddingBottom: 6,
    paddingTop: 6,
    paddingLeft: 12,
    paddingRight: 12,
  },
  fabLocation: {
    borderRadius: "50%",
    backgroundColor: "orange",
    color: "white",
  },
  fabChat: {
    borderRadius: "50%",
    backgroundColor: "green",
    color: "white",
  },
  priceDialogPrice: {
    color: "var(--dark-white-white-900, #FFF)",
    fontFeatureSettings: "'calt' off",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "-0.85px",
    display: "flex",
    justifyContent: "space-between",
  },
  favoriteFab: {
    width: "36px",
    height: "36px",
    backgroundColor: `transparent`,
    boxShadow: "none",
    marginLeft: 8,
  },
  iconWhite: {
    width: "21px",
    height: "21px",
    color: "white",
  },
  iconGreen: {
    width: "21px",
    height: "21px",
    color: "green",
  },
  iconBlack: {
    width: "21px",
    height: "21px",
    color: "black",
  },
  fabGreen: {
    width: "36px",
    height: "36px",
    backgroundColor: "green",
  },
  fabWhite: {
    width: "36px",
    height: "36px",
    backgroundColor: "white",
  },
  btnSave: {
    color: "var(--dark-dark-900, #000)",
    width: "200px",
    height: "54px",
    padding: "13px 14px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 16,
    backgroundColor: "#FF7C06",
    textTransform: "none",
  },
  defaultSpan: {
    color: "#FFF",
    fontFeatureSettings: "'calt' off",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "-0.14px",
    marginBottom: 14,
  },
  spanContactedBy: {
    color: "#fff",
    fontSize: 16,
    fontWeight: 600,
  },
  papperPros: {
    padding: "20px",
    width: "756px",
    zIndex: 1000,
    backgroundColor: "#000",
  },
  papperPros2: {
    padding: "20px",
    width: 1000,
    zIndex: 1000,
    backgroundColor: "#000",
  },
  defaultSubService: {
    color: "#FFF",
    fontFeatureSettings: "'calt' off",
    fontFamily: "Inter",
    fontSize: "12.75px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "-0.14px",
    marginBottom: 20,
    opacity: "0.8",
  },
  spanMechanical: {
    color: "#FFF",
    fontFeatureSettings: "'calt' off",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "-0.14px",
    marginBottom: 20,
    marginTop: 32,
  },
  legendPriceNotSet: {
    backgroundColor: "#000",
    width: 13,
    height: 13,
    borderRadius: "50%",
    border: "1px solid white",
  },
  legendPriceHigher: {
    backgroundColor: "#FF2D2D",
    width: 13,
    height: 13,
    borderRadius: "50%",
  },
  legendPriceLower: {
    backgroundColor: "#3CB371",
    width: 13,
    height: 13,
    borderRadius: "50%",
  },
};
