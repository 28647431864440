import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';

interface BreadcrumbProps {
  subcomponents: string[];
  currentLevel: string;
  changeLevel: (level: string) => void;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ subcomponents, currentLevel, changeLevel }) => {
  
  const currentIndex = subcomponents.indexOf(currentLevel);
  const visibleSubcomponents = subcomponents.slice(0, currentIndex + 1);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {visibleSubcomponents.map((item, index) => {
        const isLast = index === currentIndex;
        return isLast ? (
          <Typography key={index} color="textPrimary" variant="body1">
            {item}
          </Typography>
        ) : (
          <Link key={index} color="inherit" onClick={() => changeLevel(item)}>
            {item}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
