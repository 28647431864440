import { useTheme } from "@emotion/react";
import { Button, CircularProgress, Tooltip } from "@mui/material";
// import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import UserRequests from "requests/userRequests";
import { usePatchUserCriminalStatusMutation } from "state/api";
import CompanyDialogAction from "./CompanyDialogAction";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';

type Props = {
    params: any;
    pollingInterval: number;
    setPollingInterval: (value: number) => void;
    setVerificationStatus: (value: string) => void;
}

const CompanyActions = ({
  params,
  pollingInterval,
  setPollingInterval,
  setVerificationStatus,
}: Props) => {
  const theme = useTheme();
  const isSuccess = true;

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { email } = params.row;

  const handleOpen = async (e: any): Promise<void> => {
    if (e.target.value === "approved") {
      const userRequest = new UserRequests();
      const response = await userRequest.updateVerificationStatus(
        email,
        "approved",
      );
      alert("Company has been approved successfully");
      setVerificationStatus("approved");
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    isSuccess && setLoading(false);
    setPollingInterval(1000000);
  }, [setPollingInterval, pollingInterval, loading, isSuccess]);

  const ApprovedButton = () => (
    <Button
      variant="outlined"
      value="approved"
      onClick={handleOpen}
      sx={{
        ml: "10px",
        textTransform: "none",
        borderRadius: "9px",
        // @ts-ignore
        color: theme.palette.green[900],
        // @ts-ignore
        borderColor: theme.palette.green[900],
        ":hover": {
          // @ts-ignore
          backgroundColor: theme.palette.blue[700],
          // @ts-ignore
          color: theme.palette.secondary[0],
        },
      }}
    >
      Approve
    </Button>
  );

  const RejectButton = () => (
    <Button
      variant="outlined"
      value="reject"
      onClick={handleOpen}
      sx={{
        ml: "10px",
        textTransform: "none",
        borderRadius: "9px",
        // @ts-ignore
        color: theme.palette.red[900],
        // @ts-ignore
        borderColor: theme.palette.red[900],
        ":hover": {
          // @ts-ignore
          backgroundColor: theme.palette.blue[700],
          // @ts-ignore
          color: theme.palette.secondary[0],
        },
      }}
    >
      Reject
    </Button>
  );

  const verificationStatus = params.row.verificationStatus;
  const userId = params.row._id;

  const buttonsToRender = [];
  const key = userId + "_userId"
  if (verificationStatus === "approved")
    buttonsToRender.push(<RejectButton key={key} />);
  if (verificationStatus === "rejected")
    buttonsToRender.push(<ApprovedButton key={key} />);
  if (verificationStatus === "pending" || !verificationStatus) {
    buttonsToRender.push(<ApprovedButton key={key + "approveButton"} />);
    buttonsToRender.push(<RejectButton key={key + "rejectButton"} />);
  }

  return (
    <Box>
      {buttonsToRender}
      <CompanyDialogAction
        open={open}
        handleClose={handleClose}
        user={params.row}
        setVerificationStatus={setVerificationStatus}
      />
      {loading && (
        <CircularProgress size={15} sx={{ ml: "5px" }} color="success" />
      )}
    </Box>
  );
};

export default CompanyActions;
