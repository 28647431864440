// get from an array of objects, an array with all values from a specific prop.
export const arrayOfPropValues = (arrOfObj: Array<any>, prop: any) => {
    return arrOfObj.filter(obj => obj.hasOwnProperty(prop)).map(obj => obj[prop]);
}


export function getQueryString(obj: any) {
    let objects = Object.keys(obj).filter(key => obj[key] !== undefined)
    return `?${objects.map(key => `${key}=${obj[key]}`).join("&")}`
}

export function getDateFromString(dateString: string) {
  // Create a new Date object from the input string
  var date = new Date(dateString);

  // Extract the date and time components
  var year = date.getFullYear().toString().slice(-2); // Extract last two digits of the year
  var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero if needed
  var day = date.getDate().toString().padStart(2, '0'); // Add leading zero if needed
  var hours = date.getHours().toString().padStart(2, '0'); // Add leading zero if needed
  var minutes = date.getMinutes().toString().padStart(2, '0'); // Add leading zero if needed

  // Create the formatted date string
  var formattedDate = `${month}-${day}-${year} ${hours}:${minutes}`;
  return formattedDate; 
}

export function formattedDate(date: string) {

  const targetDate: any = new Date(date);

  const year = targetDate.getFullYear().toString().slice(-2).padStart(2, '0');
  const month = (targetDate.getMonth() + 1).toString().padStart(2, '0');
  const day = targetDate.getDate().toString().padStart(2, '0');
  const hours = targetDate.getHours().toString().padStart(2, '0');
  const minutes = targetDate.getMinutes().toString().padStart(2, '0');
  return `${month}/${day}/${year} ${hours}:${minutes}`;
}

export function formattedDateEpoch(timestamp: number) {
  const targetDate = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds

  const year = targetDate.getFullYear().toString().slice(-2).padStart(2, '0');
  const month = (targetDate.getMonth() + 1).toString().padStart(2, '0');
  const day = targetDate.getDate().toString().padStart(2, '0');
  const hours = targetDate.getHours().toString().padStart(2, '0');
  const minutes = targetDate.getMinutes().toString().padStart(2, '0');

  return `${month}/${day}/${year} ${hours}:${minutes}`;
}
