import { ReactComponentElement } from "react";

interface Styles {
    Input: React.CSSProperties;
    ContainerSpecialty: React.CSSProperties;
    FirstContainer: React.CSSProperties;
    Select: React.CSSProperties;
}

export const styles: Styles = {
    Input: {
        fontFeatureSettings: "'calt' off",
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        marginTop: "10px",
        color: "#fff",
        backgroundColor: "#1f1f1f",
        border: "1px solid #e1e1e1",
        borderRadius: "4px",
        padding: "14px 12px",
    },
    Select: {
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        color: "#fff",
        backgroundColor: "#1f1f1f",
        border: "1px solid #1f1f1f",
        borderRadius: "4px",
        padding: "8px 12px",
    },
    ContainerSpecialty: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "#1f1f1f",
        padding: "20px",
        borderRadius: "4px",
        gap: "5px",
    },
    FirstContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        padding: "20px",
        gap: "30px",
    },
};
