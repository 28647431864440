import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  Search,
  // SettingsOutlined,
  ArrowDropDownOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import { useDispatch } from "react-redux";
import { setMode } from "state";
import {
  AppBar,
  Button,
  Box,
  Typography,
  IconButton,
  InputBase,
  Toolbar,
  Menu,
  MenuItem,
  useTheme,
  Avatar,
} from "@mui/material";
import { DEFAULT_PROFILE_IMG } from "utils/const";
import NotificationDropdown from "./NotificationDropdown";

type Props = {
    isLoading: boolean;
    user: any;
    isSidebarOpen: boolean;
    setIsSidebarOpen: (value: boolean) => void;
}

const Navbar = ({ isLoading, user, isSidebarOpen, setIsSidebarOpen }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event: any) => setAnchorEl(event.currentTarget);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    localStorage.clear();
    setAnchorEl(null);
    navigate("/login"); // Redirect to the login page
    window.location.reload()
  }

  const handleSearch = (event: any) => {
    event.preventDefault();
    console.log(event.value);
  };
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      setIsAuthenticated(true)
    }
  }, []); 
 
  return isAuthenticated ? (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>



          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon />
          </IconButton>
          {/* <FlexBetween
            // @ts-ignore
            backgroundColor={theme.palette.background.alt}
            borderRadius="9px"
            gap="3rem"
            p="0.1rem 1.5rem"
          >
            <InputBase placeholder="Search..." />
            <IconButton>
              <Search />
            </IconButton>
          </FlexBetween> */}
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="0.5rem">
          <IconButton onClick={() => dispatch(setMode())}>
            {" "}
            {/* <--- dark mode/ light mode button */}
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlined sx={{ fontSize: "25px" }} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px" }} />
            )}
          </IconButton>


          <NotificationDropdown />
          {/* <IconButton>
            <SettingsOutlined sx={{ fontSize: "25px" }} />
          </IconButton> */}
          {/* <Avatar src={DEFAULT_PROFILE_IMG}/> */}

          <FlexBetween>
            <Button
              onClick={handleClick}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "0.5rem",
              }}
            >
              {isLoading ? (
                <Box>
                  <Typography>Loading...</Typography>
                </Box>
              ) : (
                <Box
                  component="img"
                  alt="profile"
                  src={user && user.profileImg.uri || DEFAULT_PROFILE_IMG}
                  height="32px"
                  width="32px"
                  borderRadius="50%"
                  sx={{ objectFit: "cover" }}
                />
              )}
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.85rem"
                  // @ts-ignore
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user && user.name}
                </Typography>
                <Typography
                  fontSize="0.75rem"
                  // @ts-ignore
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {user && user.occupation}
                </Typography>
              </Box>
              <ArrowDropDownOutlined
                // @ts-ignore
                sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <MenuItem onClick={signOut}>Log Out</MenuItem>
            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  ) : null;
};

export default Navbar;
