interface ButtonStyles {
  container: React.CSSProperties;
  text: React.CSSProperties;
}

export const styles: ButtonStyles = {
  container: {
    display: 'flex',
    width: '200px',
    height: 'auto',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '13px 16px',
    borderRadius: '16px',
    backgroundColor: '#FF7C06',
    cursor: 'pointer'
  },
  text: {
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '-0.15px',
    color: '#000'
  }
}