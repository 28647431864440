export const APP_ENVIRONMENT = process.env.REACT_APP_VERCEL_ENV ? process.env.REACT_APP_VERCEL_ENV : 'dev';

// Client REST API
export const getClientAPIBaseUrl = () => {
  if (APP_ENVIRONMENT === 'production') {
    return 'https://backend.roadrapp.com/api/';
  } else if (APP_ENVIRONMENT === 'dev') {
    return 'http://localhost:3000/api/';
  } else {
    return 'https://qabackend.roadrapp.com/api/';
  }
};

// Admin REST API
export const getHostAPIBaseUrl = () => {
  if (APP_ENVIRONMENT === 'production') {
    return 'https://admin.backend.roadrapp.com';
  } else if (APP_ENVIRONMENT === 'dev') {
    return 'http://localhost:8282';
  } else {
    return 'https://qa.admin.backend.roadrapp.com';
  }
};


// Admin python REST API
export const getHostPythonAPIBaseUrl = () => {

  if (APP_ENVIRONMENT === 'production') {
    return 'https://admin.backend.roadrapp.com';
  } else if (APP_ENVIRONMENT === 'dev') {
    return 'http://localhost:7777';
  }
  return 'https://qa.admin.backend.roadrapp.com';

};

// Admin python GRPC
export const getHostPythonGrpcBaseUrl = () => {

  if (APP_ENVIRONMENT === 'production') {
    return 'https://admin.grpc.roadrapp.com';
  } else if (APP_ENVIRONMENT === 'dev') {
    return 'http://localhost:8585';
  }
  return 'https://grpc.qa.python.roadrapp.com';

};

export const getHostPythonWsBaseUrl = () => {
  if (APP_ENVIRONMENT === 'production') {
    return 'wss://admin.backend.roadrapp.com';
  } else if (APP_ENVIRONMENT === 'dev') {
    return 'ws://localhost:7777';
  } else {
    return 'wss://qa.admin.backend.roadrapp.com';
  }
};


export const getHostRustAPIBaseUrl = () => {
  if (APP_ENVIRONMENT === 'production') {
    return 'https://rust.roadrapp.com';
  } else if (APP_ENVIRONMENT === 'dev') {
    return 'http://localhost:8080';
  } {
    return 'https://staging.rust.roadrapp.com';
  }
};

export const getHostRustWsBaseUrl = () => {
  if (APP_ENVIRONMENT === 'production') {
    return 'wss://rust.roadrapp.com';
  } else if (APP_ENVIRONMENT === 'dev') {
    return 'wss://localhost:8080';
  } {
    return 'wss://staging.rust.roadrapp.com';
  }
};

// Service Request REST API
export const getServiceRequestApiUrl = () => {
  if (APP_ENVIRONMENT === 'production') {
    return 'https://service-request.roadrapp.com';
  } else if (APP_ENVIRONMENT === 'dev') {
    return 'http://localhost:8181';
  } else {
    return 'https://qa.service-request.roadrapp.com';
  }
};

// Service Request Websocket
export const getServiceRequestWebsocketUrl = () => {
  if (APP_ENVIRONMENT === 'production') {
    return 'wss://service-request.roadrapp.com';
  } else if (APP_ENVIRONMENT === 'dev') {
    return 'ws://localhost:8181';
  } else {
    return 'wss://qa.service-request.roadrapp.com';
  }
};

// Host Websocket
export const getHostWebsocketUrl = () => {
  if (APP_ENVIRONMENT === 'production') {
    return 'wss://admin.backend.roadrapp.com';
  } else if (APP_ENVIRONMENT === 'dev') {
    return 'ws://localhost:7777';
  } else {
    return 'wss://qa.admin.backend.roadrapp.com';
  }
};

// Mapbox Token
export const getMapboxToken = () => {
  if (APP_ENVIRONMENT === 'production') {
    return 'pk.eyJ1Ijoicm9hZHIiLCJhIjoiY2xoams2ZHZ4MGlqYjNtczZjeG84b2MzaiJ9.Rcr9Qq_-yyj-tipbSq9Ltw';
  } else if (APP_ENVIRONMENT === 'dev') {
    return 'pk.eyJ1Ijoicm9hZHIiLCJhIjoiY2xoams2ZHZ4MGlqYjNtczZjeG84b2MzaiJ9.Rcr9Qq_-yyj-tipbSq9Ltw';
  } else {
    return 'pk.eyJ1Ijoicm9hZHIiLCJhIjoiY2xoams2ZHZ4MGlqYjNtczZjeG84b2MzaiJ9.Rcr9Qq_-yyj-tipbSq9Ltw';
  }
};

// Mapbox Token
export const getMapboxStyle = () => {
  if (APP_ENVIRONMENT === 'production') {
    return 'mapbox://styles/roadr/clhjkzb7t009y01pa0txd7u6o';
  } else if (APP_ENVIRONMENT === 'dev') {
    return 'mapbox://styles/roadr/clhjkzb7t009y01pa0txd7u6o';
  } else {
    return 'mapbox://styles/roadr/clhjkzb7t009y01pa0txd7u6o';
  }
};

// Stripe Key
export const getStripeKey = () => {
  if (APP_ENVIRONMENT === 'production') {
    return 'pk_live_51IBNyJEoiwHvWuhAPBudI1GmTPMGmlzocAQGXok2pANnr8AopKhM54A5DdFnj2b12fuBaictQvI8vkBbTERv4Pnv00Rf96h3cD';
  } else {
    return 'pk_test_51IBNyJEoiwHvWuhAgZ6k89uri7p5Dzh3XsMseBoyydRgo5plpoQLxvVjpDRNAD3nePEXwfj4doDn4Iyw23trCoMK00ZKyTqafK';
  }
};

export const getStripeUrl = () => {
  if (APP_ENVIRONMENT === 'production') {
    return 'https://dashboard.stripe.com/';
  } else {
    return 'https://dashboard.stripe.com/test/';
  }
};


export const firebaseConfig = {
  apiKey: 'AIzaSyD60k7L-oqeBT60E8j9qrASKfkLYqFR7Hw',
  authDomain: 'roadr-ios-auth.firebaseapp.com',
  projectId: 'roadr-ios-auth',
  storageBucket: 'roadr-ios-auth.appspot.com',
  messagingSenderId: '403383243820',
  appId: '1:403383243820:web:0a98ad9d85913734436d89',
  measurementId: 'G-K2RNVFJ8HH'
};

// export const isErrorEnabled = () => {
//   return APP_ENVIRONMENT !== 'production' && APP_ENVIRONMENT !== 'staging';
// };

// export const getGoogleCloudKey = () => {
//   if (APP_ENVIRONMENT === 'production' || APP_ENVIRONMENT === 'staging') {
//     return {
//       iosClientId: '868426469949-p05nurlthnmjq08gt1k5sb9klc0vjvfk.apps.googleusercontent.com',
//       androidClientId: '868426469949-qdgct2v3n7qefcljgq1g9u4ai3ssilil.apps.googleusercontent.com'
//     };
//   } else {
//     return {
//       iosClientId: '868426469949-lj7t1mae6viu5ikovj8g7jn8p8qpfliv.apps.googleusercontent.com',
//       androidClientId: '868426469949-hoqcof7g8oicud2r5krif9jptiq9ebcf.apps.googleusercontent.com'
//     };
//   }
// };

// export const GOOGLE_MAP_API_KEY = 'AIzaSyAMBvw4bx_jgzad-iswXEf2xUrk-TNz7tQ';

// 

