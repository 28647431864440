import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CompanyRequests from "requests/companyRequests";
import DialogActions from "@mui/material/DialogActions";
import Tag from "./tag";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import CompanyInfo from "./companyInfo";
import { styles } from "./styles";
import Dropdown from "scenes/companyMagangement/component/dropdown";
import { COMPANY_CATEGORIES } from "../../utils/const";
import CustomCard from "./newContact";
import axios from "axios";
import ColorPicker from "./colorPicker";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import "./style.css";
import {
  Box,
  Fab,
  TextField,
  Avatar,
  Typography,
  Button,
  Checkbox,
} from "@mui/material";

import PhoneIcon from "@mui/icons-material/Phone";
import ScheduleButton from "./companyOpenHour";
import { getHostPythonAPIBaseUrl } from "config";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DialogSpecialty from "./dialogSpecialist/dialogSpecialty";
import {
  VARIANTS_BATTERY,
  VARIANTS_REFUEL,
  VARIANTS_TIRE_CHANGE,
  VARIANTS_TOWING,
  VARIANTS_UNLOCK,
  VARIANTS_WASH,
} from "utils/const";
import ConfirmDelete from "./dialogDelete";

const companyRequests = new CompanyRequests();

const serviceObj = {
  battery: VARIANTS_BATTERY,
  carWash: VARIANTS_WASH,
  motorcycleWash: VARIANTS_WASH,
  refuel: VARIANTS_REFUEL,
  tire: VARIANTS_TIRE_CHANGE,
  unlock: VARIANTS_UNLOCK,
  towing: VARIANTS_TOWING,
} as any;

const mobileCarWashes = [
  {
    name: "coupeSedan",
    coupeSedan: {
      exterior: null,
      interior: null,
      miniDetail: null,
      fullDetail: null,
    },
  },
  {
    name: "midSize",
    midSize: {
      exterior: null,
      interior: null,
      miniDetail: null,
      fullDetail: null,
    },
  },

  {
    name: "fullSize",
    fullSize: {
      exterior: null,
      interior: null,
      miniDetail: null,
      fullDetail: null,
    },
  },
  {
    name: "heavyDuty",
    heavyDuty: {
      exterior: null,
      interior: null,
      miniDetail: null,
      fullDetail: null,
    },
  },
];

interface TireChangeFieldsProps {
  label: string;
  value: any | null;
  onChange: (value: string) => void;
}

const TireChangeFields: React.FC<TireChangeFieldsProps> = ({
  label,
  value,
  onChange,
}) => (
  <TextField
    sx={{ width: "31%" }}
    label={label}
    variant="outlined"
    color="secondary"
    type="number"
    value={value === null ? "" : value}
    onChange={(e) => {
      const newValue = e.target.value === "" ? "0" : e.target.value;
      onChange(newValue);
    }}
  />
);

interface CheckboxForm {
  battery: { [key: string]: boolean };
  towing: { [key: string]: boolean };
  carWash: { [key: string]: boolean };
  motorcycleWash: { [key: string]: boolean };
  refuel: { [key: string]: boolean };
  tire: { [key: string]: boolean };
  unlock: { [key: string]: boolean };
}

interface PriceCompanyDialogProps {
  open: boolean;
  handleClose: () => void;
  marker: any;
}

const initialCheckboxFormState: CheckboxForm = {
  battery: {},
  towing: {},
  carWash: {},
  motorcycleWash: {},
  refuel: {},
  tire: {},
  unlock: {},
};

const PriceCompanyDialog: React.FC<PriceCompanyDialogProps> = ({
  open,
  marker,
  handleClose,
}) => {
  const basePrices = {
    hasConfirmedPrices: marker.basePrices.hasConfirmedPrices,
    _id: marker.basePrices._id,
    carWash: {
      coupeSedan: {
        exterior: {
          basePrice: null,
        },
        interior: {
          basePrice: null,
        },
        miniDetail: {
          basePrice: null,
        },
        fullDetail: {
          basePrice: null,
        },
      },
      midSize: {
        exterior: {
          basePrice: null,
        },
        interior: {
          basePrice: null,
        },
        miniDetail: {
          basePrice: null,
        },
        fullDetail: {
          basePrice: null,
        },
      },
      fullSize: {
        exterior: {
          basePrice: null,
        },
        interior: {
          basePrice: null,
        },
        miniDetail: {
          basePrice: null,
        },
        fullDetail: {
          basePrice: null,
        },
      },
      heavyDuty: {
        exterior: {
          basePrice: null,
        },
        interior: {
          basePrice: null,
        },
        miniDetail: {
          basePrice: null,
        },
        fullDetail: {
          basePrice: null,
        },
      },
    },
    tire: {
      spareTire: {
        basePrice: null,
      },
      tireReplacement: {
        new: {
          basePrice: null,
        },
        used: {
          basePrice: null,
        },
      },
    },
    unlock: {
      jammedLocks: {
        basePrice: null,
      },
      lockedOut: {
        basePrice: null,
      },
      lostKeys: {
        basePrice: null,
      },
    },
    refuel: {
      diesel: {
        basePrice: null,
      },
      midgradeGasoline: {
        basePrice: null,
      },
      premiumGasoline: {
        basePrice: null,
      },
      regularGasoline: {
        basePrice: null,
      },
    },
    battery: {
      jump: {
        basePrice: null,
      },
      replacement: {
        new: {
          basePrice: null,
        },
        used: {
          basePrice: null,
        },
      },
    },
    towing: {
      flatbed: {
        basePrice: null,
        hourlyRate: null,
        mileageRate: null,
      },
      heavy: {
        basePrice: null,
        hourlyRate: null,
        mileageRate: null,
      },
      standard: {
        basePrice: null,
        hourlyRate: null,
        mileageRate: null,
      },
    },
  };

  const [formData, setFormData] = useState<any>({
    _id: "battery_replacement#ChIJ-3N1gqZSwokRAmlTtDzbv04_prices",
    basePrices: { ...basePrices },
    specialties: {
      specialties: [],
      _id: "",
    },
    detail: {
      _id: "battery_replacement#ChIJ-3N1gqZSwokRAmlTtDzbv04_detail",
      currentOpeningHours: {
        monday: [
          {
            open: "6:00 AM",
            close: "6:45 PM",
          },
        ],
        tuesday: [
          {
            open: "6:00 AM",
            close: "6:45 PM",
          },
        ],
        wednesday: [
          {
            open: "6:00 AM",
            close: "6:45 PM",
          },
        ],
        thursday: [
          {
            open: "6:00 AM",
            close: "6:45 PM",
          },
        ],
        friday: [
          {
            open: "6:00 AM",
            close: "6:45 PM",
          },
        ],
        saturday: [
          {
            open: "6:00 AM",
            close: "6:45 PM",
          },
        ],
        sunday: [
          {
            open: "6:00 AM",
            close: "6:45 PM",
          },
        ],
      },
      created: {
        $date: "2024-05-29T15:46:29.368Z",
      },
      updated: {
        $date: "2024-05-31T21:07:45.103Z",
      },
      __v: "0.1.0",
    },
    contact: {
      reachable: null,
      email: null,
      smsEnabled: null
    },
    tags: [],
    updates: [],
    hasContacted: false,
    placeId: "",
    isVerified: null,
    isFeatured: null,
    isGovernmentInstitution: null,
  });

  const [variantCheckboxes, setVariantCheckboxes] = useState<CheckboxForm>(
    initialCheckboxFormState
  );

  const [currentColorSelected, setCurrentColorSelected] = useState<string>("");
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [currentColorIndex, setCurrentIndex] = useState<number>(0);
  const [currentTagIndex, setCurrentTagIndex] = useState<number>(0);
  const colors = ["#1279F2", "#FF7C06", "#FF2D2D", "#34A853"];
  const [selectedOption, setSelectedOption] = useState<string>(
    "roadside assistance"
  );
  const [isFetch, setIsFetch] = useState<boolean>(false);
  const [showSpeacityDialog, setShowSpeacityDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showTowPrices, setShowTowPrices] = useState(false);
  const [showRefuelPrices, setShowRefuelPrices] = useState(false);
  const [showBatteryPrices, setShowBatteryPrices] = useState(false);
  const [showUnlockPrices, setShowUnlockPrices] = useState(false);
  const [showTirePrices, setShowTirePrices] = useState(false);
  const [showStandardTowPrices, setShowStandardTowPrices] = useState(false);
  const [showFlatbedTowPrices, setShowFlatbedTowPrices] = useState(false);
  const [showHeavydTowPrices, setShowHeavydTowPrices] = useState(false);

  const processAllVariants = () => {
    const newVariantCheckboxes: CheckboxForm = { ...initialCheckboxFormState };

    Object.keys(serviceObj).forEach((service) => {
      const serviceKey = service as keyof CheckboxForm;

      serviceObj[serviceKey].forEach((variant: string) => {
        newVariantCheckboxes[serviceKey][variant] = false;
      });
    });

    if (formData.specialties) {
      const specialties = formData.specialties.specialties || [];
      specialties.forEach((s: any) => {
        const serviceKey = s.name as keyof CheckboxForm;
        if (serviceKey === "towing") {
          setShowTowPrices(true);
        } else if (serviceKey === "battery") {
          setShowBatteryPrices(true);
        } else if (serviceKey === "refuel") {
          setShowRefuelPrices(true);
        } else if (serviceKey === "tire") {
          setShowTirePrices(true);
        } else if (serviceKey === "unlock") {
          setShowUnlockPrices(true);
        }
        s.variants.forEach((v: string) => {
          if (newVariantCheckboxes[serviceKey]) {
            newVariantCheckboxes[serviceKey][v] = true;
          }
        });
      });
    }

    setVariantCheckboxes(newVariantCheckboxes);
  };

  useEffect(() => {
    processAllVariants();
  }, [formData.specialties]);

  useEffect(() => {
    console.log(formData.contact);
  }, [formData]);

  const handleSetContact = (contactBool: boolean) => {
    const updatedFormData = {
      ...formData,
    };
    setFormData(updatedFormData);
  };

  const handleVariantCheck = (service: keyof CheckboxForm, variant: string) => {
    setVariantCheckboxes((prevState) => ({
      ...prevState,
      [service]: {
        ...prevState[service],
        [variant]: !prevState[service][variant],
      },
    }));
  };

  const handleSave = async () => {
    const specialties = Object.keys(variantCheckboxes)
      .map((service) => {
        const serviceKey = service as keyof CheckboxForm;
        return {
          name: service,
          service: "roadside",
          variants: Object.keys(variantCheckboxes[serviceKey]).filter(
            (variant) => variantCheckboxes[serviceKey][variant]
          ),
        };
      })
      .filter((specialty) => specialty.variants.length > 0);

    const updateSpecialties = {
      _id: `${formData.specialties._id}`,
      specialties,
    };

    let formDataSave = { ...formData };

    if (formDataSave.basePrices.hasOwnProperty("updated")) {
      delete formDataSave["basePrices"]["updated"];
    }
    if (formDataSave.basePrices.hasOwnProperty("created")) {
      delete formDataSave["basePrices"]["created"];
    }

    if (typeof formDataSave === "object") {
      if (formDataSave.hasOwnProperty("basePrices")) {
        const pricesCents = processPrices(formDataSave.basePrices, 100);

        try {
          await Promise.all([
            companyRequests.updateData({
              data: pricesCents,
              property: "basePrices",
              placeId: formDataSave.placeId,
            }),
            companyRequests.updateData({
              data: updateSpecialties,
              property: "specialties",
              placeId: formDataSave.placeId,
            }),
          ]);

          handleClose();
          setIsFetch(!isFetch);
        } catch (error) {
          alert("Error on API request");
          console.error("Error updating data:", error);
        }
      } else {
        console.log("formData is missing required properties.");
      }
    } else {
      console.log("formData is not a valid object.");
    }
  };

  const updateRechable = async (isChecked: boolean) => {
    const url = `${getHostPythonAPIBaseUrl()}/company/aggregate/update/reachable`;
    await axios.put(
      url,
      {
        placeId: formData.placeId,
        reachable: isChecked,
      },
      {
        headers: {
          Authorization: localStorage.getItem("access_token"),
        },
      }
    );
  };

  const updateHasConfirmedPrices = async (isChecked: boolean) => {
    const url = `${getHostPythonAPIBaseUrl()}/company/aggregate/update/confirmed/price`;
    await axios.put(
      url,
      {
        placeId: formData.placeId,
        hasConfirmedPrices: isChecked,
      },
      {
        headers: {
          Authorization: localStorage.getItem("access_token"),
        },
      }
    );
  };

  const updateHasEneableSms = async (isChecked: boolean) => {
    await companyRequests.updateData({
      data: {
        ...formData.contact,
        smsEnabled: isChecked,
      },
      property: "contact",
      placeId: marker.placeId,
    });
  };

  const updateIsFeatured = async (isChecked: boolean) => {
    await companyRequests.updateData({
      data: isChecked,
      property: "isFeatured",
      placeId: marker.placeId,
    });
  };

  const updateIsVerified = async (isChecked: boolean) => {
    await companyRequests.updateData({
      data: isChecked,
      property: "isVerified",
      placeId: marker.placeId,
    });
  };

  const updateIsGovernmentInstitution = async (isChecked: boolean) => {
    await companyRequests.updateData({
      data: isChecked,
      property: "isGovernmentInstitution",
      placeId: marker.placeId,
    });
  };

  const renderTypography = (item: any) => {
    const lastUpdate =
      formData.updates && formData.updates.length > 0
        ? formData.updates[formData.updates.length - 1]
        : null;

    const value = lastUpdate?.[item.key] || "";

    if (item.key === "created") {
      const formattedDate = formatDate(value);
      return (
        <Typography key={item.key} style={{ color: "#c0c0c0" }}>
          {formattedDate}
        </Typography>
      );
    }

    return (
      <Typography key={item.key} style={{ color: "#c0c0c0" }}>
        {value}
      </Typography>
    );
  };

  function formatDate(dateString: string): string {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "America/Los_Angeles", // Set to Los Angeles time zone
    };

    return new Date(dateString).toLocaleString(undefined, options);
  }

  const handleEditColor = (index: number, currentColorIndex: number) => {
    setCurrentTagIndex(index);
    setCurrentIndex(currentColorIndex);
    setShowColorPicker(true);
  };

  const handleChangeColor = async (
    hex: string,
    colorIndex: number,
    index: number
  ) => {
    setShowColorPicker(false);

    const url = `${getHostPythonAPIBaseUrl()}/tag/change`;
    const token = localStorage.getItem("access_token");

    // Clone the array to avoid mutating the state directly
    let updatedTags = [...formData.tags];

    const response = await axios.put(
      url,
      {
        name: updatedTags[index].name,
        color: colors[colorIndex],
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const data = response.data;

    // Update the color of the tag at the specified index
    updatedTags[index] = {
      ...updatedTags[index], // Fix: Use 'index' instead of 'colorIndex'
      color: colors[colorIndex],
      name: data.name,
    };

    setCurrentColorSelected(colors[index]);

    // Set the updated array in the state
    setFormData({
      ...formData,
      tags: updatedTags,
    });
  };

  const handleDelete = async () => {
    try {
     
      await companyRequests.deleteCompany(formData._id);
      setShowDeleteDialog(false);
      alert('Company deleted successfully');
      handleClose();
    } catch (error) {
      alert('An error occurred while deleting the company');
      console.error('Delete company error:', error);
    }
  };
  useEffect(() => {
    console.log("==========>: ", marker)
    if (open) {
      if (marker.placeId !== formData.placeId) {
        setFormData((prevData: any) => ({
          ...prevData,
          basePrices: processPrices(marker.basePrices, 0.01),
          detail: marker.detail,
          tags: marker.tags,
          placeId: marker.placeId,
          contact: marker.contact,
          updates: marker.updates,
          specialties: marker.specialties,
          _id:marker._id,
          isVerified: marker.isVerified,
          isFeatured: marker.isFeatured,
          isGovernmentInstitution: marker.isGovernmentInstitution,
        }));
      }
    }
  }, [open, marker]);

  const processPrices = (basePrices: any, multiply: number): any => {
    const traverseAndMultiply = (obj: any): any => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            // Recurse into the nested object
            traverseAndMultiply(obj[key]);
          } else if (
            key === "basePrice" ||
            key === "hourlyRate" ||
            key === "mileageRate"
          ) {
            // Multiply the value and ensure it has only 2 decimal places
            obj[key] = parseFloat((obj[key] * multiply).toFixed(2));
          }
        }
      }
      return obj;
    };

    return traverseAndMultiply(basePrices);
  };

  const extractTagInfo = (tagString: string) => {
    // Assuming the format is 'name#color_tag'
    const [nameColor, _tag] = tagString.split("_");
    const [name, color] = nameColor.split("#");
    return { name, color };
  };

  const handleRemoveTag = async (indexToRemove: number) => {
    const updatedTags = [...formData.tags];
    const removedTag = updatedTags.splice(indexToRemove, 1)[0];

    const url = `${getHostPythonAPIBaseUrl()}/company/aggregate/update/property`;
    await axios.put(
      url,
      {
        placeId: formData.placeId,
        data: updatedTags,
        property: "tags",
      },
      {
        headers: {
          Authorization: localStorage.getItem("access_token"),
        },
      }
    );

    setFormData({
      ...formData,
      tags: updatedTags,
    });
  };
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: styles.papperPros,
      }}
      fullWidth={true}
      maxWidth = {'md'}
      
    >
      <DialogTitle style={styles.priceDialogPrice}>
        {marker.name}
        <div style={styles.iconGroup}>
          <Fab
            style={styles.fabRed}
            onClick={() => setShowDeleteDialog(!showDeleteDialog)}
          >
            <DeleteForeverIcon style={styles.iconWhite} />
          </Fab>
          &nbsp;&nbsp;&nbsp;
          <Fab
            style={styles.fabWhite}
            onClick={() => setShowSpeacityDialog(!showSpeacityDialog)}
          >
            <FmdBadIcon style={styles.iconBlack} />
          </Fab>
          &nbsp;&nbsp;&nbsp;
          <Fab
            onClick={() => {
              setFormData({
                ...formData,
                hasContacted: !formData.hasContacted,
              });
            }}
            style={formData.hasContacted ? styles.fabGreen : styles.fabWhite}
          >
            <ConnectWithoutContactIcon
              style={
                !formData.hasContacted ? styles.iconBlack : styles.iconWhite
              }
            />
          </Fab>
          &nbsp;&nbsp;&nbsp;
          <a
            href="#"
            onClick={() => {
              window.location.href = `tel:${marker.contact.internationalPhoneNumber}`;
            }}
          >
            <Fab style={styles.fabGreen}>
              <PhoneIcon style={styles.iconWhite} />
            </Fab>
          </a>
        </div>
      </DialogTitle>
      <DialogContent
        style={{
          marginTop: 16,
        }}
      >
        {showDeleteDialog && (
          <ConfirmDelete
            onCancel={() => {
              setShowDeleteDialog(false);
            }}
              onDelete={handleDelete}
          />
        )}

        <Box style={{ display: "flex", flexWrap: "wrap", marginBottom: 8 }}>
          {formData.tags &&
            formData.tags.length > 0 &&
            formData.tags.map((tag: any, index: number) => (
              <div style={{ marginBottom: 6 }} key={`${tag}-${index}`}>
                <Tag
                  isEditable={true}
                  key={`${Math.random() * 1000000000}${index}`}
                  name={tag._id.split("#")[0]}
                  color={"#" + tag._id.split("_tag")[0].split("#")[1]}
                  onRemove={() => handleRemoveTag(index)}
                />
                &nbsp;&nbsp;
              </div>
            ))}
        </Box>

        <ColorPicker
          visible={showColorPicker}
          currentColor={currentColorSelected}
          currentColorIndex={currentColorIndex}
          handleChangeColor={handleChangeColor}
          currentTagIndex={currentTagIndex}
        />

        <CompanyInfo marker={marker} />
        {formData.detail.currentOpeningHours && (
          <ScheduleButton
            setFormData={setFormData}
            formData={formData}
            cb={() => {
              setIsFetch(!isFetch);
            }}
          />
        )}

        <Box display="flex" flexDirection="column">
          {selectedOption !== "roadside assistance" && (
            <Box>
              <Typography variant="subtitle2">Company category:</Typography>
              <Dropdown
                options={COMPANY_CATEGORIES}
                onChange={(value: any) => setSelectedOption(value)}
                value={selectedOption}
                icon="service"
              />
            </Box>
          )}

          <Box flex={"inline-flex"} mt={3}>
            <Checkbox
              checked={formData.contact.reachable}
              onChange={(event) => {
                const isChecked = event.target.checked;

                setFormData({
                  ...formData,
                  contact: {
                    ...formData.contact,
                    reachable: isChecked,
                  },
                });

                updateRechable(isChecked);
              }}
              color="success"
            />
            <small>Reachable</small>

            <Checkbox
              style={{ marginLeft: 25 }}
              checked={formData.basePrices.hasConfirmedPrices}
              onChange={(event) => {
                const isChecked = event.target.checked;

                setFormData({
                  ...formData,
                  basePrices: {
                    ...formData.basePrices,
                    hasConfirmedPrices: isChecked,
                  },
                });

                updateHasConfirmedPrices(isChecked);
              }}
              color="success"
            />
            <small>Has confirmed prices</small>

            <Checkbox
              style={{ marginLeft: 25 }}
              checked={formData.contact.smsEnabled}
              onChange={(event) => {
                const isChecked = event.target.checked;

                setFormData({
                  ...formData,
                  contact: {
                    ...formData.contact,
                    smsEnabled: isChecked,
                  },
                });

                updateHasEneableSms(isChecked);
              }}
              color="success"
            />
            <small>Is Eneable</small>

            <Checkbox
              style={{ marginLeft: 25 }}
              checked={formData.isFeatured}
              onChange={(event) => {
                const isChecked = event.target.checked;

                setFormData({
                  ...formData,
                  isFeatured: isChecked,
                });

                updateIsFeatured(isChecked);
              }}
              color="success"
            />
            <small>Is Featured</small>

            <Checkbox
              style={{ marginLeft: 25 }}
              checked={formData.isVerified}
              onChange={(event) => {
                const isChecked = event.target.checked;

                setFormData({
                  ...formData,
                  isVerified: isChecked,
                });

                updateIsVerified(isChecked);
              }}
              color="success"
            />
            <small>Is Verify</small>

            <Checkbox
              style={{ marginLeft: 25 }}
              checked={formData.isGovernmentInstitution}
              onChange={(event) => {
                const isChecked = event.target.checked;

                setFormData({
                  ...formData,
                  isGovernmentInstitution: isChecked,
                });

                updateIsGovernmentInstitution(isChecked);
              }}
              color="success"
            />
            <small>Is Gov Inst</small>
          </Box>
        </Box>
        {formData.updates && formData.updates.length > 0 && (
          <div
            style={{
              backgroundColor: "#191919",
              padding: "13px 12px",
              marginTop: 8,
              borderRadius: 8,
              marginBottom: 8,
            }}
          >
            <Typography mb={2} style={styles.subtitle}>
              Last Contact
            </Typography>
            <Box
              sx={{
                display: "flex",
              }}
              gap={1}
              mb={3}
            >
              <Avatar></Avatar>
              <Box sx={styles.flexColumn}>
                {[{ label: "Email", key: "email" }].map((item) => (
                  <Typography key={item.key} style={{ color: "#c0c0c0" }}>
                    {renderTypography(item)}
                  </Typography>
                ))}
              </Box>
              <Typography style={styles.spanDate}>
                {renderTypography({ label: "Date", key: "created" })}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <b style={{ color: "#fff", fontWeight: 700, fontSize: 12 }}>
                Notes:
              </b>
              <Typography
                variant="body1"
                color={"secondary"}
                style={{ opacity: 0.55 }}
              >
                {renderTypography({ label: "Notes", key: "notes" })}
              </Typography>
            </Box>
          </div>
        )}
        {formData.hasContacted && (
          <CustomCard handleSetContact={handleSetContact} formData={formData} />
        )}

        <br />
        <div style={styles.flexColumn}>
          <Button
            onClick={() => {
              setFormData((prevData: any) => ({
                ...prevData,
                basePrices: { ...basePrices },
              }));
            }}
            style={{
              height: 32,
              width: 120,
              marginBottom: "8px",
            }}
            variant="outlined"
            color="secondary"
          >
            Clear Prices
          </Button>

          {selectedOption === "roadside assistance" && (
            <div style={styles.flexColumn}>
              <Box sx={styles.flexColumnCenter}>
                <Box mb={2} mt={3} display={"flex"} alignItems={"center"}>
                  <span style={styles.defaultSubService}>Towing prices</span>
                  <Checkbox
                    style={{ marginLeft: 4 }}
                    checked={showTowPrices}
                    onChange={(event) => {
                      setShowTowPrices(event.target.checked);
                    }}
                    color="info"
                  />
                </Box>
                {showTowPrices && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                      flexDirection: "column",
                    }}
                  >
                    {[
                      {
                        label: "Standard",
                        type: "standard",
                        check: showStandardTowPrices,
                        change: setShowStandardTowPrices,
                      },
                      {
                        label: "Flatbed",
                        type: "flatbed",
                        check: showFlatbedTowPrices,
                        change: setShowFlatbedTowPrices,
                      },
                      {
                        label: "Heavy",
                        type: "heavy",
                        check: showHeavydTowPrices,
                        change: setShowHeavydTowPrices,
                      },
                    ].map((towType) => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          {" "}
                          <Box display={"flex"} alignItems={"center"} mb={2}>
                            <span>{towType.label}</span>{" "}
                            <Checkbox
                              style={{ marginLeft: 4 }}
                              checked={
                                variantCheckboxes[
                                  "towing" as keyof CheckboxForm
                                ]?.[towType.type] || false
                              }
                              onChange={() =>
                                handleVariantCheck(
                                  "towing" as keyof CheckboxForm,
                                  towType.type
                                )
                              }
                              color="secondary"
                            />
                          </Box>
                          {variantCheckboxes["towing" as keyof CheckboxForm]?.[
                            towType.type
                          ] && (
                            <Box mb={2}>
                              <TextField
                                color="secondary"
                                sx={styles.inputMedium}
                                label={` Base Price($)`}
                                variant="outlined"
                                type="number"
                                value={
                                  formData["basePrices"]["towing"][
                                    towType.type
                                  ]["basePrice"] || ""
                                }
                                onChange={(e) => {
                                  const newValue =
                                    e.target.value === "" ? 0 : e.target.value;

                                  setFormData((prevData: any) => ({
                                    ...prevData,
                                    basePrices: {
                                      ...prevData.basePrices,
                                      towing: {
                                        ...prevData.basePrices.towing,
                                        [towType.type]: {
                                          ...prevData.basePrices.towing[
                                            towType.type
                                          ],
                                          basePrice: newValue,
                                        },
                                      },
                                    },
                                  }));
                                }}
                              />
                              &nbsp;&nbsp;
                              <TextField
                                color="secondary"
                                sx={styles.inputMedium}
                                label={`Hourly Rate ($)`}
                                variant="outlined"
                                type="number"
                                value={
                                  formData["basePrices"]["towing"][
                                    towType.type
                                  ]["hourlyRate"] || ""
                                }
                                onChange={(e) => {
                                  const newValue =
                                    e.target.value === "" ? 0 : e.target.value;

                                  setFormData((prevData: any) => ({
                                    ...prevData,
                                    basePrices: {
                                      ...prevData.basePrices,
                                      towing: {
                                        ...prevData.basePrices.towing,
                                        [towType.type]: {
                                          ...prevData.basePrices.towing[
                                            towType.type
                                          ],
                                          hourlyRate: newValue,
                                        },
                                      },
                                    },
                                  }));
                                }}
                              />
                              &nbsp;&nbsp;
                              <TextField
                                color="secondary"
                                sx={styles.inputMedium}
                                label={`Mileage Rate ($)`}
                                variant="outlined"
                                type="number"
                                value={
                                  formData["basePrices"]["towing"][
                                    towType.type
                                  ]["mileageRate"] || ""
                                }
                                onChange={(e) => {
                                  const newValue =
                                    e.target.value === "" ? 0 : e.target.value;

                                  setFormData((prevData: any) => ({
                                    ...prevData,
                                    basePrices: {
                                      ...prevData.basePrices,
                                      towing: {
                                        ...prevData.basePrices.towing,
                                        [towType.type]: {
                                          ...prevData.basePrices.towing[
                                            towType.type
                                          ],
                                          mileageRate: newValue,
                                        },
                                      },
                                    },
                                  }));
                                }}
                              />
                            </Box>
                          )}
                        </Box>{" "}
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </div>
          )}

          {selectedOption === "roadside assistance" && (
            <Box mt={2} sx={styles.flexColumnCenter}>
              <span style={styles.toggleTitle}>
                General Roadside assistance
              </span>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                  flexDirection: "column",
                  flexGrow: 1,
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    flexDirection: "column",
                    flexGrow: 1,
                    width: "100%",
                  }}
                  mt={2}
                >
                  <Box display={"flex"} alignItems={"center"} mb={1}>
                    <span style={styles.defaultSubService}>Refuel</span>
                    <Checkbox
                      style={{ marginLeft: 4 }}
                      checked={showRefuelPrices}
                      onChange={(event) => {
                        setShowRefuelPrices(event.target.checked);
                      }}
                      color="info"
                    />
                  </Box>
                  {showRefuelPrices && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "start",
                        flexWrap: "wrap",
                        flexGrow: 1,
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box display={"flex"} alignItems={"center"} mb={2}>
                          <span>Diesel</span>{" "}
                          <Checkbox
                            style={{ marginLeft: 4 }}
                            checked={
                              variantCheckboxes[
                                "refuel" as keyof CheckboxForm
                              ]?.["diesel"] || false
                            }
                            onChange={() =>
                              handleVariantCheck(
                                "refuel" as keyof CheckboxForm,
                                "diesel"
                              )
                            }
                            color="secondary"
                          />
                        </Box>
                        {variantCheckboxes["refuel" as keyof CheckboxForm]?.[
                          "diesel"
                        ] && (
                          <TextField
                            color="secondary"
                            sx={styles.inputMedium}
                            label="Diesel ($)"
                            variant="outlined"
                            value={
                              formData["basePrices"]["refuel"]["diesel"][
                                "basePrice"
                              ] || ""
                            }
                            onChange={(e) => {
                              const newValue =
                                e.target.value === "" ? 0 : e.target.value;

                              setFormData((prevData: any) => ({
                                ...prevData,
                                basePrices: {
                                  ...prevData.basePrices,
                                  refuel: {
                                    ...prevData.basePrices.refuel,
                                    diesel: {
                                      basePrice: newValue,
                                    },
                                  },
                                },
                              }));
                            }}
                          />
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box display={"flex"} alignItems={"center"} mb={2}>
                          <span>Midgrade</span>{" "}
                          <Checkbox
                            style={{ marginLeft: 4 }}
                            checked={
                              variantCheckboxes[
                                "refuel" as keyof CheckboxForm
                              ]?.["midgradeGasoline"] || false
                            }
                            onChange={() =>
                              handleVariantCheck(
                                "refuel" as keyof CheckboxForm,
                                "midgradeGasoline"
                              )
                            }
                            color="secondary"
                          />
                        </Box>
                        {variantCheckboxes["refuel" as keyof CheckboxForm]?.[
                          "midgradeGasoline"
                        ] && (
                          <TextField
                            color="secondary"
                            sx={styles.inputMedium}
                            label="Midgrade ($)"
                            variant="outlined"
                            value={
                              formData["basePrices"]["refuel"][
                                "midgradeGasoline"
                              ]["basePrice"] || ""
                            }
                            onChange={(e) => {
                              const newValue =
                                e.target.value === "" ? 0 : e.target.value;

                              setFormData((prevData: any) => ({
                                ...prevData,
                                basePrices: {
                                  ...prevData.basePrices,
                                  refuel: {
                                    ...prevData.basePrices.refuel,
                                    midgradeGasoline: {
                                      basePrice: newValue,
                                    },
                                  },
                                },
                              }));
                            }}
                          />
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box display={"flex"} alignItems={"center"} mb={2}>
                          <span>Premium</span>{" "}
                          <Checkbox
                            style={{ marginLeft: 4 }}
                            checked={
                              variantCheckboxes[
                                "refuel" as keyof CheckboxForm
                              ]?.["premiumGasoline"] || false
                            }
                            onChange={() =>
                              handleVariantCheck(
                                "refuel" as keyof CheckboxForm,
                                "premiumGasoline"
                              )
                            }
                            color="secondary"
                          />
                        </Box>

                        {variantCheckboxes["refuel" as keyof CheckboxForm]?.[
                          "premiumGasoline"
                        ] && (
                          <TextField
                            color="secondary"
                            sx={styles.inputMedium}
                            label="Premium ($)"
                            variant="outlined"
                            value={
                              formData["basePrices"]["refuel"][
                                "premiumGasoline"
                              ]["basePrice"] || ""
                            }
                            onChange={(e) => {
                              const newValue =
                                e.target.value === "" ? 0 : e.target.value;

                              setFormData((prevData: any) => ({
                                ...prevData,
                                basePrices: {
                                  ...prevData.basePrices,
                                  refuel: {
                                    ...prevData.basePrices.refuel,
                                    premiumGasoline: {
                                      basePrice: newValue,
                                    },
                                  },
                                },
                              }));
                            }}
                          />
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box display={"flex"} alignItems={"center"} mb={2}>
                          <span>Regular</span>{" "}
                          <Checkbox
                            style={{ marginLeft: 4 }}
                            checked={
                              variantCheckboxes[
                                "refuel" as keyof CheckboxForm
                              ]?.["regularGasoline"] || false
                            }
                            onChange={() =>
                              handleVariantCheck(
                                "refuel" as keyof CheckboxForm,
                                "regularGasoline"
                              )
                            }
                            color="secondary"
                          />
                        </Box>
                        {variantCheckboxes["refuel" as keyof CheckboxForm]?.[
                          "regularGasoline"
                        ] && (
                          <TextField
                            color="secondary"
                            sx={styles.inputMedium}
                            label="Regular ($)"
                            variant="outlined"
                            value={
                              formData["basePrices"]["refuel"][
                                "regularGasoline"
                              ]["basePrice"] || ""
                            }
                            onChange={(e) => {
                              const newValue =
                                e.target.value === "" ? 0 : e.target.value;

                              setFormData((prevData: any) => ({
                                ...prevData,
                                basePrices: {
                                  ...prevData.basePrices,
                                  refuel: {
                                    ...prevData.basePrices.refuel,
                                    regularGasoline: {
                                      basePrice: newValue,
                                    },
                                  },
                                },
                              }));
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    flexDirection: "column",
                    flexGrow: 1,
                    width: "100%",
                  }}
                >
                  <Box display={"flex"} alignItems={"center"} mb={1} mt={2}>
                    <span style={styles.defaultSubService}>Battery</span>
                    <Checkbox
                      style={{ marginLeft: 4 }}
                      checked={showBatteryPrices}
                      onChange={(event) => {
                        setShowBatteryPrices(event.target.checked);
                      }}
                      color="info"
                    />
                  </Box>
                  {showBatteryPrices && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "start",
                        flexGrow: 1,
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box display={"flex"} alignItems={"center"} mb={2}>
                          <span>Jump start</span>
                          <Checkbox
                            style={{ marginLeft: 4 }}
                            checked={
                              variantCheckboxes[
                                "battery" as keyof CheckboxForm
                              ]?.["jump"] || false
                            }
                            onChange={() =>
                              handleVariantCheck(
                                "battery" as keyof CheckboxForm,
                                "jump"
                              )
                            }
                            color="secondary"
                          />
                        </Box>
                        {variantCheckboxes["battery" as keyof CheckboxForm]?.[
                          "jump"
                        ] && (
                          <TextField
                            sx={styles.inputHalf}
                            label="Jump start ($)"
                            variant="outlined"
                            color="secondary"
                            type="number"
                            value={
                              formData["basePrices"]["battery"]["jump"][
                                "basePrice"
                              ] || ""
                            }
                            onChange={(e) => {
                              const newValue =
                                e.target.value === "" ? 0 : e.target.value;

                              setFormData((prevData: any) => ({
                                ...prevData,
                                basePrices: {
                                  ...prevData.basePrices,
                                  battery: {
                                    ...prevData.basePrices.battery,
                                    jump: {
                                      basePrice: newValue,
                                    },
                                  },
                                },
                              }));
                            }}
                          />
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box display={"flex"} alignItems={"center"} mb={2}>
                          <span>New Battery</span>
                          <Checkbox
                            style={{ marginLeft: 4 }}
                            checked={
                              variantCheckboxes[
                                "battery" as keyof CheckboxForm
                              ]?.["replacement"] || false
                            }
                            onChange={() =>
                              handleVariantCheck(
                                "battery" as keyof CheckboxForm,
                                "replacement"
                              )
                            }
                            color="secondary"
                          />
                        </Box>
                        {variantCheckboxes["battery" as keyof CheckboxForm]?.[
                          "replacement"
                        ] && (
                          <TextField
                            sx={styles.inputHalf}
                            label="New Battery ($)"
                            variant="outlined"
                            color="secondary"
                            type="number"
                            value={
                              formData["basePrices"]["battery"]["replacement"][
                                "new"
                              ]["basePrice"] || ""
                            }
                            onChange={(e) => {
                              const newValue =
                                e.target.value === "" ? 0 : e.target.value;

                              setFormData((prevData: any) => ({
                                ...prevData,
                                basePrices: {
                                  ...prevData.basePrices,
                                  battery: {
                                    ...prevData.basePrices.battery,
                                    replacement: {
                                      ...prevData.basePrices.battery
                                        .replacement,
                                      new: {
                                        basePrice: newValue,
                                      },
                                    },
                                  },
                                },
                              }));
                            }}
                          />
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box display={"flex"} alignItems={"center"} mb={2}>
                          <span>Used Battery</span>
                          <Checkbox
                            style={{ marginLeft: 4 }}
                            checked={
                              variantCheckboxes[
                                "battery" as keyof CheckboxForm
                              ]?.["replacement"] || false
                            }
                            onChange={() =>
                              handleVariantCheck(
                                "battery" as keyof CheckboxForm,
                                "replacement"
                              )
                            }
                            color="secondary"
                          />
                        </Box>
                        {variantCheckboxes["battery" as keyof CheckboxForm]?.[
                          "replacement"
                        ] && (
                          <TextField
                            sx={styles.inputHalf}
                            label="Used Battery ($)"
                            variant="outlined"
                            color="secondary"
                            type="number"
                            value={
                              formData["basePrices"]["battery"]["replacement"][
                                "used"
                              ]["basePrice"] || ""
                            }
                            onChange={(e) => {
                              const newValue =
                                e.target.value === "" ? 0 : e.target.value;

                              setFormData((prevData: any) => ({
                                ...prevData,
                                basePrices: {
                                  ...prevData.basePrices,
                                  battery: {
                                    ...prevData.basePrices.battery,
                                    replacement: {
                                      ...prevData.basePrices.battery
                                        .replacement,
                                      used: {
                                        basePrice: newValue,
                                      },
                                    },
                                  },
                                },
                              }));
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box sx={styles.flexColumnGrow}>
                  <Box display={"flex"} alignItems={"center"} mb={1} mt={2}>
                    <span style={styles.defaultSubService}>Unlock</span>{" "}
                    <Checkbox
                      style={{ marginLeft: 4 }}
                      checked={showUnlockPrices}
                      onChange={(event) => {
                        setShowUnlockPrices(event.target.checked);
                      }}
                      color="info"
                    />
                  </Box>
                  {showUnlockPrices && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "start",
                        flexGrow: 1,
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box display={"flex"} alignItems={"center"} mb={2}>
                          <span>Jammed Locks</span>
                          <Checkbox
                            style={{ marginLeft: 4 }}
                            checked={
                              variantCheckboxes[
                                "unlock" as keyof CheckboxForm
                              ]?.["jammedLocks"] || false
                            }
                            onChange={() =>
                              handleVariantCheck(
                                "unlock" as keyof CheckboxForm,
                                "jammedLocks"
                              )
                            }
                            color="secondary"
                          />
                        </Box>
                        {variantCheckboxes["unlock" as keyof CheckboxForm]?.[
                          "jammedLocks"
                        ] && (
                          <TextField
                            sx={styles.inputHalf}
                            label="JammedLocks ($)"
                            variant="outlined"
                            color="secondary"
                            type="number"
                            value={
                              formData["basePrices"]["unlock"]["jammedLocks"][
                                "basePrice"
                              ] || ""
                            }
                            onChange={(e) => {
                              const newValue =
                                e.target.value === "" ? 0 : e.target.value;

                              setFormData((prevData: any) => ({
                                ...prevData,
                                basePrices: {
                                  ...prevData.basePrices,
                                  unlock: {
                                    ...prevData.basePrices.unlock,
                                    jammedLocks: {
                                      basePrice: newValue,
                                    },
                                  },
                                },
                              }));
                            }}
                          />
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box display={"flex"} alignItems={"center"} mb={2}>
                          <span>Lost keys</span>
                          <Checkbox
                            style={{ marginLeft: 4 }}
                            checked={
                              variantCheckboxes[
                                "unlock" as keyof CheckboxForm
                              ]?.["lostKeys"] || false
                            }
                            onChange={() =>
                              handleVariantCheck(
                                "unlock" as keyof CheckboxForm,
                                "lostKeys"
                              )
                            }
                            color="secondary"
                          />
                        </Box>
                        {variantCheckboxes["unlock" as keyof CheckboxForm]?.[
                          "lostKeys"
                        ] && (
                          <TextField
                            sx={styles.inputHalf}
                            label="lostKeys ($)"
                            variant="outlined"
                            color="secondary"
                            type="number"
                            value={
                              formData["basePrices"]["unlock"]["lostKeys"][
                                "basePrice"
                              ] || ""
                            }
                            onChange={(e) => {
                              const newValue =
                                e.target.value === "" ? 0 : e.target.value;

                              setFormData((prevData: any) => ({
                                ...prevData,
                                basePrices: {
                                  ...prevData.basePrices,
                                  unlock: {
                                    ...prevData.basePrices.unlock,
                                    lostKeys: {
                                      basePrice: newValue,
                                    },
                                  },
                                },
                              }));
                            }}
                          />
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Box display={"flex"} alignItems={"center"} mb={2}>
                          <span>Lockout</span>
                          <Checkbox
                            style={{ marginLeft: 4 }}
                            checked={
                              variantCheckboxes[
                                "unlock" as keyof CheckboxForm
                              ]?.["lockedOut"] || false
                            }
                            onChange={() =>
                              handleVariantCheck(
                                "unlock" as keyof CheckboxForm,
                                "lockedOut"
                              )
                            }
                            color="secondary"
                          />
                        </Box>
                        {variantCheckboxes["unlock" as keyof CheckboxForm]?.[
                          "lockedOut"
                        ] && (
                          <TextField
                            sx={styles.inputHalf}
                            label="lockedOut ($)"
                            variant="outlined"
                            color="secondary"
                            type="number"
                            value={
                              formData["basePrices"]["unlock"]["lockedOut"][
                                "basePrice"
                              ] || ""
                            }
                            onChange={(e) => {
                              const newValue =
                                e.target.value === "" ? 0 : e.target.value;

                              setFormData((prevData: any) => ({
                                ...prevData,
                                basePrices: {
                                  ...prevData.basePrices,
                                  unlock: {
                                    ...prevData.basePrices.unlock,
                                    lockedOut: {
                                      basePrice: newValue,
                                    },
                                  },
                                },
                              }));
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    flexDirection: "column",
                    flexGrow: 1,
                    width: "100%",
                  }}
                >
                  <Box display={"flex"} alignItems={"center"} mb={1} mt={2}>
                    <span style={styles.defaultSubService}>Tire Change</span>
                    <Checkbox
                      style={{ marginLeft: 4 }}
                      checked={showTirePrices}
                      onChange={(event) => {
                        setShowTirePrices(event.target.checked);
                      }}
                      color="info"
                    />
                  </Box>
                  {showTirePrices && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "start",
                        flexGrow: 1,
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box display={"flex"} alignItems={"center"} mb={2}>
                          <span>Spare Tire</span>
                          <Checkbox
                            style={{ marginLeft: 4 }}
                            checked={
                              variantCheckboxes[
                                "tire" as keyof CheckboxForm
                              ]?.["spareTire"] || false
                            }
                            onChange={() =>
                              handleVariantCheck(
                                "tire" as keyof CheckboxForm,
                                "spareTire"
                              )
                            }
                            color="secondary"
                          />
                        </Box>
                        {variantCheckboxes[
                          "tire" as keyof CheckboxForm
                        ]?.["spareTire"] && (
                          <TextField
                            sx={styles.inputHalf}
                            label="Spare tire ($)"
                            variant="outlined"
                            color="secondary"
                            type="number"
                            value={
                              formData["basePrices"]["tire"]["spareTire"][
                                "basePrice"
                              ] || ""
                            }
                            onChange={(e) => {
                              const newValue =
                                e.target.value === "" ? 0 : e.target.value;

                              setFormData((prevData: any) => ({
                                ...prevData,
                                basePrices: {
                                  ...prevData.basePrices,
                                  tire: {
                                    ...prevData.basePrices.tire,
                                    spareTire: {
                                      basePrice: newValue,
                                    },
                                  },
                                },
                              }));
                            }}
                          />
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box display={"flex"} alignItems={"center"} mb={2}>
                          <span>New Tire</span>
                          <Checkbox
                            style={{ marginLeft: 4 }}
                            checked={
                              variantCheckboxes[
                                "tire" as keyof CheckboxForm
                              ]?.["newTire"] || false
                            }
                            onChange={() =>
                              handleVariantCheck(
                                "tire" as keyof CheckboxForm,
                                "newTire"
                              )
                            }
                            color="secondary"
                          />
                        </Box>
                        {variantCheckboxes[
                          "tire" as keyof CheckboxForm
                        ]?.["newTire"] && (
                          <TextField
                            sx={styles.inputHalf}
                            label="New tire ($)"
                            variant="outlined"
                            color="secondary"
                            type="number"
                            value={
                              formData["basePrices"]["tire"][
                                "tireReplacement"
                              ]["new"]["basePrice"] || ""
                            }
                            onChange={(e) => {
                              const newValue =
                                e.target.value === "" ? 0 : e.target.value;

                              setFormData((prevData: any) => ({
                                ...prevData,
                                basePrices: {
                                  ...prevData.basePrices,
                                  tire: {
                                    ...prevData.basePrices.tire,

                                    tireReplacement: {
                                      ...prevData.basePrices.tire
                                        .tireReplacement,

                                      new: {
                                        basePrice: newValue,
                                      },
                                    },
                                  },
                                },
                              }));
                            }}
                          />
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box display={"flex"} alignItems={"center"} mb={2}>
                          <span>Used Tire</span>
                          <Checkbox
                            style={{ marginLeft: 4 }}
                            checked={
                              variantCheckboxes[
                                "tire" as keyof CheckboxForm
                              ]?.["newTire"] || false
                            }
                            onChange={() =>
                              handleVariantCheck(
                                "tire" as keyof CheckboxForm,
                                "newTire"
                              )
                            }
                            color="secondary"
                          />
                        </Box>
                        {variantCheckboxes[
                          "tire" as keyof CheckboxForm
                        ]?.["newTire"] && (
                          <TextField
                            sx={styles.inputHalf}
                            label="Used tire ($)"
                            variant="outlined"
                            color="secondary"
                            type="number"
                            value={
                              formData["basePrices"]["tire"][
                                "tireReplacement"
                              ]["used"]["basePrice"] || ""
                            }
                            onChange={(e) => {
                              const newValue =
                                e.target.value === "" ? 0 : e.target.value;

                              setFormData((prevData: any) => ({
                                ...prevData,
                                basePrices: {
                                  ...prevData.basePrices,
                                  tire: {
                                    ...prevData.basePrices.tire,

                                    tireReplacement: {
                                      ...prevData.basePrices.tire
                                        .tireReplacement,

                                      used: {
                                        basePrice: newValue,
                                      },
                                    },
                                  },
                                },
                              }));
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}
          {selectedOption === "carwash" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
              mt={3}
            >
              <Box display={"flex"} alignItems={"center"} mb={2}>
                <span style={styles.toggleTitle}>Mobile Car Wash</span>
              </Box>
              {mobileCarWashes.map((data: any) => (
                <Box
                  key={JSON.stringify(data)}
                  mt={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {" "}
                  <Box display={"flex"} alignItems={"center"} mb={2}>
                    <span style={styles.defaultSubService}> {data.name}</span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <TextField
                      color="secondary"
                      sx={styles.inputCarWash}
                      label={`Exterior ($)`}
                      variant="outlined"
                      type="number"
                      value={
                        formData["basePrices"]["carWash"][data.name][
                          "exterior"
                        ]["basePrice"] || ""
                      }
                      onChange={(e) => {
                        const newValue =
                          e.target.value === "" ? 0 : e.target.value;

                        setFormData((prevData: any) => ({
                          ...prevData,
                          basePrices: {
                            ...prevData.basePrices,
                            carWash: {
                              ...prevData.basePrices.carWash,
                              [data.name]: {
                                ...prevData.basePrices.carWash[data.name],
                                exterior: {
                                  basePrice: newValue,
                                },
                              },
                            },
                          },
                        }));
                      }}
                    />
                    &nbsp;
                    <TextField
                      color="secondary"
                      sx={styles.inputCarWash}
                      label={`Interior ($)`}
                      variant="outlined"
                      type="number"
                      value={
                        formData["basePrices"]["carWash"][data.name][
                          "interior"
                        ]["basePrice"] || ""
                      }
                      onChange={(e) => {
                        const newValue =
                          e.target.value === "" ? 0 : e.target.value;

                        setFormData((prevData: any) => ({
                          ...prevData,
                          basePrices: {
                            ...prevData.basePrices,
                            carWash: {
                              ...prevData.basePrices.carWash,
                              [data.name]: {
                                ...prevData.basePrices.carWash[data.name],
                                interior: {
                                  basePrice: newValue,
                                },
                              },
                            },
                          },
                        }));
                      }}
                    />
                    &nbsp;
                    <TextField
                      color="secondary"
                      sx={styles.inputCarWash}
                      label={`Mini Detail ($)`}
                      variant="outlined"
                      type="number"
                      value={
                        formData["basePrices"]["carWash"][data.name][
                          "miniDetail"
                        ]["basePrice"] || ""
                      }
                      onChange={(e) => {
                        const newValue =
                          e.target.value === "" ? 0 : e.target.value;

                        setFormData((prevData: any) => ({
                          ...prevData,
                          basePrices: {
                            ...prevData.basePrices,
                            carWash: {
                              ...prevData.basePrices.carWash,
                              [data.name]: {
                                ...prevData.basePrices.carWash[data.name],
                                miniDetail: {
                                  basePrice: newValue,
                                },
                              },
                            },
                          },
                        }));
                      }}
                    />
                    &nbsp;
                    <TextField
                      color="secondary"
                      sx={styles.inputCarWash}
                      label={`Full Detail ($)`}
                      variant="outlined"
                      type="number"
                      value={
                        formData["basePrices"]["carWash"][data.name][
                          "fullDetail"
                        ]["basePrice"] || ""
                      }
                      onChange={(e) => {
                        const newValue =
                          e.target.value === "" ? 0 : e.target.value;

                        setFormData((prevData: any) => ({
                          ...prevData,
                          basePrices: {
                            ...prevData.basePrices,
                            carWash: {
                              ...prevData.basePrices.carWash,
                              [data.name]: {
                                ...prevData.basePrices.carWash[data.name],
                                fullDetail: {
                                  basePrice: newValue,
                                },
                              },
                            },
                          },
                        }));
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </div>
      </DialogContent>

      <DialogActions style={{ marginTop: 16 }}>
        <Button
          style={styles.btnCancel}
          onClick={() => {
            handleClose();

            setIsFetch(!isFetch);
          }}
        >
          <span style={styles.spanCancel}>Cancel</span>
        </Button>

        <Button style={styles.btnSave} onClick={handleSave} color="primary">
          <span style={styles.spanSave}>Save</span>
        </Button>
      </DialogActions>
      {showSpeacityDialog && <DialogSpecialty />}
    </Dialog>
  );
};

export default PriceCompanyDialog;
