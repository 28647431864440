import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import UserRequests from "requests/userRequests";
import VehicleRequests from "requests/vehicleRequests";
const vehicleRequests = new VehicleRequests();
type Props = {
  id: string;
  collection:string;
};

export default function AlertDialog({ id, collection }: Props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {

      if(collection === "clients") {
        await vehicleRequests.delete(id);
      } else {

      } await vehicleRequests.deleteModel(id);
   
      alert("Vehicle deleted successfully");
      setOpen(false);
    } catch (err) {
      alert("Couldn't delete this vehicle");
      setOpen(false);
    }
  };

  

  return (
    <div>
      <Button color={"error"} variant="outlined" onClick={handleClickOpen}>
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this item forever?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Make sure you don't use this data <b>{id}</b> for anything
            important, because after you delete this item you can't acess it
            anymore.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={handleDelete}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
