import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  InputLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import VehicleRequests from "requests/vehicleRequests";
import { BODY_STYLES } from "utils/const";
const vehicleRequests = new VehicleRequests();

interface AddVehicleDialogProps {
  open: boolean;
  onClose: () => void;
  currentVehicle?: any;
}

const AddVehicleDialog: React.FC<AddVehicleDialogProps> = ({
  open,
  onClose,
  currentVehicle,
}) => {
  const [startYear, setStartYear] = useState<number | null>(null);
  const [endYear, setEndYear] = useState<number | null>(null);
  const [make, setMake] = useState<string>("");
  const [model, setModel] = useState<string>("");
  const [selectedBodyStyles, setSelectedBodyStyles] = useState<string[]>([]);
  const [vehicle, setVehicle] = useState<any>(null);

  const clearAll = () => {
    setStartYear(null);
    setEndYear(null);
    setMake("");
    setModel("");
    setSelectedBodyStyles([]);
    setVehicle(null);
  };

  useEffect(() => {
    setVehicle(currentVehicle);
  }, [currentVehicle]);

  const handleSave = async () => {
    if (startYear && endYear && make && model && selectedBodyStyles.length > 0) {
      const years = [];
      for (let year = startYear; year <= endYear; year++) {
        years.push(year);
      }

      const promises = [];
      for (const year of years) {
        for (const bodyStyle of selectedBodyStyles) {
          promises.push(
            vehicleRequests.create_vehicle_model({
              year,
              make,
              model,
              bodyStyle,
            })
          );
        }
      }

      try {
        await Promise.all(promises);
        alert(`Vehicles successfully added`);
        clearAll();
        onClose();
      } catch (err) {
        alert("Error creating vehicles");
      }
    }
  };

  const handleUpdate = async () => {
    if (vehicle._id && startYear && endYear && make && model && selectedBodyStyles.length > 0) {
      const years = [];
      for (let year = startYear; year <= endYear; year++) {
        years.push(year);
      }

      const promises = [];
      for (const year of years) {
        for (const bodyStyle of selectedBodyStyles) {
          promises.push(
            vehicleRequests.update_vehicle_model({
              id: vehicle._id,
              year,
              make,
              model,
              bodyStyle,
            })
          );
        }
      }

      try {
        await Promise.all(promises);
        alert(`Vehicles successfully updated`);
        clearAll();
        onClose();
      } catch (err) {
        alert("Error updating vehicles");
      }
    }
  };

  const handleSubmit = async () => {
    try {
      if (!vehicle) {
        await handleSave();
        return;
      }
      await handleUpdate();
    } catch (err) {
      alert(JSON.stringify(err));
    }
  };

  useEffect(() => {
    if (vehicle) {
      setInitialFormData();
    } else {
      clearAll();
    }
  }, [vehicle]);

  const setInitialFormData = () => {
    setMake(vehicle.make);
    setModel(vehicle.model);
    setStartYear(vehicle.year); // Assuming `year` is the start year
    setEndYear(vehicle.year); // Assuming `year` is the end year
    setSelectedBodyStyles([vehicle.bodyStyle]);
  };

  const handleBodyStyleChange = (bodyStyle: string) => {
    setSelectedBodyStyles((prevSelectedBodyStyles) =>
      prevSelectedBodyStyles.includes(bodyStyle)
        ? prevSelectedBodyStyles.filter((style) => style !== bodyStyle)
        : [...prevSelectedBodyStyles, bodyStyle]
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{vehicle ? "Edit" : "Add"} New Vehicle</DialogTitle>
      <DialogContent>
        <TextField
          label="Make"
          value={make}
          onChange={(e) => setMake(e.target.value)}
          fullWidth
          margin="normal"
          color="secondary"
          id="make_textfield_1"
        />
        <TextField
          label="Model"
          value={model}
          onChange={(e) => setModel(e.target.value)}
          fullWidth
          margin="normal"
          color="secondary"
          id="model_textfield_1"
        />
        <TextField
          label="Start Year"
          type="number"
          value={startYear}
          onChange={(e) => setStartYear(parseInt(e.target.value))}
          fullWidth
          margin="normal"
          color="secondary"
          id="start_year_textfield_1"
        />
        <TextField
          label="End Year"
          type="number"
          value={endYear}
          onChange={(e) => setEndYear(parseInt(e.target.value))}
          fullWidth
          margin="normal"
          color="secondary"
          id="end_year_textfield_1"
        />
        <FormControl component="fieldset" fullWidth margin="normal">
          <label htmlFor="">Body styles</label>
          <FormGroup>
            {BODY_STYLES.map((bodyStyle) => (
              <FormControlLabel
                key={bodyStyle}
                control={
                  <Checkbox
                    checked={selectedBodyStyles.includes(bodyStyle)}
                    onChange={() => handleBodyStyleChange(bodyStyle)}
                    name={bodyStyle}
                  />
                }
                label={bodyStyle}
              />
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" color="warning" onClick={handleSubmit}>
          {vehicle ? "Edit" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddVehicleDialog;
