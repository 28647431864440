import axios from "axios";
import { getHostPythonAPIBaseUrl } from "config";

class PaymentRequests {
  async getPaymentsPagination(
    page: number,
    pageSize: number,
    dateFilter: string,
    startDate: Number,
    endDate: Number
  ) {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/stripe/payment?page=${page}&limit=${pageSize}&date_filter=${dateFilter}&start_date=${startDate}&end_date=${endDate}`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error);
    }
  }

  async getPayoutsPagination(
    page: number,
    pageSize: number,
    dateFilter: string,
    startDate: Number,
    endDate: Number
  ) {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/stripe/payout?page=${page}&limit=${pageSize}&date_filter=${dateFilter}&start_date=${startDate}&end_date=${endDate}`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error);
    }
  }
}
export default PaymentRequests;
