import axios from "axios";
import { getHostPythonAPIBaseUrl } from "config";

class AdminRequests {
  async login(email: string, password: string) {
    try {
      const formData = new FormData();
      formData.append("username", email);
      formData.append("password", password);

      const url = `${getHostPythonAPIBaseUrl()}/auth/login`;
      return await axios.postForm(url, formData);
    } catch (error: any) {
      throw error.response.data;  // Lança o erro recebido do backend
    }
  }

  async register(email: string, password: string, fullname: string, phone_number: string) {
    const url = `${getHostPythonAPIBaseUrl()}/auth/signup`;
    const data = {
      email,
      fullName: fullname,
      role: "super admin",
      isActive: true,
      phoneNumber: phone_number.trim(),
      password
    };

    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error: any) {
      throw error.response.data;  // Lança o erro recebido do backend
    }
  }

  async register_invite(email: string, password: string, fullname: string, phone_number: string, seed: string) {
    const url = `${getHostPythonAPIBaseUrl()}/auth/signup/invite`;
    try {
      const response = await axios.post(url, {
        "email": email,
        "fullName": fullname,
        "role": "super admin",
        "isActive": true,
        "phoneNumber": phone_number,
        "password": password,
        "seed": seed
      });
      return response.data;
    } catch (error: any) {
      throw error.response.data;
    }
  }


  async getMe() {
    const url = `${getHostPythonAPIBaseUrl()}/auth/me`;
    try {
      return await axios.get(url, {
        headers: {
          "Authorization": localStorage.getItem("access_token"),
        },
      });
    } catch (error: any) {
      throw error.response.data;
    }
  }
  async getAll() {
    const url = `${getHostPythonAPIBaseUrl()}/admin/all`;

    return await axios.get(url, {
      headers: {
        "Authorization": localStorage.getItem("access_token"),
      },
    });
  }

  async delete(email: string) {
    try {
      const url = `${getHostPythonAPIBaseUrl()}/admin/?user_email=${email}`;
      const response = await axios.delete(url, {
        headers: {
          "Authorization": localStorage.getItem("access_token"),
        },
      });
      return response.data; // Assuming you want to return data from the response
    } catch (error) {
      // Handle error
      console.error('Error deleting user:', error);
      throw new Error('Failed to delete user');
    }
  }

  async invite(email: string, role: string) {
    try {
      const url = `${getHostPythonAPIBaseUrl()}/auth/invite`;
      const response = await axios.post(url, {
        email, role
      }, {
        headers: {
          "Authorization": localStorage.getItem("access_token"),
        },
      });
      return response.data; // Assuming you want to return data from the response
    } catch (error) {
      // Handle error
      console.error('Error sending invitation:', error);
      throw new Error('Failed to send invitation');
    }
  }


  async sendRecoverPassword(email: string) {
    try {
      const url = `${getHostPythonAPIBaseUrl()}/auth/send/password/recover?email=${email}`;
      const response = await axios.post(url);
      return response.data; // Assuming you want to return data from the response
    } catch (error) {
      // Handle error
      console.error('Error sending password recovery email:', error);
      throw new Error('Failed to send password recovery email');
    }
  }

  async changePassword(seed: string, userId: string, password: string) {
    try {
      const url = `${getHostPythonAPIBaseUrl()}/auth/password/recover`;
      const response = await axios.post(url, {
        "password": password,
        "seed": seed,
        "userId": userId
      });
      return response.data; // Assuming you want to return data from the response
    } catch (error) {
      // Handle error
      console.error('Error changing password:', error);
      throw new Error('Failed to change password');
    }
  }
}





export default AdminRequests;
