import axios from "axios";
import { getHostPythonAPIBaseUrl } from "config";
// const { IP_BACKEND_HOST } = require('../config/env');

class ChatBotRequests {
  async createVectoreStore(formData: any) {
    const url = `${getHostPythonAPIBaseUrl()}/chatbot/vector-store`;
    const token = localStorage.getItem("access_token");
    if (!token) {
      alert("No access token");
    }
    try {
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: token,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error saving vector store", error);
      throw error;
    }
  }

  async listVectoreStores() {
    const url = `${getHostPythonAPIBaseUrl()}/chatbot/list/vector-stores`;
    const token = localStorage.getItem("access_token");
    if (!token) {
      alert("No access token");
    }
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error getting vector stores", error);
      throw error;
    }
  }

  async listFiles() {
    const url = `${getHostPythonAPIBaseUrl()}/chatbot/files`;
    const token = localStorage.getItem("access_token");
    if (!token) {
      alert("No access token");
    }
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error getting vector stores", error);
      throw error;
    }
  }

  async listVectorStoreFiles(vectorStoreId: string) {
    const url = `${getHostPythonAPIBaseUrl()}/chatbot/files-from-vs?limit=50&order=desc&vector_store_id=${vectorStoreId}`;
    const token = localStorage.getItem("access_token");
    if (!token) {
      alert("No access token");
    }
    try {
      const response = await axios.get(
        url,

        {
          headers: {
            Authorization: token,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error getting vector stores", error);
      throw error;
    }
  }

  async listMaiaBrainItems(page: number, pageSize: number) {
    const url = `${getHostPythonAPIBaseUrl()}/chatbot/maia_brain?page=${page}&limit=${pageSize}`;
    const token = localStorage.getItem("access_token");
    if (!token) {
      alert("No access token");
    }
    try {
      const response = await axios.get(
        url,

        {
          headers: {
            Authorization: token,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error getting vector stores", error);
      throw error;
    }
  }

  async addQuestionAndAnswer(user_message: String, assistant_message: String) {
    const url = `${getHostPythonAPIBaseUrl()}/chatbot/add?user_message=${user_message}&assistant_message=${assistant_message}`;
    const token = localStorage.getItem("access_token");
    if (!token) {
      alert("No access token");
    }
    try {
      const response = await axios.put(url, {
        headers: {
          Authorization: token,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error saving question and answer in GPT models", error);
      throw error;
    }
  }

  async getDataSet(page: Number, limit: Number) {
    const url = `${getHostPythonAPIBaseUrl()}/chatbot/read?page=${page}&limit=${limit}&`;
    const token = localStorage.getItem("access_token");
    if (!token) {
      alert("No access token");
    }
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error saving question and answer in GPT models", error);
      throw error;
    }
  }

  async sendMessage(message: string) {
    const url = `${getHostPythonAPIBaseUrl()}/chatbot?message=${message}`;
    const token = localStorage.getItem("access_token");
    if (!token) {
      alert("No access token");
    }
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error saving question and answer in GPT models", error);
      throw error;
    }
  }
  async deleteQuestion(question: string) {
    const url = `${getHostPythonAPIBaseUrl()}/chatbot/delete`;
    const token = localStorage.getItem("access_token");
    if (!token) {
      alert("No access token");
    }
    try {
      const payload = {
        question,
      };
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: token,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error saving question and answer in GPT models", error);
      throw error;
    }
  }

  async deleteVectorStore(id: string) {
    const url = `${getHostPythonAPIBaseUrl()}/chatbot/vector-store?id=${id}`;
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: token,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error on deleting vector store", error);
      throw error;
    }
  }

  async deleteMaiaBrain(id: string) {
    const url = `${getHostPythonAPIBaseUrl()}/chatbot/maia_brain/delete-by-id?id=${id}`;
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: token,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error on deleting vector store", error);
      throw error;
    }
  }

  async deleteFile(id: string) {
    const url = `${getHostPythonAPIBaseUrl()}/chatbot/files?id=${id}`;
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: token,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error on deleting vector store", error);
      throw error;
    }
  }

  async associateFileToVectorStore(vectorStoreId: string, fileId: string) {
    const url = `${getHostPythonAPIBaseUrl()}/chatbot/add-file-to-vs`;
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.post(
        url,
        {
          file_id: fileId,
          vector_store_id: vectorStoreId,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error on deleting vector store", error);
      throw error;
    }
  }

  async disassociateFileFromVectorStore(vectorStoreId: string, fileId: string) {
    const url = `${getHostPythonAPIBaseUrl()}/chatbot/del-file-from-vs?vector_store_id=${vectorStoreId}&file_id=${fileId}`;
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: token,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error on deleting vector store", error);
      throw error;
    }
  }

  async uploadFile(vectorStoreId: string, formData: FormData) {
    let url = `${getHostPythonAPIBaseUrl()}/chatbot/upload-files`;
    if (vectorStoreId) {
      url += `?vector_store_id=${vectorStoreId}`;
    }
    const token = localStorage.getItem("access_token");

    try {
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error uploading files to vector store", error);
      throw error;
    }
  }

  async addItemMaiaBrain(formData: any) {
    let url = `${getHostPythonAPIBaseUrl()}/chatbot/maia_brain/create`;
    console.log(formData);
    const token = localStorage.getItem("access_token");

    try {
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: token,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error uploading files to vector store", error);
      throw error;
    }
  }

  async updateMaiaBrainItem(id: string, formData: any) {
    const url = `${getHostPythonAPIBaseUrl()}/chatbot/maia_brain/update-by-id?id=${id}`;
    const token = localStorage.getItem("access_token");
    if (!token) {
      alert("No access token");
    }
    try {
      const response = await axios.put(url, formData, {
        headers: {
          Authorization: token,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error saving question and answer in GPT models", error);
      throw error;
    }
  }
}
export default ChatBotRequests;
