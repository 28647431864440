import { useState } from "react";
import ServiceRequests from "../requests/serviceRequests";
import UserRequests from "../requests/userRequests";
import { DashboardViewModel } from "view_models/dashbordViewModel";
import axios from "axios";
import { getHostPythonAPIBaseUrl } from "config";
import CompanyRequests from "requests/companyRequests";
import { formattedDate } from "utils/helper";
// import { IP_BACKEND_HOST } from "config/env";


const columns = [
  {
    field: "_id",
    headerName: "ID",
    flex: 0.5,
  },
  {
    field: "user",
    headerName: "User ID",
    flex: 0.5,
  },
  {
    field: "specialist",
    headerName: "Specialist ID",
    flex: 0.5,
  },
  {
    field: "serviceType",
    headerName: "Service Type",
    flex: 0.5,
  },
  {
    field: "location",
    headerName: "Client Location",
    flex: 1,
    renderCell: (params: any) => {
      if (params.value) {
        return `${params.value.pick}`;
      }
      return "";
    },
  },

  {
    field: "createdAt",
    headerName: "CreatedAt",
    flex: 0.5,
  },
];

const useDashboardViewModel = (): DashboardViewModel => {
  // Atributes 
  const [serviceRequests, setServiceRequests] = useState(new ServiceRequests());
  const [userRequests, setUserRequests] = useState(new UserRequests());
  const [companyRequests, setCompanyRequests] = useState(new CompanyRequests());
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [emitSearch, setEmitSearch] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [serviceType, setServiceType] = useState("all");
  const [timeType, setTimeType] = useState("Year");
  const [pageSizes, setPageSizes] = useState([8, 16, 32, 64]);
  const [serviceStatus, setServiceStatus] = useState({});
  const [isFetchData, setIsFetchData] = useState<boolean>(false);
  const [dateFilter, setDateFilter] = useState("All");
  const [totalFiltered,setTotalFiltered] = useState<Number>(0)
  const [dateStartFilter, setDateStartFilter] = useState<Number>(0);
  const [dateEndFilter, setDateEndFilter] = useState<Number>(0);
  const [userStatus, setUserStatus] = useState({});
  const [companyStatus, setCompanyStatus] = useState({});
  const [rowId, setRowId] = useState(null);
  const [overviewData, setOverviewData] = useState({});
  const [overviewUser, setOverviewUser] = useState({});
  const [columnsState, setColumnsState] = useState(columns);
  const [currentTab, setCurrentTab] = useState<number>(3);
  const [pieServicesChart, setPieServicesChart] = useState<any>({});
  const [pieUserChart, setPieUserChart] = useState<any>({});
  const [pieCompanyChart, setPieCompanyChart] = useState<any>({});
  const [absoluteTotalServices, setAbsoluteTotalServices] = useState(0);
  const [absoluteTotalUsers, setAbsoluteTotalUsers] = useState(0);
  const [absoluteTotalCompanies, setAbsoluteTotalCompanies] = useState(0);
  const [absoluteTotalPlatform, setAbsoluteTotalPlatform] = useState(0);
  const [absoluteTotalQuery, setAbsoluteTotalQuery] = useState(0);
  //Methods
  function handleTimeChange(type: string) {
    setOverviewData([]);
    setTimeType(type);
  };

  async function handlePageChange(newPage: number) {
    setPage(newPage);
  }

  async function handleRowsPerPageChange(event: any) {
    setPageSize(event.target.value);

  }

  async function fetchPaginationData() {
    try {
      if (currentTab === 1) {
        await fetchServices()
      } else if (currentTab === 2) {
        await fetchUsers()
      } else if (currentTab === 3) {
        await fetchCompanies()
      }

    } catch (error) {
      console.error(error);

    }
  }

  async function fetchServices() {
    const paginationData = await serviceRequests.getPaginationCompanyCall(
      page,
      pageSize,
      dateFilter,
      dateStartFilter,
      dateEndFilter,
      searchTerm
    );
    setData(paginationData.companies)
    setTotalPages(paginationData.totalPages);
    setTotalFiltered(paginationData.total);
  }

  async function fetchUsers() {
    const paginationData = await userRequests.getPagination(
      "all",
      page,
      pageSize,
      dateFilter,
      dateStartFilter,
      dateEndFilter,
      searchTerm
    );
    setData(paginationData.users)
    setTotalPages(paginationData.totalPages);
    setTotalFiltered(paginationData.total);
  }

  async function fetchCompanies() {
    const paginationData = await companyRequests.getPagination(
      page,
      pageSize,
      dateFilter,
      dateStartFilter,
      dateEndFilter,
      searchTerm
    );
    setData(paginationData.companies)
    setTotalPages(paginationData.totalPages);
    setTotalFiltered(paginationData.total);
  }



  async function fetchPieServiceChartData() {
    let pieChart = await serviceRequests.pieServiceChartData();
    setPieServicesChart(pieChart);
  }

  async function fetchPieUserChartData() {
    let pieChart = await userRequests.pieUserChartData();
    let count = pieChart.count

    setAbsoluteTotalPlatform(count['webUsers'] + count['iosUsers'] + count['androidUsers'])

    setPieUserChart({
      "Web": count['webUsers'],
      "IOS": count['iosUsers'],
      "Android": count['androidUsers'],
    })
  }

  async function fetchPieCompanyChartData() {
    try {
      // Fetch pie chart data asynchronously
      const pieChart = await companyRequests.pieChartData();

      let total = 0;

      for (const key in pieChart) {
        if (Object.prototype.hasOwnProperty.call(pieChart, key)) {

          total += pieChart[key];
        }
      }
      setPieCompanyChart(pieChart);
      setAbsoluteTotalQuery(total)
    } catch (error) {
      console.error('Error fetching pie chart data:', error);
      // Handle error as needed (e.g., show error message)
    }
  }


  async function fetchOverviewData() {
    try {
      let overviewData = null;
      overviewData = await serviceRequests.overviewChartService();
      setOverviewData(overviewData.payload)
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchOverviewUser() {
    try {
      let overviewUser = null;
      if (timeType === "Year") {
        overviewUser = await userRequests.overviewChartUser();
      } else if (timeType === "Month") {
        overviewUser = await userRequests.overviewChartUser();
      } else if (timeType === "Week") {
        overviewUser = await userRequests.overviewChartUser();
      }

      setOverviewUser(overviewUser.payload)
    } catch (error) {
      console.error(error);
    }
  }


  async function fetchServiceStatus() {
    try {
      const promises = [
        serviceRequests.getTotal("year"),
        serviceRequests.getTotal("day"),
        serviceRequests.getTotal("month"),
        serviceRequests.getAbsolute()
      ];

      const [totalYear, totalDay, totalMonth, absolute] = await Promise.all(promises);

      const incrementDay = totalDay.current - totalDay.last;
      const incrementMonth = totalMonth.current - totalMonth.last;
      const incrementYear = totalYear.current - totalYear.last;
      const updatedData = {
        today: totalDay.current,
        currentMonth: totalMonth.current,
        currentYear: totalYear.current,
        incrementMonth,
        incrementDay,
        incrementYear
      };

      setServiceStatus(updatedData);

      setAbsoluteTotalServices(absolute)
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchUserStatus() {
    try {
      const promises = [
        userRequests.getTotal("year"),
        userRequests.getTotal("day"),
        userRequests.getTotal("month"),
        userRequests.getAbsolute()
      ];

      const [totalYear, totalDay, totalMonth, absolute] = await Promise.all(promises);

      const incrementDay = totalDay.current - totalDay.last;
      const incrementMonth = totalMonth.current - totalMonth.last;
      const incrementYear = totalYear.current - totalYear.last;


      const updatedData = {
        today: totalDay.current,
        currentMonth: totalMonth.current,
        currentYear: totalYear.current,
        incrementMonth,
        incrementDay,
        incrementYear
      };

      setUserStatus(updatedData);
      setAbsoluteTotalUsers(absolute)
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchCompanyStatus() {
    try {
      const promises = [
        companyRequests.getTotal("year"),
        companyRequests.getTotal("day"),
        companyRequests.getTotal("month"),
        companyRequests.getAbsolute()
      ];

      const [totalYear, totalDay, totalMonth, absolute] = await Promise.all(promises);

      const incrementDay = totalDay.current - totalDay.last;
      const incrementMonth = totalMonth.current - totalMonth.last;
      const incrementYear = totalYear.current - totalYear.last;

      const updatedData = {
        today: totalDay.current,
        currentMonth: totalMonth.current,
        currentYear: totalYear.current,
        incrementMonth,
        incrementDay,
        incrementYear
      };

      setCompanyStatus(updatedData);
      setAbsoluteTotalCompanies(absolute)
    } catch (error) {
      console.error(error);
    }
  }



  const dataGridStyle = () => {
    return {
      border: "none",
      "& .MuiDataGrid-root": {
        border: "0px solid black",
      },
      "& .MuiDataGrid-columnHeaders": {
        p: "12px 15px",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "8px 8px 0px 0px",
        background: "#191919",
        color: "#7E7E7E",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        letterSpacing: "-0.14px",
        border: "none",
      },
      "& .MuiDataGrid-columnSeparator": {
        visibility: "hidden",
      },
      "& .MuiDataGrid-cell": {
        borderBottom: "none",
      },
      ".MuiDataGrid-cell:focus-within": {
        outline: "none",
      },
      "& .MuiDataGrid-row": {
        background: "black",
        p: "10px 15px",
        alignItems: "center",
        border: `0.5px solid #3E3E3E`,
        minHeight: "60px",
      },
    }
  }



  return {
    columnsState,
    data,
    isLoading,
    overviewData,
    page,
    pageSize,
    pageSizes,
    rowId,
    serviceRequests,
    serviceStatus,
    serviceType,
    setColumnsState,
    setData,
    setIsLoading,
    setOverviewData,
    setPage,
    setPageSize,
    setPageSizes,
    setRowId,
    setServiceRequests,
    setServiceStatus,
    setServiceType,
    setTimeType,
    setTotalPages,
    setUserRequests,
    setUserStatus,
    setCompanyStatus,
    companyStatus,
    timeType,
    totalPages,
    userRequests,
    userStatus,
    fetchUserStatus,
    fetchServiceStatus,
    fetchOverviewData,
    fetchPaginationData,
    handleRowsPerPageChange,
    handlePageChange,
    handleTimeChange,
    fetchPieServiceChartData,
    pieServicesChart,
    setEmitSearch,
    setSearchTerm,
    searchTerm,
    emitSearch,
    dataGridStyle,
    absoluteTotalServices,
    currentTab,
    setCurrentTab,
    absoluteTotalUsers,
    absoluteTotalCompanies,
    setAbsoluteTotalCompanies,
    setAbsoluteTotalUsers,
    fetchOverviewUser,
    overviewUser,
    fetchPieUserChartData,
    pieUserChart,
    absoluteTotalPlatform,
    fetchCompanyStatus,
    fetchPieCompanyChartData,
    absoluteTotalQuery,
    setAbsoluteTotalQuery,
    pieCompanyChart,
    setDateEndFilter,
    dateEndFilter,
    setDateStartFilter,
    dateStartFilter,
    setDateFilter,
    dateFilter,
    setIsFetchData,
    isFetchData,
    setTotalFiltered,
    totalFiltered
  };
}

export default useDashboardViewModel;