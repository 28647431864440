import React, { useState, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, Button } from '@chakra-ui/react';
import axios from 'axios';

const NotificationAsking: React.FC = () => {
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    const checkNotificationPermission = async () => {
      if ("Notification" in window) {
        if (Notification.permission === "default") {
          // Notifications have not been granted or denied yet
          setShowModal(true);
        } else if (Notification.permission === "denied") {
          // Notifications have been denied
          setShowModal(true);
        } else {
          // Notifications have been granted
          setShowModal(true);
        }
      } else {
        console.log("Browser does not support notifications.");
      }
    };

    checkNotificationPermission();
  }, []);

  const handleEnableNotifications = async () => {
    if ("Notification" in window) {
      try {
        const permission = await Notification.requestPermission();
        console.log("Permission ->",permission)
        setShowModal(false);
        if (permission === 'granted') {
          console.log('Notifications enabled.');
          const deviceToken = await getDeviceToken();
          console.log("deviceToken ->",deviceToken)
          if (deviceToken) {
            console.log('Device Token:', deviceToken);

            await sendDeviceTokenToOneSignal(deviceToken);
          } else {
            console.log('Failed to get device token.');
          }

          setShowModal(false);
        } else {
          console.log('Notifications permission denied.');
        }
      } catch (error) {
        console.error('Error requesting notification permission:', error);
      }
    }
  };

  const getDeviceToken = async (): Promise<string | null> => {
    try {
      const registration = await navigator.serviceWorker.ready;
      console.log('Service Worker registration:', registration); // Add this line
      if (registration.active) {
        const messageChannel = new MessageChannel();
        return await new Promise((resolve) => {
          messageChannel.port1.onmessage = (event) => {
            if (event.data?.type === 'DEVICE_TOKEN') {
              console.log('Device Token received:', event.data.token);
              resolve(event.data.token);
            } else {
              console.log('Received message:', event.data);
              resolve(null);
            }
          };
          registration.active?.postMessage({ type: 'GET_DEVICE_TOKEN' }, [messageChannel.port2]);
        });
      }
    } catch (error) {
      console.error('Service Worker not ready:', error);
      return null;
    }
    console.log('Service Worker not supported');
    return null;
  };
  
  const sendDeviceTokenToOneSignal = async (deviceToken: string) => {
    const ONESIGNAL_APP_ID='517eb430-bfdb-41e8-b7bd-b7afe1c32cfd'
    const ONESIGNAL_APP_KEY_TOKEN='YjkzMjA1MmEtMzdkZi00NGFiLWFhMTYtMzAzZDdlMjVkZTJm'
    
    try {
      await axios.post('https://onesignal.com/api/v1/players', {
        app_id: ONESIGNAL_APP_ID,
        device_type: 1, 
        identifier: deviceToken,
      }, {
        headers: {
          Authorization: `Basic ${ONESIGNAL_APP_KEY_TOKEN}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Device token sent to OneSignal successfully.');
    } catch (error) {
      console.error('Error sending device token to OneSignal:', error);
    }
  };

  return (
    <>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <ModalOverlay />
        <ModalContent style={{ position: 'absolute', backgroundColor: 'black', marginTop: '200px', marginLeft: '300px', width: '50%', border: 'solid white', padding: '10px' }}>
          <ModalBody>
            We recommend you allow notifications for the best experience.
          </ModalBody>

          <ModalFooter>
            <Button border='solid' width='80px' padding={'4px'} colorScheme="blue" mr={3} onClick={handleEnableNotifications}>
              Enable
            </Button>
            <Button variant="ghost" width='80px' border='solid' padding={'4px'} onClick={() => setShowModal(false)}>Later</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NotificationAsking;
