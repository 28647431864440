import React, { useState, useEffect, useMemo } from "react";
import DeleteActions from "./DeleteActions";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  useTheme,
  // Avatar,
  Box,
  Button,
  ButtonGroup,
  Pagination,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import VehicleRequests from "requests/vehicleRequests";
import AddVehicleDialog from "./addVehicleDialog";
import { BODY_STYLES, BRANDS, all_years } from "utils/const";
import SearchBar from "components/SearchBar/SearchBar";

type Column = {
  field: string;
  headerName: string;
  flex: number;
  hide?: boolean;
  valueGetter?: (params: any) => string;
};

const buttonStyles = {
  display: "flex",
  width: "210px",
  height: "48px",
  padding: "13px 16px",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,
  borderRadius: "16px",
  background: "var(--Orange-Orange-900, #FF7C06)",
  color: "#000",
  textTransform: "none" as const,
  fontFamily: "Inter",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  letterSpacing: "-0.15px",
};

const makes: string[] = BRANDS
const Vehicles = () => {
  const theme = useTheme();
  const [selectedMake, setSelectedMake] = useState<string | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [currentItem, setCurrentItem] = useState<any | null>(null);
  const [selectedBodyStyle, setSelectedBodyStyle] = useState<string | null>(
    null
  );
  const [vehicleTab, setVehicleTab] = useState<string>("clients");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(8);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [data, setData] = useState([]);
  const [currentId, setCurrentId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rowId, setRowId] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState<string | null>(null)
  const[emitSearch,setEmitSearch] = useState<boolean>(false)
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const pageSizes = [8, 16, 32, 64];

  const handleMakeChange = (event: any) => {
    setSelectedMake(event.target.value as string);
  };

  const handleYearChange = (event: any) => {
    setSelectedYear(event.target.value as number);
  };

  const handleBodyStyleChange = (event: any) => {
    setSelectedBodyStyle(event.target.value as string);
  };
  useEffect(() => {
    console.log(searchTerm)
  },[searchTerm])

  const columnsVehicles = useMemo(
    () => [
      {
        field: "_id",
        headerName: "Vehicle ID",
        flex: 0.2,
      },
      {
        field: "userId",
        headerName: "User ID",
        flex: 0.2,
      },

      {
        field: "model",
        headerName: "Model",
        flex: 0.1,
      },
      {
        field: "make",
        headerName: "Make",
        flex: 0.1,
      },

      {
        field: "plate",
        headerName: "Plate",
        flex: 0.1,
      },
      {
        field: "delete",
        headerName: "Delete forever",
        flex: 0.1,
        renderCell: (params: any) => (
          <DeleteActions  collection={vehicleTab} id={"delete-vehicle-model-forever-1"} />
        ),
      },
    ],
    []
  );

  const columnsVehiclesModels = useMemo(
    () => [
      {
        field: "_id",
        headerName: "Vehicle ID",
        flex: 0.2,
      },
      {
        field: "make",
        headerName: "Make",
        flex: 0.2,
      },

      {
        field: "model",
        headerName: "Model",
        flex: 0.1,
      },
      {
        field: "year",
        headerName: "Year",
        flex: 0.1,
      },

      {
        field: "bodyStyle",
        headerName: "Body Style",
        flex: 0.2,
      },
     
      {
        field: "delete",
        headerName: "Delete forever",
        flex: 0.1,
        renderCell: (params: any) => (
          <DeleteActions collection={vehicleTab} id={params.row._id} />
        ),
      },
    ],
    []
  );

  const handleEditItemClick = async (data: any) => {
    setCurrentItem(data);
    setAddDialogOpen(true);
  };

  const fetchVehicles = async () => {
    const vehicleRequests = new VehicleRequests();
    try {
      const paginationData = await vehicleRequests.getPagination(
        vehicleTab,
        page,
        pageSize
      );
      setData(paginationData.vehicles);
      setTotalPages(paginationData.totalPages);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchVehiclesModels = async () => {
    const vehicleRequests = new VehicleRequests();
    try {
      let paginationData:any = null as any
      if(!searchTerm) {
         paginationData = await vehicleRequests.getPaginationVehicleModels(
          {
            bodyStyle: selectedBodyStyle,
            make: selectedMake,
            year: selectedYear,
            model: null,
          },
          page,
          pageSize
        );
      } else {
        paginationData = await vehicleRequests.getPaginationSearchVehicleModels(
          searchTerm,
          page,
          pageSize
        );
      }
     
      setData(paginationData.vehicleModels);
      setTotalPages(paginationData.totalPages);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {

    if (vehicleTab === "clients") {
      fetchVehicles();
    } else {
      fetchVehiclesModels();
    }
  }, [
    pageSize,
    page,
    vehicleTab,
    selectedBodyStyle,
    selectedYear,
    selectedMake,
    emitSearch,
  ]);

  const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
    // if event.target.value is not a number, return
    if (isNaN(event.target.value as number)) return;
    setPageSize(event.target.value as number);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };
  const handleCustomerChange = (type: string) => {
    setVehicleTab(type);
    setPage(1);
  };
  const handleSearch = () => {
    setEmitSearch(!emitSearch);
  }
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="VEHICLES" subtitle="List of all vehicles" />
      <Box display="flex" flexDirection="column">
        <ButtonGroup variant="outlined">
          <Button
            onClick={() => handleCustomerChange("clients")}
            sx={{
              backgroundColor:
                vehicleTab === "clients"
                  ? // @ts-ignore
                    theme.palette.secondary[300]
                  : // @ts-ignore
                    theme.palette.primary[600],
              color:
                vehicleTab === "clients"
                  ? theme.palette.primary.main
                  : // @ts-ignore
                    theme.palette.secondary[100],
              ":hover": {
                backgroundColor:
                  vehicleTab === "clients"
                    ? // @ts-ignore
                      theme.palette.secondary[300]
                    : // @ts-ignore
                      theme.palette.secondary[900],
              },
            }}
          >
            CUSTOMERS / COMPANIES
          </Button>
          <Button
            onClick={() => handleCustomerChange("models")}
            sx={{
              backgroundColor:
                vehicleTab === "models"
                  ? // @ts-ignore
                    theme.palette.secondary[300]
                  : // @ts-ignore
                    theme.palette.primary[600],
              color:
                vehicleTab === "models"
                  ? theme.palette.primary.main
                  : // @ts-ignore
                    theme.palette.secondary[100],
              ":hover": {
                backgroundColor:
                  vehicleTab === "models"
                    ? // @ts-ignore
                      theme.palette.secondary[300]
                    : // @ts-ignore
                      theme.palette.secondary[900],
              },
            }}
          >
            VEHICLES ROADR API
          </Button>
        </ButtonGroup>
        <></>
        {vehicleTab !== "clients" && (
          <>
            <Box display={"flex"} maxWidth={"600px"} mt={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="make-select-label">Make</InputLabel>
                <Select
                  labelId="make-select-label"
                  value={selectedMake}
                  onChange={handleMakeChange}
                  label="Make"
                >
                  {makes.map((make) => (
                    <MenuItem key={make + "makes"} value={make}>
                      {make}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              &nbsp;&nbsp;
              <FormControl fullWidth margin="normal">
                <InputLabel id="year-select-label">Year</InputLabel>
                <Select
                  labelId="year-select-label"
                  value={selectedYear}
                  onChange={handleYearChange}
                  label="Year"
                >
                  {all_years().map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>{" "}
              &nbsp;&nbsp;
              <FormControl fullWidth margin="normal">
                <InputLabel id="body-style-select-label">Body Style</InputLabel>
                <Select
                  labelId="body-style-select-label"
                  value={selectedBodyStyle}
                  onChange={handleBodyStyleChange}
                  label="Body Style"
                >
                  {BODY_STYLES.map((bodyStyle) => (
                    <MenuItem key={bodyStyle + "styles"} value={bodyStyle}>
                      {bodyStyle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              &nbsp; &nbsp;&nbsp; &nbsp;
              <Button
                onClick={() => {
                  setSelectedBodyStyle(null);
                  setSelectedMake(null);
                  setSelectedYear(null);
                  setSearchTerm(null);
                }}
                style={{ height: 46, marginTop: "19px", width: 80 }}
                variant="outlined"
                color="secondary"
              >
                Clear
              </Button>
            </Box>
            <Box mt={3}>
              <SearchBar
                width="400px"
                inputCallback={(e:any) => setSearchTerm(e.target.value)}
                buttonCallback={() => handleSearch()}
                placeholder={"search vehicles..."}
                fetch={() =>fetchVehiclesModels()}
              ></SearchBar>
            </Box>
          </>
        )}
      </Box>
      {vehicleTab === "models" && (
        <Box width={"100%"} display={"flex"} justifyContent={"end"}>
          <Button
            style={buttonStyles}
            variant="contained"
            onClick={() => {
              setCurrentItem(null);
              setAddDialogOpen(true);
            }}
          >
            Add Vehicle Model &nbsp; <AddIcon></AddIcon>
          </Button>
        </Box>
      )}

      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            // @ts-ignore
            backgroundColor: theme.palette.background.alt,
            // @ts-ignore
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            // @ts-ignore
            backgroundColor: theme.palette.background.alt,
            // @ts-ignore
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            // @ts-ignore
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={!data || data.length === 0}
          // @ts-ignore
          getRowId={(row) => row._id}
          rows={data || []}
          columns={
            vehicleTab === "clients" ? columnsVehicles : columnsVehiclesModels
          }
          hideFooter
          pageSize={pageSize}
          onRowClick={(params) => {
            setRowId(params.id);
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, newPage) => handlePageChange(newPage)}
            size="large"
          />

          <div
            style={{
              marginLeft: 16,
              marginBottom: 16,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label style={{ fontSize: 9 }}> Items per page</label>
            <Select
              style={{
                marginTop: 4,
                width: 64,
                height: 28,
                border: `1px solid ${theme.palette.primary.light}`,
              }}
              value={pageSize}
              onChange={handleRowsPerPageChange}
            >
              {pageSizes.map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </Box>

      <AddVehicleDialog
        open={addDialogOpen}
        currentVehicle={currentItem}
        onClose={() => {
          setCurrentItem(null);
          setAddDialogOpen(false);
        }}
      ></AddVehicleDialog>
    </Box>
  );
};

export default Vehicles;
