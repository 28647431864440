import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { getHostPythonAPIBaseUrl } from "config";
import axios from "axios";
import ColorPicker from "./colorPicker";
interface Props {
  open: boolean;
  onClose: () => void;
}

const TagDialog: React.FC<Props> = ({ open, onClose }) => {
  const [inputValue, setInputValue] = useState("");
  const [currentColorSelected, setCurrentColorSelected] = useState<string>("");
  const [currentColorIndex, setCurrentIndex] = useState<number>(0);
  const colors = ["#1279F2", "#FF7C06", "#FF2D2D", "#34A853"];
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSave = async () => {
    const url = `${getHostPythonAPIBaseUrl()}/tag`;
    const payload = {
      name: inputValue,
      color: currentColorSelected,
    };
 
    await axios.post(url,payload, {
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    });
    onClose();
  };

  const handleClose = () => {
    // Optionally handle any cleanup logic before closing the dialog
    onClose();
  };

  const handleChangeColor = (color: string, index: number) => {
    setCurrentColorSelected(color);
    setCurrentIndex(index);
  };


  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Title</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="input"
          label="Enter tag"
          type="text"
          fullWidth
          value={inputValue}
          onChange={handleInputChange}
          color="secondary"
        />

        <div
          style={{
            borderRadius: "8px",
            border: "1px solid var(--dark-dark-600, #3E3E3E)",
            background: "var(--dark-dark-500, #191919)",
            display: "flex",
            padding: "16px",
            alignItems: "flex-start",
            gap: "8px",
            
          }}
        >
          {colors.map((c, index) => (
            <div
              onClick={() => {
                handleChangeColor(c, index);
              }}
              key={"color" + index + 'tag'}
            >
              <div
                style={{
                  borderRadius: "50%",
                  height: "19px",
                  width: "19px",
                  backgroundColor: c,
                  border:`${c === currentColorSelected ? '3px solid white' : 'none'} `

                }}
              ></div>
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="secondary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagDialog;
