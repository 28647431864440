import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

type Props = {
  status: string;
};

const Pill = ({ status }: Props) => {
  const color = status === "completed" ? "#34A853" : "#1279F2";
  const text = status === "completed" ? "Done" : "In-progress";
  return (
    <Box
      sx={{
        display: "flex",
        height: "20px", // Reduce the height
        padding: "1px 5px", // Reduce the padding
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "1px",
        flexShrink: "0",
        borderRadius: "15px", // Reduce the border-radius
        border: `1px solid ${color}`,
      }}
    >
      <Typography sx={{ fontSize: "9px" }} color={color}>
        {text}
      </Typography>{" "}
      {/* Reduce the font size */}
    </Box>
  );
};

export default Pill;
