import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { Box, Typography, useTheme } from "@mui/material";

type BreakdownChartProps = {
    isDashboard?: boolean;
    categories: any
}

const BreakdownChart: React.FC<BreakdownChartProps> = ({
  isDashboard = false,
  categories,
}) => {
  const data = {
    categories:  categories
   
  };

  const theme = useTheme();
  const generateGreyScaleColors = (numColors: number) => {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const shade = i / (numColors - 1); // Calculate the shade value between 0 and 1
      const greyValue = Math.floor(shade * 254) + 1; // Map shade to grey scale value avoiding pure white and pure black
      const greyColor = `rgb(${greyValue}, ${greyValue}, ${greyValue})`; // Create the RGB color
      colors.push(greyColor);
    }
    return colors;
  };
  
  const formattedData = Object.entries(data.categories).map(
    ([category, count], i) => ({
      id: category,
      label: category,
      value: count,
      color: generateGreyScaleColors(10)[i],
    })
  );
  const getLabelTextColor = (backgroundColor:string) => {
    const rgb = backgroundColor.slice(4, -1).split(',');
    const negativeRGB = rgb.map((value:string) => 255 - parseInt(value.trim(), 10)); 
    return `rgb(${negativeRGB.join(', ')})`; 
  };
  
  return (
    <Box
      height={!isDashboard ? "400px" : "100%"}
      width={undefined}
      minHeight={isDashboard ? "270x" : undefined}
      minWidth={isDashboard ? "270x" : undefined}
      position="relative"
    >
      <ResponsivePie
        data={formattedData}
        theme={{
          axis: {
            domain: {
              line: {
                // @ts-ignore
                stroke: theme.palette.secondary[200],
              },
            },
            legend: {
              text: {
                // @ts-ignore
                fill: theme.palette.secondary[200],
              },
            },
            ticks: {
              line: {
                // @ts-ignore
                stroke: theme.palette.secondary[200],
                strokeWidth: 1,
              },
              text: {
                // @ts-ignore
                fill: theme.palette.secondary[200],
              },
            },
          },
          legends: {
            text: {
              // @ts-ignore
              fill: theme.palette.background.alt2,
            },
          },
          tooltip: {
            container: {
              // @ts-ignore
              color: theme.palette.background.alt2,
            },
          },
        }}
        colors={{ datum: "data.color" }}
        margin={
          isDashboard
            ? { top: 20, right: 80, bottom: 100, left: 50 }
            : { top: 20, right: 80, bottom: 80, left: 80 }
        }
        sortByValue={true}
        innerRadius={0.45}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.4]],
        }}
        enableArcLinkLabels={false}
        // @ts-ignore
        arcLinkLabelsTextColor={theme.palette.neutral.main}
  
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={5}
        arcLabelsTextColor={`rgb(255,255,255)`}
        
        
      />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        // @ts-ignore
        color={theme.palette.secondary[100]}
        textAlign="center"
        // pointerEvents="none"
        sx={{
          transform: isDashboard
            ? "translate(-110%, -170%)"
            : "translate(-50%, -100%)",
        }}
      >
       
      </Box>
    </Box>
  );
};

export default BreakdownChart;
