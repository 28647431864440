import axios from "axios";
import { getHostPythonAPIBaseUrl } from "config";
// const { IP_BACKEND_HOST } = require('../config/env');

class CompanyRequests {

    async addCompanyAggregate(requestData: any) {
        const url = `${getHostPythonAPIBaseUrl()}/company/aggregate/create`;
        const token = localStorage.getItem("access_token");
        try {
            const response = await axios.post(url, requestData, {
                headers: {
                    Authorization: token,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error saving Aspira company:", error);
            throw error;
        }
    }
    async getAllCompanies() {
        const url = `${getHostPythonAPIBaseUrl()}/companies/all`;
        const token = localStorage.getItem("access_token");

        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: token,
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error getting the companyes", error);
            throw error; // Puedes manejar el error según tus necesidades
        }
    }

    async getAllWorkersCompany(companyId: string) {
        const url = `${getHostPythonAPIBaseUrl()}/companies/specialists_by_company_id?id=${companyId}`;
        const token = localStorage.getItem("access_token");

        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: token,
                },
            });

            return response.data.specialists;
        } catch (error) {
            console.error("Error al obtener los especialistas", error);
            throw error;
        }
    }

    async getCloseTo(
        lat: number,
        lng: number,
        tag: string,
        open: boolean,
        reachable: boolean,
        serviceName: string,
        category: string,
        variants: string[] | null,
        hasConfirmedPrices: boolean,
        query: string,
        hasEmail: boolean,
        smsEnabled: boolean,
    ) {

        const url = `${getHostPythonAPIBaseUrl()}/company/aggregate/nearest`;
        const payload = {
            "longitude": lng,
            "latitude": lat,
            "radius": 50,
            "isOpen": open,
            "tag": tag,
            "reachable": reachable,
            "hasConfirmedPrices": hasConfirmedPrices,
            "query": query,
            "hasEmail": hasEmail,
            "smsEnabled": smsEnabled
        } as any;

        if (category) {
            payload.service = category
        }


        if (serviceName) {
            payload.name = serviceName
        }


        if (variants && variants.length > 0) {
            payload.variants = variants
        }

        const token = localStorage.getItem("access_token");
        const response = await axios.post(url, payload, {
            headers: {
                Authorization: token,
            },
        });
        console.log(response.data);
        return response.data;
    }

    async saveAspiraCompany(requestData: any) {
        const url = `${getHostPythonAPIBaseUrl()}/company/aggregate/update/property`;
        const token = localStorage.getItem("access_token");
        try {
            const response = await axios.put(url, requestData, {
                headers: {
                    Authorization: token,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error saving Aspira company:", error);
            throw error;
        }
    }

    async updateData(requestData: any) {
        const url = `${getHostPythonAPIBaseUrl()}/company/aggregate/update/property`;
        const token = localStorage.getItem("access_token");
        try {
            const response = await axios.put(url, requestData, {
                headers: {
                    Authorization: token,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error saving Aspira company:", error);
            throw error;
        }
    }

    async update(requestData: any) {
        const url = `${getHostPythonAPIBaseUrl()}/company/aggregate/update`;
        const token = localStorage.getItem("access_token");
        try {
            const response = await axios.put(url, requestData, {
                headers: {
                    Authorization: token,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error saving Aspira company:", error);
            throw error;
        }
    }


    async getByPlaceId(placeId: string) {
        const url = `${getHostPythonAPIBaseUrl()}/aspira/companies/get-by-place-id?id=${placeId}`;
        const token = localStorage.getItem("access_token");
        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: token,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error saving Aspira company:", error);
            throw error;
        }
    }

    async getPagination(page: number, pageSize: number, dateFilter: string = "All",startDate: Number=0,endDate: Number=0, search: string | null = null) {
        let url = `${getHostPythonAPIBaseUrl()}/company/aggregate/paginated?page=${page}&limit=${pageSize}&date_filter=${dateFilter}&start_date=${startDate}&end_date=${endDate}`;
        if(search) {
            url += `&search=${encodeURI(search)}`
        }
        try {
            const token = localStorage.getItem("access_token");
            const response = await axios.get(url, {
                headers: {
                    Authorization: token,
                },
            });
            return response.data;
        } catch (error) {
            console.error("error : ", error);
        }
    }

    async deleteCompany(id: string) {
        const encodedId = encodeURIComponent(id);
        const url = `${getHostPythonAPIBaseUrl()}/company/aggregate?id=${encodedId}`;
        try {
            const token = localStorage.getItem("access_token");
            const response = await axios.delete(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error("error : ", error);
            throw error;
        }
    }

    async getTotal(period: string) {
        const token = localStorage.getItem("access_token");
        const url = `${getHostPythonAPIBaseUrl()}/count/company/aggregate?period=${period}`;
        try {
            const response = await axios.get(url, {
                headers: {
                    "Authorization": token,
                },
            });
            return response.data;
        } catch (error) {
            console.error("error : ", error)
        }
    }

    async pieChartData() {
        const token = localStorage.getItem("access_token");
        const url = `${getHostPythonAPIBaseUrl()}/count/company/aggregate/group`;
        try {
            const response = await axios.get(url, {
                headers: {
                    "Authorization": token,
                },
            });
            return response.data;
        } catch (error) {
            console.error("error : ", error)
        }
    }

    async getAbsolute() {
        const token = localStorage.getItem("access_token");
        const url = `${getHostPythonAPIBaseUrl()}/count/company/aggregate/absolute`;
        try {
            const response = await axios.get(url, {
                headers: {
                    "Authorization": token,
                },
            });
            return response.data;
        } catch (error) {
            console.error("error : ", error)
        }
    }



}
export default CompanyRequests;
