import React from "react";
import { styles } from "./stylesDilog";
import Dropdown from "scenes/companyMagangement/component/dropdown";
import { name, service, variant } from "./data";

const DialogSpecialty = () => {
    const [valueName, setValueName] = React.useState("");
    const [valueService, setValueService] = React.useState("");
    const [valueVariant, setValueVariant] = React.useState("");

    return (
        <div style={styles.ContainerSpecialty}>
            <div style={styles.FirstContainer}>
                <div>
                    <h1>Name</h1>
                    <Dropdown
                        options={name}
                        onChange={(value: string) => {
                            setValueName(value);
                        }}
                        value={valueName}
                        icon="service"
                        width="230px"
                    />
                </div>
                {valueName && (
                    <div>
                        <h1>Service</h1>
                        <Dropdown
                            options={
                                service[valueName as keyof typeof service] || []
                            }
                            onChange={(value: string) => {
                                setValueService(value);
                            }}
                            value={valueService}
                            icon="service"
                            width="230px"
                        />
                    </div>
                )}
            </div>
            {valueService && (
                <div style={styles.FirstContainer}>
                    <div>
                        <h1>Variant</h1>
                        <Dropdown
                            options={
                                variant[valueService as keyof typeof variant] ||
                                []
                            }
                            onChange={(value: string) => {
                                setValueVariant(value);
                            }}
                            value={valueVariant}
                            icon="service"
                            width="230px"
                        />
                    </div>
                    <div>
                        <h1>options</h1>
                        <input type="text" style={styles.Input} />
                    </div>
                </div>
            )}
            <div style={styles.FirstContainer}>
                <div>
                    <h1>offer</h1>
                    <input type="text" style={styles.Input} />
                </div>
                <div>
                    <h1>Variant</h1>
                    <input type="text" style={styles.Input} />
                </div>
            </div>
            <div></div>
        </div>
    );
};

export default DialogSpecialty;
