import { useEffect, useState } from "react";
import PaymentRequests from "../../requests/chatBotRequests";
import UserRequests from "../../requests/userRequests";
import { OpenIAViewModel } from "view_models/openIAViewModel";
import ChatBotRequests from "requests/chatBotRequests";

const useOpenAIViewModel = (): OpenIAViewModel => {
  // Atributes
  const [chatBotRequests, setChatBotRequests] = useState(new ChatBotRequests());
  const [userRequests, setUserRequests] = useState(new UserRequests());
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [serviceType, setServiceType] = useState("all");
  const [timeType, setTimeType] = useState("Year");
  const [pageSizes, setPageSizes] = useState([8, 16, 32, 64]);
  const [serviceStatus, setServiceStatus] = useState({});
  const [userStatus, setUserStatus] = useState({});
  const [rowId, setRowId] = useState(null);
  const [overviewData, setOverviewData] = useState({});
  const [dateFilter, setDateFilter] = useState("All");
  const [dateStartFilter, setDateStartFilter] = useState<Number>(0);
  const [dateEndFilter, setDateEndFilter] = useState<Number>(0);
  const [isDialogItemAddOpen, setIsDialogItemAddOpen] =
    useState<boolean>(false);
  const [isFetchData, setIsFetchData] = useState<boolean>(false);
  const [isSwitchChecked, setIsSwitchChecked] = useState<boolean>(false);

  const fetchData = async (page: number, pageSize: number) => {
    try {
      let data = await chatBotRequests.getDataSet(page,pageSize);
    
      setData(data.conversation);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    setIsLoading(true);
    const currentPage = page;
    fetchData(currentPage,pageSize);
  }, [pageSize,page]);

  async function handlePageChange(newPage: number) {
    setPage(newPage);
    fetchData(newPage,pageSize);
  }

  async function handleRowsPerPageChange(event: any) {
    setPageSize(event.target.value);
    fetchData(page,pageSize);
  }

  async function saveFineTuning() {}

  async function fetchOverviewData() {}
  async function fetchServiceStatus() {}
  async function fetchUserStatus() {}
  const dataGridStyle = () => {
    return {
      border: "none",
      "& .MuiDataGrid-root": {
        border: "0px solid black",
      },
      "& .MuiDataGrid-columnHeaders": {
        p: "12px 15px",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "8px 8px 0px 0px",
        background: "#191919",
        color: "#7E7E7E",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        letterSpacing: "-0.14px",
        border: "none",
      },
      "& .MuiDataGrid-columnSeparator": {
        visibility: "hidden",
      },
      "& .MuiDataGrid-cell": {
        borderBottom: "none",
      },
      ".MuiDataGrid-cell:focus-within": {
        outline: "none",
      },
      "& .MuiDataGrid-row": {
        background: "black",
        p: "10px 15px",
        alignItems: "center",
        border: `0.5px solid #3E3E3E`,
        minHeight: "60px",
      },
    };
  };

  const getTotalPages = () => totalPages;
  const getPage = () => page;
  const getPageSize = () => pageSize;
  const getIsLoading = () => isLoading;
  const getPageSizes = () => pageSizes;

  return {
    data,
    isLoading,
    overviewData,
    page,
    pageSize,
    pageSizes,
    rowId,
    chatBotRequests,
    serviceStatus,
    serviceType,
    setData,
    setIsLoading,
    setOverviewData,
    setPage,
    setPageSize,
    setPageSizes,
    setRowId,
    setChatBotRequests,
    setServiceStatus,
    setServiceType,
    setTimeType,
    setTotalPages,
    setUserRequests,
    setUserStatus,
    timeType,
    totalPages,
    userRequests,
    userStatus,
    fetchUserStatus,
    fetchServiceStatus,
    fetchOverviewData,
    handleRowsPerPageChange,
    handlePageChange,
    getTotalPages,
    getPage,
    getPageSize,
    dataGridStyle,
    getIsLoading,
    getPageSizes,
    isDialogItemAddOpen,
    setIsDialogItemAddOpen,
    dateFilter,
    setDateFilter,
    setDateStartFilter,
    setDateEndFilter,
    isFetchData,
    setIsFetchData,
    saveFineTuning,
  };
};

export default useOpenAIViewModel;
