import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useState } from "react";
import UserRequests from "requests/userRequests";

type Props = {
  open: boolean;
  handleClose: () => void;
  user: any;
  setVerificationStatus: (value: string) => void;
};

const CompanyDialogAction = ({
  open,
  handleClose,
  user,
  setVerificationStatus,
}: Props) => {
  // console.log('user -> ', user);
  const { _id, name, email } = user;
  const [message, setMessage] = useState("");

  const handleChange = (e: any) => {
    setMessage(e.target.value);
  };

  const handleSend = async () => {
    const userRequest = new UserRequests();
    try {
      const response = await userRequest.updateVerificationStatus(
        email,
        "rejected",
        message
      );
      setVerificationStatus("rejected");
      handleClose();
    } catch (error) {
      console.log(error);
    }
    handleClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"md"}>
      <DialogTitle>
        Please send a message to <b>{name}</b>  to explain the rejection reason
      </DialogTitle>

      <DialogContent style={{ padding: "16px", width: "100%" }}>
        <TextField
          style={{ width: "100%" }}
          id="outlined-basic"
          value={message}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{ color: grey[50] }}>
          Cancel
        </Button>
        <Button onClick={handleSend}  sx={{ color: "#ec7307", border:"1px solid #ec7307" }}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompanyDialogAction;
