import React, { useMemo, useState } from "react";
import { IconButton, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { formattedDateEpoch } from "utils/helper";
import ChatBotRequests from "requests/chatBotRequests";

const TableVectorStoreFiles = ({
  vectorStoreFilesList,
  emitFetchData,
}: {
  vectorStoreFilesList: any[];
  emitFetchData:() => void
}) => {
  const [chatBotRequests, setChatBotRequests] = useState(new ChatBotRequests());
  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", flex: 0.2 },
      { field: "vector_store_id", headerName: "Vector Store ID", flex: 0.2 },
      { field: "object", headerName: "Object", flex: 0.3 },
      {
        field: "created_at",
        headerName: "Created At",
        flex: 0.3,
        valueFormatter: (params: any) => {
          if (!params.value == null) {
            return "";
          }
          const date = formattedDateEpoch(params.value);
          return date;
        },
      },
      {
        field: "delete",
        headerName: "Delete",
        flex: 0.1,
        renderCell: (params: any) => (
          <IconButton
            aria-label="delete"
            onClick={() => handleDeleteFile(params)}
          >
            <DeleteIcon color="error" />
          </IconButton>
        ),
      },
    ],
    []
  );

  const handleDeleteFile = async (params: any) => {
    try {
      await chatBotRequests.disassociateFileFromVectorStore(params.row.vector_store_id,params.row.id);
      emitFetchData()
    } catch (err) {
      console.error("Error creating vector store:", err);
      alert("Failed to Delete file from vector store. Please try again later."); 
    }
  };

  return (
    <Box mt="40px" minWidth={"100%"} height="auto">
      <DataGrid
        autoHeight
        rowHeight={60}
        disableExtendRowFullWidth={false}
        loading={false}
        getRowId={(row) => JSON.stringify(row)}
        rows={vectorStoreFilesList || []}
        columns={columns}
        hideFooter
        pageSize={99}
      />
    </Box>
  );
};

export default TableVectorStoreFiles;
