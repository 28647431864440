import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
// import MoreVertIcon from '@mui/icons-material/MoreVertIcon';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

type Props = {
  options: any,
  optionName?: any,
  onSelect: (option: any) => void;
}

function DropdownMenu({ onSelect, options, optionName }: Props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (option: any) => {
    onSelect(option); // Invoke the callback with the selected company
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <KeyboardArrowDownOutlinedIcon />
      </IconButton>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option: any, i: number) => (
          <MenuItem key={i + "op"} onClick={() => handleSelect(option)}>
            {typeof option === 'string' ? option : option[optionName]}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default DropdownMenu;
