import {
  useState,
  useEffect,
  SyntheticEvent,
  Fragment,
  ReactNode,
} from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { styled, Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MuiMenu, { MenuProps } from "@mui/material/Menu";
import MuiAvatar, { AvatarProps } from "@mui/material/Avatar";
import MuiMenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import Typography, { TypographyProps } from "@mui/material/Typography";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { getHostPythonGrpcBaseUrl } from "config";
import { NotifierClient } from "scenes/grpcTest/notifier/NotifierServiceClientPb";
import { EventStreamRequest } from "scenes/grpcTest/notifier/notifier_pb";
import { formattedDate } from "utils/helper";
import NotificationRequests from "requests/notificationRequests";

const notificationRequests = new NotificationRequests();

const Menu = styled(MuiMenu)<MenuProps>(({ theme }) => ({
  "& .MuiMenu-paper": {
    width: 380,
    overflow: "hidden",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "& .MuiMenu-list": {
    padding: 0,
  },
}));

const MenuItem = styled(MuiMenuItem)<MenuItemProps>(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const styles = {
  maxHeight: 349,
  overflowY: "auto",
  overflowX: "hidden",
  "& .MuiMenuItem-root:last-of-type": {
    border: 0,
  },
};

const Avatar = styled(MuiAvatar)<AvatarProps>({
  width: "2.175rem",
  height: "2.175rem",
  fontSize: "1rem",
});

const MenuItemTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  flex: "1 1 100%",
  overflow: "hidden",
  fontSize: "0.875rem",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  marginBottom: theme.spacing(0.75),
}));

const MenuItemSubtitle = styled(Typography)<TypographyProps>({
  flex: "1 1 100%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

const NotificationDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(
    null
  );
  const [notifications, setNotifications] = useState<any[]>([]);
  const client = new NotifierClient(getHostPythonGrpcBaseUrl(), null, null);
  const id = localStorage.getItem("id");

  const handleStreamEvents = () => {
    const request = new EventStreamRequest();

    request.setUserid(String(id));

    const stream = client.streamEvents(request, {});
    stream.on("data", async function (response) {
      const notification = {
        title: response.getTitle(),
        message: response.getMessage(),
        userId: response.getUserid(),
        created: response.getCreated(),
      };

      setNotifications((prevNotifications) => {
        const updatedNotifications = [notification, ...prevNotifications];
        localStorage.setItem(
          "notifications",
          JSON.stringify(updatedNotifications)
        );
        return updatedNotifications;
      });
    });

    stream.on("error", function (err) {
      console.error("Stream error:", err);
    });

    stream.on("end", function () {
      console.log("Stream ended.");
    });
  };

  useEffect(() => {
    const handleStream = async () => {
      const notificationsStr = String(localStorage.getItem("notifications"));
      const notificationObj = JSON.parse(notificationsStr);
      setNotifications(notificationObj);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      handleStreamEvents();
    };
    handleStream();
    handleNotificationPaginations();
  }, []);

  const handleNotificationPaginations = async () => {
    const notificationsResponse = await notificationRequests.getPagination(
      1,
      64,
      String(id)
    );
    setNotifications(notificationsResponse.data);
    localStorage.setItem(
      "notifications",
      JSON.stringify(notificationsResponse.data)
    );
  };

  const handleClearNotifications = async () => {
    await notificationRequests.clearNotifications(id);
    localStorage.setItem("notifications", "[]");
    setNotifications([]);
  };

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const ScrollWrapper = ({ children }: { children: ReactNode }) => {
    return <Box sx={styles}>{children}</Box>;
  };

  return (
    <Fragment>
      <IconButton
        color="inherit"
        aria-haspopup="true"
        onClick={handleDropdownOpen}
        aria-controls="customized-menu"
        style={{ position: "relative" }}
      >
        <div
          style={{
            height: 14,
            width: "auto",
            minWidth: 14,
            fontSize: "0.8rem",
            fontWeight: "500",
            borderRadius: "32px",
            position: "absolute",
            right: 10,
            top: 7,
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#FF7C06",
          }}
        >
          <small style={{ color: "black" }}>{notifications.length}</small>
        </div>
        <NotificationsNoneIcon style={{ fontSize: 30 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem disableRipple>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>Notifications</Typography>
            <Chip
              size="small"
              label={notifications.length}
              color="primary"
              sx={{
                height: 20,
                fontSize: "0.75rem",
                fontWeight: 500,
                borderRadius: "10px",
              }}
            />
          </Box>
        </MenuItem>
        <ScrollWrapper>
          {notifications.map((notification, index) => (
            <MenuItem key={index} onClick={handleDropdownClose}>
              <Box
                sx={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <Avatar alt={notification.alt} />
                <Box
                  sx={{
                    ml: 2,
                    flex: "1 1",
                    display: "flex",
                    overflow: "hidden",
                    flexDirection: "column",
                  }}
                >
                  <MenuItemTitle>{notification.title}</MenuItemTitle>
                  <Typography sx={{
                     whiteSpace: 'pre-wrap',
                     overflowWrap: 'break-word', 
                     maxWidth: '100%',
                     display: 'block' 
                  }} variant="body2">
                    {notification.message}
                  </Typography>
                </Box>
                <Typography variant="caption" sx={{ color: "text.disabled" }}>
                  {formattedDate(notification.created)}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </ScrollWrapper>
        <MenuItem
          disableRipple
          sx={{
            py: 3.5,
            borderBottom: 0,
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Button
            onClick={handleClearNotifications}
            fullWidth
            variant="contained"
          >
            Clear all
          </Button>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default NotificationDropdown;
