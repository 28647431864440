import React, { ChangeEvent, useState, useEffect, useRef } from "react";

import axios from "axios";
import { SelectChangeEvent } from "@mui/material";

import { AssistanceInquiry } from "models/assistanceInquiryResponseTypes";
import {
  ADD_SERVICES_REFUEL_OPTIONS,
  ADD_SERVICES_TAB_OPTIONS,
  ADD_SERVICES_TOWING_GEAR_OPTIONS,
  SERVICE_TYPE_VALUES,
  STATE_VALUES,
  TOWING_TYPES_VALUES,
  VARIANTS_BATTERY,
  VARIANTS_REFUEL,
  VARIANTS_TIRE_CHANGE,
  VARIANTS_TOWING,
  VARIANTS_UNLOCK,
  VARIANTS_WASH,
} from "utils/const";
import UserRequests from "requests/userRequests";
import ServiceRequests from "requests/serviceRequests";
import CompanyRequests from "requests/companyRequests";
import VehicleRequests from "requests/vehicleRequests";
import LocationRequests from "requests/locationRequests";
import { MapRef, Marker } from "react-map-gl";
import { getMapboxToken, getHostPythonAPIBaseUrl } from "config";
import {
  VARIANTS_OPTIONS,
} from "../utils/const";
// const { BACKEND, IP_BACKEND_HOST } = require('../config/env');

interface GoogleCompaniesViewModel {
  addCompany: boolean;

  currentTab: string;
  newService: any;
  isVehicleLoading: boolean;
  isVehicleResponse: boolean;
  vehicle: any;
  pickSuggestions: any;
  dropSuggestions: any;
  saveService: () => Promise<void>;
  handleAddCompany: () => void;
  handleSelectSpecialtyAddService: (option: string) => void;
  handleSelectStateAddService: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangeAddService: (e: ChangeEvent<HTMLInputElement>) => void;
  handleCloseAddCompany: () => void;
  handleCloseAddTag: () => void;
  handleDeleteVehicleAddService: () => void;
  handleSelectSuggestion: (param: string, suggestion: any) => void;
  handleSelectSpecialtyOptions: (param: string, value: string) => void;
  disabledAddServiceNextButton: () => boolean;
  disabledAddServiceSaveButton: () => boolean;
  fetchVehicle: () => void;
  getAddService: () => boolean;
  getAddTag: () => boolean;
  getVehicle: () => any;
  getCurrentTab: () => string;
  getPage: () => number;
  getPageSize: () => number;
  getAverageServiceDistance: () => number | null;
  getTotalPages: () => number;
  getData: () => AssistanceInquiry[];

  getPreviousData: () => AssistanceInquiry[];
  getMarkers: () => any[];
  getCompanyMarkers: () => any[];

  getPreviousMarkers: () => any[];
  getIsLoading: () => boolean;

  getIsMovingMap: () => boolean;
  getIsChecked: () => boolean;
  getIsBlocked: () => boolean;
  getServiceType: () => string;
  getAmountCompanies: () => string;
  getSearch: () => string;
  getCurrentCompanyId: () => string;
  getDialogOpen: () => boolean;
  getDialogOpen2: () => boolean;
  getMapViewState: () => any;
  getCustomMarker: () => any;
  getSelectedDate: () => string;
  getCompanyType: () => any;
  getPageSizes: () => number[];
  getUpdated: () => boolean;
  getRowId: () => any;
  getCurrentId: () => string;
  getCustomerType: () => string;
  getCompaniesData: () => any[];
  getClickedCoordinates: () => number[] | null;
  getAveragePrice: () => number | null;
  getAverageServicePrice: () => number | null;
  getAverageDistance: () => number | null;
  getLocationRequests: () => LocationRequests;
  handleAmountCompanyChange: (value: string) => void;
  handleServiceChange: (type: string) => void;
  setCustomMarker: (value: any | null) => void;
  handleRowsPerPageChange: (event: SelectChangeEvent<number>) => void;
  handleCompanyMarkerUpdate: (newMarkers: any[]) => void;
  handleMarkerUpdateId: (id: string) => void;
  handleMarkerUpdate: (newMarkers: any[]) => void;
  handleClickSearch: () => void;
  handleClickCompanySearch: () => void;
  handlePageChange: (newPage: number) => void;
  handleRadiusChange: (e: any) => void;
  handleMarkerClick: (marker: Marker) => Promise<void>;
  handleCompanyMarkerClick: (marker: CompanyMarker) => Promise<void>;
  handleSwitchChange: () => void;
  resetCompanyMarkers: () => void;
  handleSearchChange: (e: any) => void;
  handleSearchChangeString: (str: string) => void;
  handleCompanySearchChange: (e: any) => void;
  handleMapClick: (event: any) => void;
  handledialogOpen: () => void;
  handleCompanyChange: (type: string) => void;
  handledialogClose: () => void;
  handleAddTag: () => void;
  handledialogOpen2: () => void;
  handledialogClose2: () => void;
  handleItemClick: (params: any) => void;
  addCustomCompanyMarker: (marker: any) => void;
  isSmsEnabled: boolean;
  setIsSmsEnabled: (value: boolean) => void;
  isEmailEnabled: boolean;
  setIsEmailEnabled: (value: boolean) => void;
  setRowCurentId: (val: string) => void;
  setCurrentColumn: (data: any[]) => void;
  dataGridStyle: () => any;
  getColumns: () => any[];
  centerMapOnLocation: (
    latitude: number,
    longitude: number,
    zoomLevel: number,
  ) => void;
  getMapRef: () => any;
  getCurrentServiceMarker: () => Marker;
  getCurrentCompanyMarker: () => CompanyMarker;
  handleBorder: (type: string) => string | null;
  getShowCompanies: () => boolean;
  setNowCompanyMarker: (marker: CompanyMarker) => void;
  generateAvatar: (
    marker: Marker,
    selectedClicked: string,
    selected: string,
  ) => string;
  setDropSuggestionsEmpty: (data: string) => void;
  setCompanyMarkers: (data: any[]) => void;
  getSuggestionCompaniesList: () => any[];
  clearCompanySuggestions: () => void;
  getShowOnly: () => string;
  handleCompanySearchEmpty: () => void;
  getAllTags: () => string[];
  handleGetAllTags: () => Promise<void>;
  handleChangeTagFilter: (value: string) => void;
  handleChangeQueryFilter: (value: string) => void;
  getCurrentTagFilter: () => string;
  getSearchCompany: () => string;
  getIsCompanyOpen: () => boolean;
  getIsCompanyReacheble: () => boolean;
  getIsCompanyConfirmedPrices: () => boolean;
  handleCheckboxOpenChange: () => void;
  handleCheckboxReachebleOpenChange: () => void;
  handleCheckboxConfirmedPricesOpenChange: () => void;
  handleFocusCompanySearch: (val: boolean) => void;
  handleFocusAddressSearch: (val: boolean) => void;
  getIsSearchCompanyFocused: () => boolean;
  getIsSearchAddressFocused: () => boolean;
  getIsCompanyPriced: () => boolean;
  handleCheckboxPriceChange: () => void;
  handleChangeSmsOption: (event: any) => void;
  handleCurrentServiceCategoryFilterChange: (val: string) => void;
  handleCurrentServiceNameFilterChange: (val: string) => void;
  handleCurrentServiceVariantFilterChange: (val: string) => void;
  handleCurrentServiceFilterClear: () => void;
  getCurrentServiceCategoryFilter: () => string;
  getCurrentServiceVariantFilter: () => string;
  setCurrentVariants: (variants: string[]) => void;
  setClickedCoordinates: (variants: number[] | null) => void;
  getCurrentServiceNameFilter: () => string;
  changeReload: () => void;
  handleFetchSearch: () => void;
  saveUser: () => Promise<void>;
  variants: string[];
  queryFilter: string;
  currentVariants: string[] | null;
}

type Marker = {
  _id: string;
  __v: string;
  companyId: string;
  locations: any[];
  customerId: string;
  distance: number;
  created: Date;
  updated: Date;
  specialty: any;
};
type CompanyMarker = {
  name: string;
  phoneNumber: string;
  specialistPhoneNumber: string;
  placeId: string;
  rating: number;
  distance: number;
  location: {
    address: string;
    coordinates: {
      latitude: number;
      longitude: number;
    };
  };
  formatted_address: string;
  status: string;
  estimatedDuration: number;
  price: any;
  updates: Array<any>;
  query: string;
  color: string;
  favorite: boolean;
  checked?: boolean;
  assitanceId: string;
  specialties: any;
};

const companyRequests = new CompanyRequests();
const vehicleRequests = new VehicleRequests();
const userRequests = new UserRequests();
const UseGoogleCompaniesViewModel = (): GoogleCompaniesViewModel => {
  const [addCompany, setAddCompany] = useState<boolean>(false);
  const [addTag, setAddTag] = useState<boolean>(false);
  const [vehicle, setVehicle] = useState<any>({});
  const [currentTab, setCurrentTab] = useState(ADD_SERVICES_TAB_OPTIONS[0]);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(8);
  const [totalPages, setTotalPages] = useState<number>(8);
  const [data, setData] = useState<any[]>([]);
  const [previousData, setPreviousData] = useState<AssistanceInquiry[]>([]);
  const [markers, setMarkers] = useState<any[]>([]);
  const [companyMarkers, setCompanyMarkers] = useState<any[]>([]);
  const [previousMarkers, setPreviousMarkers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isMovingMap, setIsMovingMap] = useState<boolean>(true);
  const [isChecked, setIsChecked] = useState(false);
  const [showOnly, setShowOnly] = useState<string>("all");
  const [variants, setVariants] = useState<string[]>(VARIANTS_OPTIONS);
  const [currentVariants, setCurrentVariants] = useState<string[] | null>(null);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isEmailEnabled, setIsEmailEnabled] = useState<boolean>(false);
  const [serviceType, setServiceType] = useState<string>("all");
  const [companyType, setCompanyType] = useState<string>("all");
  const [amountCompanies, setAmountCompanies] = useState<string>("40");
  const [columns, setColumns] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const [companySearch, setCompanySearch] = useState<string>("");
  const [currentCompanyId, setCurrentCompanyId] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [suggestionCompaniesList, setSuggestionCompaniesList] = useState<
    any[]
  >([]);
  const [allTags, setAllTags] = useState<string[]>([]);
  const [currentTagFilter, setCurrentTagFilter] = useState<string>("all");
  const [currentServiceCategoryFilter, setCurrentServiceCategoryFilter] = useState<string>("");
  const [currentServiceNameFilter, setCurrentServiceNameFilter] = useState<string>("");
  const [currentServiceVariantFilter, setCurrentServiceVariantFilter] = useState<string>("");
  const [queryFilter, setQueryFilter] = useState<string>("");
  const [dialogOpen2, setDialogOpen2] = useState<boolean>(false);
  const [mapViewState, setMapViewState] = useState({
    latitude: 34.053691,
    longitude: -118.242766,
    zoom: 8, // You might want to adjust the zoom level based on your preference
  });

  const [customMarker, setCustomMarker] = useState<any | null>({
    id: Date.now(),
    latitude: 34.053691,
    longitude: -118.242766,
  });
  const [selectedDate, setSelectedDate] = useState<string>("all");
  const pageSizes: number[] = [99, 64, 32, 16, 8, 5];
  const [updated, setUpdated] = useState<boolean>(false);
  const [rowId, setRowId] = useState<any>(null);
  const [currentId, setCurrentId] = useState<string>("");
  const [customerType, setCustomerType] = useState<string>("clients");
  const [companiesData, setCompaniesData] = useState<any[]>([]);
  const [clickedCoordinates, setClickedCoordinates] = useState<
    number[] | null
  >(null);
  const [averagePrice, setAveragePrice] = useState<number | null>(null);
  const [averageDistance, setAverageDistance] = useState<number | null>(null);
  const locationRequests = new LocationRequests();
  const mapRef = useRef<MapRef | null>(null);
  const [currentCompanyMarker, setCurrentCompanyMarker] = useState<any>({});
  const [currentServiceMarker, setCurrentServiceMarker] = useState<any>({});
  const [showCompanies, setShowCompanies] = useState(false);
  const [isVehicleLoading, setIsVehicleLoading] = useState<boolean>(false);
  const [isVehicleResponse, setIsVehicleResponse] = useState<boolean>(false);
  const [reloadPage, setReloadPage] = useState<boolean>(false);
  const [pickSuggestions, setPickSuggestions] = useState([]);
  const [dropSuggestions, setDropSuggestions] = useState([]);
  const [averageServicePrice, setAverageServicePrice] = useState<
    number | null
  >(null);
  const [averageServiceDistance, setAverageServiceDistance] = useState<
    number | null
  >(null);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [isCompanyOpen, setIsCompanyOpen] = useState<boolean>(true);
  const [isSmsEnabled, setIsSmsEnabled] = useState<boolean>(false);
  const [isCompanyRecheable, setIsCompanyRecheable] = useState<boolean>(true);
  const [isCompanyConfirmedPrices, setIsCompanyConfirmedPrices] = useState<boolean>(true);
  const [isCompanyPriced, setIsCompanyPriced] = useState<boolean>(true);
  const [isSearchCompanyFocused, setIsSearchCompanyFocused] =
    useState<boolean>(false);
  const [isSearchAddressFocused, setIsSearchAddressFocused] =
    useState<boolean>(false);

  const handleCurrentServiceCategoryFilterChange = (value: string) => {
    setCurrentServiceNameFilter('')
    setCurrentServiceVariantFilter('')
    setVariants(VARIANTS_OPTIONS)
    setCurrentServiceCategoryFilter(value);
  };
  const handleCurrentServiceNameFilterChange = (value: string) => {
    setCurrentServiceVariantFilter('')
    setCurrentServiceNameFilter(value)

    if (value === "towing") {
      setVariants(VARIANTS_TOWING)
    } else if (value === "battery") {
      setVariants(VARIANTS_BATTERY)
    } else if (value === "carWash") {
      setVariants(VARIANTS_WASH)
    } else if (value === "motorcycleWash") {
      setVariants(VARIANTS_WASH)
    } else if (value === "refuel") {
      setVariants(VARIANTS_REFUEL)
    } else if (value === "tireChange") {
      setVariants(VARIANTS_TIRE_CHANGE)
    } else if (value === "unlock") {
      setVariants(VARIANTS_UNLOCK)
    } else {
      setVariants(VARIANTS_OPTIONS)
    }
  }

  const handleCurrentServiceFilterClear = () => {
    setCurrentServiceNameFilter('')
    setCurrentServiceVariantFilter('')
    setCurrentServiceCategoryFilter('')
    setCurrentVariants(null)
  }


  const handleCurrentServiceVariantFilterChange = (value: string) => {
    setCurrentServiceVariantFilter(value)
  }

  const handleCheckboxOpenChange = () => {
    setIsCompanyOpen(!isCompanyOpen);
  };

  const handleCheckboxReachebleOpenChange = () => {
    setIsCompanyRecheable(!isCompanyRecheable);
  };

  const handleCheckboxConfirmedPricesOpenChange = () => {
    setIsCompanyConfirmedPrices(!isCompanyConfirmedPrices);
  };

  const handleCheckboxPriceChange = () => {
    setIsCompanyPriced(!isCompanyPriced);
  };

  useEffect(() => {
    if (!getCustomMarker()) return;
    centerMapOnLocation(
      getCustomMarker().latitude,
      getCustomMarker().longitude,
      0,
    );
  }, [isMovingMap]);

  useEffect(() => {
    const data = getCurrentServiceMarker();
    setCurrentServiceMarker(data);
  }, [currentId]);

  const fetchData = async (isData: boolean, page: number) => {
    const serviceRequests = new ServiceRequests();
    try {
      let paginationData = await serviceRequests.getPaginationCompanyCall(
        isData ? page : page + 1,
        pageSize,
      );

      let companies = paginationData.companies;

      setMarkers(companies);
      setData(companies);

      // Process the paginationData here
    } catch (error) {
      // Handle the error here
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setMarkers([]);

    const currentPage = page; // Get the current page from the ref
    fetchData(true, currentPage);
  }, [pageSize, serviceType, selectedDate, reloadPage]);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const companyData = await userRequests.getAllFilter(
          "isCompany",
          true,
        );
        setCompaniesData(companyData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCompany();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (search === "" && suggestionCompaniesList.length > 0) {
        setSuggestionCompaniesList([]);
      }
    }, 3000); // Set the interval to 3000 milliseconds (3 seconds)

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const handleSwitchChange = () => {
    setIsChecked((prev) => !prev); // Toggle the state
  };

  const handleSearchChange = (event: any) => {
    if (event.target.value == "") {
      setSuggestionCompaniesList([]);
    }
    setSearch(event.target.value);
  };
  const handleSearchChangeString = (str: string) => {
    setSearch(str);
  };

  const changeReload = () => {
    setReloadPage(!reloadPage);
  };

  const handleCompanySearchChange = async (event: any) => {
    const searchCompany = event.target.value;
    setCompanySearch(searchCompany);


  };

  const handleFetchSearch = async () => {
    let url = `${getHostPythonAPIBaseUrl()}/company/aggregate/search?name=${companySearch}`;

    if (getIsChecked()) {
      const encodedId = encodeURIComponent(companySearch);
      url = `${getHostPythonAPIBaseUrl()}/company/aggregate/search-by-id?id=${encodedId}`;

    }

    if (
      companySearch === "" ||
      companySearch === undefined ||
      companySearch === null
    ) {
      setSuggestionCompaniesList([]);
      return;
    }
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("access_token"),
        },
      });

      if (!getIsChecked()) {
        setSuggestionCompaniesList(response.data);
      } else {
        setSuggestionCompaniesList([response.data]);
      }

    } catch (err) {
      console.log(err);
      setSuggestionCompaniesList([]);
    }
  }

  const handleCompanySearchEmpty = async () => {
    setCompanySearch("");
  };

  const generateAvatar = (
    marker: Marker,
    selectedClicked: string,
    selected: string,
  ) => {
    const isSelected =
      getCurrentId().substring(0, 24) === marker._id.substring(0, 24);
    return isSelected ? selectedClicked : selected;
  };

  const handleItemClick = (params: any) => {
    window.scrollTo(0, 0);
    setIsBlocked(true);
    const rowId = params.value;

    setRowId(rowId);
    setCurrentId(rowId);

    // Create a custom event object with the lngLat properties
    let customEvent = {
      lngLat: {
        lng: 0,
        lat: 0,
      },
    };

    let marker: Marker | {} = {};

    for (let i = 0; i < markers.length; i++) {
      const loopMarker = markers[i];

      if (loopMarker._id.substring(0, 24) === rowId) {
        marker = loopMarker;
        break;
      }
    }

    if (marker && "_id" in marker) {
      customEvent.lngLat = {
        lng: marker.locations[0].geoJsonPoint.coordinates[0],
        lat: marker.locations[0].geoJsonPoint.coordinates[1],
      };
      // Call handleMapClick with the custom event
      handleMapClick(customEvent);
    }
    setIsBlocked(false);
  };

  const handleCompanyMarkerClick = async (marker: any) => {
    setIsBlocked(true);
    setCurrentCompanyId(marker._id);
    setIsMovingMap(!isMovingMap);
    setDialogOpen(true);
    setIsBlocked(false);
  };

  const handleGetAllTags = async (): Promise<void> => {
    const url = `${getHostPythonAPIBaseUrl()}/tag/all`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("access_token"),
        },
      });
      const responseTags: any[] = response.data;

      // Use map to extract tag names from the array of tag objects
      let strTags = responseTags.map((tag) => tag.name);
      strTags = ["all", ...strTags];
      setAllTags(strTags);
    } catch (err) {
      console.error(err);
      setAllTags([]);
    }
  };

  useEffect(() => {
    handleGetAllTags();
  }, []);

  const handleMarkerClick = async (marker: Marker) => {
    setCurrentId("");
    let id = marker._id.substring(0, 24);

    setCurrentId(id);
    setDialogOpen2(true);

  };

  const handleChangeTagFilter = (value: string) => {
    setCurrentTagFilter(value);
  };

  const resetCompanyMarkers = () => {
    setCompanyMarkers([]);

    setCurrentId("");
  };

  const handleRadiusChange = (e: any) => {
    e.preventDefault();
    setSelectedDate(e.target.value);
  };

  const handleMapClick = async (event: any) => {
    if (isBlocked) return;
    let { lng, lat } = event.lngLat;

    setCurrentId("");
    setRowId("");
    setCustomMarker(null);
    setCompanyMarkers([]);

    if (isNaN(lng) || isNaN(lat) || !lng || !lat) {
      lng = 0;
      lat = 0;
      return;
    }

    setClickedCoordinates([lng, lat]);
    const data = {
      _id: Date.now().toString() + lat + ", " + lng,
      latitude: lat,
      longitude: lng,
    };

    setCustomMarker(data);
    setIsMovingMap(!isMovingMap);
    companyRequests
      .getCloseTo(
        lat,
        lng,
        currentTagFilter,
        isCompanyOpen,
        isCompanyRecheable,
        currentServiceNameFilter,
        currentServiceCategoryFilter,
        currentVariants,
        isCompanyConfirmedPrices,
        queryFilter,
        isEmailEnabled,
        isSmsEnabled,
      )
      .then(async (data) => {
        const companyMarks = data;
        if (companyMarks === undefined || companyMarks === null) {
          return;
        }

        setCompanyMarkers(companyMarks);

      });
  };
  function toRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
  }

  function haversineDistance(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
  ): number {
    // Radius of the Earth in kilometers
    const R = 6371.0;

    // Convert latitude and longitude from degrees to radians
    const lat1_rad = toRadians(lat1);
    const lon1_rad = toRadians(lon1);
    const lat2_rad = toRadians(lat2);
    const lon2_rad = toRadians(lon2);

    // Differences in coordinates
    const delta_lat = lat2_rad - lat1_rad;
    const delta_lon = lon2_rad - lon1_rad;

    // Haversine formula
    const a =
      Math.sin(delta_lat / 2) ** 2 +
      Math.cos(lat1_rad) *
      Math.cos(lat2_rad) *
      Math.sin(delta_lon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Distance in kilometers
    const distance = R * c;

    return distance;
  }

  function calculateHaversineDistance(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
  ): number {
    const R = 6371;
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distanceKm = R * c;
    const distanceMiles = distanceKm * 0.621371;

    return distanceMiles;
  }

  function funSetAverageServiceDistance() {
    let totalDistance = 0;
    let validCount = 0; // Count of valid inputs

    if (markers && Array.isArray(markers)) {
      for (let i = 0; i < markers.length; i++) {
        if (!markers[i].distance || markers[i].distance > 20) continue;

        let distance = markers[i].distance;
        totalDistance += distance;
        validCount++;
      }

      const averageDist = validCount > 0 ? totalDistance / validCount : 0;
      setAverageServiceDistance(averageDist);
    }
  }

  const handlePageChange = async (newPage: number) => {
    const serviceRequests = new ServiceRequests();
    setPage(newPage);

    let paginationData = await serviceRequests.getPaginationCompanyCall(
      newPage,
      pageSize,
    );

    let companies = paginationData.companies;

    companies.forEach((c: any) => {
      c.itemId = c._id;
    });

    if (paginationData) {
      setData(paginationData.companies as any[]);
      setTotalPages(paginationData.totalPages);
      setIsLoading(false);
    }
  };

  const handleClickSearch = () => {
    locationRequests.getLocation(search).then(
      (data: any) => {
        if (Array.isArray(data) && data.length > 0) {
          const firstResult = data[0];
          const lat = parseFloat(firstResult.lat);
          const lon = parseFloat(firstResult.lon);
          if (!isNaN(lat) && !isNaN(lon) && lat !== 0 && lon !== 0) {
            const customEvent = {
              lngLat: {
                lng: lon,
                lat: lat,
              },
            };
            handleMapClick(customEvent);
          }
        }
      },
      (err: any) => {
        console.log(err);
        setSearch("");
      },
    );
  };

  const handleClickCompanySearch = async () => {
    const url = `${getHostPythonAPIBaseUrl()}/company/aggregate/search?name=${companySearch}`;
    setIsBlocked(true);
    const response = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    });
    const location = response.data.location.coordinates;
    centerMapOnLocation(location[1], location[0], 0);
    companyRequests
      .getCloseTo(
        location[1],
        location[0],
        currentTagFilter,
        isCompanyOpen,
        isCompanyRecheable,
        currentServiceNameFilter,
        currentServiceCategoryFilter,
        currentVariants,
        isCompanyConfirmedPrices,
        queryFilter,
        isEmailEnabled,
        isSmsEnabled,
      )
      .then((data) => {

        setCompanyMarkers(data);
        clearCompanySuggestions();
        setIsBlocked(false);
      });
  };

  const handleMarkerUpdate = (newMarkers: any[]) => {
    setMarkers(newMarkers);
  };

  const handleMarkerUpdateId = (id: string) => {
    setCurrentId(id);
  };

  const handleCompanyMarkerUpdate = (newMarkers: any[]) => {
    setCompanyMarkers(newMarkers);
  };

  const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
    if (isNaN(event.target.value as number)) return;
    setPageSize(event.target.value as number);
  };

  const handleServiceChange = (type: string) => {
    setServiceType(type);
    setPage(1);
  };
  const handleCompanyChange = (type: string) => {
    setCompanyType(type);
    setPage(1);
  };
  const handleAmountCompanyChange = (value: string) => {
    setCompanyMarkers([]);
    setCurrentId("");
    setAmountCompanies(value);
    setCustomMarker(null);
    setPage(1);
  };

  const [newService, setNewService] = useState({
    _id: "",
    userId: "",
    name: "",
    email: "",
    phoneNumber: "",
    plateNumber: "",

    state: STATE_VALUES[4],
    specialty: "",
    serviceLocation: {
      address: "",
      coordinates: {
        latitude: 0,
        longitude: 0,
      },
    },
    destinationLocation: {
      address: "",
      coordinates: {
        latitude: 0,
        longitude: 0,
      },
    },
    customerVehicle: {
      _id: "",
      image: "",
      make: "",
      model: "",
      plate: "",
      vin: "",
      year: 0,
    },
    customer: {
      _id: "",
    },
    specialtyOptions: [
      {
        name: "",
        value: "",
      },
    ],
    isSms: false,
  });

  const handleAddCompany = () => {
    setAddCompany(true);
  };
  const handleAddTag = () => {
    setAddTag(true);
  };
  const handleCloseAddCompany = () => {
    setAddCompany(false);
  };
  const handleCloseAddTag = () => {
    setAddTag(false);
  };

  const handleChangeAddService = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setNewService((prev) => {
      if (
        ["serviceLocation", "destinationLocation"].includes(
          e.target.name,
        )
      ) {
        fetchAutocompleteOptions(e.target.name, e.target.value);
        return {
          ...prev,
          [e.target.name]: {
            address: e.target.value,
          },
        };
      }
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setNewService((prev) => {
      if (
        ["serviceLocation", "destinationLocation"].includes(
          e.target.name,
        )
      ) {
        fetchAutocompleteOptions(e.target.name, e.target.value);
        return {
          ...prev,
          [e.target.name]: {
            address: e.target.value,
          },
        };
      }
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handleSelectSpecialtyAddService = (option: string) => {
    if (option === "refuel") {
      setNewService((prev) => {
        return {
          ...prev,
          specialty: option,
          specialtyOptions: [
            {
              name: "refuelType",
              value: ADD_SERVICES_REFUEL_OPTIONS[0],
            },
          ],
        };
      });
      return;
    }

    if (TOWING_TYPES_VALUES.includes(option)) {
      setNewService((prev) => {
        return {
          ...prev,
          specialty: option,
          specialtyOptions: [
            {
              name: "gearToNeutral",
              value: ADD_SERVICES_TOWING_GEAR_OPTIONS[0],
            },
          ],
        };
      });
      return;
    }
    setNewService((prev) => {
      return { ...prev, specialty: option };
    });
  };
  const handleSelectStateAddService = (e: ChangeEvent<HTMLInputElement>) => {
    setNewService((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleChangeSmsOption = (event: any) => {
    setNewService((prevService) => ({
      ...prevService,
      isSms: event.target.checked,
    }));
  };

  const handleDeleteVehicleAddService = async () => {
    setIsVehicleResponse(false);

    await vehicleRequests.delete(newService.customerVehicle.plate);
    setNewService({
      _id: "",
      userId: "",
      name: "",
      email: "",
      phoneNumber: "",
      plateNumber: "",
      state: "",
      specialty: "",
      serviceLocation: {
        address: "",
        coordinates: {
          latitude: 0,
          longitude: 0,
        },
      },
      destinationLocation: {
        address: "",
        coordinates: {
          latitude: 0,
          longitude: 0,
        },
      },
      customerVehicle: {
        _id: "",
        image: "",
        make: "",
        model: "",
        plate: "",
        vin: "",
        year: 0,
      },
      customer: {
        _id: "",
      },
      specialtyOptions: [
        {
          name: "",
          value: "",
        },
      ],
      isSms: false,
    });
  };

  const handleSelectSuggestion = (param: string, suggestion: any) => {
    setNewService((prev) => {
      return {
        ...prev,
        [param]: {
          address: suggestion.label,
          coordinates: {
            latitude: suggestion.location[1],
            longitude: suggestion.location[0],
          },
        },
      };
    });
    setPickSuggestions([]);
    setDropSuggestions([]);
  };

  const handleSelectSpecialtyOptions = (param: string, value: string) => {
    setNewService((prev) => {
      return {
        ...prev,
        specialtyOptions: [
          {
            name: param,
            value,
          },
        ],
      };
    });
  };

  const setDropSuggestionsEmpty = (newAddress: string) => {
    setPickSuggestions([]);
    setDropSuggestions([]);
    setSearch("");
    setNewService((prev) => ({
      ...prev,
      destinationLocation: {
        address: newAddress,
        coordinates: {
          latitude: 0,
          longitude: 0,
        },
      },
    }));
  };



  const disabledAddServiceNextButton = () => {
    if (
      newService.name.length === 0 ||
      newService.email.length === 0 ||
      newService.phoneNumber.length === 0
    )
      return true;
    return false;
  };

  const disabledAddServiceSaveButton = () => {
    const plateNumberCondition = newService.plateNumber.length > 0;
    const stateCondition = newService.state.length > 0;
    const specialtyCondition = newService.specialty.length > 0;
    const serviceLocationCondition =
      newService.serviceLocation.address.length > 0;



    if (
      plateNumberCondition &&
      stateCondition &&
      specialtyCondition &&
      serviceLocationCondition
    ) {
      return false;
    }
    return true;
  };

  const fetchVehicle = async () => {
    setIsVehicleLoading(true);
    const url = `${getHostPythonAPIBaseUrl()}/vehicle/save_vehicle_by_plate`;
    const token = localStorage.getItem("access_token");

    const requestBody = {
      _id: newService.userId,
      plate: newService.plateNumber,
      state: newService.state,
    };

    try {
      const response = await axios.post(url, requestBody, {
        headers: {
          Authorization: token,
        },
      }); // Pass the requestBody as data and token as header in the POST request
      const vehicle = response.data;
      setVehicle(response.data);
      setNewService((prev: any) => {
        return {
          ...prev,
          customerVehicle: {
            _id: vehicle._id,
            make: vehicle.maker,
            model: vehicle.model,
            plate: vehicle.plateNumber,
            vin: vehicle.vin,
            year: vehicle.year,
          },
        };
      });
      setIsVehicleLoading(false);
      setIsVehicleResponse(true);
    } catch (error) {
      console.error(error);
      setIsVehicleLoading(false);
      setIsVehicleResponse(false);
    }
  };

  const fetchAutocompleteOptions = (param: string, query: string) => {
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?proximity=ip&access_token=${getMapboxToken()}`,
    )
      .then((response) => response.json())
      .then((data) => {
        if (param === "serviceLocation") {
          setPickSuggestions(
            data.features.map((place: any) => ({
              label: place.place_name,
              location: place.center,
            })),
          );

        }
        if (param === "destinationLocation") {
          setDropSuggestions(
            data.features.map((place: any) => ({
              label: place.place_name,
              location: place.center,
            })),
          );

        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  };

  const saveUser = async () => {
    const saveUrl = `${getHostPythonAPIBaseUrl()}/regular_users/express`;
    const saveResponse = await axios.post(
      saveUrl,
      {
        email: newService.email,
        name: newService.name,
        phoneNumber: newService.phoneNumber,
      },
      {
        headers: {
          Authorization: localStorage.getItem("access_token"),
        },
      },
    );
    const userId = saveResponse.data._id;

    let updatedNewService = { ...newService };
    updatedNewService.userId = userId;
    updatedNewService.customer._id = userId;
    setNewService(updatedNewService);
  };

  const saveService = async () => {
    const saveUrl = `${getHostPythonAPIBaseUrl()}/assistances`;
    const token = localStorage.getItem("access_token");

    try {
      let sessionString = localStorage.getItem("session");
      let session: any = {};
      if (token !== null && sessionString !== null) {
        session = JSON.parse(sessionString);
      }
      const saveResponse = await axios.post(saveUrl, newService, {
        headers: {
          Authorization: token,
        },
      });

      let assistance = saveResponse.data;
      const requestData = {
        assistance: assistance,
      };

      if (!newService.isSms) return;

      try {
        const smsUrl = `${getHostPythonAPIBaseUrl()}/twilio/send/sms/assistance?id=${assistance._id}`;
        const response = await axios.post(smsUrl, requestData, {
          headers: {
            Authorization: token,
          },
        });
        setAddCompany(false);
      } catch (error: any) {
        console.error(
          "Error:",
          error.response ? error.response.data : error.message,
        );
      }
      setPickSuggestions([]);
      setDropSuggestions([]);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handledialogOpen = () => {
    setDialogOpen(true);
  };
  const handledialogClose = () => {

    setDialogOpen(false);
    let customEvent = {
      lngLat: {
        lng: 0,
        lat: 0,
      },
    };

    if (clickedCoordinates && clickedCoordinates[0] !== null && clickedCoordinates[1] !== null) {
      customEvent.lngLat = {
        lng: clickedCoordinates[0],
        lat: clickedCoordinates[1]
      };
      handleMapClick(customEvent)
    }

  };

  const handledialogOpen2 = () => {
    setDialogOpen2(true);
  };
  const handledialogClose2 = () => {
    setDialogOpen2(false);
  };

  const handleChangeQueryFilter = (value: string) => {
    setQueryFilter(value)
  }

  const centerMapOnLocation = (
    latitude: number,
    longitude: number,
    zoomLevel: number,
  ) => {
    if (mapRef.current) {
      mapRef.current.getMap().flyTo({
        center: [longitude, latitude],
        zoom:
          zoomLevel === 0
            ? mapRef.current.getMap().getZoom()
            : zoomLevel,
        essential: true,
      });
    }
  };

  const getCurrentCompanyMarker = (): any => {
    if (companyMarkers && Array.isArray(companyMarkers)) {
      for (let i = 0; i < getCompanyMarkers().length; i++) {
        const marker = getCompanyMarkers()[i];

        if (marker._id === currentCompanyId) {
          return marker;
        }
      }
    }
    return null;
  };

  const getCurrentServiceMarker = (): Marker => {
    for (let i = 0; i < markers.length; i++) {
      const marker = markers[i];

      if (marker._id.startsWith(getCurrentId())) {
        return marker;
      }
    }
    return {
      _id: "",
      locations: [],
      specialty: {},
      distance: 0,
      __v: "0.1.0",
      companyId: "",
      customerId: "",
      created: new Date(),
      updated: new Date(),
    };
  };
  function handleBorder(status: string): string | null {
    if (status === "no") {
      return "none";
    } else if (status === "yes") {
      return "3px solid green";
    } else if (status === "not_interested") {
      return "3px solid red";
    } else {
      return null; // Handle any other cases if needed
    }
  }

  function clearCompanySuggestions(): void {
    setSuggestionCompaniesList([]);
    setCompanySearch("");
  }

  function addCustomCompanyMarker(companyMarker: any): void {
    let updatedMarkers = companyMarkers;
    updatedMarkers.push(companyMarker);
    setCompanyMarkers(updatedMarkers);
  }

  const dataGridStyle = () => {
    return {
      border: "none",
      "& .MuiDataGrid-root": {
        border: "0px solid black",
      },
      "& .MuiDataGrid-columnHeaders": {
        p: "12px 15px",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "8px 8px 0px 0px",
        background: "#191919",
        color: "#7E7E7E",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        letterSpacing: "-0.14px",
        border: "none",
      },
      "& .MuiDataGrid-columnSeparator": {
        visibility: "hidden",
      },
      "& .MuiDataGrid-cell": {
        borderBottom: "none",
      },
      ".MuiDataGrid-cell:focus-within": {
        outline: "none",
      },
      "& .MuiDataGrid-row": {
        background: "black",
        p: "10px 15px",
        alignItems: "center",
        border: `0.5px solid #3E3E3E`,
        minHeight: "60px",
      },
    };
  };

  const handleFocusCompanySearch = (val: boolean) => {
    setIsSearchCompanyFocused(val);
  };

  const handleFocusAddressSearch = (val: boolean) => {
    setIsSearchAddressFocused(val);
  };

  const getMapRef = () => mapRef;
  const getAddService = () => addCompany;
  const getAddTag = () => addTag;
  const getVehicle = () => vehicle;
  const getCurrentTab = () => currentTab;
  const getPage = () => page;
  const getPageSize = () => pageSize;
  const getTotalPages = () => totalPages;
  const getData = () => data;

  const getPreviousData = () => previousData;
  const getMarkers = () => markers;
  const getCompanyMarkers = () => companyMarkers;

  const getIsCompanyOpen = () => isCompanyOpen;
  const getIsCompanyPriced = () => isCompanyPriced;

  const getPreviousMarkers = () => previousMarkers;
  const getIsLoading = () => isLoading;
  const getIsMovingMap = () => isMovingMap;
  const getIsChecked = () => isChecked;
  const getIsBlocked = () => isBlocked;
  const getServiceType = () => serviceType;
  const getCompanyType = () => companyType;
  const getAmountCompanies = () => amountCompanies;
  const getShowOnly = () => showOnly;
  const getAllTags = () => allTags;
  const getSearch = () => search;
  const getSearchCompany = () => companySearch;
  const getCurrentCompanyId = () => currentCompanyId;
  const getDialogOpen = () => dialogOpen;
  const getDialogOpen2 = () => dialogOpen2;
  const getMapViewState = () => mapViewState;
  const getCustomMarker = () => customMarker;
  const getSelectedDate = () => selectedDate;
  const getPageSizes = () => pageSizes;
  const getAverageServicePrice = () => averageServicePrice;
  const getUpdated = () => updated;
  const getRowId = () => rowId;
  const getCurrentId = () => currentId;
  const getCustomerType = () => customerType;
  const getCompaniesData = () => companiesData;
  const getCurrentTagFilter = () => currentTagFilter;
  const getClickedCoordinates = () => clickedCoordinates;
  const getSuggestionCompaniesList = () => suggestionCompaniesList;
  const getAveragePrice = () => averagePrice;
  const getAverageDistance = () => averageDistance;
  const getAverageServiceDistance = () => averageServiceDistance;
  const getCurrentServiceCategoryFilter = () => currentServiceCategoryFilter;
  const getLocationRequests = () => locationRequests;
  const getIsCompanyReacheble = () => isCompanyRecheable;
  const getIsCompanyConfirmedPrices = () => isCompanyConfirmedPrices;
  const getColumns = () => columns;
  const getShowCompanies = () => showCompanies;
  const setRowCurentId = (val: string) => setRowId(val);
  const getIsSearchCompanyFocused = () => isSearchCompanyFocused;
  const getIsSearchAddressFocused = () => isSearchAddressFocused;
  const setCurrentColumn = (columns: any[]) => setColumns(columns);
  const setNowCompanyMarker = (marker: CompanyMarker) => setCurrentCompanyMarker(marker);
  const getCurrentServiceNameFilter = () => currentServiceNameFilter;
  const getCurrentServiceVariantFilter = () => currentServiceVariantFilter;
  return {
    addCompany,
    currentTab,
    newService,
    handleFetchSearch,
    isVehicleLoading,
    changeReload,
    isVehicleResponse,
    vehicle,
    pickSuggestions,
    dropSuggestions,
    dataGridStyle,
    handleAddCompany,
    handleChangeAddService,
    handleCloseAddCompany,
    handleSelectSpecialtyAddService,
    handleSelectStateAddService,
    handleDeleteVehicleAddService,
    handleCheckboxPriceChange,
    handleCurrentServiceCategoryFilterChange,
    handleSelectSuggestion,
    handleSelectSpecialtyOptions,
    isEmailEnabled,
    setIsEmailEnabled,
    disabledAddServiceNextButton,
    disabledAddServiceSaveButton,
    saveService,
    fetchVehicle,
    getAddService,
    getVehicle,
    getCurrentTab,
    getPage,
    addCustomCompanyMarker,
    getPageSize,
    setCustomMarker,
    getTotalPages,
    getData,
    getCurrentServiceNameFilter,
    getPreviousData,
    getCurrentServiceVariantFilter,
    handleCurrentServiceVariantFilterChange,
    getMarkers,
    setClickedCoordinates,
    getCompanyMarkers,
    variants,
    getPreviousMarkers,
    getIsLoading,
    getIsMovingMap,
    getIsChecked,
    getIsBlocked,
    handleChangeQueryFilter,
    getServiceType,
    getAmountCompanies,
    getSearch,
    getCurrentCompanyId,
    getDialogOpen,
    handleCurrentServiceFilterClear,
    handleCurrentServiceNameFilterChange,
    getDialogOpen2,
    getMapViewState,
    getCustomMarker,
    getSelectedDate,
    getPageSizes,
    getUpdated,
    handleFocusAddressSearch,
    getRowId,
    handleCheckboxConfirmedPricesOpenChange,
    getCurrentId,
    getCustomerType,
    getCompaniesData,
    queryFilter,
    getIsCompanyPriced,
    getClickedCoordinates,
    getAverageServiceDistance,
    getAveragePrice,
    getAverageDistance,
    getLocationRequests,
    handleAmountCompanyChange,
    getAllTags,
    handleServiceChange,
    handleRowsPerPageChange,
    handleCompanyMarkerUpdate,
    handleGetAllTags,
    handleMarkerUpdateId,
    handleMarkerUpdate,
    handleClickSearch,
    handlePageChange,
    handleRadiusChange,
    handleMarkerClick,
    handleCompanyMarkerClick,
    handleBorder,
    handleSwitchChange,
    resetCompanyMarkers,
    handleSearchChange,
    handleMapClick,
    handledialogOpen,
    handledialogClose,
    handledialogClose2,
    getIsCompanyOpen,
    handledialogOpen2,
    handleItemClick,
    setRowCurentId,
    setCurrentColumn,
    getColumns,
    setCurrentVariants,
    centerMapOnLocation,
    getMapRef,
    getCurrentServiceMarker,
    getCurrentCompanyMarker,
    getShowCompanies,
    setNowCompanyMarker,
    generateAvatar,
    handleCompanyChange,
    getCompanyType,
    handleCompanySearchChange,
    handleClickCompanySearch,
    setDropSuggestionsEmpty,
    handleSearchChangeString,
    getAverageServicePrice,
    setCompanyMarkers,
    getIsCompanyReacheble,
    getSuggestionCompaniesList,
    clearCompanySuggestions,
    getShowOnly,
    handleCompanySearchEmpty,
    handleChangeTagFilter,
    getCurrentTagFilter,
    getSearchCompany,
    handleCheckboxOpenChange,
    handleFocusCompanySearch,
    getIsSearchCompanyFocused,
    getIsSearchAddressFocused,
    handleChangeSmsOption,
    saveUser,
    getAddTag,
    handleAddTag,
    handleCloseAddTag,
    handleCheckboxReachebleOpenChange,
    getCurrentServiceCategoryFilter,
    getIsCompanyConfirmedPrices,
    currentVariants,
    isSmsEnabled,
    setIsSmsEnabled
  };
};

export default UseGoogleCompaniesViewModel;
