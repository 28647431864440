import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
} from "@mui/material";
import ChatBotRequests from "requests/chatBotRequests";

interface UploadFileDialogProps {
  open: boolean;
  onClose: () => void;
  vectorStoreId: string;
  vectorStoreList: { id: string }[];
  emitFetchData: () => void;
}

const UploadFileDialog: React.FC<UploadFileDialogProps> = ({
  open,
  onClose,
  vectorStoreId,
  vectorStoreList,
  emitFetchData,
}) => {
  const [files, setFiles] = useState<FileList | null>(null);
  const [selectedVectorStoreId, setSelectedVectorStoreId] =
    useState<string>("None");
  const [chatBotRequests, setChatBotRequests] = useState(new ChatBotRequests());

  useEffect(() => {
    console.log("vectorStoreId ->", vectorStoreId);
    if (vectorStoreId) {
      setSelectedVectorStoreId(vectorStoreId);
    } else {
      setSelectedVectorStoreId("None");
    }
  }, [vectorStoreId]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      setFiles(selectedFiles);
    }
  };

  const handleChangeVectorStoreId = (event: SelectChangeEvent<string>) => {
    setSelectedVectorStoreId(event.target.value);
  };

  const handleUpload = async () => {
    if (!files || files.length === 0) {
      alert("Please select at least one file to upload.");
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    formData.append("vector_store_id", selectedVectorStoreId);

    try {
      let vectorId = "";

      if (selectedVectorStoreId !== "None") {
        vectorId = selectedVectorStoreId;
      }

      await chatBotRequests.uploadFile(vectorId, formData);
      emitFetchData();
      onClose();

    } catch (error) {
      console.error("Error uploading files:", error);
      alert("Error on upload file(s)");
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>Upload Files</DialogTitle>
      <DialogContent>
        <input type="file" onChange={handleFileChange} multiple />
        <Box mt={2}>
          {files && (
            <ul>
              <b>Selected Files:</b>
              {Array.from(files).map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          )}
        </Box>

        <Box mt={2}>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="vectorStoreIdLabel" color="secondary">
              Vector Store Id
            </InputLabel>
            <Select
              labelId="vectorStoreIdLabel"
              value={selectedVectorStoreId}
              onChange={handleChangeVectorStoreId}
              color="secondary"
              label="Vector Store Id"
            >
              <MenuItem value="None">None</MenuItem>
              {vectorStoreList.map((store) => (
                <MenuItem key={store.id} value={store.id}>
                  {store.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button variant="outlined" color="warning" onClick={handleUpload}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadFileDialog;
