import { styled } from '@mui/material/styles';
import Badge, { BadgeProps } from '@mui/material/Badge';

type Props = {
  theme?: any,
  isOnline: boolean
}

export const StyledBadge = styled(({ isOnline, ...props }: Props & BadgeProps) => (
  <Badge {...props} />
))(({ theme, isOnline }: Props) => ({
  '& .MuiBadge-badge': {
    backgroundColor: isOnline ? '#44b700' : '#7e7e7e',
    color: isOnline ? '#44b700' : '#7e7e7e',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      // animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));