import axios from "axios";
import { getMapboxToken, getMapboxStyle } from "config";
class LocationRequests {

  async getAddress(lat: number, lng: number) {

    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json`;
    const params = {
      access_token: getMapboxToken(),
      types: 'address',
      limit: 1
    };

    try {
      const response = await axios.get(url, { params });
      const features = response.data.features;

      if (features.length > 0) {
        const address = features[0].place_name;
        console.log('Address:', address);
        return address;
      } else {
        console.error('No address found for the coordinates.');
        return null;
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      return null;
    }
  }

  async getLocationInfo(lat:number, lng:number) {
    console.log('lat ->', lat);
    console.log('lng ->', lng);
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json`;
    const params = {
      access_token: getMapboxToken(),
      types: 'place,region,country,locality,neighborhood',  // Less restrictive
      limit: 1
    };

    try {
      const response = await axios.get(url, { params });
      console.log('Full Response:', response.data);  // Print the full response

      const features = response.data.features;

      if (features.length > 0) {
        const feature = features[0];
        const locationInfo = {
          city: null,
          state: null,
          country: null
        };

        // Attempt to extract relevant information
        if (feature.context) {
          for (const contextFeature of feature.context) {
            if (contextFeature.id.includes('place')) {
              locationInfo.city = contextFeature.text;
            } else if (contextFeature.id.includes('region')) {
              locationInfo.state = contextFeature.text;
            } else if (contextFeature.id.includes('country')) {
              locationInfo.country = contextFeature.text;
            }
          }
        }

        console.log('Location Info:', locationInfo);
        return locationInfo;
      } else {
        console.error('No location information found for the coordinates.');
        return null;
      }
    } catch (error) {
      console.error('Error fetching location information:', error);
      return null;
    }
  }
  async getLocation(address:string) {
    const url = `https://geocode.maps.co/search?q={${address}`;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error("error : ",error)
    }
  }
}



export default LocationRequests;
