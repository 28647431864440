import React, { useState } from "react";
import { GreeterClient } from "./greet/GreetServiceClientPb"; // Adjust path according to your structure
import { HelloRequest } from "./greet/greet_pb"; // Adjust path
import { Box, Button, TextField, Typography } from "@mui/material";
import { getHostPythonGrpcBaseUrl } from "config";

const ClientStreamingInterface = () => {
  const [name, setName] = useState<string>("");
  const [greeting, setGreeting] = useState<string>("");
  const [requests, setRequests] = useState<HelloRequest.AsObject[]>([]);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [summaryResponse, setSummaryResponse] = useState<string>("");

  const client = new GreeterClient(getHostPythonGrpcBaseUrl(), null, null);

  // Function to handle adding messages to the local "sent" list
  const handleSendMessage = () => {
    const newRequest = { name, greeting };
    setRequests((prev) => [...prev, newRequest]);
  };

  // Function to send all collected messages as a summary request when streaming ends
  const endStreamingAndSummarize = async () => {
    setIsStreaming(false);
    try {
      // Simulating a response, so we don't actually need to assign it to 'response'
      await client.sayHello(new HelloRequest(), {}); // Assume this sends the collected data
      // Simulating the response content directly in state update
      setSummaryResponse(
        JSON.stringify(
          {
            request: requests,
            message: `You have sent ${requests.length} messages. Please expect a delayed response.`,
          },
          null,
          2
        )
      ); // Format the JSON for display
    } catch (err: any) {
      console.error("Error with summary request:", err.message);
    }
  };

  // Start or stop the simulated streaming
  const toggleStreaming = () => {
    setIsStreaming(!isStreaming);
    if (isStreaming) {
      endStreamingAndSummarize();
    } else {
      setRequests([]); // Clear previous session data
      setSummaryResponse("");
    }
  };

  return (
    <Box display="flex" flexDirection="column" mb={4}>
      <Typography mb={2} variant="h3">
        Simulated Client Streaming gRPC Test
      </Typography>
      <Box display="flex" mb={2}>
        <TextField
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Name"
          variant="filled"
        />{" "}
        &nbsp;&nbsp;
        <TextField
          type="text"
          value={greeting}
          onChange={(e) => setGreeting(e.target.value)}
          label="Greeting"
          variant="filled"
        />{" "}
        &nbsp;&nbsp;
        <Button
          style={{ textTransform: "none", width: "124px" }}
          color="secondary"
          variant="outlined"
          onClick={toggleStreaming}
        >
          {isStreaming ? "Stop Streaming & Summarize" : "Start Streaming"}
        </Button>
        &nbsp;
        {isStreaming && (
          <Button
            style={{
              background: "#FF7C06",
              textTransform: "none",
              paddingRight: "16px",
              paddingLeft: "16px",
              width: "124px",
            }}
            onClick={handleSendMessage}
          >
            Send Greeting
          </Button>
        )}
      </Box>
      {requests.length > 0 && (
        <div style={{ marginBottom: "14px" }}>
          <b>Messages:</b>
          <br />
          {requests.map((req, index) => (
            <p key={index}>{`Sent: ${req.name} says '${req.greeting}'`}</p>
          ))}
        </div>
      )}

      {summaryResponse && (
        <div>
          <b>Summary Response from Server:</b> <br />
          <pre>{summaryResponse}</pre>
        </div>
      )}
    </Box>
  );
};

export default ClientStreamingInterface;
