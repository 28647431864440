import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'

type Props = {
  options: any[],
  selected: string,
  handleRadiusChange: any
}

const RadiusCheckbox = ({ options, selected, handleRadiusChange }: Props) => {
  return (
    <FormControl sx={{ display: 'flex', justifyContent: 'flex-end', color: 'white' }}>
      <RadioGroup
        row
        value={selected}
        onChange={handleRadiusChange}
      >
        {
          options.map((option, i) => {
            return (
              <FormControlLabel
                key={i + "radius"}
                value={option.value}
                label={option.label}
                control={
                  <Radio
                    color="secondary"
                    sx={{
                      '& .Mui-checked': {
                        backgroundColor: 'white', // Transparent outer circle when checked
                        border: '1px solid white', // White border when checked
                      },
                    }}
                  />
                }
              />
            )
          })
        }

      </RadioGroup>
    </FormControl>
  )
}

export default RadiusCheckbox