import { getHostPythonWsBaseUrl, getHostPythonAPIBaseUrl } from 'config';
import { MarkerWs } from "../Map";

export function webSocketConnection(callbackUser: (data: MarkerWs) => void, callbackCompany: (data: MarkerWs) => void, route: string) {
    console.log('IP_WS_HOST ', getHostPythonAPIBaseUrl());

   
    let socket = new WebSocket(`${getHostPythonWsBaseUrl()}/ws`); 

    socket.onopen = () => {
        console.log("WebSocket connected");
    };


    socket.onmessage = (event) => {
        const message = event.data;
        console.log(message);
        //if (typeof message === "string") return;
        const markerWs: MarkerWs = JSON.parse(message) as MarkerWs;
        console.log(`${route} message: `, markerWs);
        markerWs.isClient ? callbackUser(markerWs) : callbackCompany(markerWs);
    };

    return () => {
        if (socket.readyState === WebSocket.OPEN) {
            socket.close(); // Close the WebSocket connection when the component unmounts
        }
    };
}
