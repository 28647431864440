import React, { useMemo, useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import DeleteActions from "./DeleteActions";
import UserRequests from "../../requests/userRequests";
import FilterComponent from "./Filter";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import DialogNotifier from "./DialogNotification";

const Customers = () => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(8);
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rowId, setRowId] = useState<any>(null);
  const pageSizes = [8, 16, 32, 64];

  const [filter, setFilter] = useState<any>({});
  const [logicOperator, setLogicOperator] = useState<string>("$and");
  const [isOpenNotifier, setIsOpenNotifier] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      const userRequests = new UserRequests();
      try {
        const paginationData = await userRequests.getPaginationGraphQL(
          filter,
          page,
          pageSize,
          logicOperator
        );

        console.log(paginationData);
        setData(paginationData.payload);

        setIsLoading(false);
        // Process the paginationData here
      } catch (error) {
        // Handle the error here
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [pageSize, page, filter]);

  useEffect(() => {
    setPage(1);
  }, [filter]);

  const handleRowsPerPageChange = (event: any): void => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const columns = useMemo(
    () => [
      {
        field: "profileImg",
        headerName: "Avatar",
        flex: 0.2,
        renderCell: (params: any) => (
          <Avatar
            src={params.value?.uri}
            sx={{ width: params.width, height: params.height }}
          />
        ),
        sortable: false,
        filterable: false,
      },
      {
        field: "name",
        headerName: "Name",
        flex: 0.4,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 0.6,
      },
      {
        field: "phoneNumber",
        headerName: "Phone Number",
        flex: 0.6,
      },
      {
        field: "companyName",
        headerName: "Category",
        flex: 0.5,
        renderCell: (params: any) => (
          <div>
            {params.row.isCompany || params.row.isSpecialist
              ? "Company"
              : params.row.isClient
              ? "Client" : "Other"}
          </div>
        ),
      },

      {
        field: "delete",
        headerName: "Delete forever",
        flex: 0.2,
        renderCell: (params: any) => <DeleteActions email={params.row.email} />,
      },
    ],
    []
  );

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="CUSTOMERS" subtitle="List of Customers" />
      <Box display={"flex"} justifyContent={"end"} width={"100%"}>
        <Button
          onClick={() => {
            setIsOpenNotifier(true);
          }}
          variant="outlined"
          color="warning"
        >
          Notifier&nbsp;<NotificationsActiveIcon></NotificationsActiveIcon>
        </Button>
      </Box>

      <DialogNotifier
        open={isOpenNotifier}
        onClose={() => {
          setIsOpenNotifier(false);
        }}
        filter={filter}
        logicOperator={logicOperator}
      ></DialogNotifier>

      <Box mt={3}>
        <FilterComponent
          filterCallBackFn={setFilter}
          logicOperatorBackFn={setLogicOperator}
        ></FilterComponent>
      </Box>

      <Box mt="20px" height="75vh">
        <DataGrid
          loading={isLoading || !data}
          // @ts-ignore
          getRowId={(row) => row.id}
          rows={data || []}
          columns={columns}
          hideFooter
          pageSize={pageSize}
          onRowClick={(params) => {
            setRowId(params.id);
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          <Box mr={3}>
            <IconButton
              disabled={page === 1}
              onClick={() => {
                setPage(page - 1);
              }}
            >
              <ArrowBackIosIcon></ArrowBackIosIcon>
            </IconButton>
            <IconButton
              onClick={() => {
                setPage(page + 1);
              }}
            >
              <ArrowForwardIosIcon></ArrowForwardIosIcon>
            </IconButton>
          </Box>

          <div
            style={{
              marginLeft: 16,
              marginBottom: 16,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label style={{ fontSize: 9 }}> Items per page</label>
            <Select
              style={{
                marginTop: 4,
                width: 64,
                height: 28,
                border: `1px solid rgba(255, 255, 255,.4)`,
              }}
              value={pageSize}
              onChange={handleRowsPerPageChange}
            >
              {pageSizes.map((size) => (
                <MenuItem key={size + "<MenuItem key={size}"} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Customers;
