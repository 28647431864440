import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, useNavigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Services from "scenes/services";
import Customers from "scenes/customers";

import Geography from "scenes/geography";
import Payment from "scenes/payment";
import Daily from "scenes/daily";
import Monthly from "scenes/monthly";
import Breakdown from "scenes/breakdown";
import Admin from "scenes/admin";
import Performance from "scenes/performance";
import Vehicles from "scenes/vehicles";
import Reviews from "scenes/reviews";
import Login from "scenes/login";
import Connect from "scenes/connect/index";
import Signup from "scenes/signup";
import Recover from "scenes/recoverPassword";
import LiveMap from "scenes/liveMap";
import OpenAI from "scenes/openai";
import MaiaAI from "scenes/maiaAI"
import Heatmap from "scenes/heatmap";
import Chat from "scenes/chat";
import CompanyManagement from "./scenes/companyMagangement";
import { ChakraProvider } from "@chakra-ui/react";
import ServiceRequest from "scenes/servicesRequest";
import GoogleCompanies from "scenes/googleCompanies";
import axios from "axios";
import GrpcTest from "./scenes/grpcTest";
import { APP_ENVIRONMENT } from "config";
import ActivityLogs from "scenes/activityLogs";

function App() {
  const mode = useSelector((state: any) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response &&
        error.response.status === 401 
      ) {
        localStorage.clear();
        window.location.reload();
        return Promise.reject(error);
      }
    }
  );

  return (
    <div className="app">
      <BrowserRouter>
        <ChakraProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route element={<Layout />}>
                {isAuthenticated ? (
                  <>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/live roadr" element={<LiveMap />} />
                    <Route path="/open ai" element={<OpenAI />} />
                    <Route path="/maia" element={<MaiaAI />} />
                    <Route path="/heatmap" element={<Heatmap />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/requests" element={<ServiceRequest />} />
                    <Route path="/customers" element={<Customers />} />
                    <Route path="/geography" element={<Geography />} />
                    <Route path="/payment" element={<Payment />} />
                    <Route path="/daily" element={<Daily />} />
                    <Route path="/monthly" element={<Monthly />} />
                    <Route path="/breakdown" element={<Breakdown />} />
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/performance" element={<Performance />} />
                    <Route path="/vehicles" element={<Vehicles />} />
                    <Route path="/reviews" element={<Reviews />} />
                    <Route path="/Activity Logs" element={<ActivityLogs />} />

                    <Route path="/operation" element={<GoogleCompanies />} />
                    <Route
                      path="/company managment"
                      element={<CompanyManagement />}
                    />

                    <Route path="/chat/:id" element={<Chat />} />
                    <Route path="/chat" element={<Chat />} />
                    <Route path="/grpc-test" element={<GrpcTest />} />
                  </>
                ) : (
                  <>
                    <Route path="/" element={<Login />} />
                    <Route path="/connect" element={<Connect />} />
                    <Route path="/dashboard" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup/:seed" element={<Signup />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/recover" element={<Recover />} />
                    <Route path="/recover/:seed/:id" element={<Recover />} />
                  </>
                )}
              </Route>
            </Routes>
          </ThemeProvider>
        </ChakraProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
