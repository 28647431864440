import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { getMapboxToken } from "config";
import companyData from "./companyData.json";
import Button from "components/Button/Button";
import TabOptions from "components/TabOptions/TabOptions";
import React, { useEffect, useState } from "react";
import { ADD_COMPANY_TAB_OPTIONS, COMPANY_QUERIES } from "utils/const";
import { styles } from "./styles";
import CompanyRequests from "requests/companyRequests";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { CategoryOptionsType, categoryOptions } from "./options"

const companyRequests = new CompanyRequests();

type Props = {
  open: boolean;
  title: string;
  onClose: () => void;
};

const generateQueries = () => {
  const id = generateRandomHexId();
  const emptyQueries = [] as any[];

  COMPANY_QUERIES.forEach((q) => {
    emptyQueries.push(`${q}#${id}`);
  });

  return emptyQueries;
};
function generateRandomHexId(length = 24) {
  const characters = "0123456789abcdef";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

const AddCompanyDialog = ({ open, title, onClose }: Props) => {
  const [formData, setFormData] = useState<any>({ ...companyData });
  const [currentTab, setCurrentTab] = useState(ADD_COMPANY_TAB_OPTIONS[0]);
  const [pickSuggestions, setPickSuggestions] = useState([]);
  const [allQueries, setAllQueries] = useState<any>(generateQueries());

  const handleClose = () => {
    onClose();
  };

  const handleAddressChange = (event: any) => {
    const address = event.target.value;
    setFormData({
      ...formData,
      geometry: {
        ...formData.geometry,
        formattedAddress: address,
      },
    });
    fetchAutocompleteOptions(address);
  };

  function extractLocationInfo(address: string) {
    const cityRegex =
      /, ([A-Za-z\s]+),\s*[A-Za-z\s]+\s*\d{5},\s*United States$/;
    const stateRegex = /, ([A-Za-z\s]+)\s*\d{5},\s*United States$/;
    const countryRegex = /, United States$/;

    const cityMatch = address.match(cityRegex);
    const stateMatch = address.match(stateRegex);
    const countryMatch = address.match(countryRegex);

    let city = null;
    let state = null;
    let country = null;

    if (cityMatch && cityMatch[1]) {
      city = cityMatch[1];
    }

    if (stateMatch && stateMatch[1]) {
      state = stateMatch[1];
    }

    if (countryMatch) {
      country = "United States";
    }

    return {
      city: city,
      state: state,
      country: country,
    };
  }

  const handleClickSave = async () => {
    try {
      await companyRequests.addCompanyAggregate(formData);
      console.log("Company added successfully!"); // Optional success message
      setFormData({ ...companyData });
    } catch (error) {
      console.error("Error adding company:", error);
      alert("Error adding company");
    }
  };

  const fetchAutocompleteOptions = (address: string) => {
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?proximity=ip&access_token=${getMapboxToken()}`
    )
      .then((response) => response.json())
      .then((data) => {
        setPickSuggestions(
          data.features.map((place: any) => ({
            label: place.place_name,
            location: place.center,
          }))
        );
      })
      .catch((error) => console.error("Error fetching data: ", error));
  };

  const isValidPhone = () => {
    return !/^\d*$/.test(formData.contact.internationalPhoneNumber);
  };

  const isValidEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return !emailRegex.test(formData.contact.email);
  };

  const arraysEqual = (a: any[], b: any[]) => {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "20px",
          gap: "15px",
          width: "858px",
          minHeight: "150px",
          height: "auto",
          border: "1px solid rgba(126, 126, 126, 0.60)",
          borderRadius: "8px",
          background: "#191919 !important",
          boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.30)",
          backdropFilter: "blur(15px)",
        },
      }}
      open={open}
    >
      <Box style={styles.dialogContainer}>
        <Box style={styles.header}>
          <DialogTitle style={styles.dialogTitle}>{title}</DialogTitle>
          <DialogActions>
            <IconButton onClick={handleClose}>
              <CloseOutlined />
            </IconButton>
          </DialogActions>
        </Box>
        <DialogActions style={styles.tabOptionsContainer}>
        
        </DialogActions>
        {/* INFORMATION TAB */}
        {currentTab === ADD_COMPANY_TAB_OPTIONS[0] && (
          <DialogContent style={styles.inputsContainer}>
            <Box display="flex" flexDirection={"column"}>
              <Box mt={1} display="flex" flexDirection={"column"}>
                <TextField
                  color="secondary"
                  label="Company name"
                  value={formData.name}
                  onChange={(e: any) => {
                    setFormData({
                      ...formData,
                      name: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Box>
              <Box mt={2} display="flex" flexDirection={"column"}>
                <TextField
                  color="secondary"
                  label="Internacional Phone"
                  value={formData.contact.internationalPhoneNumber}
                  onChange={(e: any) => {
                    setFormData({
                      ...formData,
                      contact: {
                        ...formData.contact,
                        internationalPhoneNumber: e.target.value + "",
                      },
                    });
                  }}
                  fullWidth
                />

                {isValidPhone() && (
                  <Typography color="error">
                    Number should only contain digits, no spaces or special
                    characters
                  </Typography>
                )}
              </Box>

              <Box mt={2} display="flex" flexDirection={"column"}>
                <TextField
                  color="secondary"
                  label="E-mail"
                  value={formData.contact.email}
                  onChange={(e: any) => {
                    setFormData({
                      ...formData,
                      contact: {
                        ...formData.contact,
                        email: e.target.value,
                      },
                    });
                  }}
                  fullWidth
                />

                {isValidEmail() && formData.contact.email && (
                  <Typography color="error">
                   E-mail invalid
                  </Typography>
                )}
              </Box>
              <Box mt={2} display="flex" flexDirection={"column"}>
                <Typography mb={1}>Query</Typography>
                <Select
                  color="secondary"
                  labelId="select-label"
                  placeholder="Select a Query"
                  value={formData._id || ""}
                  onChange={(event) => {
                    const selectedId = event.target.value;
                    setFormData({
                      ...formData,
                      _id: selectedId,
                      placeId: selectedId.split("#")[1],
                      contact: {
                        ...formData.contact,
                        _id: selectedId + "_contact",
                      },
                      detail: {
                        ...formData.detail,
                        _id: selectedId + "_detail",
                      },
                      geometry: {
                        ...formData.geometry,
                        _id: selectedId + "_geometry",
                      },
                      basePrices: {
                        ...formData.basePrices,
                        _id: selectedId + "_prices",
                      },
                      specialties: {
                        ...formData.specialties,
                        _id: selectedId + "_specialties",
                      },
                    });
                  }}
                  label="Select a Query"
                >
                  {allQueries.map((q: any, i: number) => (
                    <MenuItem key={q + i} value={q}>
                      {q}
                    </MenuItem>
                  ))}
                </Select>
                <Box mt={2}>
                  <button
                    onClick={() => {
                      setAllQueries(generateQueries());
                    }}
                    style={{
                      height: 40,
                      width: 40,
                      marginBottom: "8px",
                      border: "1px solid white",
                      borderRadius: "8px",
                    }}
                    color="secondary"
                  >
                    <RestartAltIcon></RestartAltIcon>
                  </button>
                </Box>
              </Box>
              <Box mt={1}>
                <TextField
                  color="secondary"
                  label="Address"
                  value={formData.geometry.formattedAddress}
                  onChange={handleAddressChange}
                  fullWidth
                />
                {!arraysEqual(
                  formData.geometry.location.coordinates,
                  [0.0, 0.0]
                ) && (
                  <Box mt={1}>
                    <Box display="flex">
                      <b>City:&nbsp;</b>
                      <span>{formData.geometry.city}</span>
                    </Box>
                    <Box display="flex">
                      <b>State:&nbsp;</b>
                      <span>{formData.geometry.state.replace(/\s+/g, "")}</span>
                    </Box>
                    <Box display="flex">
                      <b>country:&nbsp;</b>
                      <span>{formData.geometry.country}</span>
                    </Box>
                  </Box>
                )}
                <Box mt={2} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" gap={2}>
                  <Box mt={2} display="flex" flexDirection={"column"}>
                    <Typography mb={1}>Google Rating</Typography>
                    <input
                      type="number"
                      id="quantity"
                      name="quantity"
                      min="1"
                      max="5"
                      style={{
                        width: "30px",
                        padding: "0px 3px",
                        marginLeft: "4px",
                        backgroundColor: "transparent",
                        border: "1px solid rgba(255,255,255,0.22)",
                        borderRadius: "5px",
                      }}
                      value={formData.contact.rating}
                      onChange={(e: any) => {
                        const value = parseFloat(e.target.value);

                        if (value && (isNaN(value) || value < 1 || value > 5)) {
                          return;
                        }
                        setFormData({
                          ...formData,
                          contact: {
                            ...formData.contact,
                            rating: value,
                          },
                        });
                      }}
                    />
                  </Box>
                  <Box mt={2} display="flex" flexDirection={"column"}>
                    <Typography mb={1}>Select Category</Typography>
                    <Select
                      color="secondary"
                      value={formData.category}
                      onChange={(event) => {
                        const selectedService = event.target.value as keyof CategoryOptionsType;
                        setFormData({
                          ...formData,
                          category: selectedService,
                        });
                      }}
                      label="Select Category"
                    >
                      {Object.keys(categoryOptions).map((key) => (
                        <MenuItem key={key} value={key}>
                          {categoryOptions[key as keyof CategoryOptionsType]}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box mt={1} display="flex" flexDirection={"column"}>
                    <Typography mb={1}>Currency</Typography>
                    <TextField
                      color="secondary"
                      value={formData.currency}
                      onChange={(e: any) => {
                        setFormData({
                          ...formData,
                          currency: e.target.value,
                        });
                      }}
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>
              {pickSuggestions.length > 0 &&
                formData.geometry.formattedAddress && (
                  <List
                    sx={{
                      height: "auto",
                      border: "1px solid grey",
                    }}
                  >
                    {pickSuggestions.map((suggestion: any, i: number) => (
                      <ListItem
                        key={i + "pickSuggestions"}
                        onClick={async () => {
                          const result = extractLocationInfo(suggestion.label);

                          setFormData({
                            ...formData,
                            geometry: {
                              ...formData.geometry,
                              formattedAddress: suggestion.label,
                              location: {
                                ...formData.geometry.location,
                                coordinates: suggestion.location,
                              },
                              city: result.city ? result.city : "",
                              state: result.state
                                ? result.state.replace(/\s+/g, "")
                                : "",
                              country: result.country ? result.country : "",
                            },
                          });

                          setPickSuggestions([]);
                        }}
                      >
                        <Typography>{suggestion.label}</Typography>
                      </ListItem>
                    ))}
                  </List>
                )}
              <Box flex={"inline-flex"} mt={3}>
                <Checkbox
                  checked={formData.contact.reachable}
                  onChange={(event) => {
                    const isChecked = event.target.checked;

                    setFormData({
                      ...formData,
                      contact: {
                        ...formData.contact,
                        reachable: isChecked,
                      },
                    });
                  }}
                  color="success"
                />
                <small>Reachable</small>

                <Checkbox
                  style={{ marginLeft: 25 }}
                  checked={formData.basePrices.hasConfirmedPrices}
                  onChange={(event) => {
                    const isChecked = event.target.checked;

                    setFormData({
                      ...formData,
                      basePrices: {
                        ...formData.basePrices,
                        hasConfirmedPrices: isChecked,
                      },
                    });
                  }}
                  color="success"
                />
                <small>Has confirmed prices</small>

                <Checkbox
                  checked={formData.contact.smsEnabled}
                  onChange={(event) => {
                    const isChecked = event.target.checked;

                    setFormData({
                      ...formData,
                      contact: {
                        ...formData.contact,
                        smsEnabled: isChecked,
                      },
                    });
                  }}
                  color="success"
                />
                <small>Sms Enabled</small>

                <Checkbox
                  checked={formData.isVerified}
                  onChange={(event) => {
                    const isChecked = event.target.checked;

                    setFormData({
                      ...formData,
                      isVerified: isChecked,
                    });
                  }}
                  color="success"
                />
                <small>Is Verified</small>

                <Checkbox
                  checked={formData.isFeatured}
                  onChange={(event) => {
                    const isChecked = event.target.checked;

                    setFormData({
                      ...formData,
                      isFeatured: isChecked,
                    });
                  }}
                  color="success"
                />
                <small>Is Featured</small>

                <Checkbox
                  checked={formData.isGovernmentInstitution}
                  onChange={(event) => {
                    const isChecked = event.target.checked;

                    setFormData({
                      ...formData,
                      isGovernmentInstitution: isChecked,
                    });
                  }}
                  color="success"
                />
                <small>Is Gover Insti</small>

              </Box>
              <Box mt={2}>
                <button
                  onClick={() => {
                    setFormData({ ...companyData });
                  }}
                  style={{
                    height: 32,
                    width: 120,
                    marginBottom: "8px",
                    border: "1px solid white",
                    borderRadius: "8px",
                  }}
                  color="secondary"
                >
                  Clear All
                </button>
              </Box>
            </Box>
            <DialogContent style={styles.inputRowContainer}></DialogContent>
          </DialogContent>
        )}
        {/* SERVICE REQUEST TAB */}

        <DialogContent style={styles.inputRowContainer}>
          <FormControl
            style={styles.stateContainer}
            sx={{
              "& label.Mui-focused": {
                color: "#FF7C06 !important", // Change the label color when focused
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#FF7C06 !important", // Border color when input is focused
                },
              },
            }}
          ></FormControl>
          <Box
            sx={{ display: "flex", flexDirection: "column", width: "50%" }}
          ></Box>
        </DialogContent>
        <DialogContent style={styles.inputRowContainer}></DialogContent>

        {currentTab !==
          ADD_COMPANY_TAB_OPTIONS[ADD_COMPANY_TAB_OPTIONS.length - 1] && (
          <DialogActions style={styles.buttonContainer}>
            <Button
              text="Save"
              onClick={handleClickSave}
              disabled={isValidPhone() || isValidEmail()}
            />
          </DialogActions>
        )}
      </Box>
    </Dialog>
  );
};

export default AddCompanyDialog;
