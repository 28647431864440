export const styles: TabOptionsStyles = {
tabDefault: {
    width: "25%",
    display: "flex",
    justifyContent: "center",
    borderBottom: "2px solid #fff",
    paddingBottom: 8,
  },
  buttonTab: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "-0.32px",
    color: "#fff",
    margin: "0 auto",
    background: "transparent",
    textDecoration: "none",
    cursor: "pointer",
    width: "100%",
    border: "none",
  },
};

interface TabOptionsStyles {
  tabDefault: React.CSSProperties;
  buttonTab: React.CSSProperties;
}
