import React, { useMemo } from "react";
import {
  Box,
  Pagination,
  Select,
  MenuItem,
  Avatar,
  Typography,
  Fab,
  Checkbox,
  Button,
} from "@mui/material";

import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import Switch from "@mui/material/Switch";

import {
  SERVICE_NAME_OPTIONS,
  SERVICE_OPTIONS,
  SERVICE_WASH_NAME_OPTIONS,
  SERVICE_ROADSIDE_NAME_OPTIONS,
  COMPANY_QUERY,
} from "../../utils/const";

import GenericInput from "components/GenericInput/GenericInput";
import Dropdown from "scenes/companyMagangement/component/dropdown";
import StaticMapCompanies from "components/StaticMapCompanies";
import SearchBar from "components/SearchBar/SearchBar";
import { styles } from "./styles";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import AddCompanyDialog from "components/AddCompanyDialog/AddCompanyDialog";
import UseGoogleCompaniesViewModel from "view_models/googleCompanies";
import TagDialog from "./addTagDialog";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { formattedDate } from "utils/helper";
import jumpIcon from "../../assets/icons/services/jumpSelected.svg";
import refuelIcon from "../../assets/icons/services/refuelSelected.svg";
import tireChangeIcon from "../../assets/icons/services/tireChangeSelected.svg";
import towingIcon from "../../assets/icons/services/towingSelected.svg";
import unlockIcon from "../../assets/icons/services/unlockSelected.svg";
import carWashIcon from "../../assets/icons/services/carWashSelected.svg";
import { List, ListItem } from "@mui/material";
import DropdownCheckbox from "scenes/companyMagangement/component/dropdownCheckbox";

const ServiceRequest = () => {
  const viewModel = UseGoogleCompaniesViewModel();

  const columns: any = useMemo(
    () => [
      {
        field: "created",
        headerName: "Date",
        flex: 0.4,
        valueFormatter: (params: any) => {
          if (!params.value == null) {
            return "";
          }
          const date = formattedDate(params.value);
          return date;
        },
      },
      {
        field: "companyName",
        headerName: "Company Name",
        flex: 0.4,
        valueFormatter: (params: any) => {
          return params.value;
        },
      },
      {
        field: "customerName",
        headerName: "Customer Name",
        flex: 0.4,
        valueGetter: (params:any) => params.row.customer?.name || '',
      }, 
      {
        field: "companyPhoneNumber",
        headerName: "Company Phone",
        flex: 0.4,
        valueGetter: (params: any) => params.row.companyPhoneNumber,
      },
      {
        field: "customerPhone",
        headerName: "Customer Phone",
        flex: 0.4,
        valueGetter: (params:any) => params.row.customer?.phoneNumber || '',
      },
      {
        field: "specialty",
        headerName: "Specialty",
        flex: 0.3,
        renderCell: (params: any) => {
          const service = params.value.name;
          const serviceType =
            service === "jump"
              ? jumpIcon
              : service === "tireChange"
              ? tireChangeIcon
              : service === "refuel"
              ? refuelIcon
              : service === "unlock"
              ? unlockIcon
              : service.toLowerCase().includes("wash")
              ? carWashIcon
              : towingIcon;
          return (
            <Avatar
              src={serviceType}
              sx={{ width: "50px", height: "50px", mt: "8px" }}
            />
          );
        },
      },
      {
        field: "location1",
        headerName: "Vehicle Location",
        flex: 0.6,
        valueGetter: (params: any) => {
          if (params.row.locations && params.row.locations[0]) {
            return params.row.locations[0].address;
          }
          return "";
        },
      },
      {
        field: "location2",
        headerName: "Drop Location",
        flex: 0.6,
        valueGetter: (params: any) => {
          if (params.row.locations && params.row.locations[1]) {
            return params.row.locations[1].address;
          }
          return "";
        },
      },
      {
        field: "_id",
        headerName: "Map",
        flex: 0.2,

        renderCell: (params: { value: any }) => (
          <Box>
            {params.value ? (
              <Fab
                size="small"
                variant="extended"
                style={styles.fabLocation}
                onClick={() => viewModel.handleItemClick(params)}
              >
                <AddLocationIcon />
              </Fab>
            ) : (
              <span>No value</span>
            )}
          </Box>
        ),
      },
    ],
    [viewModel.getCompanyMarkers(), viewModel.getData()]
  );

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Operation" />

      <Box mt={6} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box display="flex" flexWrap={"wrap"} gap={2}>
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="column">
                <Typography variant="subtitle2">Tags</Typography>
                <Dropdown
                  options={viewModel.getAllTags()}
                  onChange={(value: string) => {
                    viewModel.handleChangeTagFilter(value);
                  }}
                  value={viewModel.getCurrentTagFilter()}
                  icon="show"
                  width="180px"
                />
              </Box>

              <Box display="flex" mt={2} flexDirection="column">
                <Typography variant="subtitle2">Query</Typography>
                <Dropdown
                  options={COMPANY_QUERY}
                  onChange={(value: string) => {
                    viewModel.handleChangeQueryFilter(value);
                  }}
                  value={viewModel.queryFilter}
                  icon="company"
                  width="180px"
                />
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              style={{
                border: "1px solid rgba(255,255,255,0.2)",
                padding: "16px 8px",
                borderRadius: "17px",
                // width:"37vw"
              }}
            >
              <Typography variant="subtitle1">Specialty</Typography>
              <Box display="flex" mt={1}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="subtitle2">Categories</Typography>
                  <Dropdown
                    options={SERVICE_OPTIONS}
                    onChange={(value: string) => {
                      viewModel.handleCurrentServiceCategoryFilterChange(value);
                    }}
                    placeholder=""
                    value={viewModel.getCurrentServiceCategoryFilter()}
                    icon="category"
                    width="180px"
                  />
                </Box>
                &nbsp;
                {viewModel.getCurrentServiceCategoryFilter().length > 0 && (
                  <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle2">Services</Typography>
                    <Dropdown
                      options={
                        !viewModel.getCurrentServiceCategoryFilter()
                          ? SERVICE_NAME_OPTIONS
                          : viewModel.getCurrentServiceCategoryFilter() ===
                            "wash"
                          ? SERVICE_WASH_NAME_OPTIONS
                          : SERVICE_ROADSIDE_NAME_OPTIONS
                      }
                      onChange={(value: string) => {
                        viewModel.handleCurrentServiceNameFilterChange(value);
                      }}
                      placeholder=""
                      value={viewModel.getCurrentServiceNameFilter()}
                      icon="service"
                      width="180px"
                    />
                  </Box>
                )}
                &nbsp;
                {viewModel.getCurrentServiceNameFilter().length > 0 && (
                  <Box display="flex" flexDirection="column">
                    <Typography marginLeft={"16px"} variant="subtitle2">
                      Variants
                    </Typography>
                    <DropdownCheckbox
                      options={viewModel.variants}
                      onChange={(value: string) => {
                        viewModel.handleCurrentServiceVariantFilterChange(
                          value
                        );
                      }}
                      placeholder=""
                      value={""}
                      icon="tables"
                      width="180px"
                      callback={viewModel.setCurrentVariants}
                    />
                  </Box>
                )}
                &nbsp; &nbsp;
                <Button
                  onClick={() => {
                    viewModel.handleCurrentServiceFilterClear();
                  }}
                  style={{ height: 32, marginTop: "25px" }}
                  variant="outlined"
                  color="secondary"
                >
                  Clear X
                </Button>
              </Box>
            </Box>
          </Box>

          <Box
            marginTop={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Box display={"flex"}>
              <SearchBar
                width="456px"
                inputCallback={(e: any) =>
                  viewModel.handleCompanySearchChange(e)
                }
                fetch={viewModel.handleFetchSearch}
                buttonCallback={() => viewModel.handleClickCompanySearch()}
                onFocus={() => {
                  viewModel.handleFocusCompanySearch(true);
                }}
                onBlur={async () => {
                  await new Promise((resolve) => setTimeout(resolve, 1000));
                  viewModel.handleFocusCompanySearch(false);
                }}
                placeholder={"Search companies ..."}
              />
              <Box
                marginTop={1}
                ml={3}
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
              >
                <p>{viewModel.getIsChecked() ? "" : "search by name"}</p>
                <Switch
                  checked={viewModel.getIsChecked()}
                  onChange={() => viewModel.handleSwitchChange()}
                  color="info"
                />
                <p>{viewModel.getIsChecked() ? "search by _id" : ""}</p>
              </Box>
            </Box>

            {viewModel.getSearchCompany() !== "" &&
              viewModel.getSuggestionCompaniesList() &&
              viewModel.getIsSearchCompanyFocused() &&
              viewModel.getSuggestionCompaniesList().length > 0 && (
                <List sx={styles.listLarger}>
                  {viewModel
                    .getSuggestionCompaniesList()
                    .map((suggestion: any, i: number) => (
                      <ListItem
                        key={i + "suggestion1"}
                        onClick={() => {
                          const lat =
                            suggestion.geometry.location.coordinates[1];
                          const lng =
                            suggestion.geometry.location.coordinates[0];

                          viewModel.handleClickSearch();
                          viewModel.handleCompanySearchEmpty();
                          viewModel.centerMapOnLocation(lat, lng, 16);
                          //viewModel.handleMapClick({ lngLat: { lng, lat } });

                          viewModel.setCustomMarker({
                            id: Date.now(),
                            latitude: lat,
                            longitude: lng,
                          });
                          viewModel.clearCompanySuggestions();
                          suggestion.distance = 0;
                          viewModel.setCompanyMarkers([suggestion]);
                        }}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>{suggestion.name}</Typography>
                        <small style={{ opacity: "0.77" }}>
                          {suggestion.geometry.formattedAddress}
                        </small>
                      </ListItem>
                    ))}
                </List>
              )}
          </Box>
          <Box
            marginTop={1}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            flexWrap={"wrap"}
          >
            <Box flex={"inline-flex"} ml={2} mt={3}>
              <Checkbox
                checked={viewModel.getIsCompanyOpen()}
                onChange={viewModel.handleCheckboxOpenChange}
                color="secondary"
              />
              <small>Only openned companies</small>
            </Box>

            <Box flex={"inline-flex"} ml={2} mt={3}>
              <Checkbox
                checked={viewModel.getIsCompanyReacheble()}
                onChange={viewModel.handleCheckboxReachebleOpenChange}
                color="secondary"
              />
              <small>Only recheable companies</small>
            </Box>

            <Box flex={"inline-flex"} ml={2} mt={3}>
              <Checkbox
                checked={viewModel.getIsCompanyConfirmedPrices()}
                onChange={viewModel.handleCheckboxConfirmedPricesOpenChange}
                color="secondary"
              />
              <small>Only confirmed prices</small>
            </Box>

            <Box flex={"inline-flex"} ml={2} mt={3}>
              <Checkbox
                checked={viewModel.isSmsEnabled}
                onChange={() =>
                  viewModel.setIsSmsEnabled(!viewModel.isSmsEnabled)
                }
                color="secondary"
              />
              <small>Sms Enabled</small>
            </Box>

            <Box flex={"inline-flex"} ml={2} mt={3}>
              <Checkbox
                checked={viewModel.isEmailEnabled}
                onChange={() =>
                  viewModel.setIsEmailEnabled(!viewModel.isEmailEnabled)
                }
                color="secondary"
              />
              <small>Has e-mail</small>
            </Box>
          </Box>
        </Box>

        <Box style={styles.headerLeftBox}>
          {/* <RadiusCheckbox
            options={REQUEST_RADIUS_CHECKBOX_DATE_OPTIONS}
            selected={viewModel.getSelectedDate()}
            handleRadiusChange={(e: any) => viewModel.handleRadiusChange(e)}
          /> */}
          <Box sx={styles.flexColumn} style={{ position: "relative" }}>
            <GenericInput
              width={"359px"}
              text
              title={"Search address"}
              name={"destinationLocation"}
              viewModel={viewModel}
              value={viewModel.newService.destinationLocation.address}
              type="add service"
              onBlur={async () => {
                await new Promise((resolve) => setTimeout(resolve, 2000));
                viewModel.handleFocusAddressSearch(false);
              }}
              onFocus={() => {
                viewModel.handleFocusAddressSearch(true);
              }}
            />

            {viewModel.dropSuggestions.length > 0 &&
              viewModel.getIsSearchAddressFocused() && (
                <List sx={styles.listSmaller}>
                  {viewModel.dropSuggestions.map(
                    (suggestion: any, i: number) => (
                      <ListItem
                        key={i + "suggestion2"}
                        // p={2}
                        onClick={() => {
                          const lat = suggestion.location[1];
                          const lng = suggestion.location[0];

                          viewModel.centerMapOnLocation(lat, lng, 16);
                          viewModel.handleClickSearch();

                          let event = {
                            lngLat: {
                              lng: suggestion.location[0],
                              lat: suggestion.location[1],
                            },
                          };

                          viewModel.setDropSuggestionsEmpty(suggestion.label);
                          viewModel.handleMapClick(event);
                          viewModel.handleSearchChangeString("");
                        }}
                      >
                        <Typography>{suggestion.label}</Typography>
                      </ListItem>
                    )
                  )}
                </List>
              )}
          </Box>
          <Box
            style={styles.addServiceBox}
            onClick={viewModel.handleAddCompany}
          >
            <AddCircleOutlineOutlined sx={{ color: "#FF7C06" }} />
            <Typography sx={{ color: "#FF7C06" }}>Add company</Typography>
          </Box>

          <Box
            mt="14px"
            style={styles.addServiceBox}
            onClick={viewModel.handleAddTag}
          >
            <AddCircleOutlineOutlined sx={{ color: "#FF7C06" }} />
            <Typography sx={{ color: "#FF7C06" }}>Add Tag</Typography>
          </Box>

          <AddCompanyDialog
            open={viewModel.getAddService()}
            title="Add company"
            onClose={viewModel.handleCloseAddCompany}
          />
          <TagDialog
            open={viewModel.getAddTag()}
            onClose={viewModel.handleCloseAddTag}
          />
        </Box>
      </Box>

      {/* MAP */}
      <Box mt="20px" mb="20px" height="600px">
        <StaticMapCompanies mapHeight={600} viewModel={viewModel} />
      </Box>

      {/* TABLE */}
      <Box mt="40px" minHeight={"300px"} height="auto">
        <DataGrid
          autoHeight
          rowHeight={60}
          disableExtendRowFullWidth
          getRowId={(row) => row._id}
          rows={viewModel.getData() || []}
          columns={columns}
          hideFooter
          pageSize={viewModel.getPageSize()}
          sx={viewModel.dataGridStyle()}
        />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
          marginBottom={2}
        >
          <Pagination
            count={viewModel.getTotalPages()}
            page={viewModel.getPage()}
            onChange={(event, newPage) => viewModel.handlePageChange(newPage)}
            size="large"
            disabled={!viewModel.getData() || viewModel.getData().length === 0}
          />

          <Box
            marginLeft={2}
            marginBottom={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <label>Items per page</label>
            <Select
              style={styles.itemsPerPage}
              value={viewModel.getPageSize()}
              onChange={viewModel.handleRowsPerPageChange}
            >
              {viewModel.getPageSizes().map((size) => (
                <MenuItem key={size + "  - size"} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceRequest;
