import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';
import React, { ReactNode } from 'react'

type Props = {
  width?: string;
  text?: boolean;
  phone?: boolean;
  title: string;
  name: string;
  value?: any;
  viewModel: any;
  type:string;
  onChange?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

const GenericInput = ({ width, text, phone, name, title, value, viewModel,onChange,type,onBlur,onFocus }: Props) => {
  const handleChange = (e: any) => {

    if (type === "add service") {
      viewModel.handleChangeAddService(e)
    } 

  }
  return (
    <>
      {text &&
        <TextField
        onFocus={onFocus} onBlur={onBlur}
        sx={{
          width: `${width ? width : '100%'}`,
          '& label.Mui-focused': {
            color: '#FF7C06 !important', // Change the label color when focused
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#FF7C06 !important', // Border color when input is focused
            },
          },
        }}
          label={title}
          variant="outlined"
          margin="normal"
          name={name}
          value={value}
          onChange={handleChange}
        />
      }
    </>
  )
}

export default GenericInput;