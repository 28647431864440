import React, { FC, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Checkbox from "@mui/material/Checkbox";
import PhoneIcon from "@mui/icons-material/Phone";
import { styles } from "./styles";
import { Box, Button, MenuItem, Select, TextField, Typography } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import axios from "axios";
import { getHostPythonAPIBaseUrl } from "config";

interface AutoCallDialogProps {
  viewModel: any;
  mapHeight: number;
  companyMarkers: any[];
}

interface NotifierPayload {
  recipients: string[];
  messages: string[];
  title?: string;
}

const AutoCallDialog: FC<AutoCallDialogProps> = ({
  viewModel,
  mapHeight,
  companyMarkers,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [textContent, setTextContent] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const [selectedVariable, setSelectedVariable] = useState("");
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [checkList, setCheckList] = useState<boolean[]>([]);

  const availableVariables = [
    "name",
    "contact.email",
    "contact.internationalPhoneNumber",
    "geometry.formattedAddress",
    "geometry.city",
    "geometry.state",
    "geometry.country",
  ];
  const handleVariableChange = (event: any) => {
    const selectedVariable = event.target.value;
    setSelectedVariable(selectedVariable);
    const variableText = `{{${selectedVariable}}}`;
    setTextContent((prevContent) => prevContent + variableText);
  };

  const handleContentChange = (event: any) => {
    let value = event.target.value;

    // Check if Enter key is pressed and add line break
    if (event.key === "Enter") {
      value += "\n"; // Add a newline character
    }

    setTextContent(value);
  };

  useEffect(() => {
    let htmlContent = textContent;
    const divOpen =
      '&nbsp;<span style="background: black; padding: 2px 6px; border-radius: 20px; font-size: 10.5px; display: inline-block; height: 20px;">';
    const divClosed = "</span>&nbsp;";
    htmlContent = htmlContent
      .replaceAll("{{", divOpen)
      .replaceAll("}}", divClosed)
      .replace(/\n/g, "<br>");

    setHtmlContent(htmlContent);
  }, [textContent]);
  useEffect(() => {
    const arr1 = Array(companyMarkers.length).fill(false);
    setCheckList(arr1);
  }, [companyMarkers]);

  const handleChangeAutoCallCheckBox = (index: number) => {
    const newArr = [...checkList];
    newArr[index] = !newArr[index];
    setCheckList(newArr);
  };

  const handleChangeSelectAll = () => {
    const nowSelect = !selectedAll;
    setSelectedAll(nowSelect);
    const newArr = Array(checkList.length).fill(nowSelect);
    setCheckList(newArr);
  };

  const handleChangeTab = (index: number) => {
    setCurrentTab(index);
  };

  const replaceVariables = (marker: any, textContent: string): string => {
    let message = textContent;

    availableVariables.forEach((variable) => {
      const value = getValueFromMarker(marker, variable);
      message = message.replaceAll(`{{${variable}}}`, value);
    });

    return message;
  };

  const getValueFromMarker = (marker: any, variable: string): string => {
    const keys = variable.split(".");
    let value = marker;
    for (const key of keys) {
      value = value[key];
      if (value === undefined) {
        return ""; // Return empty string if any key is undefined
      }
    }
    return String(value);
  };

  const handleSubmit = () => {
    let recipients = [];
    let messages = [];

    for (let i = 0; i < checkList.length; i++) {
      const item = checkList[i];
      if (item === true) {
        if (currentTab === 0) {
          recipients.push(
            `+${companyMarkers[i].contact.internationalPhoneNumber}`
          );
        } else {
          if (!companyMarkers[i].contact.email) {
            continue;
          }
          recipients.push(companyMarkers[i].contact.email);

        }

        messages.push(replaceVariables(companyMarkers[i], textContent));
      }
    }

    const requestBody: NotifierPayload = {
      recipients,
      messages,
      title
    };
    if (currentTab === 0) {
      sendSmsTwillio(requestBody);
    } else {
      sendEmailNotification(requestBody);
    }
  };

  const sendSmsTwillio = (requestBody: NotifierPayload) => {
    let url = `${getHostPythonAPIBaseUrl()}/twilio/send/sms/automation`;
    axios
      .post(url, requestBody, {
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        alert("Automation successfully completed");
      })
      .catch((err) => {
        alert(`Error occurred: ${err.message}`);
      });
  };

  const sendEmailNotification = (requestBody: NotifierPayload) => {
    let url = `${getHostPythonAPIBaseUrl()}/api/v1/notification/send/email/automation`;
    axios
      .post(url, requestBody, {
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        alert("Emails successfully sent");
      })
      .catch((err) => {
        alert(`Error occurred: ${err.message}`);
      });
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        style={{ height: "30px", padding: "3px 6px" }}
        color="secondary"
        variant="outlined"
        onClick={handleOpen}
      >
        <small>Automation</small>
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={isOpen}
        onClose={handleClose}
      >
        <Box>
          <Box>
            <DialogTitle style={styles.priceDialogPrice}>
              {"Automation"}
            </DialogTitle>
          </Box>

          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
            }}
          >
            {companyMarkers && companyMarkers.length > 0 && (
              <List
                style={{
                  position: "relative",

                  zIndex: 999,
                  background: "rgba(0, 0, 0, 0.55)", // Use rgba for transparency
                  borderRadius: 16,
                  height: `auto`,
                  width: "100%",
                  paddingTop: 14,
                  paddingLeft: 14,
                  paddingRight: 14,
                  paddingBottom: 14,
                  maxHeight: `auto`, // Set a max height for the list
                  boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.5)", // Shadow effect
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    marginBottom: 16,
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h5">{`Companies (${companyMarkers.length})`}</Typography>
                  <Box
                    display={"flex"}
                    justifyContent={"start"}
                    style={{ width: "100%" }}
                    mb={1}
                    mt={2}
                  >
                    <Checkbox
                      sx={{
                        width: "12px",
                        height: "12px",
                        marginRight: "8px",
                        marginLeft: "0px",
                        marginTop: "8px",
                      }}
                      checked={selectedAll}
                      color="secondary"
                      onChange={handleChangeSelectAll}
                    />
                    <label style={{ marginTop: 6, color: "#fff" }}>
                      Select All
                    </label>
                  </Box>
                </div>
                <hr style={{ opacity: 0.1 }} />
                <div
                  style={{
                    overflowY: "auto",
                    height: `${(mapHeight / 2) * 0.76}px`,
                  }}
                >
                  {companyMarkers &&
                    companyMarkers.length > 0 &&
                    companyMarkers.map((marker: any, index: number) => (
                      <Box
                        display="flex"
                        flexDirection="column"
                        width={`${100}%`}
                        key={JSON.stringify(marker)}
                      >
                        <ListItem>
                          <Box display="flex" width={`${100}%`}>
                            {isOpen && (
                              <Box onClick={(e) => e.stopPropagation()}>
                                <Checkbox
                                  sx={{
                                    width: "12px",
                                    height: "12px",
                                    marginRight: "8px",
                                    marginLeft: "0px",
                                    marginTop: "8px",
                                  }}
                                  color="secondary"
                                  onChange={() => {
                                    handleChangeAutoCallCheckBox(index);
                                  }}
                                  checked={checkList[index]}
                                />
                              </Box>
                            )}
                            <Box
                              display="flex"
                              flexDirection="column"
                              width={`${100}%`}
                            >
                              <Box
                                display="inline-flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography
                                  style={{
                                    color: "var(--dark-white-white-900, #FFF)",
                                    fontFeatureSettings: "'calt' off",
                                    fontFamily: "Inter",
                                    fontSize: "12px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "30px",
                                    letterSpacing: "-0.32px",
                                  }}
                                >
                                  {marker.name}
                                </Typography>
                                <a
                                  href="#"
                                  onClick={() => {
                                    window.location.href = `tel:${marker.phoneNumber}`;
                                  }}
                                >
                                  <PhoneIcon
                                    style={{
                                      color:
                                        "var(--dark-white-white-900, #34A853)",
                                      width: 18,
                                      marginTop: 4,
                                    }}
                                  />{" "}
                                </a>
                              </Box>
                              <Box display="inline-flex" alignItems="center">
                                <Box display="inline-flex" alignItems="center">
                                  <PlaceIcon
                                    style={{
                                      color:
                                        "var(--dark-white-white-900, #7E7E7E)",
                                      width: 16,
                                    }}
                                  />

                                  <small
                                    style={{
                                      color:
                                        "var(--dark-white-white-900, #FFF)",
                                      marginBottom: 2,
                                    }}
                                  >
                                    {marker.distance.toFixed(2)} MI
                                  </small>
                                </Box>

                                <div
                                  style={{
                                    width: 8,
                                    height: 8,
                                    background: "#7E7E7E",
                                    borderRadius: "50%",
                                    marginLeft: 8,
                                    marginRight: 4,
                                    marginBottom: 2,
                                  }}
                                ></div>

                                <Typography
                                  style={{
                                    color: "var(--dark-white-white-900, #FFF)",
                                    fontFeatureSettings: "'calt' off",
                                    fontFamily: "Inter",
                                    fontSize: "11px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "normal",
                                    letterSpacing: "-0.14px",
                                    marginBottom: 2,
                                  }}
                                >
                                  +{marker.contact.internationalPhoneNumber}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </ListItem>
                        <hr style={{ opacity: 0.15 }} />
                      </Box>
                    ))}
                </div>
              </List>
            )}

            <Box
              mt={4}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  padding: "6px",
                  marginBottom: "16px",
                }}
              >
                <Box
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    borderBottom: `3px solid var(--gray-gray-900, ${
                      currentTab === 1 ? "#7E7E7E" : "#FF7C06"
                    })`,
                  }}
                  onClick={() => {
                    handleChangeTab(0);
                  }}
                >
                  <Typography
                    style={{
                      color: `var(--gray-gray-900, ${
                        currentTab === 1 ? "#7E7E7E" : "#FF7C06"
                      })`,
                      textAlign: "center",
                      fontFeatureSettings: "'calt' off",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "22px",
                      letterSpacing: "-0.32px",
                      marginBottom: "8px",
                    }}
                  >
                    SMS
                  </Typography>
                </Box>

                <Box
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    borderBottom: `3px solid var(--gray-gray-900, ${
                      currentTab === 0 ? "#7E7E7E" : "#FF7C06"
                    })`,
                  }}
                  onClick={() => {
                    handleChangeTab(1);
                  }}
                >
                  <Typography
                    style={{
                      color: `var(--gray-gray-900, ${
                        currentTab === 0 ? "#7E7E7E" : "#FF7C06"
                      })`,
                      textAlign: "center",
                      fontFeatureSettings: "'calt' off",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "22px",
                      letterSpacing: "-0.32px",
                      marginBottom: "8px",
                    }}
                  >
                    E-mail
                  </Typography>
                </Box>
              </Box>

              <br />

              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
              {currentTab === 1 && (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "16px",
                  }}
                >
                  <label style={{ color: "#FFF" }} htmlFor="titleInput">
                    Title
                  </label>
                  <input
                    type="text"
                    id="titleInput"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "8px",
                      margin: "8px 0",
                      borderRadius: "4px",
                      border: "1px solid #7E7E7E",
                      backgroundColor: "transparent",
                      color: "white",
                    }}
                  />
                </Box>
              )}
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "16px",
                  }}
                >
                  <label style={{ color: "#FFF" }} htmlFor="TextareaAutosize1">
                    Custom message
                  </label>

                  <Select
                    value={selectedVariable}
                    onChange={handleVariableChange}
                    color="primary"
                    style={{
                      marginBottom: "6px",
                      color: "#7E7E7E",
                      height: "32px",
                      marginTop: "6px",
                      background: "#000",
                    }}
                  >
                    {availableVariables.map((variable: any) => (
                      <MenuItem
                        style={{ color: "white" }}
                        key={variable + "var"}
                        value={variable}
                      >
                        {variable}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <div
                  style={{
                    border: "1px solid #7E7E7E",
                    minHeight: "100px",
                    width: "100%",
                    resize: "none",
                    background: "transparent",
                    marginTop: "6px",
                    position: "relative",
                    borderRadius: 8,
                    padding: 8,
                  }}
                >
                  <textarea
                    id="TextareaAutosize1"
                    style={{
                      width: "100%",
                      height: "100px",
                      resize: "none",
                      background: "transparent",
                      color: "white",
                      caretColor: "white",
                      border: "none",
                      outline: "none",
                      verticalAlign: "top", // Add this line to align text to the top
                    }}
                    rows={3} // Set the number of rows
                    value={textContent}
                    onChange={handleContentChange}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "8px",
                  }}
                >
                  <small>Preview</small>
                  <div>
                    <div>
                      <div
                        style={{
                          minHeight: "100px",
                          padding: "8px",
                          border: "1px solid #7E7E7E",
                          borderRadius: 8,
                          width: "100%",
                          overflowX: "auto",
                          overflowY: "hidden",
                          background: "transparent",
                          color: "white",
                          caretColor: "white",
                          whiteSpace: "pre-wrap",
                        }}
                        dangerouslySetInnerHTML={{ __html: htmlContent }}
                      />
                    </div>
                  </div>
                </div>
              </Box>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button style={styles.btnCancel} onClick={handleClose}>
              <span style={styles.spanCancel}>Cancel</span>
            </Button>

            <Button
              style={styles.btnSave}
              onClick={() => {
                handleSubmit();
              }}
              color="primary"
            >
              <span style={styles.spanSave}>Contact</span>
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default AutoCallDialog;
