import { Avatar, Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import VehicleCard from "components/VehicleCard";
import { DUMMY_INFO_COMPANY_VEHICLES } from "utils/const";

const styles = {
  title: {
    color: "var(--dark-white-white-900, #FFF)",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px" /* 150% */,
    letterSpacing: "-0.4px",
  },
  subtitle: {
    color: "var(--gray-gray-900, #7E7E7E)",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.12px",
  },
  picture: {
    width: 50,
    height: 50,
    borderRadius: "50%",
  },
  tabDefault: {
    width: "25%",
    display: "flex",
    justifyContent: "center",
    borderBottom: "2px solid #fff",
    paddingBottom: 8,
  },
  buttonTab: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "-0.32px",
    color: "#fff",
    margin: "0 auto",
    background: "transparent",
    textDecoration: "none",
    cursor: "pointer",
    width: "100%",
    border: "none",
  },
  value: {
    color: "var(--dark-white-white-900, #FFF)",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "30px" /* 187.5% */,
    letterSpacing: "-0.32px",
  },
  label: {
    color: "var(--gray-gray-900, #c0c0c0)",
    fontFamily: "Inter",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.12px",
  },
};

type Props = {
  open: boolean;
  handleClose: () => void;
  params: any;
  // user: any;
  // vehicles: any;
  // services: any;
  // servicesRequests: any;
};

const DetailsDialog = ({
  open,
  handleClose,
  params
  // user,
  // vehicles,
  // services,
  // servicesRequests
}: Props) => {
  console.log("🚀 ~ params:", params);
  const {
    name,
    email,
    phoneNumber,
    date,
    profileImg,
    // vehicles,
    services,
    serviceRequest
  } = params;

  const vehicles = DUMMY_INFO_COMPANY_VEHICLES;


  const [currentTab, setCurrentTab] = useState("Information");
  const information = [
    { label: "Name", value: name },
    { label: "E-mail", value: email},
    { label: "Phone", value: phoneNumber },
 
    { label: "Created at", value: date },
    // { label: "Gender", value: `${user.gender?user.gender: 'null'}` },
  ];

  const handleTabChange = (value: string) => {
    setCurrentTab(value)
  }

  return (
   <Dialog
      PaperProps={{
        style: {
          background: "#191919",
          padding: "20px",
          minHeight: "618px",
          // minWidth: "783px",
          borderRadius: "8px",
          border: '1px solid rgba(126, 126, 126, 0.60)'
        },
      }}
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <div style={{ display: "flex", alignItems: "center", objectFit: "cover", justifyContent: 'space-between' }}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: "center"}}>
          <img
            src={
              profileImg
              ? profileImg
              : "https://res.cloudinary.com/dtrv9pha8/image/upload/v1692727400/account-profile-icon-2_odt4x2.png"
            }
            style={styles.picture}
            alt="User"
          />
          <DialogTitle>
            <Typography style={styles.title} sx={styles.subtitle}>
              {name}
            </Typography>
            <Typography
              variant="subtitle2"
              style={styles.subtitle}
              sx={styles.subtitle}
            >
              &nbsp;Los Angeles, CA
            </Typography>
          </DialogTitle>
        </div>
        <DialogActions style={{marginBottom: '20px'}}>
          <Button
            variant="text"
            onClick={handleClose}
            sx={{ color: 'gray' }}
          >
            <CloseIcon />
          </Button>
        </DialogActions>
      </div>
      <DialogContent>
        <div style={{ display: "flex", width: "100%" }}>
          <div
            style={{
              ...styles.tabDefault,
              borderBottom: `${
                currentTab === "Information"
                  ? "2px solid #FF7C06"
                  : "2px solid #fff"
              }`,
            }}
          >
            <button
              onClick={() => handleTabChange("Information")}
              style={{
                ...styles.buttonTab,
                color: `${currentTab === "Information" ? "#FF7C06" : "#fff"}`,
              }}
            >
              Information
            </button>
          </div>
          <div
            style={{
              ...styles.tabDefault,
              borderBottom: `${
                currentTab === "Vehicles"
                  ? "2px solid #FF7C06"
                  : "2px solid #fff"
              }`,
            }}
          >
            <button
              onClick={() => handleTabChange("Vehicles")}
              style={{
                ...styles.buttonTab,
                color: `${currentTab === "Vehicles" ? "#FF7C06" : "#fff"}`,
              }}
            >
              Vehicles
            </button>
          </div>
          <div
            style={{
              ...styles.tabDefault,
              borderBottom: `${
                currentTab === "Workers"
                  ? "2px solid #FF7C06"
                  : "2px solid #fff"
              }`,
            }}
          >
            <button
              onClick={() => handleTabChange("Workers")}
              style={{
                ...styles.buttonTab,
                color: `${currentTab === "Workers" ? "#FF7C06" : "#fff"}`,
              }}
            >
              Workers
            </button>
          </div>
          <div
            style={{
              ...styles.tabDefault,
              borderBottom: `${
                currentTab === "Services"
                  ? "2px solid #FF7C06"
                  : "2px solid #fff"
              }`,
            }}
          >
            <button
              onClick={() => handleTabChange("Services")}
              style={{
                ...styles.buttonTab,
                color: `${currentTab === "Services" ? "#FF7C06" : "#fff"}`,
              }}
            >
              Services
            </button>
          </div>
          <div
            style={{
              ...styles.tabDefault,
              borderBottom: `${
                currentTab === "Activity"
                  ? "2px solid #FF7C06"
                  : "2px solid #fff"
              }`,
            }}
          >
            <button
              onClick={() => handleTabChange("Activity")}
              style={{
                ...styles.buttonTab,
                color: `${currentTab === "Activity" ? "#FF7C06" : "#fff"}`,
              }}
            >
              Activity
            </button>
          </div>
          <div
            style={{
              ...styles.tabDefault,
              borderBottom: `${
                currentTab === "Notes"
                  ? "2px solid #FF7C06"
                  : "2px solid #fff"
              }`,
            }}
          >
            <button
              onClick={() => handleTabChange("Notes")}
              style={{
                ...styles.buttonTab,
                color: `${currentTab === "Notes" ? "#FF7C06" : "#fff"}`,
              }}
            >
              Notes
            </button>
          </div>
        </div>

        {currentTab === "Information" && (
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              // m: "auto",
              width: "fit-content",
            }}
          >
            {information.map(({ label, value }, index) => (
              <div
                key={index + 'information.map'}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 16,
                }}
              >
                <Typography style={styles.label}>{label}</Typography>
                <Typography style={styles.value}>{value}</Typography>
              </div>
            ))}
          </Box>
        )}

        {currentTab === "Vehicles" && vehicles && (
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              // m: "auto",
              width: "100%",
            }}
          >
            {vehicles.map((vehicle: any, index: number) => <VehicleCard key={vehicle._id} {...vehicle} worker/>)}
          </Box>
        )}

        {currentTab === "Services" && services && (
          <div style={{ marginTop: 32 }}>
            <Typography variant="subtitle2" style={styles.value}>
              Last Services
            </Typography>
            <table className="service-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Service Type</th>
                  <th>Pick</th>
                  <th>Drop</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {services.map((service: any) => (
                  <tr key={service._id + "service"}>
                    <td>{service.createdAt}</td>
                    <td>{service.serviceType}</td>
                    <td>{service.location.pick}</td>
                    <td>{service.location.drop}</td>
                    <td>{service.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {currentTab === "Requests" && serviceRequest && (
          <div style={{ marginTop: 32 }}>
            <Typography variant="subtitle2" style={styles.value}>
              Service Requests
            </Typography>
            <table className="service-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Service Type</th>
                  <th>Pick</th>
                  
                  <th>Step</th>
                </tr>
              </thead>
              <tbody>
                {serviceRequest.map((request: any) => (
                  
                  <tr key={request._id + 'request._id'}>
                    <td>{request.created}</td>
                    <td>{request.specialty}</td>
                    <td>{request.serviceLocation.address}</td>
                    
                    <td>{request.step}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default DetailsDialog