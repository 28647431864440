
import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import { styles } from './styles'

type Props = {
  text: string,
  disabled: boolean,
  onClick: () => void
}

const Button = ({ text, disabled, onClick }: Props) => {

  const handleClick = () => {
    if (!disabled) onClick()
  }
  return (
    <Box style={disabled ? {...styles.container, backgroundColor: '#7e7e7e', cursor: 'auto'} : styles.container} onClick={handleClick}>
      <Typography style={styles.text}>
        {text}
      </Typography>
    </Box>
  )
}

export default Button