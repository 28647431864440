import React, { useState, useEffect } from "react";
import { GreeterClient } from "./greet/GreetServiceClientPb"; // Adjust path according to your structure
import { HelloRequest } from "./greet/greet_pb"; // Adjust path
import { Box, Button, TextField, Typography } from "@mui/material";
import { getHostPythonGrpcBaseUrl } from "config";

const BiDirectionalStreamingInterface = () => {
  const [name, setName] = useState<string>("");
  const [greeting, setGreeting] = useState<string>("");
  const [messages, setMessages] = useState<string[]>([]);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);

  const client = new GreeterClient(getHostPythonGrpcBaseUrl(), null, null);

  // Function to handle sending messages manually
  const handleSendMessage = async () => {
    const request = new HelloRequest();
    request.setName(name);
    request.setGreeting(greeting);
    try {
      const response = await client.sayHello(request, {}); // Assuming 'sayHello' is used for sending messages
      setMessages((prev) => [...prev, `Server says: ${response.getMessage()}`]);
    } catch (err: any) {
      console.error("Error sending message:", err.message);
    }
  };

  // Function to continuously send requests, simulating streaming
  const sendRepeatedRequests = async () => {
    while (isStreaming) {
      const request = new HelloRequest();
      request.setName(name);
      request.setGreeting(greeting);
      try {
        const response = await client.sayHello(request, {}); // Simulate a stream by sending requests repeatedly
        setMessages((prev) => [
          ...prev,
          `Server says: ${response.getMessage()}`,
        ]);
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Delay next request
      } catch (err: any) {
        console.error("Error with streaming:", err.message);
        setIsStreaming(false); // Stop streaming on error
      }
    }
  };

  // Start or stop the simulated streaming
  const toggleStreaming = () => {
    setIsStreaming(!isStreaming);
    if (!isStreaming) {
      sendRepeatedRequests();
    }
  };

  useEffect(() => {
    // Cleanup on component unmount
    return () => {
      setIsStreaming(false);
    };
  }, []);

  return (
    <Box display="flex" flexDirection="column" mb={4}>
      <Typography mb={2} variant="h3">
        Simulated Bi-Directional gRPC Streaming Test
      </Typography>
      <Box display="flex"  mb={2}>
        <TextField
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Name"
          variant="filled"
        />
        &nbsp;&nbsp;
        <TextField
          type="text"
          value={greeting}
          onChange={(e) => setGreeting(e.target.value)}
          label="Greeting"
          variant="filled"
        />
        &nbsp;&nbsp;
        <Button
          style={{ textTransform: "none", width: "124px" }}
          color="secondary"
          variant="outlined"
          onClick={toggleStreaming}
        >
          {isStreaming ? "Stop Streaming & Summarize" : "Start Streaming"}
        </Button>
        &nbsp;
        {isStreaming && (
          <Button
            style={{
              background: "#FF7C06",
              textTransform: "none",
              paddingRight: "16px",
              paddingLeft: "16px",
              width: "124px",
            }}
            onClick={handleSendMessage}
          >
            Send Greeting
          </Button>
        )}
      </Box>
      {messages.length > 0 && (
        <div>
          <b>Messages from server:</b>
          <br />
          {messages.map((msg, index) => (
            <p key={index}>{msg}</p>
          ))}
        </div>
      )}
    </Box>
  );
};

export default BiDirectionalStreamingInterface;
