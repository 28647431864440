import React, { useRef, useEffect, useState } from "react";
import MapBox, { MapRef, Marker } from "react-map-gl";
import "./Map.css";
import onlineCar from "../../assets/icons/online_car.png";
import inactiveCar from "../../assets/icons/inactive_car.png";
import routeCar from "../../assets/icons/on_route_car.png";
import clientCar from "../../assets/icons/profileImgGeneric.svg";
import { useLocation } from "react-router-dom";
import { webSocketConnection } from "./AuxiliarComponents/webSocketConnection";
import LocationRequests from "requests/locationRequests";
import { getMapboxToken, getMapboxStyle } from "config";
// const { MAP_BOX_STYLE } = require("../../config/env");
// const { MAP_BOX_TOKEN } = require("../../config/env");
const locationRequest = new LocationRequests();

// region types

type Marker = {
  id: string;
  latitude: number;
  longitude: number;
  type: string;
  name: string;
  isClient: boolean | "";
};


interface Location {
  latitude: number;
  longitude: number;
  zoom: number;
}

type Session = {
  ID?: string;
  Email?: string;
  Approved?: boolean;
  Password?: string;
  Token?: string;
  FullName?: string;
  CreatedAt?: string;
};

export type MarkerWs = {
  location: string;
  userId: string;
  status: string;
  isClient: boolean;
  name: string;
};

interface Props {
  onMarkersChange: (markers: Marker[]) => void;
  // Other props here
  mapHeight?: string
}

const Map = ({ onMarkersChange, mapHeight }: Props) => {
  const height: string = mapHeight ? mapHeight : "75vh";
  const marginTop: string = "3vh";
  const [viewPort, setViewPort] = useState<Location>({
    latitude: 34.053691,
    longitude: -118.242766,
    zoom: 8,
  });
  const [isUserLocationAccepted, setIsUserLocationAccepted] = useState(false);
  // Coloque mapRef no início da função do componente
  const mapRef = useRef<MapRef>(null);
  const session: string | null = localStorage.getItem("session");
  const sessionData: Session | null = session ? JSON.parse(session) : null;
  const userId = sessionData?.ID;
  const location = useLocation();
  const [markers, setMarkers] = useState<Marker[]>([]);

  const markerLocationCompany = (data: any) => {
    try {
      data = data.message
      console.log(`markerLocationCompany() ${data.name}`, data);
      console.log(`Location ----------------> ${data.location}`);
      let latitude: any = 0; // Initialize latitude
      let longitude: any = 0; // Initialize longitude

      if (typeof data.location === "string") {
        const [latStr, lonStr] = data.location.split(" ");
        latitude = parseFloat(latStr);
        longitude = parseFloat(lonStr);
      } else {
        latitude = data.location.coordinates.latitude;
        longitude = data.location.coordinates.longitude;
      }

      let marker: Marker = {
        id: data.userId,
        latitude:latitude,
        longitude: longitude,
        type: data.status,
        isClient: data.isClient,
        name: data.name,
      };
      setMarkers((prevMarkers) => {
        const updatedMarkers = [...prevMarkers];
        const markerIndex = updatedMarkers.findIndex((m) => m.id === marker.id);
        if (markerIndex !== -1) {
          // @ts-ignore
          updatedMarkers[markerIndex] = marker;
          console.log("Marker already exists, updating...");
        } else {
          updatedMarkers.push(marker);
          console.log("Marker doesn't exist, adding...");
        }
        console.log("latitude", latitude)
        console.log("longitude", longitude)
        return updatedMarkers;
      });
    } catch {
      return;
    }
  };

  const markerLocationCustomer = (data: any) => {
    try {
      console.log("markerLocationCustomer() ", data);
      console.log(`Location ----------------> ${data.location}`);
      console.log("data", data);
      console.log("typeof data", typeof data);


      let latitude: any = 0; // Initialize latitude
      let longitude: any = 0; // Initialize longitude
      if (typeof data.location === "string") {
        const [latStr, lonStr] = data.location.split(" ");
        latitude = parseFloat(latStr);
        longitude = parseFloat(lonStr);
      } else {


        latitude = data.location.coordinates.latitude;
        longitude = data.location.coordinates.longitude;
        
      }
      console.log("latitude", latitude)
      console.log("longitude", longitude)
      let marker: Marker = {
        id: data.userId,
        latitude: latitude,
        longitude: longitude,
        type: "client",
        isClient: data.isClient,
        name: data.name,
      };
      console.log("marker", marker);
      setMarkers((prevMarkers) => {
        const updatedMarkers = [...prevMarkers];
        const markerIndex = updatedMarkers.findIndex((m) => m.id === marker.id);
        if (markerIndex !== -1) {
          // @ts-ignore
          updatedMarkers[markerIndex] = marker;
          console.log("Marker already exists, updating...");
        } else {
          updatedMarkers.push(marker);
          console.log("Marker doesn't exist, adding...");
        }

        return updatedMarkers;
      });
    } catch {
      return;
    }
  };

  // region useEffects
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setViewPort({ latitude, longitude, zoom: 10 });
          setIsUserLocationAccepted(true);
        },
        (error) => {
          console.error("Erro ao obter a localização:", error.message);
        }
      );
    } else {
      console.error("Seu navegador não suporta Geolocalização.");
    }
  }, []);

  useEffect(() => {
    const delayedConnect = setTimeout(() => {
      
      webSocketConnection(
        markerLocationCustomer,
        markerLocationCompany,
        "specialist_tracking_channel*",
       
      );
    }, 1000); // Adicionando um atraso de 1 segundo (1000 milissegundos)

    return () => clearTimeout(delayedConnect); // Limpa o timeout se o componente for desmontado antes do atraso terminar
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      onMarkersChange(markers);
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => {
      clearInterval(interval);
    };
  }, [markers, onMarkersChange]);

  // endregion

  const getAddressOnClick = async (marker: Marker) => {
    const address = await locationRequest.getAddress(
      marker.latitude,
      marker.longitude
    );
    alert(
      `Name: ${marker.name}\n
    UserId: ${marker.id}\n
    Status: ${marker.type}\n
    Address: ${address.display_name}`
    );
  };

  // region MapBox
  return (
    isUserLocationAccepted && (
      <MapBox
        ref={mapRef}
        initialViewState={viewPort}
        style={{
          width: "auto",
          height: height,
          borderRadius: "10px",
          marginTop: marginTop,
        }}
        mapStyle={getMapboxStyle()}
        // transitionDuration="200"
        mapboxAccessToken={getMapboxToken()}
        // onViewportChange={(viewPort) => setViewPort(viewPort)}
        attributionControl={false}
      >
        {markers.map((marker) => {
          
          return (
            <Marker
              onClick={() => getAddressOnClick(marker)}
              key={marker.id}
              latitude={marker.latitude}
              longitude={marker.longitude}
            >
              {marker.type === "online" && (
                <img
                  style={{ width: 24, height: 24 }}
                  src={onlineCar}
                  alt="Marker"
                />
              )}
              {marker.type === "inactive" && (
                <img
                  style={{ width: 24, height: 24 }}
                  src={inactiveCar}
                  alt="Marker"
                />
              )}
              {marker.type === "busy" && (
                <img
                  style={{ width: 24, height: 24 }}
                  src={routeCar}
                  alt="Marker"
                />
              )}
              {marker.type === "route" && (
                <img
                  style={{ width: 24, height: 24 }}
                  src={routeCar}
                  alt="Marker"
                />
              )}
              {marker.type === "client" && (
                <div
                  style={{
                    width: 22,
                    height: 22,
                    borderRadius: "50%",
                    background: "#fff",
                  }}
                >
                  <img
                    style={{ width: 22, height: "auto" }}
                    src={clientCar}
                    alt="Marker"
                  />
                </div>
              )}
            </Marker>
          );
        })}
      </MapBox>
    )
  );
  // endregion
};

export default Map;
