const name = ["wash", "roadside", "premiumGasoline"];
const service = {
    wash: ["carWash", "motorcycleWash"],
    roadside: ["battery", "refuel", "tireChange", "unlock", "towing"],
};
const variant = {
    carWash: ["basic", "standard", "deluxe", "premium"],
    motorcycleWash: ["basic", "standard", "deluxe", "premium"],
    battery: ["jump", "replacement"],
    refuel: [
        "regularGasoline",
        "midgradeGasoline",
        "premiumGasoline",
        "diesel",
    ],
    tireChange: ["spareTire", "newTire"],
    unlock: ["lockedOut", "lostKeys", "jammedLocks"],
    towing: ["flatbed", "heavy", "standard"],
};
export { name, service, variant };
