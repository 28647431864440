import { ChangeEvent } from "react";
import { Search } from "@mui/icons-material";
import { IconButton, InputBase } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import React from "react";

type Props = {
  inputCallback: (e: ChangeEvent<HTMLInputElement>) => void;
  buttonCallback?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  width: string | undefined;
  fetch?: () => void; // This indicates fetch prop is required
};

const SearchBar = ({
  inputCallback,
  buttonCallback,
  placeholder,
  width,
  onBlur,
  onFocus,
  fetch,
}: Props) => {
  return (
    <FlexBetween
      borderRadius="9px"
      gap="3rem"
      p="0.1rem 1.5rem"
      sx={{
        border: "1px solid #3E3E3E",
        width: width,
      }}
    >
      <InputBase
        fullWidth
        placeholder={!placeholder ? "Search..." : placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={inputCallback}
        id="search-input"
      />

      <IconButton
        onClick={buttonCallback}
      >
        <Search sx={{ color: "#3E3E3E" }} />
      </IconButton>
    </FlexBetween>
  );
};

export default SearchBar;
