import { useEffect, useState } from "react";
import UserRequests from "../../requests/userRequests";
import { MaiaIAViewModel } from "view_models/maiaViewModel";
import ChatBotRequests from "requests/chatBotRequests";

const useMaiaAIViewModel = (): MaiaIAViewModel => {
  // Atributes
  const [chatBotRequests, setChatBotRequests] = useState(new ChatBotRequests());
  const [userRequests, setUserRequests] = useState(new UserRequests());
  const [vectorStoreList, setVectorStoreList] = useState<any[]>([]);
  const [currentMaiaItem, setCurrentMaiaItem] = useState<any>(null);
  const [isEditMaiaItem, setIsEditMaiaItem] = useState(false);
  const [vectorStoreFilesList, setVectorStoreFilesList] = useState<any[]>([]);
  const [maiaBrainList, setMaiaBrainList] = useState<any[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [breadcrumb, setBreadcrumb] = useState<any>({
    currentLevel: "Vector Store",
    subcomponents: ["Vector Store", "Files"],
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [pageSizes, setPageSizes] = useState([8, 16, 32, 64]);
  const [totalPages, setTotalPages] = useState(0);
  const [addVectorStoreDialogOpen, setAddVectorStoreDialogOpen] =
    useState(false);

  const [addEditMaisBrainDialogOpen, setAddEditMaisBrainDialogOpen] =
    useState(false);
  const [uploadFileDialogOpen, setUploadFileDialogOpen] = useState(false);
  const [addFileVectorStoreDialogOpen, setAddFileVectorStoreDialogOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [userStatus, setUserStatus] = useState({});
  const [selectOption, setSelectOption] = useState("Vector Store");
  const [rowId, setRowId] = useState(null);
  const [selectedVectorStoreId, setSelectedVectorStoreId] = useState("");
  const [selectedMaiaBrainId, setSelectedMaiaBrainId] = useState("");
  const [selectedFileId, setSelectedFileId] = useState("");
  const [isDialogItemAddOpen, setIsDialogItemAddOpen] =
    useState<boolean>(false);
  const [newVectorStoreFormData, setnewVectorStoreFormData] = useState<any>({
    name: "",
  });

  const fetchVectorStore = async () => {
    try {
      const vectorStoresResponse = await chatBotRequests.listVectoreStores();
      console.log(vectorStoresResponse);
      setVectorStoreList(vectorStoresResponse.payload.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchVectorStoreFiles = async (id: string) => {
    try {
      const vectorStoresResponse = await chatBotRequests.listVectorStoreFiles(
        id
      );
      setVectorStoreFilesList(vectorStoresResponse.payload.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMaiaBrainItems = async () => {
    try {
      const maiaBrainResponse = await chatBotRequests.listMaiaBrainItems(
        page,
        pageSize
      );
      setMaiaBrainList(maiaBrainResponse.maia_brain);
      setTotalPages(maiaBrainResponse.totalPages);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchFiles = async () => {
    try {
      const listFiles = await chatBotRequests.listFiles();
      setFileList(listFiles.payload.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchVectorStore();
    fetchFiles();
  }, []);

  useEffect(() => {
    if (selectOption == "Files") {
      fetchFiles();
    }
  }, [selectOption]);

  useEffect(() => {
    if (selectOption == "Maia Brain") {
      fetchMaiaBrainItems();
    }
  }, [selectOption, page]);

  const handleOpenDeleteVectoreStore = async (id: string) => {
    setSelectedVectorStoreId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleOpenDeleteFile = async (id: string) => {
    setSelectedFileId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleOpenDeleteMaiaBrainItem = async (id: string) => {
    setSelectedMaiaBrainId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleSelectVectorStore = async (id: string) => {
    console.log("Selected id -> " + id);
    setSelectedVectorStoreId(id);
    setBreadcrumb({
      currentLevel: "Files",
      subcomponents: ["Vector Store", "Files"],
    });

    fetchVectorStoreFiles(id);
  };

  const deleteData = async (id: string) => {
    try {
      if (selectOption == "Files") {
        await chatBotRequests.deleteFile(id);
        removeFromList(setFileList, fileList, id);
      } else if (selectOption === "Maia Brain") {
        await chatBotRequests.deleteMaiaBrain(id);
        removeFromList(setVectorStoreList, maiaBrainList, id);
      } else if (breadcrumb.currentLevel === "Files") {
        await chatBotRequests.disassociateFileFromVectorStore(
          selectedVectorStoreId,
          id
        );
        removeFromList(setVectorStoreFilesList, vectorStoreFilesList, id);
      } else if (breadcrumb.currentLevel === "Vector Store") {
        await chatBotRequests.deleteVectorStore(id);
        removeFromList(setVectorStoreList, vectorStoreList, id);
      }
      setIsDeleteDialogOpen(false);
    } catch (err) {
      console.error("Error on delete:", err);
      alert("Failed delete data. Please try again later.");
    }
  };

  const handleEditMaiaItem = (id: string) => {
    setIsEditMaiaItem(true);
    setSelectedMaiaBrainId(id);
    setAddEditMaisBrainDialogOpen(true);

    for (let i = 0; i < maiaBrainList.length; i++) {
      const element = maiaBrainList[i];

      if (element._id === id) {
        setCurrentMaiaItem(element);
        setIsEditMaiaItem(true);
        break;
      }
    }
  };

  const handleFetchData = async () => {
    try {
      if (selectOption == "Files") {
        fetchFiles();
      } else if (selectOption == "Maia Brain") {
        fetchMaiaBrainItems();
      } else if (breadcrumb.currentLevel === "Files") {
        fetchVectorStoreFiles(selectedVectorStoreId);
      } else if (breadcrumb.currentLevel === "Vector Store") {
        fetchVectorStore();
      }
    } catch (err) {
      console.error("Error on delete:", err);
      alert("Failed delete data. Please try again later.");
    }
  };

  const removeFromList = <T extends { id: string }>(
    setFn: (value: T[]) => void,
    list: T[],
    id: string
  ) => {
    const updatedList = list.filter((item) => item.id !== id);
    setFn(updatedList);
  };

  return {
    vectorStoreList,
    vectorStoreFilesList,
    isLoading,
    rowId,
    chatBotRequests,
    handleFetchData,
    setVectorStoreList,
    setIsLoading,
    setRowId,
    setChatBotRequests,
    setUserRequests,
    setUserStatus,
    userRequests,
    userStatus,
    isDialogItemAddOpen,
    setIsDialogItemAddOpen,
    handleSelectVectorStore,
    handleOpenDeleteVectoreStore,
    handleOpenDeleteFile,
    handleOpenDeleteMaiaBrainItem,
    breadcrumb,
    setBreadcrumb,
    addVectorStoreDialogOpen,
    setAddVectorStoreDialogOpen,
    uploadFileDialogOpen,
    setUploadFileDialogOpen,
    addFileVectorStoreDialogOpen,
    setAddFileVectorStoreDialogOpen,
    selectedVectorStoreId,
    totalPages,
    setTotalPages,
    setFileList,
    fileList,
    isDeleteDialogOpen,
    setIsDeleteDialogOpen,
    deleteData,
    selectOption,
    setSelectOption,
    setPage,
    setPageSize,
    setPageSizes,
    pageSizes,
    pageSize,
    page,
    maiaBrainList,
    setMaiaBrainList,
    selectedMaiaBrainId,
    setSelectedFileId,
    selectedFileId,
    setAddEditMaisBrainDialogOpen,
    addEditMaisBrainDialogOpen,
    handleEditMaiaItem,
    setIsEditMaiaItem,
    isEditMaiaItem,
    currentMaiaItem,
    setCurrentMaiaItem,
  };
};

export default useMaiaAIViewModel;
